/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  ISwapRouterUni,
  ISwapRouterUniInterface,
} from "../../../../contracts/helper/ExternalContractsUni.sol/ISwapRouterUni";

const _abi = [
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "tokenIn",
            type: "address",
          },
          {
            internalType: "address",
            name: "tokenOut",
            type: "address",
          },
          {
            internalType: "uint24",
            name: "fee",
            type: "uint24",
          },
          {
            internalType: "address",
            name: "recipient",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amountIn",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "amountOutMinimum",
            type: "uint256",
          },
          {
            internalType: "uint160",
            name: "sqrtPriceLimitX96",
            type: "uint160",
          },
        ],
        internalType: "struct ISwapRouterUni.ExactInputSingleParams",
        name: "params",
        type: "tuple",
      },
    ],
    name: "exactInputSingle",
    outputs: [
      {
        internalType: "uint256",
        name: "amountOut",
        type: "uint256",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
] as const;

export class ISwapRouterUni__factory {
  static readonly abi = _abi;
  static createInterface(): ISwapRouterUniInterface {
    return new utils.Interface(_abi) as ISwapRouterUniInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ISwapRouterUni {
    return new Contract(address, _abi, signerOrProvider) as ISwapRouterUni;
  }
}
