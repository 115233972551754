import React from 'react';
import { Route } from 'react-router-dom';

import { IssueHomePage } from './pages/IssueHomePage';
import { RedeemHomePage } from './pages/RedeemHomePage';
import { DashboardHomePage } from './pages/DashboardHomePage';
import { Activated } from '@backed-fi/shared/components/compound';

export const HomePages = (
  <React.Fragment>
    <Route
      path="dashboard"
      element={
        <DashboardHomePage />
      }
    />

    <Route
      path="redeem"
      element={
        <Activated>
          <RedeemHomePage />
        </Activated>
      }
    />
    <Route
      path="issue"
      element={
        <Activated>
          <IssueHomePage />
        </Activated>
      }
    />
  </React.Fragment>
);
