import { useToggle } from '@backed-fi/shared/hooks/useToggle';

const DarkModeLocalStorageKey = 'BackedFi.DarkMode';

export const useDarkMode = () => {
  const darkModeToggle = useToggle(localStorage.getItem(DarkModeLocalStorageKey) === 'true');

  return {
    toggle: () => darkModeToggle.setOpen((prev) => {
      const newValue = !prev;

      localStorage.setItem(DarkModeLocalStorageKey, String(newValue));

      return newValue;
    }),
    enabled: darkModeToggle.open
  };
};
