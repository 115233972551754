import React from 'react';

import { styled } from '@mui/material/styles';
import { Link as ReactLink } from 'react-router-dom';
import { Box, LinearProgress, Link, Typography } from '@mui/material';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';

import { useUsageContext } from '@backed-fi/context';
import { CentimesFormatter } from '@backed-fi/shared';
import { UsageLimitTier } from '@backed-fi/graphql';

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 8,
  borderRadius: 4,
  marginTop: '0.5rem',

  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#f2f4f8'
  },

  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: '#0066ff'
  }
}));

export const AccountUsageTile: React.FC = () => {
  const {
    usage,
    limits,
    loading
  } = useUsageContext();

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography sx={{ fontSize: 14 }}>
          Account {limits.tier !== UsageLimitTier.Unlimited ? 'Limits' : 'Usage'}
        </Typography>

        <Link
          component={ReactLink}
          to="/settings/account"
          sx={{
            display: 'flex',
            gap: '0.25rem',
            color: '#0066ff',
            alignItems: 'center',
            textDecoration: 'none'
          }}
        >
          <SyncAltOutlinedIcon fontSize="small" />

          <Typography component="span" sx={{ fontSize: 12 }}>
            Transaction Fees
          </Typography>
        </Link>
      </Box>

      <Box>
        <Typography
          sx={{
            fontSize: 12,
            lineHeight: 1.75
          }}
        >
          <Box
            sx={{ color: '#848a98' }}
            component="span"
          >
            Daily Limit {limits.tier !== UsageLimitTier.Unlimited ? 'Limits' : 'Usage'}
          </Box>

          <br />
          {CentimesFormatter.format(usage.daily.toNumber())}

          {limits.tier !== UsageLimitTier.Unlimited && (
            <React.Fragment>
              {' '}of{' '}
              {CentimesFormatter.format(limits.dailyTransactionLimit)}
              {' '}
              ({Math.round(usage.dailyPct.toNumber())}%)
            </React.Fragment>
          )}
        </Typography>

        {limits.tier !== UsageLimitTier.Unlimited && (
          <BorderLinearProgress
            value={usage.daily.toNumber()}
            variant="determinate"
          />
        )}
      </Box>

      <Box>
        <Typography
          sx={{
            fontSize: 12,
            lineHeight: 1.75
          }}
        >
          <Box
            sx={{ color: '#848a98' }}
            component="span"
          >
            Monthly {limits.tier !== UsageLimitTier.Unlimited ? 'Limits' : 'Usage'}
          </Box>

          <br />
          {CentimesFormatter.format(usage.monthly.toNumber())}

          {limits.tier !== UsageLimitTier.Unlimited && (
            <React.Fragment>
              {' '}of{' '}
              {CentimesFormatter.format(limits.monthlyTransactionLimit)}
              {' '}
              ({Math.round(usage.monthlyPct.toNumber())}%)
            </React.Fragment>
          )}
        </Typography>

        {limits.tier !== UsageLimitTier.Unlimited && (
          <BorderLinearProgress
            value={usage.monthlyPct.toNumber()}
            variant="determinate"
          />
        )}
      </Box>
    </Box>
  );
};
