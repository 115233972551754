import React from 'react';

export const useClickPosition = () => {
  const [position, setPosition] = React.useState<{
    positionX?: number;
    positionY?: number;
  }>({});


  return {
    position,
    clearPosition: () => {
      setPosition({});
    },
    setPosition: (e: React.MouseEvent) => {
      e.preventDefault();

      setPosition({
        positionX: e.clientX,
        positionY: e.clientY
      });
    }
  };
};
