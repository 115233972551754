/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  ITypeAndVersion,
  ITypeAndVersionInterface,
} from "../../../../../../../@chainlink/contracts-ccip/src/v0.8/shared/interfaces/ITypeAndVersion";

const _abi = [
  {
    inputs: [],
    name: "typeAndVersion",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
] as const;

export class ITypeAndVersion__factory {
  static readonly abi = _abi;
  static createInterface(): ITypeAndVersionInterface {
    return new utils.Interface(_abi) as ITypeAndVersionInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ITypeAndVersion {
    return new Contract(address, _abi, signerOrProvider) as ITypeAndVersion;
  }
}
