import React, { ReactNode } from 'react';
import { SxProps, Theme, Typography } from '@mui/material';

export interface ParagraphBlock {
  content: string | ReactNode;
  margin?: boolean;
  footnote?: boolean;
  sx?: SxProps<Theme>;
}

export const OnboardingParagraphBlock: React.FC<ParagraphBlock> = ({ content, margin, footnote, sx }) => {
  return (
    <Typography
      variant="body"
      sx={{
        fontSize: '14px',
        color: 'utility.textLowContrast',

        ...(margin && ({
          my: '.75rem'
        })),

        ...(footnote && ({
          ...(margin && ({
            mt: '3rem',
            pt: '.5rem',
            borderTop: '1px solid',
            borderColor: 'utility.borderElement'
          })),

          whiteSpace: 'pre-wrap',
          fontSize: '12px',
          color: 'utility.textHighContrast'
        })),
        ...sx
      }}
    >
      {content}
    </Typography>
  );
};
