import React from 'react';
import { Box, SxProps, Theme } from '@mui/material';

interface Props {
  variant?: 'outline' | 'soft';
  sx?: SxProps<Theme>;
}

export const ContentCard: React.FC<React.PropsWithChildren<Props>> = ({ children, variant, sx }) => {
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: '0.5rem',

        ...(!variant && {
          boxShadow: '0 0.125rem 0.25rem #e2e5eb',
        }),

        ...(variant === 'outline' && {
          border: 1,
          borderColor: '#edeff4'
        }),

        ...(variant === 'soft' && {
          backgroundColor: '#f2f4f8'
        }),

        ...sx
      }}
    >
      {children}
    </Box>
  )
}
