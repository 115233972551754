/* eslint-disable max-len */
import { IOnboardingStep } from './onboarding-step-interface';
import { UsStatment } from './us-statment';

export const Annex2: IOnboardingStep = {
  id: 'annex-2',
  name: 'Annex 2: Opting Out Declaration',
  shouldRenderStep: (data) => {
    return !!data['professional-investor']?.['default']?.includes('is-professional')
      && (
        // And they reside in the EEA
        !!data['eea-residency']?.['in-the-eea']?.includes('within-eea')
        // Or are EEA resident
        || !!(data['residency']?.['default']?.includes('resident-with-residency-permit')));
  },
  blocks: () => [
    {
      type: 'title',
      title: 'Annex 2: Opting Out Declaration'
    },
    {
      type: 'paragraph',
      content: 'I/we ',
      margin: true
    }, {
      type: 'textInput',
      label: 'Name',
      valueKey: 'name'
    }, {
      type: 'textInput',
      label: 'Address',
      valueKey: 'address'
    }, {
      type: 'textInput',
      label: 'Country',
      valueKey: 'country'
    },
    {
      type: 'paragraph',
      content:
        'the undersigned prospective purchaser(s) \n' +
        '(Purchaser) hereby declare and confirm towards Backed Assets (JE) Limited (Issuer) that I/we fulfil at least \n' +
        'two of the following criteria:'
    }, {
      type: 'checkbox',
      valueKey: 'default',
      multiSelect: true,
      options: [
        {
          value: '1',
          content:
            'I/we have carried out transactions, in significant size, on the relevant market at an average \n' +
            'frequency of 10 per quarter over the previous four quarters;'
        }, {
          value: '2',
          content:
            'the size of my/our financial instrument portfolio, defined as including cash deposits and financial \n' +
            'instruments⁴ exceeds EUR 500,000;'
        }, {
          value: '3',
          content:
            'I/we work or have worked in the financial sector for at least one year in a professional position, \n' +
            'which requires knowledge of the transactions or services envisaged.'
        }
      ]
    }, {
      type: 'paragraph',
      content:
        'I/we, hereby declare and confirm towards the Issuer that I/we possess the experience, knowledge and \n' +
        'expertise to make my/our own investment decisions and properly assess the risks involved in investing in \n' +
        'the relevant Product. '
    }, {
      type: 'paragraph',
      content:
        'By signing this Opting Out Declaration (Declaration), I/we explicitly declare that I/we wish to be treated as\n' +
        'a Professional Client in terms of Section II of Annex II of MiFID II and undertake (i) to deliver all \n' +
        'documentation and information required by the Issuer with regard to this Declaration (in particular \n' +
        'documentation supporting the criteria above), and (ii) to immediately inform the Issuer, should I/we cease \n' +
        'to fulfil the conditions above.'
    }, {
      type: 'paragraph',
      content:
        'I/we hereby confirm that I/we have read and understood the relevant Product Documentation including the mandatory\n' +
        'risk warnings that even though I/we have been warned that I/we may request non-professional treatment, I/we have still\n' +
        'opted to be treated as a Professional Client.  I/we understand that by opting to be treated as a Professional Client,\n' +
        'I/we am/are subject to a lower level of client protection under MiFID II when compared to a retail client.'
    }, {
      type: 'paragraph',
      content:
        'I/we have provided my/our CV/short bio (for individuals) / the summary of the strategy (for corporate entities) outlining\n' +
        'the trading experience mentioned above along with this Declaration.'
    }, {
      type: 'paragraph',
      margin: true,
      footnote: true,
      content: '4. Financial instruments include:' +

        '\n- Transferable securities;' +
        '\n- Money-market instruments;' +
        '\n- Units in collective investment undertakings;' +
        '\n- Options, futures, swaps, forward rate agreements and any other derivative contracts relating to securities, currencies, interest rates or yields, emission allowances or other derivatives instruments, financial indices or financial measures which may be settled physically or in cash;' +
        '\n- Options, futures, swaps, forwards and any other derivative contracts relating to commodities that must be settled in cash or may be settled in cash at the option of one of the parties other than by reason of default or other termination event;' +
        '\n- Options, futures, swaps, and any other derivative contracts relating to commodities that can be physically settled provided that they are traded on a regulated market, a MTF, or an OTF, except for wholesale energy products traded on an OTF that must be physically settled;' +
        '\n- Options, futures, swaps, forwards and any other derivative contracts relating to commodities, that can be physically settled not otherwise mentioned in point 6 of this Section and not being for commercial purposes, which have the characteristics of other derivative financial instruments;' +
        '\n- Derivative instruments for the transfer of credit risk;' +
        '\n- Financial contracts for differences;' +
        '\n- Options, futures, swaps, forward rate agreements and any other derivative contracts relating to climatic variables, freight rates or inflation rates or other official economic statistics that must be settled in cash or may be settled in cash at the option of one of the parties other than by reason of default or other termination event, as well as any other derivative contracts relating to assets, rights, obligations, indices and measures not otherwise mentioned in this Section, which have the characteristics of other derivative financial instruments, having regard to whether, inter alia, they are traded on a regulated market, OTF, or an MTF;'

    }
  ],
  getNextStep: (stepData) => {
    if (
      !!stepData['default']?.length &&
      !!stepData['name'] &&
      !!stepData['address'] &&
      !!stepData['country']
    ) {
      return UsStatment.id;
    }

    return undefined;
  }
};
