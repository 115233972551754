import React from 'react';
import { Navigate } from 'react-router-dom';
import { useClientContext } from '@backed-fi/context';

/**
 * Component, that will render its children, only
 * if the client has been fully activated
 */
export const Activated: React.FC<React.PropsWithChildren<any>> = ({
  children
}) => {
  const clientContext = useClientContext();

  const { shouldRegisterWallet, shouldActivateWallet } = clientContext;
  const isClientActive = !(shouldRegisterWallet || shouldActivateWallet);

  if (!isClientActive) {
    return <Navigate to="/dashboard" />;
  }
  return <>{children}</>;
};
