import { useToggle } from '@backed-fi/shared/hooks/useToggle';

const FunModeLocalStorageKey = 'BackedFi.FunMode';

export const useFunMode = () => {
  const funModeToggle = useToggle(localStorage.getItem(FunModeLocalStorageKey) === 'true');

  return {
    toggle: () => funModeToggle.setOpen((prev) => {
      const newValue = !prev;

      localStorage.setItem(FunModeLocalStorageKey, String(newValue));

      return newValue;
    }),
    enabled: funModeToggle.open
  };
};
