import { Route } from 'react-router-dom';
import React from 'react';
import { AuthenticatePage } from './auth/AuthenticatePage';
import { ForbiddenPage } from './status/ForbiddenPage';
import { MultiFactorPage } from '@backed-fi/shared/pages/auth/MultiFactorPage';

export { AuthenticatePage } from './auth/AuthenticatePage';

export const SharedPages = (
  <>
    <Route path='/authenticate' element={<AuthenticatePage />} />
    <Route
      path='/mfa'
      element={
        <MultiFactorPage />
      }
    />

    <Route path='/forbidden' element={<ForbiddenPage />} />
  </>
);
