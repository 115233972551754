/* eslint-disable max-len */
import { ClientType } from '@prisma/client';

import { IOnboardingStep } from './onboarding-step-interface';
import { Rejected } from './rejected';
import { Facta } from './facta';
import { InvestorWarning } from './jersey-investor-warning';

export const UsStatment: IOnboardingStep = {
  id: 'us-citizen',
  name: 'US Statement',
  blocks: (clientContext) => {
    const content = (() => {
      switch (clientContext.type) {
        case ClientType.Institution:
          return {
            title: 'Is any of the legal representatives, shareholders (direct or indirect), and Ultimate Beneficial Owners of the business a US Person (i.e. US resident or citizen)?',
            options: [
              {
                value: 'is-us-citizen',
                content: 'Yes, some of the legal representatives, shareholders (direct or indirect), and Ultimate Beneficial Owners of the business are US resident or citizen'
              },
              {
                value: 'is-not-us-citizen',
                content: 'No, none of the legal representatives, shareholders (direct or indirect), and Ultimate Beneficial Owners of the business are US resident or citizen'
              }
            ]
          };
        case ClientType.Individual:
        default:
          return {
            title: 'Are you a US Person (i.e. US resident or citizen)?',
            options: [
              {
                value: 'is-us-citizen',
                content: 'Yes, I\'m a US resident or citizen'
              },
              {
                value: 'is-not-us-citizen',
                content: 'No, I\'m not a US resident or citizen'
              }
            ]
          };
      }
    })();
    return [
      {
        type: 'title',
        title: content.title
      },
      {
        type: 'checkbox',
        valueKey: 'default',
        title: '',
        options: content.options
      }
    ];
  },
  shouldRenderStep: () => true,
  getNextStep: (stepData, allSteps, clientContext) => {
    const data = stepData['default'];

    if (data) {
      if (clientContext.type === ClientType.Individual && data.includes('is-us-citizen')) {
        return Rejected.id;
      }
      return clientContext.type === ClientType.Individual ? Facta.id : InvestorWarning.id;
    }

    return undefined;
  }
};
