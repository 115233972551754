import { dialogClasses, Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiDialog: Components<Theme>['MuiDialog'] = {
  styleOverrides: {
    root: ({ theme }) => {
      const { utility } = theme.palette;

      return ({
        [`.${dialogClasses.paper}`]: {
          color: utility.textHighContrast,
          backgroundColor: utility.background,

          border: '1px solid',
          borderRadius: '0.5rem',
          borderColor: utility.borderSubtle
        }
      });
    }
  }
};
