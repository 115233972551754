import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiFormControlLabel: Components<Theme>['MuiFormControlLabel'] = {
  styleOverrides: {
    root: ({ theme }) => {
      const { utility } = theme.palette;

      return ({
        gap: '.5rem',
        color: utility.textHighContrast
      });
    }
  }
};
