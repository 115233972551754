import React from 'react';

import { z } from 'zod';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { Alert, Box, Button, Card, CircularProgress, Link, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';

import EmailIcon from '@mui/icons-material/Email';

import { config } from '@backed-fi/config';
import { useUrlQuery } from '@backed-fi/hooks';
import { DomainRestricted } from '@backed-fi/primitives';
import { BackgroundContainer } from '@backed-fi/app/src/app/components';
import { useOneTimeAuthentication } from '@backed-fi/shared/authentication/oneTimeCodeAuthentication';
import { UnauthenticatedNavigationBar } from '@backed-fi/app/src/app/components/navigation/UnauthenticatedNavigationBar';
import { useAuthContext } from '@backed-fi/context';


// region Form Schema

const FormSchema = z.object({
  email: z.string()
    .email()
    .nonempty(),

  password: z.string()
    .optional()
});

// endregion

export const AuthenticatePage = () => {
  const query = useUrlQuery();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const authContext = useAuthContext();
  const [hasPassword, setHasPassword] = React.useState(false);

  const {
    startEmailChallenge,
    authenticateWithPassword,
    authenticateWithEmailChallenge,
    loading
  } = useOneTimeAuthentication();

  // region State

  const [emailRequested, setEmailRequested] = React.useState(false);
  const [authenticating, setAuthenticating] = React.useState(false);

  // endregion

  // region Form Control

  const {
    formState,
    ...form
  } = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema)
  });

  const { errors } = formState;

  // endregion

  // region Effects

  React.useEffect(() => {
    authContext.logout();
  }, []);

  React.useEffect(() => {
    let timeout: any;

    if (
      query.has('challengeId') &&
      query.has('privateCode')
    ) {
      setAuthenticating(true);

      timeout = setTimeout(() => {
        authenticateWithEmailChallenge(
          query.get('challengeId')!,
          query.get('privateCode')!
        )
          .then(() => {
            setTimeout(() => {
              navigate('/');
            }, 1000);
          })
          .catch((e) => {
            console.error(e);

            snackbar.enqueueSnackbar(e.message || 'Something went wrong. Please try again', {
              variant: 'error'
            });
          })
          .finally(() => {
            setTimeout(() => {
              setAuthenticating(false);
            }, 1000);
          });
      }, 500);
    }

    return () => clearTimeout(timeout);
  }, []);

  // endregion

  // region Actions

  const onStartChallenge = (forceSend: boolean) => form.handleSubmit(async (data) => {
    if (data.password) {
      await authenticateWithPassword(data.email, data.password)
        .then(() => {
          navigate('/');
        })
        .catch((e) => {
          console.error(e);

          snackbar.enqueueSnackbar(e.message || 'Something went wrong. Please try again', {
            variant: 'error'
          });
        });
    } else {
      try {
        const result = await startEmailChallenge(data.email);

        if (result.successful) {
          setEmailRequested(true);
        }

        if (result.hasPassword) {
          setHasPassword(true);
        }
      } catch (e: any) {
        snackbar.enqueueSnackbar(e.message || 'Something went wrong. Please try again', {
          variant: 'error'
        });
      }
    }
  });

  // endregion

  return (
    <BackgroundContainer
      sx={{
        height: '100vh',
        width: '100wv',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <UnauthenticatedNavigationBar signUp />

      <Box
        sx={{
          width: '100vw',
          maxWidth: '512px'
        }}
      >
        <DomainRestricted dev local stage sandbox>
          <Alert
            variant="outlined"
            severity="warning"
            sx={{
              mb: '1rem',
              width: '100%'
            }}
          >
            {config.isDev && 'Development Environment'}
            {config.isStage && 'Staging Environment'}
          </Alert>
        </DomainRestricted>

        <Card
          sx={{
            borderRadius: '0.5rem',
            borderWidth: 0
          }}
        >
          <Box
            sx={{
              display: 'flex',
              padding: '2rem',
              flexFlow: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >

            {!emailRequested && (
              <Box
                sx={{
                  margin: '1rem 0 2.5rem',
                  textAlign: 'center'
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: 20
                  }}
                >
                  {authenticating ? 'Signing you in...' : 'Sign into Backed'}
                </Typography>

                <Typography
                  sx={{
                    color: '#848a98',
                    fontSize: 14
                  }}
                >
                  Trouble signing in?{' '}

                  <Link
                    href="mailto:support@backed.fi"
                    variant="link"
                    color="inherit"
                  >
                    Contact Support
                  </Link>
                </Typography>
              </Box>
            )}

            <Box
              sx={{
                width: '100%'
              }}
            >
              {(authenticating) && (
                <Box
                  sx={{
                    display: 'flex',
                    paddingBottom: '1rem',
                    justifyContent: 'center'
                  }}
                >
                  <CircularProgress />
                </Box>
              )}

              {!authenticating && (
                <React.Fragment>
                  {emailRequested ? (
                    <React.Fragment>
                      <Box
                        sx={{
                          gap: '0.75rem',
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          margin: '1rem 0 2.5rem'
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            width: '64px',
                            height: '64px',
                            color: 'white',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#0066ff',
                            borderRadius: '100%'
                          }}
                        >
                          <EmailIcon fontSize="large" />
                        </Box>

                        <Typography
                          sx={{
                            textAlign: 'center',
                            fontWeight: 400,
                            fontSize: 20
                          }}
                        >
                          Check Your Email
                        </Typography>
                      </Box>

                      <Typography
                        sx={{
                          padding: '0 1rem 1rem',
                          textAlign: 'center',
                          color: '#848a98',
                          fontSize: 14
                        }}
                      >
                        A login link will be sent to the email address you entered ({form.getValues('email')}) if you
                        have already signed up. If you have received an invitation to join Backed but have not yet
                        signed up, please do that first.
                      </Typography>
                    </React.Fragment>
                  ) : (
                    <Box component="form">
                      <TextField
                        fullWidth
                        label="Email Address"
                        placeholder="Email Address"
                        sx={{ flex: 1 }}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        disabled={hasPassword}
                        {...form.register('email')}
                      />

                      {hasPassword && (
                        <TextField
                          fullWidth
                          label="Password"
                          placeholder="Password"
                          type="password"
                          error={!!errors.password}
                          {...form.register('password')}
                          helperText={errors.password?.message}
                          sx={{
                            flex: 1
                          }}
                        />
                      )}

                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="publicDashboard"
                        loading={loading}
                        onClick={onStartChallenge(false)}
                        onSubmit={onStartChallenge(false)}
                        sx={{
                          marginTop: '1rem'
                        }}
                      >
                        Continue
                      </LoadingButton>
                    </Box>
                  )}
                </React.Fragment>
              )}
            </Box>
          </Box>
        </Card>
      </Box>
    </BackgroundContainer>
  );
};
