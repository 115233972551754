import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiStepContent: Components<Theme>['MuiStepContent'] = {
  styleOverrides: {
    root: ({ theme }) => {
      return ({
        borderLeft: 'unset'
      });
    }
  }
};
