import React from 'react';
import { Box } from '@mui/material';

export const Page: React.FC<React.ComponentProps<typeof Box>> = ({
  children,
  ...props
}) => (
  <Box
    sx={{
      maxWidth: '1200px',
      width: '95vw',
      margin: '0 auto',

      ...props.sx,
    }}
  >
    {children}
  </Box>
);
