import { stepLabelClasses, Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiStepLabel: Components<Theme>['MuiStepLabel'] = {
  styleOverrides: {
    root: ({ theme }) => {
      const { utility } = theme.palette;

      return ({
        padding: '1rem .5rem',
        borderRadius: '0.5rem',

        [`.${stepLabelClasses.label}`]: {
          color: utility.textHighContrast
        }
      });
    }
  }
};
