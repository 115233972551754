import React from 'react';
import { gql } from '@apollo/client';

import { AdminContextQuery, useAdminContextQuery } from '@backed-fi/graphql';
import { useAuthContext } from '@backed-fi/context';

// region Graph

const Graph = gql`
  query adminContext {
    admin {
      id

      firstName
      lastName

      email
      permissions

      isMFAEnabled
    }
  }
`;

// endregion

// region Types

type AdminContextPayload = {
  loaded: boolean
} & AdminContextQuery['admin'];

// endregion

const AdminContext = React.createContext<AdminContextPayload>({} as any);

export const AdminContextProvider: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  const authContext = useAuthContext();

  const { data, loading } = useAdminContextQuery({
    skip: !authContext.authenticated
  });

  const [state, setState] = React.useState<AdminContextPayload>({ loaded: false } as any);

  // region Effects

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      ...(data?.admin),
      loaded: prev.loaded || !loading
    }));
  }, [data, loading]);

  // endregion

  return (
    <AdminContext.Provider value={state}>
      {children}
    </AdminContext.Provider>
  );
};

export const useAdminContext = () => React.useContext(AdminContext);
