export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const truncate = (string: string, maxLength: number) => {
  if (string.length <= maxLength) {
    return string;
  }

  const front = Math.ceil(maxLength / 2);
  const back = Math.floor(maxLength / 2);

  return `${string.substring(0, front)}...${string.substring(string.length - back)}`;
};
