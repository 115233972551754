/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  IAlgebraPoolState,
  IAlgebraPoolStateInterface,
} from "../../../../../../@cryptoalgebra/core/contracts/interfaces/pool/IAlgebraPoolState";

const _abi = [
  {
    inputs: [],
    name: "activeIncentive",
    outputs: [
      {
        internalType: "address",
        name: "incentiveAddress",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "communityFeeLastTimestamp",
    outputs: [
      {
        internalType: "uint32",
        name: "",
        type: "uint32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getCommunityFeePending",
    outputs: [
      {
        internalType: "uint128",
        name: "communityFeePending0",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "communityFeePending1",
        type: "uint128",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getReserves",
    outputs: [
      {
        internalType: "uint128",
        name: "reserve0",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "reserve1",
        type: "uint128",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "globalState",
    outputs: [
      {
        internalType: "uint160",
        name: "price",
        type: "uint160",
      },
      {
        internalType: "int24",
        name: "tick",
        type: "int24",
      },
      {
        internalType: "int24",
        name: "prevInitializedTick",
        type: "int24",
      },
      {
        internalType: "uint16",
        name: "fee",
        type: "uint16",
      },
      {
        internalType: "uint16",
        name: "timepointIndex",
        type: "uint16",
      },
      {
        internalType: "uint8",
        name: "communityFee",
        type: "uint8",
      },
      {
        internalType: "bool",
        name: "unlocked",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "int24",
        name: "tick",
        type: "int24",
      },
    ],
    name: "limitOrders",
    outputs: [
      {
        internalType: "uint128",
        name: "amountToSell",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "soldAmount",
        type: "uint128",
      },
      {
        internalType: "uint256",
        name: "boughtAmount0Cumulative",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "boughtAmount1Cumulative",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "initialized",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "liquidity",
    outputs: [
      {
        internalType: "uint128",
        name: "",
        type: "uint128",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "key",
        type: "bytes32",
      },
    ],
    name: "positions",
    outputs: [
      {
        internalType: "uint256",
        name: "liquidity",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "innerFeeGrowth0Token",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "innerFeeGrowth1Token",
        type: "uint256",
      },
      {
        internalType: "uint128",
        name: "fees0",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "fees1",
        type: "uint128",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "secondsPerLiquidityCumulative",
    outputs: [
      {
        internalType: "uint160",
        name: "",
        type: "uint160",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tickSpacing",
    outputs: [
      {
        internalType: "int24",
        name: "",
        type: "int24",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tickSpacingLimitOrders",
    outputs: [
      {
        internalType: "int24",
        name: "",
        type: "int24",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "int16",
        name: "wordPosition",
        type: "int16",
      },
    ],
    name: "tickTable",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "int24",
        name: "tick",
        type: "int24",
      },
    ],
    name: "ticks",
    outputs: [
      {
        internalType: "uint128",
        name: "liquidityTotal",
        type: "uint128",
      },
      {
        internalType: "int128",
        name: "liquidityDelta",
        type: "int128",
      },
      {
        internalType: "uint256",
        name: "outerFeeGrowth0Token",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "outerFeeGrowth1Token",
        type: "uint256",
      },
      {
        internalType: "int24",
        name: "prevTick",
        type: "int24",
      },
      {
        internalType: "int24",
        name: "nextTick",
        type: "int24",
      },
      {
        internalType: "uint160",
        name: "outerSecondsPerLiquidity",
        type: "uint160",
      },
      {
        internalType: "uint32",
        name: "outerSecondsSpent",
        type: "uint32",
      },
      {
        internalType: "bool",
        name: "hasLimitOrders",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalFeeGrowth0Token",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalFeeGrowth1Token",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

export class IAlgebraPoolState__factory {
  static readonly abi = _abi;
  static createInterface(): IAlgebraPoolStateInterface {
    return new utils.Interface(_abi) as IAlgebraPoolStateInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IAlgebraPoolState {
    return new Contract(address, _abi, signerOrProvider) as IAlgebraPoolState;
  }
}
