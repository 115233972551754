import React, { useMemo } from 'react';
import SumsubWebSdk from '@sumsub/websdk-react';

import { gql } from '@apollo/client';
import { Box, CircularProgress, Typography } from '@mui/material';
import { MessageHandler } from '@sumsub/websdk';

import { useUserContext } from '@backed-fi/context';
import { useCreateVerificationMutation } from '@backed-fi/graphql';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const Graph = gql`
  mutation createVerification {
    createVerification {
      vendorToken
    }
  }
`;

const lastLevelName = {
  INDIVIDUAL: 'Jersey Individual Prod 2025 POR 2',
  COMPANY: 'Institutional last step Phone 2025'
}

export const OnboardingVerificationPage: React.FC = () => {
  const navigate = useNavigate();
  const userContext = useUserContext();
  const snackbar = useSnackbar();
  const levelNames = useMemo(() => [lastLevelName.INDIVIDUAL, lastLevelName.COMPANY], []);

  // ---- State ---- //
  const [token, setToken] = React.useState<string>();
  const [count, setCount] = React.useState<number>(0);

  // ---- Networking ---- //
  const [createVerification] = useCreateVerificationMutation();
  // ---- Effects ---- //
  React.useEffect(() => {
    const createVer = () => {
      setCount((p) => p + 1);

      if (count >= 5) {
        snackbar.enqueueSnackbar('Something went wrong. Please try again later', {
          variant: 'error'
        });

        return;
      }

      createVerification()
        .then(({ data }) => {
          setToken(data?.createVerification?.vendorToken!);
        })
        .catch((e) => {
          setTimeout(() => {
            createVer();
          }, 3000);
        });
    };

    createVer();
  }, []);

  // Action handlers
  const onMessageReceived: MessageHandler = async (msg, ctx) => {
    if (msg === 'idCheck.onApplicantStatusChanged' && (ctx as any).reviewStatus === 'completed') {
      if (levelNames.includes((ctx as any).levelName)) {
        navigate('/onboarding/verification/pending');
      }
    }
  };

  return (
    !token ? (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '600px' }}>
        <CircularProgress />
      </div>
    ) : (
      <Box
        sx={{
          display: 'flex',
          padding: '0',
          borderRadius: '0.5rem',
          flexFlow: 'column',
          height: '100%',
          maxHeight: '75vh',
          minHeight: '75vh',
          overflow: 'auto',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            position: 'sticky',
            backgroundColor: 'white',
            scrollBehavior: 'smooth',
            top: '-1rem',
            p: '2rem 2rem 1rem',
            transition: 'all 0.3s',
            borderBottom: '1px solid #e0e0e0',
            width: '100%'
          }}
        >

          <Typography
            sx={{
              fontWeight: 900,
              fontSize: 22,
              marginBottom: '0.125rem'
            }}
          >
            Backed onboarding
          </Typography>
        </Box>
        <SumsubWebSdk
          style={{ width: '100%', height: '100%', minHeight: '100%' }}
          accessToken={token || ''}
          expirationHandler={(async () => {
          }) as any}
          config={{
            lang: 'en',
            email: userContext.user?.email
          }}
          options={{}}
          onMessage={onMessageReceived}
          onError={(err) => console.error(err)}
        />
      </Box>
    )

  );
};
