import React from 'react';
import { Box, Typography, FormControlLabel, Checkbox, Radio } from '@mui/material';
import { useOnboardingContext } from '@backed-fi/app/src/app/domain/Onboarding/OnboardingContext';

export interface CheckboxBlock {
  /**
   * The title that will be rendered to the client explaining
   * what they are actually selecting
   */
  title?: string;

  /**
   * The key that will be used to store the client
   * input data in the context
   */
  valueKey: string;

  /**
   * Optional. Boolean, indicating if more than one option can
   * be selected.
   */
  multiSelect?: boolean;

  /**
   * Array of the available options
   */
  options: {
    /**
     * The value that will be stored if the client
     * selects that option. If it's not provided, it will be just display block
     */
    value?: string;

    /**
     * The content, describing that option, that will
     * be show to the client
     */
    content: string;

    /**
     * The content that will be shown to the client in the summary
     */
    summaryContent?: string;
  }[];
}

export const OnboardingCheckboxBlock: React.FC<CheckboxBlock> = ({
  title,
  valueKey,
  options,
  multiSelect
}) => {
  const {
    setData,
    getData
  } = useOnboardingContext();
  const [selectedValues, setSelectedValue] = React.useState<string[]>(getData(valueKey) ?? []);

  const onToggleValue = (value: string) => () => {
    if (!multiSelect) {
      setSelectedValue([value]);
    } else {
      setSelectedValue((prev) => {
        const arr = [...prev];
        const index = arr.indexOf(value);

        if (index !== -1) {
          arr.splice(index, 1);
        } else {
          arr.push(value);
        }

        return arr;
      });
    }
  };

  // region Context Update

  React.useEffect(() => {
    setData(valueKey, selectedValues);
  }, [selectedValues]);

  // endregion

  const InputElement = multiSelect
    ? Checkbox
    : Radio;

  return (
    <Box
      sx={{
        mt: '1rem'
      }}
    >
      {title && (
        <Typography
          sx={{
            mb: '.25rem',
            fontWeight: 'bolder'
          }}
        >
          {title}
        </Typography>
      )}

      {options.map((option, index) => (
        <Box
          key={index}
          sx={{
            mb: '.75rem'
          }}
        >
          { option.value && <FormControlLabel
            sx={{
              ml: '0'
            }}
            label={
              <Typography
                variant="label"
                sx={{
                  fontSize: '14px'
                }}
              >
                {option.content}
              </Typography>
            }
            control={
              <InputElement
                onChange={onToggleValue(option.value)}
                checked={selectedValues.includes(option.value)}
                sx={{
                  mt: '.2rem',
                  mr: '.5rem',
                  alignSelf: 'flex-start'
                }}
              />
            }
          /> }
          { !option.value && <Typography
            variant="label"
            sx={{
              fontSize: '14px'
            }}
          >
            {option.content}
          </Typography>}
        </Box>
      ))}
    </Box>
  );
};
