import * as React from 'react';
import { forwardRef, Ref, SVGProps } from 'react';

const SvgHelp = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    data-name='help icon'
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <defs>
      <clipPath id='Help_svg__a'>
        <path data-name='Rectangle 12' fill='none' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
    <g data-name='Group 298' clipPath='url(#Help_svg__a)' fill='#01000d'>
      <path
        data-name='Path 150'
        d='M12 24a12 12 0 1 1 12-12 12 12 0 0 1-12 12m0-22a10 10 0 1 0 10 10A10 10 0 0 0 12 2'
      />
      <path
        data-name='Path 151'
        d='M13 15.004h-2v-.743a3.954 3.954 0 0 1 1.964-3.5 2 2 0 0 0 1-2.125 2.024 2.024 0 0 0-1.6-1.595A2 2 0 0 0 10 9.004H8a4 4 0 1 1 5.93 3.5 1.982 1.982 0 0 0-.93 1.752Z'
      />
      <path data-name='Rectangle 11' d='M11 17h2v2h-2z' />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgHelp);
export default ForwardRef;
