import React, { useMemo } from 'react';
import { z } from 'zod';
import { useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSearchParams } from 'react-router-dom';
import { Box, TextField, Typography } from '@mui/material';

import { ClientType } from '@backed-fi/graphql';
import { OnboardingTerms } from './OnboardingTerms';

// region Schema

const Schema = z.object({
  email: z.string().email(),

  clientType: z.nativeEnum(ClientType)
});

// endregion

// region Helpers

const ClientTypeDisplayNameMap: Partial<Record<ClientType, string>> = {
  Institution: 'Company'
};

// endregion

export const OnboardingContactPage: React.FC = () => {
  const [searchParams] = useSearchParams();

  // region State

  const [emailDisabled, setEmailDisabled] = React.useState<boolean>(false);

  // endregion

  // region Form Control
  const form = useForm<z.infer<typeof Schema>>({
    resolver: zodResolver(Schema),
    defaultValues: {
      clientType: ClientType.Individual
    }
  });
  const clientType = useWatch({ control: form.control, name: 'clientType' });
  const email = useWatch({ control: form.control, name: 'email' });
  const { errors } = form.formState;

  // endregion

  // region Effects

  React.useEffect(() => {
    const email = searchParams.get('email');
    if (email) {
      form.setValue('email', email, { shouldValidate: true });
      setEmailDisabled(true);
    }
  }, [searchParams, form]);


  // endregion

  return (
    <Box
      sx={{
        p: '2rem',
      }}
    >
      {/* region Information */}
      <Typography
        sx={{
          fontWeight: 900,
          fontSize: 22,
          marginBottom: '0.125rem'
        }}
      >
        Backed onboarding
      </Typography>
      <Typography
        sx={{
          marginBottom: '1rem',
          fontWeight: 400,
          fontSize: 16,
        }}
      >
        To access Backed’s tokenized products, please enter your details:
      </Typography>
      {/* endregion */}

      {/* region Form */}
      <TextField
        fullWidth
        label="Email"
        disabled={emailDisabled}
        error={!!errors.email?.message}
        helperText={errors.email?.message}
        sx={{
          margin: '1rem 0'
        }}
        {...form.register('email')}
      />

      <TextField
        select
        fullWidth
        label="Client Type"
        SelectProps={{ native: true }}
        {...form.register('clientType')}
      >
        <option value="" disabled>
          Please select
        </option>

        {[ClientType.Individual, ClientType.Institution].map((type) => (
          <option key={type} value={type}>
            {ClientTypeDisplayNameMap[type] ?? type}
          </option>
        ))}
      </TextField>
      <OnboardingTerms
        clientType={clientType}
        email={email}
        isContactFormValid={form.formState.isValid}
      />
      {/* endregion */}
    </Box>
  );
};
