export const SharedRoutesConfiguration = {
  _root: '/',

  accounts: {
    _root: 'accounts',

    authenticate: 'authenticate',
    register: 'register',
  },

  errors: {
    _root: 'errors',

    notFound: 'notfound',
  },
};
