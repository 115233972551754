/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  ICommitStore,
  ICommitStoreInterface,
} from "../../../../../../../@chainlink/contracts-ccip/src/v0.8/ccip/interfaces/ICommitStore";

const _abi = [
  {
    inputs: [],
    name: "getExpectedNextSequenceNumber",
    outputs: [
      {
        internalType: "uint64",
        name: "sequenceNumber",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32[]",
        name: "hashedLeaves",
        type: "bytes32[]",
      },
      {
        internalType: "bytes32[]",
        name: "proofs",
        type: "bytes32[]",
      },
      {
        internalType: "uint256",
        name: "proofFlagBits",
        type: "uint256",
      },
    ],
    name: "verify",
    outputs: [
      {
        internalType: "uint256",
        name: "timestamp",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

export class ICommitStore__factory {
  static readonly abi = _abi;
  static createInterface(): ICommitStoreInterface {
    return new utils.Interface(_abi) as ICommitStoreInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ICommitStore {
    return new Contract(address, _abi, signerOrProvider) as ICommitStore;
  }
}
