import React from 'react';
import { useLocation } from 'react-router-dom';

import { ClientType } from '@backed-fi/graphql';
import { config } from '@backed-fi/config';

export const useRoutePrincipal = () => {
  const location = useLocation();

  const [principal, setPrincipal] = React.useState<ClientType>();

  React.useEffect(() => {
    if (location.state.principal) {
      setPrincipal(location.state.principal);
    } else if (config.isDev) {
      throw new Error('Cannot get the principal from the route');
    }
  }, [location.state]);

  return principal!;
};
