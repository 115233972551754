import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

// noinspection ES6UnusedImports
import {} from '@mui/x-data-grid/themeAugmentation';

export const MuiDataGrid: Components<Theme>['MuiDataGrid'] = {
  defaultProps: {
    hideFooter: true,
    disableColumnMenu: true,
    disableColumnFilter: true,
    disableColumnSelector: true,
    disableDensitySelector: true
  },
  styleOverrides: {
    root: ({ theme }) => {
      const { utility } = theme.palette;

      return {
        color: utility.textHighContrast,
        borderColor: utility.borderSubtle,
        backgroundColor: utility.background
      };
    },

    columnSeparator: ({ theme }) => {
      const { utility } = theme.palette;

      return {
        color: utility.borderHovered,
        borderColor: utility.borderHovered
      };
    },

    withBorderColor: ({ theme }) => {
      const { utility } = theme.palette;

      return {
        borderColor: utility.borderSubtle
      };
    },

    footerContainer: ({ theme }) => {
      const { utility } = theme.palette;

      return {
        color: utility.textHighContrast

        // svg: {
        //   color: utility.textHighContrast
        // }
      };
    },

    overlay: {
      background: 'unset'
    }
  }
};
