import React from 'react';
import { Box } from '@mui/material';
import { useOnboardingContext } from '@backed-fi/app/src/app/domain/Onboarding/OnboardingContext';
import { gql } from '@apollo/client';
import { useUpdateQuestionnaireMutation } from '@backed-fi/graphql';

import SavedIcon from '@mui/icons-material/PublishedWithChangesRounded';
import SavingIcon from '@mui/icons-material/CloudSyncRounded';
import PendingIcon from '@mui/icons-material/HourglassBottomRounded';

// region Graph

gql`
  mutation updateQuestionnaire($input: UpdateClientQuestionnaireInput!) {
    updateClientQuestionnaire(input: $input) {
      id
    }
  }
`;

// endregion

export const OnboardingSavingIndicator = () => {
  const context = useOnboardingContext();

  return (
    <Box
      sx={{
        gap: '.5rem',
        left: '25px',
        bottom: '25px',
        position: 'fixed',

        borderRadius: '4px',

        width: '300px',

        color: 'white',

        fontWeight: 500,
        fontFamily: 'Inter',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
      }}
    >
      {context.isSaving && (
        <React.Fragment>
          <SavingIcon />

          Saving data
        </React.Fragment>
      )}

      {context.isAllDataSaved && (
        <React.Fragment>
          <SavedIcon />

          Data Saved
        </React.Fragment>
      )}

      {(!context.isAllDataSaved && !context.isSaving) && (
        <React.Fragment>
          <PendingIcon />

          Pending changes
        </React.Fragment>
      )}
    </Box>
  );
};
