import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiAlert: Components<Theme>['MuiAlert'] = {
  defaultProps: {
    variant: 'outlined'
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      const { warning, info, success, error } = theme.palette;

      return {
        borderRadius: '4px',

        ...(ownerState.severity === 'warning' && {
          color: warning.textLowContrast,
          borderColor: warning.borderSubtle,
          backgroundColor: warning.elementBackground,

          '& .MuiAlert-icon': {
            color: warning.textLowContrast
          }
        }),

        ...(ownerState.severity === 'info' && {
          color: info.textLowContrast,
          borderColor: info.borderSubtle,
          backgroundColor: info.elementBackground,

          '& .MuiAlert-icon': {
            color: info.textLowContrast
          }
        }),

        ...(ownerState.severity === 'success' && {
          color: success.textLowContrast,
          borderColor: success.borderSubtle,
          backgroundColor: success.elementBackground,

          '& .MuiAlert-icon': {
            color: success.textLowContrast
          }
        }),

        ...(ownerState.severity === 'error' && {
          color: error.textLowContrast,
          borderColor: error.borderSubtle,
          backgroundColor: error.elementBackground,

          '& .MuiAlert-icon': {
            color: error.textLowContrast
          }
        })
      };
    }
  },

  variants: []
};
