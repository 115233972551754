import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

export const MuiChip: Components<Theme>['MuiChip'] = {
  defaultProps: {
    variant: 'outlined',
  },
  styleOverrides: {
    root: ({ theme }) => {
      const { error, primary, warning, success, info, secondary } =
        theme.palette;

      return {
        '&.MuiChip-colorError': {
          color: error.textLowContrast,
          borderColor: error.borderElement,
          backgroundColor: error.elementBackground,

          '&:hover': {
            borderColor: error.borderHovered,
            color: error.textHighContrast,
          },
        },

        '&.MuiChip-colorSecondary': {
          color: secondary.textLowContrast,
          borderColor: secondary.borderElement,
          backgroundColor: secondary.elementBackground,

          '&:hover': {
            borderColor: secondary.borderHovered,
            color: secondary.textHighContrast,
          },
        },

        '&.MuiChip-colorPrimary': {
          color: primary.textLowContrast,
          borderColor: primary.borderElement,
          backgroundColor: primary.elementBackground,

          '&:hover': {
            borderColor: primary.borderHovered,
            color: primary.textHighContrast,
          },
        },

        '&.MuiChip-colorWarning': {
          color: warning.textLowContrast,
          borderColor: warning.borderElement,
          backgroundColor: warning.elementBackground,

          '&:hover': {
            borderColor: warning.borderHovered,
            color: warning.textHighContrast,
          },
        },

        '&.MuiChip-colorSuccess': {
          color: success.textLowContrast,
          borderColor: success.borderElement,
          backgroundColor: success.elementBackground,

          '&:hover': {
            borderColor: success.borderHovered,
            color: success.textHighContrast,
          },
        },

        '&.MuiChip-colorInfo': {
          color: info.textLowContrast,
          borderColor: info.borderElement,
          backgroundColor: info.elementBackground,

          '&:hover': {
            borderColor: info.borderHovered,
            color: info.textHighContrast,
          },
        },
      };
    },
  },
};
