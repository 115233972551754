import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Box, Tab, Tabs } from '@mui/material';

import { BreadcrumbHeader } from '../../components/BreadcrumbHeader';
import { useHasFeature } from '@backed-fi/hooks';
import { ClientFeature } from '@backed-fi/graphql';

export const SettingsLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const hasAPIAccess = useHasFeature(ClientFeature.ApiAccess);

  const settingsTabs = [
    {
      value: 'wallets',
      label: 'Wallets'
    },
    {
      value: 'bank-accounts',
      label: 'Bank Accounts'
    },
    {
      value: 'transactions',
      label: 'Transactions'
    },
    {
      value: 'deposit',
      label: 'Deposit Addresses'
    },
    ...(hasAPIAccess ? [{
      value: 'api',
      label: 'API Access'
    }] : []),
    {
      value: 'account',
      label: 'Account Details'
    },
    {
      value: 'security',
      label: 'Account Security'
    }
  ];

  // ---- Actions ---- //
  const onNavigate = (_: any, value: string): void => {
    navigate(`/settings/${value}`);
  };

  const getCurrentPageTitle = () => {
    for (const {
      value,
      label
    } of settingsTabs) {
      if (location.pathname.includes(value)) {
        return label;
      }
    }

    return undefined;
  };

  return (
    <Box
      sx={{
        maxWidth: '1300px'
      }}
    >
      <BreadcrumbHeader
        mainTitle="Settings"
        currentPage={getCurrentPageTitle()}
      />

      {/* Navigation */}
      <Tabs
        value={location.pathname.split('/')[2]}
        indicatorColor="primary"
        onChange={onNavigate}
        sx={{
          marginTop: '0.5rem'
        }}
      >
        {settingsTabs.map(({
          value,
          label
        }) => (
          <Tab
            key={value}
            value={value}
            label={label}
          />
        ))}
      </Tabs>

      <Box
        sx={{
          marginTop: '3rem'
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
