import React from 'react';
import { gql } from '@apollo/client';
import { FieldError, UseFormReturn } from 'react-hook-form';

import { ExternalWalletsDropdownQuery, useExternalWalletsDropdownQuery } from '@backed-fi/graphql';
import { TextField } from '@mui/material';

const Graph = gql`
  query externalWalletsDropdown {
    externalWallets {
      id
      address
    }
  }
`;

interface Props {
  /**
   * Show only verified external wallets in the dropdown
   */
  onlyVerified?: boolean;
  name: string;
  form: UseFormReturn<any>;
}

export const ExternalWalletSelect: React.FC<Props> = ({ form, name }) => {
  const { data } = useExternalWalletsDropdownQuery();

  // ---- Destructure ---- //
  const { errors } = form.formState;
  const { externalWallets } = (data || {}) as ExternalWalletsDropdownQuery;

  return (
    <TextField
      select
      label='Wallet'
      error={!!errors[name]}
      helperText={(errors[name] as FieldError)?.message}
      {...form.register(name)}
    >
      <option
        style={{
          display: 'none'
        }}
      />

      {externalWallets?.map((wallet) => (
        <option
          key={wallet.id}
          value={wallet.id}
        >
          {wallet.address}
        </option>
      ))}
    </TextField>
  );
};
