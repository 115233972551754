import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

export const MuiIcon: Components<Theme>['MuiIcon'] = {
  styleOverrides: {
    root: ({ theme }) => {
      const { primary, success } = theme.palette;

      return {
        '&.MuiIcon-colorPrimary': {
          color: primary.textLowContrast,
        },
        '&.MuiIcon-colorSuccess': {
          color: success.textLowContrast,
        },
      };
    },
  },
};
