import React from 'react';
import { Avatar, Badge, Box, Typography } from '@mui/material';
import { ethers } from 'ethers';
import { BlockchainNetwork, FiatCurrency, IncomingTransactionType, OutgoingTransactionType } from '@backed-fi/graphql';
import BankAccountIcon from '@mui/icons-material/AccountBalanceRounded';
import { CentsFormatter, CryptoFormatter } from '@backed-fi/shared';


// region Props

interface Props {
  small?: boolean;
  clickable?: boolean;

  assets: ({
    type: 'Blockchain'
    id: string;

    hash?: string | null;
    explorerUrl?: string | null;
    confirmations?: number;
    confirmationsNeeded?: number;

    network: BlockchainNetwork;
    tokenSymbol: string;
    amount: string;
    decimals: number;
    assetType: IncomingTransactionType | OutgoingTransactionType
  } | {
    type: 'Fiat'
    id: string;
    amount: string;
    currency: FiatCurrency;
  })[];
}

// endregion

export const InteractionAssetsList: React.FC<Props> = ({
  assets,
  small,
  clickable
}) => {
  return (
    <Box
      sx={{
        gap: '1rem',
        display: 'flex',
        flexFlow: 'column'
      }}
    >
      {assets.map((asset) => (
        <React.Fragment>
          {asset.type === 'Blockchain' && (
            <Box
              key={asset.hash}
              sx={{
                gap: '0.5rem',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Badge
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                badgeContent={
                  <Avatar
                    alt={asset.network}
                    src={`/assets/icons/assets/native/${asset.network}.svg`}
                    sx={{
                      width: '24px',
                      height: '24px',
                      border: '2px solid white',

                      ...((small) && ({
                        width: '18px',
                        height: '18px'
                      }))
                    }}
                  />
                }
              >
                <Avatar
                  alt={asset.tokenSymbol}
                  src={`/assets/icons/assets/${asset.tokenSymbol}.svg`}
                  sx={{
                    width: '36px',
                    height: '36px',

                    ...((small) && ({
                      width: '28px',
                      height: '28px'
                    }))
                  }}
                />
              </Badge>

              <Typography
                sx={{
                  ...((clickable && !!asset.explorerUrl) && ({
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    color: 'primary.textLowContrast',

                    '&:hover': {
                      color: 'primary.textHighContrast'
                    }
                  })),

                  ...((small) && ({
                    fontSize: '14px'
                  }))
                }}

                onClick={(event) => {
                  if (clickable && !!asset.explorerUrl) {
                    window.open(asset.explorerUrl, '_blank');
                    event.stopPropagation();
                  }
                }}
              >
                {
                  CryptoFormatter.format(
                    asset.amount,
                    asset.decimals,
                    undefined,
                    asset.assetType === 'Stablecoin'
                  )
                }

                <Box
                  component="span"
                  sx={{
                    fontWeight: 700
                  }}
                >
                  {' '}{asset.tokenSymbol}{' '}
                </Box>

                on {asset.network}

                {!!asset.confirmations && (
                  (() => {
                    if (!asset.confirmationsNeeded) {
                      return ` (${asset.confirmations} confirmations)`;
                    }

                    if (asset.confirmations > asset.confirmationsNeeded) {
                      return ' (Confirmed)';
                    }

                    return ` (${asset.confirmations}/${asset.confirmationsNeeded} confirmations)`;
                  })()
                )}
              </Typography>
            </Box>
          )}

          {asset.type === 'Fiat' && (
            <Box
              sx={{
                gap: '.5rem',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Box
                sx={{
                  width: '32px',
                  height: '32px',
                  borderRadius: '32px',
                  color: 'yellow.textLowContrast',
                  backgroundColor: 'yellow.elementBackgroundActive',
                  border: '1px solid',
                  borderColor: 'yellow.borderSubtle',

                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <BankAccountIcon />
              </Box>

              <Typography>
                Bank transfer for {CentsFormatter.format(asset.amount, asset.currency)}
              </Typography>
            </Box>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};
