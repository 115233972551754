import React from 'react';
import { Box } from '@mui/material';
import { useOnboardingContext } from '@backed-fi/app/src/app/domain/Onboarding/OnboardingContext';
import { gql } from '@apollo/client';
import { ClientStatus, OnboardingRoutingQuery, useOnboardingRoutingLazyQuery } from '@backed-fi/graphql';
import { useAuthContext } from '@backed-fi/context';
import { useNavigate } from 'react-router';

// region Graph

gql`
  query onboardingRouting {
    client {
      status

      questionnaires (
        where: {
          type: OnboardingQuestionnaire
        }
      ) {
        id
        status
        payload
      }
    }
  }
`;

// endregion

export const OnboardingSortingPage: React.FC = () => {
  const { authenticated } = useAuthContext();
  const { initData } = useOnboardingContext();

  const navigate = useNavigate();

  // region Networking

  const [fetchQuestionnaire] = useOnboardingRoutingLazyQuery();

  // endregion

  // region Fetch Data

  React.useEffect(() => {
    if (!authenticated) {
      navigate('/onboarding/explainer');
    }
  }, [authenticated]);

  React.useEffect(() => {
    (async () => {
      if (authenticated) {
        const { client } = ((await fetchQuestionnaire()).data as OnboardingRoutingQuery);

        if (client.status === ClientStatus.Onboarding) {
          const questionnaire = client.questionnaires?.[0];

          // If the client needs to feel the questionnaire, redirect to the questionnaire
          if (!!questionnaire && questionnaire.status !== 'Completed') {
            initData(questionnaire.id, questionnaire.payload);

            navigate('/onboarding/questionnaire');
          } else {
            navigate('/onboarding/verification');
          }
        }

        if (client.status === ClientStatus.Active) {
          navigate('/');
        }

        if (
          client.status === ClientStatus.Rejected ||
          client.status === ClientStatus.Closed
        ) {
          navigate('/rejected');
        }
      }
    })();
  }, [authenticated]);

  // endregion

  return (
    <Box />
  );
};
