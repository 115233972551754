import React from 'react';

import { Box, Typography } from '@mui/material';
import { AssetBadge } from '../../../components/AssetBadge';
import { BlockchainNetwork } from '@backed-fi/graphql';

interface Props {
  label: string;
  symbol: string;
  network: BlockchainNetwork;
  iconName?: string;
}

export const FormAsset: React.FC<Props> = ({ label, symbol, network, iconName }) => {
  return (
    <Box
      sx={{
        gap: '0.75rem',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <AssetBadge
        symbol={symbol}
        network={network}
        large/>

      <Box>
        <Typography
          sx={{
            fontSize: 12,
            lineHeight: 1.25,
            color: '#848a98'
          }}
        >
          {label}
        </Typography>

        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 700
          }}
        >
          {symbol}
        </Typography>
      </Box>
    </Box>
  );
};
