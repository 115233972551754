import { ClientType } from '@prisma/client';
import { IOnboardingStep } from './onboarding-step-interface';
import { Rejected } from './rejected';
import { Annex1 } from './annex-1';

export const Residency: IOnboardingStep = {
  id: 'residency',
  name: 'Residency',
  blocks: () => [
    {
      type: 'title',
      title: 'Are you a EU, EEA, UK, Jersey citizen/resident?'
    }, {
      type: 'checkbox',
      valueKey: 'default',
      title: '',
      options: [
        {
          value: 'non-resident',
          content: 'No, I am not'
        }, {
          value: 'resident-with-residency-permit',
          content: 'Yes, I do hold an EU, EEA, UK or Jersey citizenship/residence permit'
        }
      ]
    }
  ],
  getNextStep: (stepData) => {
    if (
      stepData.default?.includes('resident-with-residency-permit')
    ) {
      return Annex1.id;
    }

    if (
      stepData.default?.includes('resident') ||
      stepData.default?.includes('non-resident')
    ) {
      return Rejected.id;
    }

    return '';
  },
  shouldRenderStep: (data, clientContext) => {
    return !!data['restricted-jurisdictions-exposure']?.['default']?.includes('true')
      && clientContext.type === ClientType.Individual;
  }
};
