import React from 'react';

import { Box, Typography } from '@mui/material';
import { ContentCard } from '../../../components/ContentCard';

interface Props extends React.PropsWithChildren {
  title: string;
}

export const SettingsGroup: React.FC<Props> = ({ title, children }) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        gap: '0.75rem',
        display: 'flex',
        minHeight: '100%',
        flexDirection: 'column'
      }}
    >
      <Typography>
        {title}
      </Typography>

      <ContentCard
        variant='outline'
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          padding: '2rem',
          gap: '1.5rem'
        }}
      >
        {children}
      </ContentCard>
    </Box>
  )
}
