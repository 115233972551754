import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';
import { Application } from '@backed-fi/constants';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    heading: true;
    subheading: true;

    title: true;
    subtitle: true;

    titleSmall: true;
    subtitleSmall: true;

    sectionTitle: true;

    link: true;

    label: true;
    labelBig: true;
    labelSmall: true;

    body: true;
    small: true;
    inline: true;

    code: true;

    modalTitle: true;
    modalSubtitle: true;

    h1: undefined;
    h2: undefined;
    h3: undefined;
    h4: undefined;
    h5: undefined;
    h6: undefined;
    subtitle1: undefined;
    subtitle2: undefined;
    body1: undefined;
    body2: undefined;
    caption: undefined;
    button: undefined;
    overline: undefined;
  }
}

export const createMuiTypography = (
  application: Application
): Components<Theme>['MuiTypography'] => ({
  defaultProps: {
    variant: 'body',
    variantMapping: {
      heading: 'h1',
      title: 'h2',
      subheading: 'h3',
      titleSmall: 'h2',
      subtitleSmall: 'h4',
      subtitle: 'h4',
      sectionTitle: 'h6',
      body: 'p',
      small: 'p',
      inline: 'span',
      label: 'p',
      labelBig: 'p',
      labelSmall: 'span',
      modalTitle: 'h3',
      modalSubtitle: 'p',
    },
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      fontWeight: 400,

      ...(ownerState.color === 'error.main' && {
        color: `${theme.palette.error.textLowContrast} !important`
      }),

      ...(ownerState.variant === 'heading' && {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 700,
        fontSize: '2.25rem',
        color: theme.palette.primary.textHighContrast
      }),

      ...(ownerState.variant === 'link' && {
        display: 'inline',
        color: theme.palette.primary.textLowContrast,
        cursor: 'pointer',
        fontWeight: 'inherit',
        fontSize: 'inherit'
      }),

      ...(ownerState.variant === 'subheading' && {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 600,
        fontSize: '1.75rem',
        color: theme.palette.primary.textLowContrast
      }),

      ...(ownerState.variant === 'title' && {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 700,
        fontSize: '1.75rem',
        color: theme.palette.primary.textHighContrast,
      }),

      ...(ownerState.variant === 'subtitle' && {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 600,
        fontSize: '1.25rem',
        color: theme.palette.primary.textHighContrast,
      }),

      ...(ownerState.variant === 'titleSmall' && {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 700,
        fontSize: '1.25rem',
        color: theme.palette.primary.textHighContrast,
      }),

      ...(ownerState.variant === 'subtitleSmall' && {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: '.9rem',
        color: theme.palette.utility.textLowContrast,
      }),

      ...(ownerState.variant === 'sectionTitle' && {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: '1.25rem',
        marginBottom: '1rem',
      }),

      ...(ownerState.variant === 'labelBig' && {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.utility.textHighContrast,
        fontWeight: 500,
        fontSize: '.9rem',
      }),

      ...(ownerState.variant === 'label' && {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: '.75rem',
        color: theme.palette.utility.textLowContrast,
      }),

      ...(ownerState.variant === 'labelSmall' && {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 600,
        fontSize: '.6rem',
        color: theme.palette.utility.textLowContrast,
      }),

      ...(ownerState.variant === 'small' && {
        fontFamily: theme.typography.fontFamily,
        fontSize: '.9em'
      }),

      ...(ownerState.variant === 'body' && {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: '1rem',
      }),

      ...(ownerState.variant === 'code' && {
        fontFamily: theme.typography.fontFamilyMono,
        fontWeight: 500,
        fontSize: '1rem',
        whiteSpace: 'pre',
      }),

      // ---- Main Application ---- //
      ...(application === Application.Main && {
        ...(ownerState.variant === 'heading' && {
          fontFamily: theme.typography.fontFamily,
          fontWeight: 300,
          fontSize: '24px',
          textTransform: 'uppercase',
        }),

        ...(ownerState.variant === 'title' && {
          fontFamily: theme.typography.fontFamily,
          fontWeight: 400,
          fontSize: '16px',
          textTransform: 'uppercase',
        }),

        ...(ownerState.variant === 'modalTitle' && {
          fontFamily: theme.typography.fontFamily,
          fontWeight: 400,
          fontSize: '20px',
        }),

        ...(ownerState.variant === 'modalSubtitle' && {
          fontFamily: theme.typography.fontFamily,
          fontWeight: 500,
          fontSize: '14px',
          marginTop: '0.5rem',
        }),
      }),
    }),
  },

  variants: [],
});
