import { Route } from 'react-router-dom';
import { OnboardingLayout } from './OnboardingLayout';

import { Protector } from '@backed-fi/compound';
import { OnboardingCreation } from './pages/Creation/OnboardingCreation';
import { OnboardingContactPage } from './pages/Creation/OnboardingContactPage';
import { OnboardingVerificationPage } from './pages/OnboardingVerificationPage';
import { OnboardingVerificationPendingPage } from './pages/OnboardingVerificationPendingPage';
import { OnboardingContextProvider } from '@backed-fi/app/src/app/domain/Onboarding/OnboardingContext';
import { RejectedPage } from '@backed-fi/app/src/app/domain/Onboarding/pages/Status/RejectedPage';
import { OnboardingSortingPage } from '@backed-fi/app/src/app/domain/Onboarding/pages/OnboardingSortingPage';

const VerificationRoutes = (
  <Route
    path="verification"
  >
    <Route
      index
      element={
        <Protector>
          <OnboardingVerificationPage />
        </Protector>
      }
    />

    <Route
      path="pending"
      element={
        <OnboardingVerificationPendingPage />
      }
    />
  </Route>
);


export const OnboardingRoutes = (
  <Route
    element={(
      <OnboardingContextProvider>
        <OnboardingLayout />
      </OnboardingContextProvider>
    )}
  >
    <Route
      path="rejected"
      element={(
        <RejectedPage />
      )}
    />

    <Route path="onboarding">
      {/*
          // No one should be navigated to /onboarding from anywhere
          // within the site. But if someone manually navigates here
          // make sure to correct their mistake
        */}
      <Route
        index
        element={
          <OnboardingSortingPage />
        }
      />

      {VerificationRoutes}

      <Route
        path="explainer"
        element={<OnboardingContactPage />}
      />

      <Route
        path="creation"
        element={
          <OnboardingCreation />
        }
      />

    </Route>
  </Route>
);
