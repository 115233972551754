import React from 'react';

import { Box, Typography } from '@mui/material';

interface Props extends React.PropsWithChildren {
  label: string;

  fullWidth?: boolean;
}

export const InteractionDetailsItem: React.FC<Props> = ({
  label,
  children,

  fullWidth,
}) => {
  return (
    <Box
      sx={{
        ...((fullWidth) && ({
          gridColumn: 'span 2'
        }))
      }}
    >
      <Typography
        sx={{
          marginBottom: '0.125rem',
          color: '#848a98',
          fontSize: 12
        }}
      >
        {label}
      </Typography>

      <Typography
        component="div"
        sx={{
          gap: '0.5rem',
          minHeight: '24px',
          display: 'flex',
          alignItems: 'center',
          fontSize: 14
        }}
      >
        {children}
      </Typography>
    </Box>
  );
};
