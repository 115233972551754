import React from 'react';

import { Box, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface Props {
  mainTitle: string;
  currentPage?: string;
}

export const BreadcrumbHeader: React.FC<Props> = ({ mainTitle, currentPage }) => {
  return (
    <Box>
      <Typography
        sx={{
          display: 'flex',
          gap: '0.25rem',
          fontSize: 28,
          fontWeight: 400,
          alignItems: 'center'
        }}
      >
        {mainTitle}
        {currentPage && (
          <>
            <ChevronRightIcon sx={{ fill: '#a4aab6' }} />
            {currentPage}
          </>
        )}
      </Typography>
    </Box>
  );
};
