import React from 'react';
import { Navigate } from 'react-router-dom';

export interface RedirectionBlock {
  /**
   * The path, to which the end user will be
   * redirected when this block is rendered
   */
  path: string;
}

export const OnboardingRedirectionBlock: React.FC<RedirectionBlock> = ({ path }) => {
  return (
    <Navigate
      to={path}
    />
  );
};
