import React from 'react';

import { Box } from '@mui/material';
import { BankAccountSection } from '../components/BankAccountSection';

export const BankAccountsSettingsPage: React.FC = () => (
  <Box>
    <BankAccountSection />
  </Box>
);
