import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Box, Card, Step, StepLabel, Stepper, Typography } from '@mui/material';

import { BackgroundContainer } from '../../components';
import { UnauthenticatedNavigationBar } from '../../components/navigation/UnauthenticatedNavigationBar';
import { OnboardingConfiguration } from '@backed-fi/app/src/app/domain/Onboarding/configuration';
import { useOnboardingContext } from '@backed-fi/app/src/app/domain/Onboarding/OnboardingContext';
import { useClientContext } from '@backed-fi/context';

const StepperIcon =
  (label: string) =>
    ({ completed, active }: any) => {
      return (
        <Box
          sx={{
            color: active ? 'white' : 'rgba(255, 255, 255, 0.5)',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              ml: '.2rem',
              mr: '1rem',
              my: '.5rem',
              border: '2px solid',
              width: '1.2rem',
              height: '1.2rem',
              transform: 'rotate(45deg)',

              borderColor: 'rgba(255, 255, 255, 0.5)',

              ...(active && {
                borderColor: 'white'
              }),

              ...(completed && {
                borderColor: 'rgba(255, 255, 255, 0)',
                backgroundColor: '#06f'
              })
            }}
          />

          <Typography>{label}</Typography>
        </Box>
      );
    };

export const OnboardingLayout = () => {
  const clientContext = useClientContext();

  const { currentStep, steps } = useOnboardingContext();

  const location = useLocation();

  const availableSteps = React.useMemo(() => {
    return OnboardingConfiguration.steps.filter((step) =>
      step.shouldRenderStep(steps, clientContext)
    );
  }, [steps]);

  return (
    <BackgroundContainer>
      <UnauthenticatedNavigationBar />

      <Box
        sx={{
          py: '5rem',
          width: '100vw',
          minHeight: '100vh',
          overflow: 'auto',
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center'
        }}
      >
        <Card
          sx={{
            position: 'relative',
            overflow: 'unset',
            width: '100%',
            maxWidth: '720px',
            marginTop: '4vh',
            borderRadius: '0.5rem',
            borderWidth: 0
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              right: '-340px',
              width: '300px'
            }}
          >
            {location.pathname === '/onboarding/questionnaire' && (
              <Stepper
                orientation="vertical"
                activeStep={availableSteps.findIndex(
                  (x) => x.id === currentStep
                )}
                sx={{
                  gap: 'unset'
                }}
              >
                {availableSteps.map((step) => (
                  <Step
                    key={step.id}
                    sx={{
                      ml: '-0.5rem',
                      border: 'unset',
                      padding: 'unset',
                      backgroundColor: 'unset !important'
                    }}
                  >
                    <StepLabel StepIconComponent={StepperIcon(step.name)} />
                  </Step>
                ))}
              </Stepper>
            )}
          </Box>

          <Box>
            <Outlet />
          </Box>
        </Card>
      </Box>
    </BackgroundContainer>
  );
};
