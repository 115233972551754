import React from 'react';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';


// region Props

interface Props {
  address: string;
  explorerUrl?: string | null;
}

// endregion

export const AddressTile: React.FC<Props> = ({
  address,
  explorerUrl
}) => {
  const snackbar = useSnackbar();

  const onCopy = async (e: any) => {
    e.preventDefault();

    try {
      await navigator.clipboard.writeText(address);

      snackbar.enqueueSnackbar('Successfully copied address');
    } catch (e) {
      snackbar.enqueueSnackbar(`Cannot copy address. Copy manually: ${address}`, {
        variant: 'error',
        action: () => (
          <Button
            color="error"
            onClick={onCopy}
          >
            Copy
          </Button>
        )
      });
    }
  };

  const onOpenExplorer = () => {
    if (explorerUrl) {
      window.open(explorerUrl, '_blank');
    }
  };

  return (
    <Box
      onClick={
        explorerUrl
          ? onOpenExplorer
          : onCopy
      }
      onContextMenu={onCopy}
      sx={{
        maxWidth: '100%'
      }}
    >
      <Tooltip
        placement="top"
        title={
          address ? explorerUrl
            ? 'Click to see in explorer. Right click to copy address'
            : 'Click to copy'
            : ''
        }
      >

        <Typography>
          {address}
        </Typography>
      </Tooltip>
    </Box>
  );
};
