import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

export const MuiCircularProgress: Components<Theme>['MuiCircularProgress'] = {
  styleOverrides: {
    root: ({ theme }) => {
      const { primary } = theme.palette;

      return {
        '&.MuiCircularProgress-colorPrimary': {
          color: primary.textLowContrast
        }
      };
    }
  }
};
