import { stepConnectorClasses, Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiStepConnector: Components<Theme>['MuiStepConnector'] = {
  styleOverrides: {
    root: ({ theme }) => {
      const { utility } = theme.palette;

      return ({
        [`&:not(.${stepConnectorClasses.vertical})`]: {
          marginTop: '1rem'
        },

        [`.${stepConnectorClasses.line}`]: {
          borderColor: utility.borderElement
        }
      });
    }
  }
};
