import React from 'react';
import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { Box, CircularProgress, Link, Typography } from '@mui/material';

import { useLocationState } from '@backed-fi/hooks';
import { useOneTimeAuthentication } from 'frontend/shared/src/authentication/oneTimeCodeAuthentication';
import { ClientClassification, ClientType, CreateClientResultEnum, useCreateClientMutation } from '@backed-fi/graphql';
import { useNavigate } from 'react-router-dom';
import { ContactForm } from '@backed-fi/app/src/app/components/ContactForm';
import { useAuthContext } from '@backed-fi/context';


// region Graph Declaration

gql`
  mutation createClient($input: CreateClientInput!) {
    createClient(input: $input) {
      result
    }
  }

  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
    }
  }
`;

// endregion

export const OnboardingCreation = () => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const authContext = useAuthContext();
  const state = useLocationState<{
    email: string,
    clientType: ClientType,
    classification?: ClientClassification,
    challengeId?: string,
    privateCode?: string,
    acceptsMarketingCommunication?: boolean
  }>();

  const {
    startEmailChallenge,
    authenticateWithEmailChallenge
  } = useOneTimeAuthentication();

  // region Networking

  const [createClient] = useCreateClientMutation();

  // endregion

  // region State

  const [loading, setLoading] = React.useState<boolean>(true);
  const [errored, setErrored] = React.useState<boolean>(false);
  const [notInvited, setNotInvited] = React.useState<boolean>(false);
  const [invitationAlreadyClaimed, setInvitationAlreadyClaimed] = React.useState<boolean>(false);

  // endregion

  // region Effects

  // Request authentication email
  React.useEffect(() => {
    const {
      email,
      clientType,
      ...rest
    } = state;

    // Make sure that we don't send auth tokens
    authContext.logout();

    // Put it in a timeout so if it is called twice only the second call will be executed
    const timeout = setTimeout(() => {
      createClient({
        variables: {
          input: {
            type: clientType,
            // TODO: remove classification from the system
            classification: clientType === ClientType.Individual ? undefined : ClientClassification.Operating,
            acceptsMarketingCommunication: rest.acceptsMarketingCommunication,

            user: {
              email
            }
          }
        }
      })
        .then(({ data }) => {
          if (data?.createClient?.result === CreateClientResultEnum.Successful) {
            if (rest.challengeId) {
              // If there is challenge ID just authenticate the client
              authenticateWithEmailChallenge(
                rest.challengeId!,
                rest.privateCode!
              )
                .then(() => {
                  setTimeout(() => {
                    navigate('/');
                    window.location.reload();
                  }, 1000);
                })
                .catch((e) => {
                  console.error(e);

                  snackbar.enqueueSnackbar(e.message || 'Something went wrong. Please login to continue', {
                    variant: 'error'
                  });
                });
            } else {
              // Otherwise start an email challenge
              startEmailChallenge(email)
                .then(() => {
                  setLoading(false);
                })
                .catch((e) => {
                  snackbar.enqueueSnackbar(e.message || 'Something went wrong. Please try again', {
                    variant: 'error'
                  });
                })
            }
          } else if (data?.createClient?.result === CreateClientResultEnum.NotInvited) {
            setNotInvited(true);
            setLoading(false);
          } else if (data?.createClient?.result === CreateClientResultEnum.InvitationAlreadyClaimed) {
            setInvitationAlreadyClaimed(true);
            setLoading(false);
          } else {
            setLoading(false);
            setErrored(true);
          }
        });
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  // endregion


  return (
    <Box
      sx={{
        p: '2rem',
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center'
      }}
    >
      {!loading && (
        <Box>
          {notInvited && (
            <ContactForm
              context="Invitation Request"
              title="APPLY FOR A BACKED ACCOUNT INVITATION"
              subtitle={
                'Our platform currently operates on an invitation-only basis. ' +
                'If you believe you should have received an invitation or would ' +
                'like to request one, please fill in the form below.'
              }
            />
          )}


          {invitationAlreadyClaimed && (
            <React.Fragment>
              <Typography
                align="center"
                variant="title"
              >
                Invitation already claimed
              </Typography>

              <Typography
                maxWidth="700px"
                m="1rem auto"
                sx={{
                  textAlign: 'justify',
                  textAlignLast: 'center'
                }}
              >
                Invite already claimed. To continue onboarding, log into your account
                <Link
                  style={{
                    marginLeft: '4px'
                  }}
                  href='/authenticate'
                  color='inherit'
                >
                  Here
                </Link>.
                If you encounter any issues, feel free to contact our support
                <Link
                  href="mailto:support@backed.fi"
                  variant="link"
                  color="inherit"
                  style={{
                    marginLeft: '4px'
                  }}
                >
                  here
                </Link>.
              </Typography>
            </React.Fragment>
          )}

          {errored && (
            <React.Fragment>
              <Typography
                align="center"
                variant="title"
              >
                An error occurred
              </Typography>

              <Typography
                maxWidth="700px"
                m="1rem auto"
                sx={{
                  textAlign: 'justify',
                  textAlignLast: 'center'
                }}
              >
                An error occurred while creating your account. Please retry and and if the error persists, contact our
                support team for further assistance.
              </Typography>
            </React.Fragment>
          )}

          {(!errored && !notInvited && !invitationAlreadyClaimed) && (
            <React.Fragment>
              <Typography
                align="center"
                variant="title"
              >
                Thank you
              </Typography>

              <Typography
                maxWidth="700px"
                m="1rem auto"
                sx={{
                  textAlign: 'justify',
                  textAlignLast: 'center'
                }}
              >
                You will need to verify your email address to continue the onboarding process. Please check your inbox
                for an email from us and follow the link. You will be redirected to our verification provider to
                complete the verification process.
              </Typography>
            </React.Fragment>
          )}
        </Box>
      )}

      {loading && (
        <Box
          sx={{
            textAlign: 'center',
            gap: '8px',
            display: 'flex',
            flexFlow: 'column',
            minHeight: '600px',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress />


          <Typography>
            Creating your account
          </Typography>
        </Box>
      )}
    </Box>
  );
};
