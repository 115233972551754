import React from 'react';

import { Box, Grid } from '@mui/material';
import { PageHeader } from '../components/PageHeader';
import { ContentCard } from '../../../components/ContentCard';
import { AccountUserSection } from '../components/AccountUserSection';
import { AccountUsageSection } from '../components/AccountUsageSection';

export const AccountDetailsPage: React.FC = () => {
  return (
    <Box>
      <PageHeader
        title='User and Transaction Details'
        subtitle='Please email us at support@backed.fi to update your personal information or increase your limits.'
        action={{
          label: 'Update Account Details',
          href: 'mailto:support@backed.fi?subject=Update%20Account%20Details'
        }}
      />

      <ContentCard sx={{ padding: '2rem' }}>
        <Grid spacing='1.5rem' container>
          <Grid lg={4} md={12} item>
            <AccountUserSection />
          </Grid>

          <Grid lg={8} md={12} item>
            <AccountUsageSection />
          </Grid>
        </Grid>
      </ContentCard>
    </Box>
  );
};
