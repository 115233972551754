/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  IAlgebraPoolDerivedState,
  IAlgebraPoolDerivedStateInterface,
} from "../../../../../../@cryptoalgebra/core/contracts/interfaces/pool/IAlgebraPoolDerivedState";

const _abi = [
  {
    inputs: [
      {
        internalType: "int24",
        name: "bottomTick",
        type: "int24",
      },
      {
        internalType: "int24",
        name: "topTick",
        type: "int24",
      },
    ],
    name: "getInnerCumulatives",
    outputs: [
      {
        internalType: "uint160",
        name: "innerSecondsSpentPerLiquidity",
        type: "uint160",
      },
      {
        internalType: "uint32",
        name: "innerSecondsSpent",
        type: "uint32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

export class IAlgebraPoolDerivedState__factory {
  static readonly abi = _abi;
  static createInterface(): IAlgebraPoolDerivedStateInterface {
    return new utils.Interface(_abi) as IAlgebraPoolDerivedStateInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IAlgebraPoolDerivedState {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as IAlgebraPoolDerivedState;
  }
}
