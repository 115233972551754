import * as z from 'zod';
import { ethers } from 'ethers';
import Decimal from 'decimal.js';
import { useSnackbar } from 'notistack';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import DoneIcon from '@mui/icons-material/Done';
import LoadingButton from '@mui/lab/LoadingButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';

import { ArrowDownward, NotInterested } from '@mui/icons-material';
import { Alert, Box, Button, TextField, Typography } from '@mui/material';

import { getContractAt } from '@backed-fi/common';
import { ConnectWeb3Button } from '@backed-fi/compound';
import { CryptoFormatter, truncate } from '@backed-fi/shared';
import { useClientContext, useWeb3Context } from '@backed-fi/context';
import { FormAsset } from './FormAsset';
import { FormValue } from './FormValue';
import { BlockchainNetwork, ExternalWalletStatus } from '@backed-fi/graphql';

interface Props {
  sendAsset: {
    symbol: string;
    iconName?: string;
    decimals: number;
    type: 'Stablecoin' | 'BackedTokenImplementation'
    address: string;
    network: BlockchainNetwork
  };
  receiveAsset: {
    symbol: string;
    iconName?: string;
    network: BlockchainNetwork
  };
  network: string;
  address: string;
  isMarketOpen?: boolean;
  message: React.ReactNode;
  transactionMinimum: string;
  sendChildren?: React.ReactNode;
  receiveChildren?: React.ReactNode;
}

const Schema = z.object({
  value: z.preprocess(
    (v) => Number(v),
    z.number().positive()
  )
});

type Schema = z.infer<typeof Schema>;

export const TransactionForm: React.FC<Props> = ({
  sendAsset,
  receiveAsset,
  network,
  address,
  message,
  transactionMinimum,
  isMarketOpen,
  sendChildren,
  receiveChildren
}) => {
  const web3Context = useWeb3Context();
  const clientContext = useClientContext();

  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const [walletBalance, setWalletBalance] = useState(0);
  const [connectedWalletStatus, setConnectedWalletStatus] =
    useState<{ isRegistered: boolean, isActive: boolean }>({
      isRegistered: false,
      isActive: false
    });

  const form = useForm<Schema>({
    resolver: zodResolver(Schema)
  });

  // ----- Destructing ----- //
  const {
    errors,
    isSubmitting
  } = form.formState;

  // Effects

  React.useEffect(() => {
    if (web3Context && clientContext) {
      const wallet = clientContext.externalWallets.find((e) => e.address === web3Context.account?.toLowerCase());

      if (!!wallet && wallet?.status === ExternalWalletStatus.Active) {
        let contract;

        switch (sendAsset.type) {
          case 'Stablecoin':
            contract = getContractAt(sendAsset.type, sendAsset.address, web3Context.signer);
            break;
          case 'BackedTokenImplementation':
            contract = getContractAt(sendAsset.type, sendAsset.address, web3Context.signer);
            break;
        }

        contract.balanceOf(wallet!.address).then((balance) => {
          const balanceDecimal = new Decimal(balance.toString()).div(Decimal.pow(10, sendAsset.decimals).toString());
          setWalletBalance(balanceDecimal.toNumber());
        });
      }

      setConnectedWalletStatus({
        isRegistered: !!wallet,
        isActive: wallet?.status === ExternalWalletStatus.Active
      });
    }
  }, [web3Context, clientContext, sendAsset]);

  // Networking

  const onTransfer = form.handleSubmit(async ({ value }) => {
    try {
      const signer = web3Context.signer;

      let contract;
      switch (sendAsset.type) {
        case 'Stablecoin':
          contract = getContractAt(sendAsset.type, sendAsset.address, signer);
          break;
        case 'BackedTokenImplementation':
          contract = getContractAt(sendAsset.type, sendAsset.address, signer);
          break;
      }

      const tx = await contract.transfer(address, ethers.utils.parseUnits(value.toString(), sendAsset.decimals));

      // Wait for the transaction to be mined.
      await tx.wait();

      snackbar.enqueueSnackbar('Transaction has been successfully sent', {
        variant: 'success'
      });


      navigate('/dashboard');
    } catch (e: any) {
      snackbar.enqueueSnackbar(e.reason, {
        variant: 'error'
      });
    }
  });

  return (
    <Box>
      <Box
        sx={{
          gap: '1.5rem',
          display: 'flex',
          alignItems: 'center',
          padding: '1rem 1.5rem',
          backgroundColor: '#f2f4f8',
          justifyContent: 'space-between',
          borderTopLeftRadius: '0.5rem',
          borderTopRightRadius: '0.5rem'
        }}
      >
        <FormAsset
          label="Send"
          symbol={sendAsset.symbol}
          iconName={sendAsset.iconName}
          network={sendAsset.network}
        />

        {sendChildren}
      </Box>

      <Box
        sx={{
          position: 'relative',
          border: 1,
          borderTop: 0,
          borderColor: '#edeff4',
          borderBottomLeftRadius: '0.5rem',
          borderBottomRightRadius: '0.5rem'
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            top: '0',
            left: '50%',
            width: '36px',
            height: '36px',
            color: '#848a98',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f2f4f8',
            transform: 'translate(-50%, -50%)',
            borderRadius: '100%',
            border: 2,
            borderColor: 'white'
          }}
        >
          <ArrowDownward fontSize="small" />
        </Box>

        <Box
          sx={{
            gap: '1.5rem',
            display: 'flex',
            alignItems: 'center',
            padding: '1rem 1.5rem',
            justifyContent: 'space-between',
            borderBottom: 1,
            borderColor: '#edeff4'
          }}
        >
          <FormAsset
            label='Receive'
            network={receiveAsset.network}
            symbol={receiveAsset.symbol}
            iconName={receiveAsset.iconName}
          />

          {receiveChildren}
        </Box>

        <Box
          sx={{
            gap: '1.5rem',
            display: 'flex',
            padding: '1.5rem',
            flexDirection: 'column',
            borderBottom: 1,
            borderColor: '#edeff4'
          }}
        >
          <Box
            sx={{
              gap: '1.5rem',
              display: 'flex'
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexWrap: 'wrap',
                gap: '1rem 2rem'
              }}
            >
              <FormValue
                label="Address"
                value={address}
                isCode
              />

              <Box
                sx={{
                  minWidth: '72px'
                }}
              >
                <FormValue
                  label="Networks"
                  value={network}
                />
              </Box>

              <FormValue
                label='Transaction Minimum'
                value={`${transactionMinimum} ${sendAsset.symbol}` }
              />

              {typeof isMarketOpen === 'boolean' && (
                <Box
                  sx={{
                    minWidth: '72px'
                  }}
                >
                  <FormValue
                    label="Market Status"
                    value={isMarketOpen ? 'Open' : 'Closed'}
                    color={isMarketOpen ? 'success' : 'error'}
                  />
                </Box>
              )}
            </Box>

            <Box
              sx={{
                flexShrink: 0,
                width: '96px',
                height: '96px'
              }}
            >
              {address && (
                <QRCode size={96} value={address} />
              )}

            </Box>
          </Box>
          <Typography
            sx={{
              fontSize: 12
            }}
          >
            {message}
          </Typography>
        </Box>
        <Box>
          <Box
            sx={{
              gap: '1.5rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '1.5rem',
              justifyContent: 'space-between'
            }}
          >
            <Box
              flex={1}
              sx={{
                display: 'flex',
                width: '100%'
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  gap: '1.5rem',
                  display: 'flex'
                }}
              >
                <FormValue
                  label="Wallet Address"
                  value={!web3Context.connected ? 'Not connected' : truncate(web3Context.account!, 16)}
                  Icon={
                    !web3Context.connected ? <NotInterested sx={{ marginLeft: '4px' }} /> :
                      (!connectedWalletStatus.isRegistered || !connectedWalletStatus.isActive ?
                        <InfoOutlinedIcon fontSize="small" /> : <DoneIcon color="success" fontSize="small" />)} />
                {(web3Context.connected && connectedWalletStatus.isRegistered && connectedWalletStatus.isActive) && (
                  <FormValue label="Wallet Balance" value={CryptoFormatter.format(walletBalance, 0, sendAsset.symbol)} />
                )}
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  marginLeft: 'auto'
                }}>
                  {!web3Context.connected &&
                    <ConnectWeb3Button variant="publicDashboard" />
                  }
                  {(web3Context.connected) &&
                    <Button variant="publicDashboardGhost" startIcon={<CachedOutlinedIcon />} onClick={web3Context.switchAccount}
                      sx={{ padding: '0.25rem 1rem !important' }}>Switch Wallet</Button>
                  }
                </Box>
              </Box>
            </Box>
            {
              web3Context.connected && <Box sx={{ width: '100%' }}>
                {!connectedWalletStatus.isRegistered && <Alert severity="warning" icon={false}
                  sx={{
                    '& .MuiAlert-message': {
                      textAlign: 'center',
                      width: '100%'
                    }
                  }}>
                  The connected wallet is not registered in your account
                </Alert>
                }
                {(connectedWalletStatus.isRegistered && !connectedWalletStatus.isActive) && (
                  <Alert
                    severity="warning"
                    icon={false}
                    sx={{
                      '& .MuiAlert-message': {
                        textAlign: 'center',
                        width: '100%'
                      }
                    }}
                  >
                    The connected wallet is not active
                  </Alert>
                )}
                {(connectedWalletStatus.isRegistered && connectedWalletStatus.isActive) && (
                  <Box>
                    <Box sx={{ position: 'relative' }}>
                      <Box
                        sx={{
                          position: 'absolute',
                          zIndex: 1,
                          right: '1rem',
                          top: '1.1875rem',
                          color: '#0066ff',
                          cursor: 'pointer',
                          userSelect: 'none',
                          padding: '0.25rem 0.75rem',
                          backgroundColor: 'white',
                          border: 1,
                          borderColor: '#0066ff',
                          borderRadius: '1rem'
                        }}
                        onClick={() => form.setValue('value', walletBalance)}
                      >
                        <Typography fontSize={12}>
                          Use Max
                        </Typography>
                      </Box>
                      <TextField
                        disabled={!web3Context.connected}
                        key="transaction-amount"
                        label={`Transaction Amount in ${sendAsset.symbol}`}
                        placeholder="Enter Amount"
                        type="text"
                        fullWidth
                        error={!!errors.value}
                        helperText={errors.value?.message}
                        InputLabelProps={{ shrink: true }}
                        {...form.register('value')}
                      />
                    </Box>
                    <LoadingButton
                      variant="publicDashboard"
                      sx={{
                        width: '100%',
                        marginTop: '8px'
                      }}
                      loading={isSubmitting}
                      onClick={onTransfer}
                    >
                      Review Transaction
                    </LoadingButton>
                  </Box>
                )}
              </Box>
            }
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
