import React from 'react';
import { useToggle } from '@backed-fi/hooks';
import { useNavigate } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Avatar, Box, Typography } from '@mui/material';

import { RegisterWalletDialog } from '../../../components';
import { ContentCard } from '../../../components/ContentCard';
import { IssuanceIcon, RedemptionIcon } from '../../../components/icons/comps';
import { TablePlaceholder } from '../../../components/TablePlaceholder';
import { ClientInteractionsQuery, InteractionStatus, useClientInteractionsQuery } from '@backed-fi/graphql';
import { InteractionAssetsList } from '@backed-fi/app/src/app/domain/Transactions/components/InteractionAssetsList';
import { InteractionStatusLabel } from '@backed-fi/app/src/app/domain/Transactions/components/InteractionStatusLabel';

const tableHeaders = [
  'Asset',
  'Action',
  'Status',
  'Date and Time Initiated',
  'Received Assets',
  'Backed Reference ID'
];

// region Props

interface Props {
  onlyHistory?: boolean;
}

// endregion

export const InteractionsTable: React.FC<Props> = ({ onlyHistory }) => {
  const toggle = useToggle();
  const navigate = useNavigate();

  // region Networking

  const {
    loading,
    data,
    previousData
  } = useClientInteractionsQuery({
    variables: {
      where: {
        status: {
          [onlyHistory ? 'in' : 'notIn']: [
            InteractionStatus.Failed,
            InteractionStatus.Completed
          ]
        }
      }
    }
  });

  const { interactions } = (data || previousData || {}) as ClientInteractionsQuery;

  // endregion

  return (
    <>
      <RegisterWalletDialog
        onCreated={toggle.setFalse}
        {...toggle}
      />

      <ContentCard>
        <TableContainer
          sx={{
            minHeight: '280px'
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                {tableHeaders.map((label, key) => (
                  <TableCell key={key}>{label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!interactions?.nodes?.length && (
                <TablePlaceholder
                  message="No pending transactions."
                  isLoading={loading}
                />
              )}

              {!!interactions?.nodes?.length && interactions.nodes.map((interaction) => (
                <TableRow
                  key={interaction.id}
                  onClick={() => navigate(`/interactions/details/${interaction.id}`)}

                  sx={{
                    cursor: 'pointer'
                  }}
                >
                  <TableCell>
                    <Box
                      sx={{
                        gap: '0.5rem',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      { interaction.tokenDeployments?.length > 0 && <>
                        <Avatar
                        alt={interaction.tokenDeployments[0].token.symbol}
                        src={`/assets/icons/assets/${interaction.tokenDeployments[0].token.symbol}.svg`}
                        sx={{
                          width: '36px',
                          height: '36px',
                        }}
                      />
                      <Typography>
                        {interaction.tokenDeployments[0].token.symbol}
                      </Typography></>}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        gap: '0.5rem',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      {
                        interaction.type === 'Redemption'
                          ? <RedemptionIcon width={24} height={24} />
                          : <IssuanceIcon width={24} height={24} />
                      }

                      <Typography>
                        {interaction.type}
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell>
                    <InteractionStatusLabel
                      status={interaction.status}
                    />
                  </TableCell>

                  <TableCell>
                    {new Date(interaction.createdAt).toLocaleString()}
                  </TableCell>

                  <TableCell>
                    <InteractionAssetsList
                      assets={interaction.incomingTransactions.map((x) => ({
                        ...x,
                        assetType: x.type,
                        type: 'Blockchain' as const
                      }))}
                    />
                  </TableCell>

                  {/*<TableCell>*/}
                  {/*  {*/}
                  {/*    interaction.type === 'Redemption'*/}
                  {/*      ? `${interaction.tokensReceived} ${interaction.tokenSymbol}`*/}
                  {/*      : 'N/A'*/}
                  {/*  }*/}
                  {/*</TableCell>*/}

                  {/*<TableCell>*/}
                  {/*  {*/}
                  {/*    interaction.type === 'Issuance'*/}
                  {/*      ? `${interaction.tokensReceived} ${interaction.tokenSymbol}`*/}
                  {/*      : 'N/A'*/}
                  {/*  }*/}
                  {/*</TableCell>*/}

                  <TableCell>
                    {interaction.id}
                  </TableCell>

                  <TableCell>
                    {/*<TransactionActions*/}
                    {/*  explorerUrl={interaction.explorerUrl!}*/}
                    {/*  incomingHash={interaction.incomingHash}*/}
                    {/*/>*/}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ContentCard>
    </>
  );
};
