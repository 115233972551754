import React from 'react';
import { Card, Skeleton, Typography } from '@mui/material';

interface InternalProps {
  title: string;

  data?: string | number;
}

export const InfoCard: React.FC<
  InternalProps & React.ComponentProps<typeof Card>
> = ({ title, data, ...cardProps }) => (
  <Card
    {...cardProps}
    sx={{
      flex: 1,
      pl: '1rem',
      pr: '3rem',
      py: '2rem',
      ...cardProps.sx
    }}
  >
    <Typography variant="heading">
      {typeof data !== 'undefined' ? (
        data
      ) : (
        <Skeleton animation="wave" variant="text" />
      )}
    </Typography>

    <Typography>{title}</Typography>
  </Card>
);
