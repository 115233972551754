import React from 'react';

import { styled } from '@mui/material/styles';
import { Box, ButtonBase, Menu, MenuItem, SxProps, Theme, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { BlockchainNetwork } from '@backed-fi/graphql';
import { BlockchainNetworkLabel } from '@backed-fi/shared/components/compound/BlockchainNetworkLabel';

interface ButtonProps {
  variant?: 'soft' | 'outline';
}

export interface NetworkSelectorConfig {
  network: BlockchainNetwork;
  chainId: number;
}

interface Props extends ButtonProps {
  selectedNetwork: NetworkSelectorConfig;
  availableNetworks: NetworkSelectorConfig[];
  // eslint-disable-next-line no-unused-vars
  onNetworkChange: (network: NetworkSelectorConfig) => void;
  sx?: SxProps<Theme>;
}

const SelectorButton = styled(ButtonBase)(({ variant }: ButtonProps) => ({
  display: 'flex',
  gap: '0.25rem',
  height: '40px',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0.5rem 0.9375rem 0.5rem 0.5rem',
  backgroundColor: '#e2e5eb',
  borderRadius: '20px',

  '&:hover': {
    backgroundColor: '#d4d8e0'
  },

  ...(variant === 'soft' && {
    backgroundColor: '#f2f4f8',

    '&:hover': {
      backgroundColor: '#e2e5eb'
    }
  }),

  ...(variant === 'outline' && {
    backgroundColor: 'white',
    border: 1,
    borderStyle: 'solid',
    borderColor: '#edeff4',

    '&:hover': {
      backgroundColor: '#f2f4f8'
    }
  })
}));

export const NetworkSelector: React.FC<Props> = ({
  selectedNetwork,
  availableNetworks,
  onNetworkChange,
  variant,
  sx
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [menuMinWidth, setMenuMinWidth] = React.useState(0);

  React.useEffect(() => {
    if (anchorEl?.clientWidth) {
      setMenuMinWidth(anchorEl.clientWidth);
    }
  }, [anchorEl]);

  return (
    <Box>
      <SelectorButton
        onClick={(e) => setAnchorEl(!anchorEl ? e.currentTarget : null)}
        variant={variant}
        sx={sx}
      >
        <Typography
          component="div"
          sx={{
            fontWeight: 500,
            fontSize: 14
          }}
        >
          <BlockchainNetworkLabel
            label={`${selectedNetwork.network} Network`}
            network={selectedNetwork.network}
            size={24}
          />
        </Typography>

        <ExpandMoreIcon
          fontSize="small"
        />
      </SelectorButton>

      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {availableNetworks.map((network, index) => (
          <MenuItem
            key={index}
            selected={network === selectedNetwork}
            onClick={() => {
              onNetworkChange(network);
              setAnchorEl(null);
            }}
            sx={{
              minWidth: menuMinWidth,
              fontWeight: 500,
              fontSize: 14
            }}
          >
            <BlockchainNetworkLabel
              network={network.network}
              size={24}
            />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
