import { ClientType } from '@prisma/client';
import { IOnboardingStep } from './onboarding-step-interface';
import { Rejected } from './rejected';
import { Residency } from './residency';
import { UsStatment } from './us-statment';

export const RestrictedJurisdictionsExposure: IOnboardingStep = {
  id: 'restricted-jurisdictions-exposure',
  name: 'Restricted Jurisdictions',
  blocks: (clientContext) => {
    const options = (() => {
      const optionsGenerator = (placeholder: string) => ([
        {
          value: 'true',
          content: `Yes, ${placeholder} a resident of a country listed above`
        },
        {
          value: 'false',
          content: `No, ${placeholder} not a resident of any country listed above`
        }
      ]);

      switch (clientContext.type) {
        case ClientType.Institution:
          return optionsGenerator('my company is');
        case ClientType.Fund:
          return optionsGenerator('my fund is');
        case ClientType.Foundation:
          return optionsGenerator('my foundation is');
        default:
          return optionsGenerator('I am');
      }
    })();

    return [
      {
        type: 'title',
        title: 'Country of residence/domicile'
      },
      {
        type: 'paragraph',
        content: 'Please refer to the list of countries below'
      },
      {
        block: true,
        type: 'list',
        items: [
          'Belarus',
          'Iran',
          'North Korea',
          'Russia'
        ],
        sx: {
          gridTemplateColumns: {
            xxs: '1fr',
            md: '1fr 1fr'
          }
        }
      },
      {
        type: 'checkbox',
        valueKey: 'default',
        title: '',
        options: options
      }
    ];
  },
  shouldRenderStep: (data) => !!data['high-risk-jurisdictions-exposure']?.['default']?.includes('true'),
  getNextStep: (stepData, allSteps, clientContext) => {
    const data = stepData['default'];

    if (data) {
      if (data.includes('true')) {
        if (clientContext.type === ClientType.Individual) {
          return Residency.id;
        }
        return Rejected.id;
      } else {
        return UsStatment.id;
      }
    }
    return undefined;
  }
};
