import { Box, Button, Typography } from '@mui/material';
import { Logo } from '@backed-fi/primitives';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  hideSignIn?: boolean;
  signUp?: boolean;
}

export const UnauthenticatedNavigationBar: React.FC<Props> = ({
  hideSignIn,
  signUp
}) => {
  const navigate = useNavigate();

  const buttonSx = {
    color: 'white !important',
    fontWeight: '500 !important',
    backgroundColor: 'transparent !important',
    borderColor: 'currentColor !important',
    borderRadius: '20px !important'
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '1rem 2rem',
        width: '100vw',
        color: 'white',
        position: 'fixed',
        top: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        zIndex: 10000,
        backdropFilter: 'saturate(180%) blur(20px)'
      }}
    >
      <Box
        sx={{
          height: '40px'
        }}
      >
        <Logo
          style={{
            width: 'auto',
            height: '40px'
          }}
          assets
        />
      </Box>

      {(!hideSignIn && !signUp) && (
        <Box
          sx={{
            gap: '1rem',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography>
            Already have an account?
          </Typography>

          <Button
            onClick={() => {
              navigate('/authenticate');
            }}
            variant="outlined"
            sx={buttonSx}
          >
            Sign In
          </Button>
        </Box>
      )}

      {signUp && (
        <Box
          sx={{
            gap: '1rem',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography>
            Don't have an account?
          </Typography>

          <Button
            onClick={() => {
              window.open('https://backed.fi/contact');
            }}
            variant="outlined"
            sx={buttonSx}
          >
            Contact us to onboard
          </Button>
        </Box>
      )}
    </Box>

  );
};
