import { Box, Typography } from '@mui/material';

export function ForbiddenPage() {
  return (
    <Box>
      <Typography variant="heading">Forbidden</Typography>

      <Typography>You cannot access that page</Typography>
    </Box>
  );
}
