import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

export const MuiBadge: Components<Theme>['MuiBadge'] = {
  styleOverrides: {
    root: ({ theme }) => {
      const { primary } = theme.palette;

      return {
        '.MuiBadge-colorPrimary': {
          backgroundColor: primary.elementBackgroundActive,
          stroke: primary.textLowContrast,
          color: primary.textLowContrast,
        },
      };
    },
  },
};
