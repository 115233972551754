import React from 'react';
import { Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocation, useNavigate } from 'react-router-dom';

interface SideBarItemProps {
  /**
   * The icon that will be rendered
   */
  Icon: React.FC<any>;

  /**
   * The title that will be displayed
   */
  title: string;

  /**
   * The path to which the sidebar item leads
   */
  path?: string;

  /**
   * Boolean, specifying if the current item
   * should be treated as sub item
   */
  subSideBarItem?: boolean;

  /**
   * If the item is the main sidebar item
   * this is list of the definitions for
   * all sub items
   */
  subSideBarItems?: SideBarItemProps[];
}

/**
 * Component that is expandable navigation tile
 */
export const SideBarItem: React.FC<SideBarItemProps> = ({
  Icon,
  title,
  subSideBarItem,
  subSideBarItems,
  ...props
}) => {
  // ---- Hooks ---- //
  const location = useLocation();
  const navigate = useNavigate();

  // ---- State ---- //
  const [active, setActive] = React.useState<boolean>(false);
  const [expanded, setExpanded] = React.useState<boolean>(false);

  // region Effects

  React.useEffect(() => {
    if (props.path) {
      setActive(location.pathname.endsWith(props.path));
    }

    // Expand item if a sub item is linked to outside of sidebar navigation
    if (subSideBarItems?.length) {
      for (const { path } of subSideBarItems) {
        if (path && location.pathname.endsWith(path)) {
          setExpanded(true)
        }
      }
    }
  }, [location, subSideBarItems]);

  // endregion

  // ---- Actions ---- //
  const onItemClick = () => {
    // If there are sub items - toggle the expansion
    if (subSideBarItems?.length) {
      // Navigate to first sub item if parent item does not link to a path
      // Only applies to non-expanded items without active sub item
      if (!expanded && !props.path) {
        const activeSubSideBarItem = subSideBarItems.find(
          (item) => item.path && location.pathname.endsWith(item.path)
        )

        if (!activeSubSideBarItem && subSideBarItems[0].path) {
          navigate(subSideBarItems[0].path)
        }
      }

      setExpanded((p) => !p);
    }

    // Check if there is path set, if there is - navigate
    if (props.path) {
      navigate(props.path);
    }
  };


  return (
    <Box
      sx={{
        cursor: 'pointer',
        userSelect: 'none',

        ...(subSideBarItem && {
          marginX: '1rem'
        })
      }}
    >
      <Box
        onClick={onItemClick}
        sx={{
          width: '100%',
          color: 'black',
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          padding: '0.5rem 1rem',
          marginBottom: '0.125rem',
          gap: '0.75rem',
          borderRadius: '0.5rem',

          ...(active && ({
            backgroundColor: '#f2f4f8'
          })),

          '&:hover': {
            backgroundColor: '#f2f4f8'
          }
        }}
      >
        <Icon
          sx={{
            width: subSideBarItem ? '20px' : '24px',
            height: 'auto',
            color: '#a4aab6',

            ...(active && ({
              color: '#0066ff'
            }))
          }}
        />

        <Typography
          sx={{
            fontSize: subSideBarItem ? 14 : 16,
            letterSpacing: 0
          }}
        >
          {title}
        </Typography>

        {!!subSideBarItems?.length && (
          <ExpandMoreIcon
            sx={{
              marginLeft: 'auto',
              color: '#a4aab6',

              ...(expanded && {
                transform: 'rotate(180deg)'
              })
            }}
          />
        )}
      </Box>

      {expanded &&
        subSideBarItems?.length &&
        subSideBarItems.map((item, index) => (
          <SideBarItem subSideBarItem key={index} {...item} />
        ))}
    </Box>
  );
};
