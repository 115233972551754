import React from 'react';
import { decodeJwt } from 'jose';

import { LocalStorage } from '@backed-fi/constants';

import isFuture from 'date-fns/isFuture';
import { isPast } from 'date-fns';

// region Types

interface AuthContextPayloadType {
  // Boolean, indicating whether the user is currently authenticated
  authenticated: boolean;
}

interface AuthContextHelpersType {
  /**
   * Function, used to properly set the authentication token the
   * user has successfully been authenticated
   *
   * @param token - The valid JWT auth token
   */
  authenticate: (token: string) => void;

  /**
   * Function, used for logging out the currently authenticated user
   */
  logout: () => void;
}

type AuthContextType = AuthContextHelpersType & AuthContextPayloadType;


// endregion


// region Temporary Helpers

const isTokenValid = () => {
  const token = localStorage.getItem(LocalStorage.AuthToken);

  if (!token) {
    return false;
  }

  const decodedToken = decodeJwt(token);

  return isFuture(new Date((decodedToken.exp || 0) * 1000));
};

const decodeToken = () => {
  const token = localStorage.getItem(LocalStorage.AuthToken);

  if (!token) {
    return {};
  }

  return decodeJwt(token);
};

// endregion

const defaultContextData: AuthContextPayloadType = {
  authenticated: isTokenValid()
};

const AuthContext = React.createContext<AuthContextType>(defaultContextData as any);

export const AuthContextProvider: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  // region State

  const [authenticated, setAuthenticated] = React.useState<boolean>();
  const [authContextState, setAuthContextState] = React.useState<AuthContextPayloadType>(defaultContextData);

  // endregion

  // region Actions

  const authenticate: AuthContextHelpersType['authenticate'] = async (token) => {
    localStorage.setItem(LocalStorage.AuthToken, token);

    setAuthContextState((prevState) => ({
      ...prevState,
      authenticated: true
    }));
  };

  const logout: AuthContextHelpersType['logout'] = () => {
    localStorage.removeItem(LocalStorage.AuthToken);

    setAuthContextState((prevState) => ({
      ...prevState,
      authenticated: false
    }));
  };

  // endregion

  // region Effects

  React.useEffect(() => {
    const interval = setInterval(() => {
      const decodedToken = decodeToken();

      if (decodedToken.exp && isPast(new Date((decodedToken.exp || 0) * 1000))) {
        setAuthContextState(p => ({
          ...p,
          authenticated: false
        }));
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };

  }, []);

  // endregion

  return (
    <AuthContext.Provider
      value={{
        ...authContextState,
        authenticate,
        logout
      }}
      children={children}
    />
  );
};

export const useAuthContext = () => React.useContext(AuthContext);
