import { Box, TextField, Typography } from '@mui/material';
import React from 'react';
import { LoadingButton } from '@mui/lab';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { gql } from '@apollo/client';
import { useContactUsMutation } from '@backed-fi/graphql';


// region Props

interface Props {
  /**
   * Optional context about what the client might be contacting us
   */
  context?: string;

  /**
   * Boolean, indicating whether the form should render a title, or string with its content
   */
  title?: boolean | string;

  /**
   * Boolean, indicating the subtitle to render, or string with its content
   */
  subtitle?: boolean | string;
}

// endregion

// region Form Control

const Schema = z.object({
  /**
   * The email of the client that wants to contact us
   */
  email: z.string()
    .email(),

  /**
   * The name of the client
   */
  name: z.string(),

  /**
   * Optional name of the company if the user is representing institutional client
   */
  company: z.string()
    .optional(),

  /**
   * Optional message
   */
  message: z.string()
    .optional()
    .nullable()
});

// endregion

// region Graph

gql`
  mutation contactUs($input: ContactInput!) {
    contact(input: $input)
  }
`;

// endregion

export const ContactForm: React.FC<Props> = ({
  title,
  subtitle,
  context
}) => {
  const [contactMutation, { data }] = useContactUsMutation();

  // region Form Control

  const form = useForm<z.infer<typeof Schema>>({
    resolver: zodResolver(Schema)
  });

  const {
    isSubmitting,
    errors,
    isSubmitSuccessful
  } = form.formState;

  // endregion

  // region Actions

  const onSubmit = form.handleSubmit(async (data) => {
    await contactMutation({
      variables: {
        input: {
          email: data.email,

          name: data.company
            ? `${data.name} representing ${data.company}`
            : data.name,

          message:
            `Context: \n${context ?? 'No system context'} \n\n\n` +
            `Message: \n${data.message ?? 'No message provided'}`
        }
      }
    });
  });

  // endregion

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column'
      }}
    >
      {!isSubmitSuccessful && (
        <React.Fragment>
          {title && (
            <Typography
              mb={1}
              variant="titleSmall"
            >
              {typeof title === 'string' ? title : 'Contact Us'}
            </Typography>
          )}

          {subtitle && (
            <Typography
              mb={1}
              variant="subtitleSmall"
            >
              {typeof subtitle === 'string' ? subtitle : 'A member of our team will get to you as soon as possible'}
            </Typography>
          )}

          <TextField
            fullWidth
            label="Full Name"
            error={!!errors.name}
            helperText={errors.name?.message}
            {...form.register('name')}
          />

          <TextField
            fullWidth
            label="Company (optional)"
            error={!!errors.company}
            helperText={errors.company?.message}
            {...form.register('company')}
          />

          <TextField
            fullWidth
            label="Contact Email"
            error={!!errors.email}
            helperText={errors.email?.message}
            {...form.register('email')}
          />

          <TextField
            fullWidth
            multiline
            rows={3}
            label="Message"
            error={!!errors.message}
            helperText={errors.message?.message}
            {...form.register('message')}
          />

          <LoadingButton
            variant="publicDashboard"
            loading={isSubmitting}
            onClick={onSubmit}
            sx={{
              width: '200px',
              alignSelf: 'end'
            }}
          >
            Submit
          </LoadingButton>
        </React.Fragment>
      )}

      {isSubmitSuccessful && (
        <Box
          sx={{
            my: '3rem',
            textAlign: 'center'
          }}
        >
          <Typography variant="titleSmall">
            Thank You for Reaching Out
          </Typography>

          <Typography variant="subtitleSmall">
            A member of our team will be in touch shortly
          </Typography>
        </Box>
      )}
    </Box>
  );
};
