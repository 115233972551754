import React from 'react';
import { useSnackbar } from 'notistack';

import { Box, IconButton, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface Props {
  label: string;
  value: string;
  Icon?: React.ReactElement;
  isCode?: boolean;
  color?: 'success' | 'error';
}

export const FormValue: React.FC<Props> = ({
  label,
  value,
  color,
  Icon,
  isCode
}) => {
  const snackbar = useSnackbar();

  return (
    <Box>
      <Typography
        sx={{
          fontSize: 12,
          color: '#848a98'
        }}
      >
        {label}
      </Typography>

      <Typography
        variant={isCode ? 'code' : undefined}
        sx={{
          minHeight: '21px',
          gap: '0.25rem',
          display: 'flex',
          alignItems: 'center',
          fontSize: 14,

          ...(color && ({
            color: `${color}.textLowContrast`
          }))
        }}
      >
        {value}

        {Icon}

        {(!Icon && isCode) && (
          <IconButton
            color='inherit'
            onClick={() => {
              navigator.clipboard.writeText(value).then(() => {
                snackbar.enqueueSnackbar('Copied address to clipboard', {
                  variant: 'success'
                });
              });
            }}
          >
            <ContentCopyIcon fontSize='small' />
          </IconButton>
        )}
      </Typography>
    </Box>
  );
};
