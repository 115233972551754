import * as React from 'react';
import { forwardRef, Ref, SVGProps } from 'react';

const SvgSettings = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <defs>
      <clipPath id='Settings_svg__a'>
        <path data-name='Rectangle 3' fill='#01000d' d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
    <g data-name='Group 289' clipPath='url(#Settings_svg__a)' opacity={0.6}>
      <path
        data-name='Path 145'
        d='M15.027 24H8.973v-3.513A9.1 9.1 0 0 1 6.1 18.841L3.027 20.6 0 15.4l3.07-1.755a9.021 9.021 0 0 1 0-3.29L0 8.6l3.027-5.2L6.1 5.159a9.1 9.1 0 0 1 2.873-1.646V0h6.054v3.513A9.1 9.1 0 0 1 17.9 5.159L20.973 3.4 24 8.6l-3.07 1.755a9.021 9.021 0 0 1 0 3.29L24 15.4l-3.027 5.2-3.073-1.758a9.1 9.1 0 0 1-2.875 1.645Zm-4.036-2h2.018v-3.027l.758-.194a7.061 7.061 0 0 0 3.272-1.879l.548-.553 2.647 1.515 1.009-1.732-2.643-1.513.208-.746a6.988 6.988 0 0 0 0-3.75l-.208-.746 2.644-1.513-1.011-1.732-2.646 1.519-.548-.549a7.062 7.062 0 0 0-3.272-1.875l-.758-.2V2h-2.018v3.027l-.758.194A7.062 7.062 0 0 0 6.961 7.1l-.548.553-2.646-1.519-1.009 1.732L5.4 9.379l-.208.746a6.988 6.988 0 0 0 0 3.75l.208.746-2.642 1.513 1.009 1.732 2.647-1.515.548.553a7.062 7.062 0 0 0 3.272 1.875l.758.194ZM12 16a4 4 0 1 1 4.036-4A4.018 4.018 0 0 1 12 16m0-6a2 2 0 1 0 2.018 2A2.009 2.009 0 0 0 12 10'
        fill='#01000d'
      />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgSettings);
export default ForwardRef;
