import React from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { AdminPermission, ClientFeature } from '@backed-fi/graphql';
import { Shortcut } from '@backed-fi/compound';
import { useClientContext } from '@backed-fi/context';
import { useAdminContext } from '../../../../apps/admin/src/app/context/AdminContext';

interface InternalProps {
  client?: boolean;
  title: string;
  path: string;

  feature?: ClientFeature;
  permission?: AdminPermission;
  onNavigate?: () => any;

  Icon: React.FC;

  sections?: {
    title: string;
    path: string;

    shortcut?: string;

    feature?: ClientFeature;
    permission?: AdminPermission;
  }[];
}

export const NavigationTile: React.FC<InternalProps> = ({
  Icon,
  path,
  title,
  sections,
  ...props
}) => {
  const clientContext = useClientContext();
  const adminContext = useAdminContext();
  const location = useLocation();
  const rawNavigate = useNavigate();

  const navigate = (path: string) => {
    if (typeof props.onNavigate === 'function') {
      props.onNavigate();
    }

    rawNavigate(path);
  };

  // ---- Component State ---- //
  const [open, setOpen] = React.useState<boolean>();
  const [availableSection, setAvailableSections] =
    React.useState<typeof sections>();

  // ---- Component effects ---- //
  React.useEffect(() => {
    setOpen(
      sections
        ? sections.some((s) => location.pathname === `${path}${s.path}`)
        : location.pathname.endsWith(path)
    );
  }, [location.pathname]);

  React.useEffect(() => {
    setAvailableSections(
      sections?.filter((section) => {
        if (
          section.feature &&
          !clientContext?.features?.includes(section.feature)
        ) {
          return false;
        }

        if (
          section.permission &&
          !adminContext?.permissions.includes(section.permission)
        ) {
          return false;
        }

        return true;
      })
    );
  }, [sections, clientContext?.features, adminContext?.permissions]);

  // ---- Actions ---- //
  const onMainClick = () => {
    if (sections) {
      setOpen((prev) => !prev);
    } else if (path.includes('mailto')) {
      window.open(path);
    } else {
      navigate(path);
    }
  };

  const onSectionClick = (sectionPath: string) => () => {
    navigate(path + sectionPath);
  };

  // If we had sections provided, but none of them will be shown do not render the tile at all
  if (sections?.length && !availableSection?.length) {
    return null;
  }

  // Check if there are required permissions/feature on the root
  if (props.feature && !clientContext?.features?.includes(props.feature)) {
    return null;
  }

  if (props.permission && !adminContext.permissions.includes(props.permission)) {
    return null;
  }

  return (
    <Box
      sx={({ palette }) => {
        const { primary } = palette;

        return {
          display: 'flex',
          flexFlow: 'column',
          userSelect: 'none',
          marginTop: '.25rem',
          borderRadius: '4px',

          border: '1px solid transparent',

          color: primary.textHighContrast,

          '&:hover': {
            ...(!open && {
              backgroundColor: primary.elementBackgroundHovered,
              color: primary.textHighContrast
            })
          },

          // Show the border if there are no sections and the tile is "open"
          ...(open &&
            !sections && {
            borderColor: primary.borderElement,
            backgroundColor: primary.elementBackground,

            '&:hover': {
              borderColor: primary.borderHovered,
              backgroundColor: primary.elementBackgroundHovered
            }
          })
        };
      }}
    >
      <Box
        onClick={onMainClick}
        display="flex"
        sx={{
          cursor: 'pointer',

          padding: '.5rem'
        }}
      >
        <Icon />

        <Typography
          sx={{
            marginLeft: '.45rem'
          }}
        >
          {title}
        </Typography>
      </Box>

      {/* If the tile is open and there are sections - show them */}
      {open && availableSection && (
        <Box
          sx={{
            // marginTop: '.75rem',
            marginLeft: '1rem',
            display: 'flex'
          }}
        >
          <Box
            sx={({ palette }) => {
              const { primary } = palette;

              return {
                height: '100%',
                width: '3px',
                borderRadius: '1.5px',
                backgroundColor: primary.borderSubtle
              };
            }}
          />

          <Box flex={1}>
            {availableSection.map((section, index) => {
              const active = location.pathname.includes(path + section.path);

              return (
                <Box
                  key={index}
                  onClick={onSectionClick(section.path)}
                  sx={({ palette }) => {
                    const { primary } = palette;

                    return {
                      margin: '.5rem',
                      cursor: 'pointer',
                      padding: '.5rem .75rem',
                      borderRadius: '4px',
                      display: 'flex',
                      justifyContent: 'space-between',

                      '&:hover': {
                        backgroundColor: primary.elementBackgroundHovered
                      },

                      ...(active && {
                        backgroundColor: primary.elementBackgroundActive,
                        fontWeight: '500'
                      })
                    };
                  }}
                >
                  <Typography
                    sx={{
                      ...(active && {
                        fontWeight: 'bolder'
                      })
                    }}
                  >
                    {section.title}
                  </Typography>

                  {section.shortcut && <Shortcut>{section.shortcut}</Shortcut>}
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};
