import React from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { gql } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';

import { useClientContext } from '@backed-fi/context';
import { useResetQuestionnaireMutation } from '@backed-fi/graphql';
import { OnboardingConfiguration } from '../configuration';
import { useOnboardingContext } from '../OnboardingContext';

// region Graph

gql`
  mutation resetQuestionnaire($input: ResetClientQuestionnaireInput!) {
    resetClientQuestionnaire(input: $input)
  }
`;

// endregion

export const OnboardingQuestionnaireSummaryPage = () => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const {
    steps,
    questionnaireId
  } = useOnboardingContext();
  const clientContext = useClientContext();

  const [resetQuestionnaireMutation, { loading: resettingQuestionnaire }] = useResetQuestionnaireMutation();

  const answers = React.useMemo(() => {
    return OnboardingConfiguration.steps
      .filter((step) => (
        step.shouldRenderStep(steps, clientContext)
      )).map((step) => {
        const blocks = step.blocks(clientContext, steps[step.id]);

        return {
          name: step.name,
          // Move multi selection to the bottom of the answer's array
          answers: Object.entries(steps[step.id] ?? {}).sort(([_, value1]) => {
            return Array.isArray(value1) ? 1 : -1;
          }).reduce((acc, [key, value]) => {
            const block: any = blocks.find((b: any) => b.valueKey === key);

            if (block) {
              return [...acc, ...(Array.isArray(value) ? value : [value]).map((v) => {
                if (block.options) {
                  const option = block.options.find((op: any) => op.value === v);
                  return `${option.content}`;
                }

                return `${key}: ${v}`;
              })];
            }

            return acc;
          }, [] as string[])
        };
      });
  }, [steps]);

  React.useEffect(() => {
    if (!questionnaireId) {
      navigate('/onboarding');
    }
  }, [questionnaireId]);

  const onContinue = async () => {
    await navigate('/onboarding/questionnaire/completed?result=success');
  };
  const onReset = async () => {
    try {
      await resetQuestionnaireMutation({
        variables: {
          input: {
            questionnaireId: questionnaireId!
          }
        },
        refetchQueries: [
          'onboardingRouting'
        ],
        awaitRefetchQueries: true
      });

      snackbar.enqueueSnackbar('Questionnaire successfully reset', {
        variant: 'success'
      });

      await navigate('/onboarding');
    } catch (e) {
      snackbar.enqueueSnackbar('Failed to reset questionnaire', {
        variant: 'error'
      });
    }
  };

  return (
    <Box>
      <Typography variant="titleSmall">
        Summary
      </Typography>


      <Typography variant="subtitleSmall" sx={{ marginBottom: '16px' }}>
        Please verify that your answers are correct
      </Typography>

      {questionnaireId && <Box>
        {answers.map((step, index) => <>
          <Typography variant='titleSmall' key={index}>{index + 1}. {step.name}:</Typography>
          {step.answers.map((answer: string, i: number) => (
            <Typography key={`${step.name}-${i}`}
              variant='subtitleSmall'
              sx={{
                '&::first-letter': {
                  textTransform: 'uppercase'
                },
                paddingBottom: '8px'
              }}
            >
              {answer}
            </Typography>
          ))}
        </>)}
      </Box>}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '16px'
        }}
      >
        <LoadingButton
          loading={resettingQuestionnaire}
          variant="publicDashboardGhost"
          sx={{
            marginRight: '8px'
          }}
          onClick={onReset}
        >
              Reset form
        </LoadingButton>
        <Button
          variant="publicDashboard"
          onClick={onContinue}
        >
              Continue
        </Button>
      </Box>
    </Box>
  );
};
