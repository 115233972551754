import React from 'react';
import { AppBar, Badge, Box, IconButton, Typography } from '@mui/material';
import { Logout } from '@mui/icons-material';

import { Logo } from '@backed-fi/primitives';
import { useClientContext } from '@backed-fi/context';
import { useAuthContext, useUserContext } from '@backed-fi/context';

export const NavigationBar: React.FC = ({ ...props }) => {
  const clientContext = useClientContext();
  const authContext = useAuthContext();
  const { user } = useUserContext();

  const onLogout = async () => {
    await authContext.logout();
  };

  const [status, setStatus] = React.useState<{
    title: string;
    color: string;
  }>({} as any);

  React.useEffect(() => {
    if (!clientContext.loading) {
      if (clientContext.shouldRegisterWallet || clientContext.shouldActivateWallet) {
        setStatus((prev) => ({
          ...prev,
          title: 'Pending Activation',
          color: 'warning.solidBackground'
        }));
      } else {
        setStatus((prev) => ({
          ...prev,
          title: `Active${!clientContext.isProfessional ? ' - Redemption Only' : ''}`,
          color: 'success.solidBackground'
        }));
      }
    }
  }, [clientContext]);

  return (
    <AppBar
      position='fixed'
      elevation={0}
      {...props}
      sx={({ zIndex }) => ({
        zIndex: zIndex.drawer + 1,
        backgroundColor: 'black'
      })}
    >
      <Box
        sx={{
          p: '1.25rem 2rem',
          display: 'flex',
          height: '80px',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Logo full />
        </Box>

        <Box
          sx={{
            gap: '0.75rem',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              flexDirection: 'column'
            }}
          >
            <Typography
              sx={{
                fontSize: 14
              }}
            >
              {user?.firstName} {user?.lastName}
            </Typography>

            <Box
              sx={{
                gap: '0.625rem',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Badge
                variant='dot'
                sx={{
                  '.MuiBadge-badge': {
                    backgroundColor: status.color
                  }
                }}
              />

              <Typography
                sx={{
                  fontSize: 12,
                  color: 'utility.solidBackground'
                }}
              >
                {status.title}
              </Typography>
            </Box>
          </Box>

          <IconButton
            onClick={onLogout}
            aria-label='logout'
            color='inherit'
          >
            <Logout fontSize='small' />
          </IconButton>
        </Box>
      </Box>
    </AppBar>
  );
};
