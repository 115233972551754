import React from 'react';

import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';

import LogoutIcon from '@mui/icons-material/LogoutRounded';
import ExpandIcon from '@mui/icons-material/UnfoldMoreRounded';

import { useAuthContext } from '@backed-fi/context';
import { useNavigate } from 'react-router-dom';

export const UserCardMenu = () => {
  const navigate = useNavigate();
  const authContext = useAuthContext();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // ---- Actions ---- //
  const onClose = () => {
    setAnchorEl(null);
  };

  const onSignOut = async () => {
    setAnchorEl(null);

    await authContext.logout();

    navigate('/authenticate');
  };

  return (
    <>
      <IconButton
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        <ExpandIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={onSignOut}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>

          <ListItemText>Sign Out</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
