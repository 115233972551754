import React from 'react';
import { Avatar, Box, Card, Typography } from '@mui/material';
import UserIcon from '@mui/icons-material/PersonOutlineRounded';

import { useUserContext } from '@backed-fi/context';
import { UserCardMenu } from './UserCardMenu';

export const UserCard: React.FC = () => {
  const { user } = useUserContext();

  return (
    <Card
      sx={{
        p: '.5rem',
        display: 'flex',
      }}
    >
      <Avatar
        sx={{
          mr: '.5rem',
        }}
      >
        <UserIcon />
      </Avatar>

      <Box>
        <Typography
          sx={{
            fontWeight: 'bolder',
          }}
        >
          {user?.firstName} {user?.lastName}
        </Typography>

        <Typography
          sx={{
            fontWeight: 'thin',
            fontSize: '10px',
          }}
        >
          {user?.email}
        </Typography>
      </Box>

      <Box
        sx={{
          marginLeft: 'auto',
        }}
      >
        <UserCardMenu />
      </Box>
    </Card>
  );
};
