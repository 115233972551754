import { Annex1 } from './steps/annex-1';
import { Annex2 } from './steps/annex-2';
import { EEAResidency } from './steps/eea-residency';
import { Facta } from './steps/facta';
import { InvestorWarning } from './steps/jersey-investor-warning';
import { IOnboardingStep } from './steps/onboarding-step-interface';
import { ProfessionalInvestor } from './steps/professional-investor';
import { Rejected } from './steps/rejected';
import { RestrictedJurisdictionsExposure } from './steps/restricted-jurisdictions-exposure';
import { Residency } from './steps/residency';
import { Summary } from './steps/summary';
import { TokenHolder } from './steps/token-holder';
import { UsStatment } from './steps/us-statment';
import { HighRiskJurisdictionsExposure } from './steps/high-risk-jurisdictions-exposure';

interface OnboardingConfigurationDefinition {
  /**
   * The version of the onboarding configuration. Used to make sure
   * we keep onboarding clients with the same version of the onboarding
   * even if we change the onboarding after they start their onboarding
   */
  version: number;

  /**
   * List of the onboarding steps that the client needs to complete
   */

  steps: IOnboardingStep[];
}

export const OnboardingConfiguration: OnboardingConfigurationDefinition = {
  version: 1,
  steps: [
    ProfessionalInvestor,
    TokenHolder,
    HighRiskJurisdictionsExposure,
    RestrictedJurisdictionsExposure,
    Residency,
    EEAResidency,
    Annex1,
    Annex2,
    UsStatment,
    Facta,
    InvestorWarning,
    // endregion

    // -- Temp Steps
    Summary,
    Rejected
  ]
};
