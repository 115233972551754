import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { gql } from '@apollo/client';
import { ClientClassification, ClientQuestionnaireResult, ClientType, useFinaliseQuestionnaireMutation } from '@backed-fi/graphql';
import { useNavigate } from 'react-router-dom';
import { useOnboardingContext } from '@backed-fi/app/src/app/domain/Onboarding/OnboardingContext';
import { useClientContext } from '@backed-fi/context';

import DocumentIcon from '@mui/icons-material/DescriptionRounded';
import * as jspdf from 'jspdf';

// region Graph

gql`
  mutation finaliseQuestionnaire($input: FinaliseClientQuestionnaireInput!) {
    finaliseClientQuestionnaire(input: $input) {
      id

      result
    }
  }
`;

// endregion

// region Configuration

const Documents = {
  [ClientType.Individual]: {
    mandatory: [
      'Recent Proof of Address (less than 6 months) (e.g. utility bill, bank statement, tax slip , letter / attestation from a public authority etc) of all legal representatives and shareholders (with 25% or more)\n' +
      'One certified Proof of Address*, OR 2 uncertified Proof of Address'
    ],
    relevant: [
      'Tax Slip - electronically certified or original copy*',
      'Annual Report of your Business(es) - electronically certified or original copy*',
      'Invoice - electronically certified or original copy*',
      'Inheritance Deed - electronically certified or original copy*',
      'Sale of Shares Certificate - electronically certified or original copy*',
      'Any other document attesting Source of Funds - electronically certified or original copy*'
    ]
  },


  [ClientType.Institution]: {
    mandatory: [
      'Incorporation Deed / Articles of Association*',
      'Commercial Registry Extract (less than 6 months old) - electronically certified or original copy*',
      'Share Register (less than 6 months) - electronically certified or original copy*',
      'FATCA/CRS form (to be filled and electronically signed** for your entity and for each controlling person (if Passive NFE))'
    ],
    relevant: [
      'Audited or unaudited latest Financial statements of the company - electronically certified or original copy*. If there are none, a declaration of income and assets signed by a director',
      'Annual Report- electronically certified or original copy*',
      'Overview of Shareholding Structure Chart (incl. all shareholders & UBOs above 25%))',
      'Organisational Chart',
      'Invoice - electronically certified or original copy*',
      'Any other document attesting Source of Funds - electronically certified or original copy*',
      'Proof of Place of Business Address (if different from Registered Address) - electronically certified or original copy*',
      'UBO Proof of Address / Residence - one electronically certified* OR 2 uncertified'
    ]
  }
};

// endregion

// region Supporting Components

const DocumentRow: React.FC<{
  notes?: string[],
  document: string,
}> = ({
  notes,
  document
}) => {
  return (
    <Box
      sx={{
        mb: notes?.length
          ? '1rem'
          : '.5rem'
      }}
    >
      <Box
        sx={{
          display: 'flex'
        }}
      >
        <Box
          sx={{
            width: '30px'
          }}
        >
          <DocumentIcon
            sx={{
              fontSize: '22px',
              color: 'utility.solidBackground'
            }}
          />
        </Box>

        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: '500',
            width: '100%'
          }}
        >
          {document}
        </Typography>
      </Box>

      {notes && notes.map((note, index) => (
        <Box key={index}
          sx={{
            mb: '4px',
            ml: '30px',
            display: 'grid',
            gridTemplateColumns: '16px 1fr'
          }}
        >
          <Box
            sx={{
              mt: '4px',
              width: '8px',
              height: '8px',
              border: '1px solid',
              borderRadius: '4px',
              borderColor: 'utility.borderElement'
            }}
          />

          <Typography
            key={index}
            sx={{
              fontSize: '12px',
              fontWeight: '400',
              color: 'utility.textLowContrast'
            }}
          >
            {note}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

// endregion

export const OnboardingQuestionnaireResultPage: React.FC = () => {
  const navigate = useNavigate();
  const context = useOnboardingContext();
  const clientContext = useClientContext();
  const documentsContainerId = React.useId();

  const finalised = React.useRef(false);
  const [loaded, setLoaded] = React.useState(false);

  const documents = React.useMemo<typeof Documents[ClientType.Institution]>(() => {
    let value = Documents[clientContext.type as ClientType.Institution | ClientType.Individual] as any;

    return value;
  }, [clientContext.type, clientContext.classification]);

  const [finaliseQuestionnaireMutation] = useFinaliseQuestionnaireMutation();

  React.useEffect(() => {
    (async () => {
      if (!finalised.current) {
        finalised.current = true;

        await context.saveData();

        const { result } = (
          await finaliseQuestionnaireMutation({
            variables: {
              input: {
                questionnaireId: context.questionnaireId!
              }
            }
          })
        ).data?.finaliseClientQuestionnaire!;

        if (result !== ClientQuestionnaireResult.Pass) {
          navigate('/rejected', {
            state: {
              from: 'questionnaire'
            }
          });
        } else {
          setLoaded(true);
        }
      }
    })();
  }, []);

  const onContinue = async () => {
    await navigate('/');
  };

  const downloadDocumentsList = () => {
    const doc = new jspdf.jsPDF('p', 'px', [620, 877]);

    doc
      .html(
        `<div style="padding: 32px; width: 596px">
        ${window.document.getElementById(documentsContainerId)?.innerHTML ?? ''}</div>`,
        {
          margin: [64, 0]
        }
      )
      .save('Backed Required Document.pdf');
  };

  return (
    <Box>
      {!loaded && (
        <Typography>
          Loading...
        </Typography>
      )}

      {loaded && (
        <Box
          sx={{
            width: '100%'
          }}
          id={documentsContainerId}
        >
          <Typography
            sx={{
              fontSize: '22px',
              fontWeight: '500'
            }}
          >
            KYC Documents
          </Typography>

          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: '400'
            }}
          >
            You are now going to do KYC with our KYC provider. Before you do make sure
            you have all the required documents
          </Typography>

          {documents?.mandatory && (
            <Box mt="2rem">
              <Typography
                mb=".5rem"
                variant="titleSmall"
              >
                Mandatory Documents
              </Typography>

              {documents.mandatory.map((document, index) => (
                <DocumentRow
                  key={index}
                  document={document}
                />
              ))}
            </Box>
          )}


          {documents?.relevant && (
            <Box mt="1rem">
              <Typography
                mb=".5rem"
                variant="titleSmall"
              >
                Also Relevant (if Applicable)*
              </Typography>

              {documents.relevant.map((document, index) => (
                <DocumentRow
                  key={index}
                  document={document}
                />
              ))}
            </Box>
          )}

          <Box>
            <Typography
              mb=".5rem"
              variant="titleSmall"
            >
              *Certification requirements
            </Typography>

            <Typography variant="subtitleSmall" sx={{ marginBottom: '16px' }}>
              The original document can be provided or a certified true copy with the certification worded as follows:
            </Typography>
            <Typography variant="subtitleSmall" sx={{ marginBottom: '16px',
              fontStyle: 'italic' }}>
              “I certify that this is a true copy of the original taken on the [ ] day of [ ] 20XX.”
            </Typography>
            <Typography variant="subtitleSmall" sx={{ marginBottom: '16px' }}>
              The certifier should then sign the certification and print their name and occupation and/or professional
              qualification and contact details.The certification should then be dated.

              All certifications should be signed and dated by the certifier, with the name of the certifier,
              their occupation and/or professional qualifications  (e.g. membership of professional
              organisation and membership number) and contact details.  Certifications can be provided
              electronically as long as a suitable electronic signing software package is used such as Docusign,
              and the signing certificate is provided with the document
            </Typography>
          </Box>
          <Box>

            <Typography
              mb=".5rem"
              variant="titleSmall"
            >
              **Electronic Signatures
            </Typography>

            <Typography variant="subtitleSmall" sx={{ marginBottom: '16px' }}>
              Through accepted electronic signature service provider under Jersey law, i.e. DocuSign
            </Typography>
          </Box>

          <Box
            sx={{
              gap: '1rem',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              variant="publicDashboardGhost"
              onClick={downloadDocumentsList}
            >
              Download Document List
            </Button>

            <Button
              variant="publicDashboard"
              onClick={onContinue}
            >
              Continue
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};
