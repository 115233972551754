import React from 'react';

import { Box, Typography } from '@mui/material';

interface Props extends React.PropsWithChildren {
  label: string;
}

export const AccountLabel: React.FC<Props> = ({ label, children }) => {
  return (
    <Box>
      <Typography
        sx={{
          color: '#848a98',
          fontSize: 12
        }}
      >
        {label}
      </Typography>

      <Typography
        sx={{
          fontSize: 14
        }}
      >
        {children}
      </Typography>
    </Box>
  )
}
