import React from 'react';
import { Box } from '@mui/material';
import { InteractionsTable } from '@backed-fi/app/src/app/domain/Transactions/components/InteractionsTable';

export const InteractionsHistoryPage: React.FC = () => {
  return (
    <Box>
      {/* region Transaction History */}

      <Box
        sx={{
          mt: '40px'
        }}
      >
        <InteractionsTable onlyHistory />
      </Box>

      {/* endregion */}
    </Box>
  );
};
