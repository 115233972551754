import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

export const MuiSkeleton: Components<Theme>['MuiSkeleton'] = {
  defaultProps: {
    animation: 'wave',
  },
  styleOverrides: {
    root: () => ({}),
  },
};
