import * as React from 'react';
import { forwardRef, Ref, SVGProps } from 'react';

const SvgHome = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    data-name='home icon_title'
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 32 32.002'
    ref={ref}
    {...props}
  >
    <path
      data-name='home icon'
      d='m30.465 10.242-12-9.391a4 4 0 0 0-4.933 0l-12 9.391A3.977 3.977 0 0 0 0 13.393v18.609h32V13.393a3.977 3.977 0 0 0-1.535-3.151Zm-1.132 19.093H2.666V13.393a1.325 1.325 0 0 1 .512-1.051l12-9.391a1.333 1.333 0 0 1 1.643 0l12 9.391a1.325 1.325 0 0 1 .512 1.051Z'
      fill='#01000d'
    />
  </svg>
);

const ForwardRef = forwardRef(SvgHome);
export default ForwardRef;
