import React from 'react';

import { Typography } from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface Props {
  to: LinkProps['to'];
  label: string;
}

export const ArrowLink: React.FC<Props> = ({ to, label }) => {
  return (
    <Link to={to}>
      <Typography
        component='span'
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          color: '#0066ff',
          gap: '0.25rem',
          fontSize: 14
        }}
      >
        {label}
        <ArrowForwardIcon
          sx={{
            width: '16px',
            height: 'auto'
          }}
        />
      </Typography>
    </Link>
  )
}
