import { ClientType } from '@prisma/client';

import { IOnboardingStep } from './onboarding-step-interface';
import { TokenHolder } from './token-holder';
import { HighRiskJurisdictionsExposure } from './high-risk-jurisdictions-exposure';

export const ProfessionalInvestor: IOnboardingStep = {
  id: 'professional-investor',
  name: 'Professional Investor',
  blocks: (clientContext) => {
    const content = (() => {
      const contentGenerator = (titlePlaceholder: string, optionsPlaceholder: string) => ({
        title: `${titlePlaceholder} a professional investor`,
        options: [
          {
            value: 'is-professional',
            content: `Yes, ${optionsPlaceholder} a professional investor`
          },
          {
            value: 'is-not-professional',
            content: `No, ${optionsPlaceholder} not a professional investor`
          }
        ]
      });

      switch (clientContext.type) {
        case ClientType.Institution:
          return contentGenerator('Is your company', 'my company is');
        case ClientType.Fund:
          return contentGenerator('Is your fund', 'my fund is');
        case ClientType.Foundation:
          return contentGenerator('Is your foundation', 'my foundation is');
        default:
          return contentGenerator('Are you', 'I am');
      }
    })();
    return [
      {
        type: 'title',
        title: content.title
      },
      {
        type: 'paragraph',
        content: 'At Backed, only Professional Investors can both issue and redeem'
      },
      {
        type: 'checkbox',
        valueKey: 'default',
        title: '',
        options: content.options
      }
    ];
  },
  shouldRenderStep: () => true,
  getNextStep: (stepData) => {
    const data = stepData['default'];

    if (data) {
      if (data.includes('is-professional')) {
        return HighRiskJurisdictionsExposure.id;
      } else {
        return TokenHolder.id;
      }
    }

    return undefined;
  }
};
