import { Button } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface InternalProps {
  to: string;
  client?: boolean;

  first?: boolean;
}

export const NavigationButton: React.FC<InternalProps & React.ComponentProps<typeof Button>> = ({
  to,
  client,
  first,
  ...props
}) => {
  // ---- Hooks ---- //
  const location = useLocation();
  const navigate = useNavigate();

  // ---- State ---- //
  const [active, setActive] = React.useState<boolean>();

  // ---- Effects ---- //
  React.useEffect(() => {
    setActive(location.pathname.endsWith(to));
  }, [location, to]);

  // ---- Actions ---- //
  const onClick = () => {
    navigate(to);
  };

  return (
    <Button
      {...props}
      onClick={onClick}
      color={active ? 'primary' : 'secondary'}
      sx={{
        border: '0 !important',
        m: '.25rem',
        ml: first ? '0' : undefined,

        ...props.sx
      }}
    />
  );
};
