/* eslint-disable max-len */
import React from 'react';
import { IOnboardingStep } from './onboarding-step-interface';
import { Summary } from './summary';
import { ClientType } from '@prisma/client';
import { Block } from '../../components/blocks/block.type';

const individualAcknowledgement: Block[] = [
  {
    type: 'checkbox',
    valueKey: 'jersey-risk',
    options: [
      {
        content: 'By clicking the below checkbox you hereby represent, warrant and confirm that:',

        value: 'jersey-risk-accept',
        summaryContent:
        'I/we represent, warrant and confirm that:\n' +
        '1. I/we have read this document carefully, as well as the Registration Document, Securities Note and Final Terms;\n' +
        '2. I have received and acknowledged the warning set out above under the JFSC Guidance (as defined above)\n' +
          'and understand the risks set out above; that the Products are suitable for you and that you wish to proceed to\n' +
          'acquire the Products from Backed Assets (JE) Limited; and\n' +
        '3. under the SPB Order (as defined above), I/we am a Professional Investor (as defined above), or\n' +
          'alternatively that you I/we have received and acknowledged the SPB Order Investment Warning (as \n' +
          'defined above).'
      }
    ]
  },
  {
    type: 'paragraph',
    sx: {
      paddingLeft: '16px'
    },
    content: '1. you have read this document carefully, as well as the Registration Document, Securities Note and Final Terms;'
  },
  {
    type: 'paragraph',
    sx: {
      paddingLeft: '16px'
    },
    content: '2. you have received and acknowledged the warning set out above under the JFSC Guidance (as defined above)\n' +
      'and understand the risks set out above; that the Products are suitable for you and that you wish to proceed to\n' +
      'acquire the Products from Backed Assets (JE) Limited; and'
  },
  {
    type: 'paragraph',
    sx: {
      paddingLeft: '16px'
    },
    content: '3. under the SPB Order (as defined above), you are a Professional Investor (as defined above), or\n' +
      'alternatively that you have received and acknowledged the SPB Order Investment Warning (as \n' +
      'defined above).'
  }
];

const companyAcknowledgement: Block[] = [
  {
    type: 'checkbox',
    valueKey: 'jersey-risk',
    options: [
      {
        content: 'By clicking the below checkbox you hereby represent, warrant and confirm that:',

        value: 'jersey-risk-accept',
        summaryContent:
        'I represent, warrant and confirm that:\n' +
        '1. I am duly authorised to act on behalf of the entity stated below;\n' +
        '2. I have read this document carefully, as well as the Registration Document, Securities Note and Final Terms\n' +
          'and understand the risks set out above; that the Products are suitable for you and that you wish to proceed to\n' +
          'acquire the Products from Backed Assets (JE) Limited; and\n' +
        '3. I have received the warning set out above under the JFSC Guidance and understand the risks set out above;\n' +
          'that the entity I represent has considered the Products carefully and considers them suitable and wishes to proceed to acquire the Products from Backed Assets (JE) Limited; and' +
        '4. under the SPB Order (as defined above), the entity I represent is a Professional Investor (as defined above)\n' +
          'or alternatively I have received and acknowledged the SPB Order Investment Warning (as defined above) on behalf of the entity I represent'
      }
    ]
  },
  {
    type: 'paragraph',
    sx: {
      paddingLeft: '16px'
    },
    content: '1. you are duly authorised to act on behalf of the entity stated below;'
  },
  {
    type: 'paragraph',
    sx: {
      paddingLeft: '16px'
    },
    content: '2. you have read this document carefully, as well as the Registration Document, Securities Note and Final Terms;'
  },
  {
    type: 'paragraph',
    sx: {
      paddingLeft: '16px'
    },
    content: '3. you have received the warning set out above under the JFSC Guidance and understand the risks set out above;\n' +
    'that the entity you represent has considered the Products carefully and considers them suitable and wishes to proceed\n' +
    'to acquire the Products from Backed Assets (JE) Limited; and'
  },
  {
    type: 'paragraph',
    sx: {
      paddingLeft: '16px'
    },
    content: '4. under the SPB Order (as defined above), the entity you represent is a Professional Investor (as defined above),\n' +
    'or alternatively that you have received and acknowledged the SPB Order Investment Warning (as defined above) on behalf of the entity you represent.'
  }
];

export const InvestorWarning: IOnboardingStep = {
  id: 'investor-warning',
  name: 'Investor Warning',
  blocks: (clientContext) => [
    {
      type: 'title',
      title: 'IMPORTANT: PLEASE READ THIS DOCUMENT CAREFULLY.'
    }, {
      type: 'paragraph',
      content: 'BACKED ASSETS (JE) LIMITED (THE "ISSUER")\n' +
        'JERSEY REGULATORY WARNINGS TO BE ACKNOWLEDGED BY THE INVESTOR',
      margin: true
    }, {
      type: 'paragraph',
      content: <>
        You acknowledge receipt of the latest Registration Document, Securities Note and Final Terms
        together with any supplements prepared pursuant to Art. 23 of the Prospectus Regulation.
        You are aware that the Issuer may choose to produce updated documentation to replace the current Registration Document
        Securities Note and/or any supplements you have been provided with whenever significant new information
        regarding the Issuer is available.  The Issuer will make these documents available to you on the following website <a href="https://www.backedassets.fi/">https://www.backedassets.fi/</a>
      </>,
      margin: true
    }, {
      type: 'paragraph',
      content:
        <>
        All defined terms referred to in this document shall be as defined in the Registration Document and
        Securities Note provided to you and available at <a href="https://www.backedassets.fi/">https://www.backedassets.fi/</a>.
        </>,
      margin: true
    }, {
      type: 'paragraph',
      content: 'Requirements under the Guidance issued by the JFSC in respect of Token Issuances (the "JFSC Guidance")',
      margin: true,
      sx: {
        textDecoration: 'underline',
        fontWeight: 'bold'
      }
    }, {
      type: 'paragraph',
      sx: {
        fontWeight: 'bold'
      },
      content: 'Token sales or coin offerings are typically a highly speculative form of investment. You should be\n' +
        'prepared for the possibility of losing your investment completely. Investment in token sales or \n' +
        'coin offerings is not necessarily subject to existing capital market regulations and protections.',
      margin: true
    }, {
      type: 'paragraph',
      content: 'Requirements under the Financial Services (Investment Business (Special Purpose Investment Business – Exemption)) (Jersey) Order 2001 (the "SPB Order")',
      margin: true,
      sx: {
        textDecoration: 'underline',
        fontWeight: 'bold'
      }
    }, {
      type: 'paragraph',
      content: 'Pursuant to the SPB Order, the Products may only be issued or allotted exclusively to:',
      margin: true,
      sx: {
        fontWeight: 'bold'
      }
    }, {
      type: 'paragraph',
      content: '1. A person whose ordinary activities involve them acquiring, holding, managing or\n' +
        'disposing of investments (as principal or agent) for the purposes of their business or who\n' +
        'it is reasonable to expect will acquire, hold, arrange or dispose of investments (as\n' +
        'principal or agent) for the purposes of their business (a "Professional Investor"); or',
      margin: true,
      sx: {
        fontWeight: 'bold',
        paddingLeft: '32px'
      }
    }, {
      type: 'paragraph',
      content: '2. A person who has received and acknowledged a warning to the effect that (A) the\n' +
        'Products are only suitable for acquisition by a person who: (i) has a significantly\n' +
        'substantial asset base such as would enable them to sustain any loss that might be\n' +
        'incurred as a result of acquiring the Products; and (ii) is sufficiently financially\n' +
        'sophisticated to be reasonably expected to know the risks involved with acquiring the\n' +
        'Products; and (B) neither the issue of the Products nor the activities of any functionary\n' +
        'with regard to the issue of the Products are subject to all the provisions of the Financial\n' +
        'Services (Jersey) Law 1998 (the "SPB Order Investment Warning")',
      margin: true,
      sx: {
        fontWeight: 'bold',
        paddingLeft: '32px'
      }
    }, {
      type: 'paragraph',
      content: 'Investor Acknowledgement:',
      margin: true,
      sx: {
        textDecoration: 'underline',
        fontWeight: 'bold'
      }
    },
    ...(clientContext.type === ClientType.Individual ?
      individualAcknowledgement : companyAcknowledgement)
  ],
  shouldRenderStep: () => true,
  getNextStep: (stepData) => {
    if (stepData['jersey-risk'].length > 0) {
      return Summary.id;
    }

    return undefined;
  }
};
