import React from 'react';

import { Box, CircularProgress } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

interface Props {
  message: string;
  isLoading?: boolean;
}

export const TablePlaceholder: React.FC<Props> = ({ message, isLoading }) => {
  return (
    <TableRow>
      <TableCell colSpan={100}>
        <Box
          sx={{
            display: 'flex',
            height: '192px',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#848a98',
            fontSize: 16
          }}
        >
          {isLoading ? <CircularProgress size={32} /> : message}
        </Box>
      </TableCell>
    </TableRow>
  )
}
