import { DomainRestricted } from '@backed-fi/primitives';
import { Alert, Box } from '@mui/material';

export const WorkInProgress = () => {
  return (
    <DomainRestricted
      dev
      local
      stage
    >
      <Box
        sx={{
          position: 'fixed',
          bottom: '1rem',
          right: '1rem',
          zIndex: 999999
        }}
      >
        <Alert severity='warning'>
          This page is "Work In Progress". If you find a problem, or have any feedback please ping me
        </Alert>
      </Box>
    </DomainRestricted>
  );
};
