import React from 'react';
import { gql } from '@apollo/client';
import { Box, Grid, Typography } from '@mui/material';

import { useHasFeature } from '@backed-fi/hooks';
import { ClientFeature } from '@backed-fi/graphql';
import { useClientContext, useUserContext } from '@backed-fi/context';
import { useDashboardQuery } from '@backed-fi/graphql';

import { ArrowLink } from '../../../components/ArrowLink';
import { BreadcrumbHeader } from '../../../components/BreadcrumbHeader';
import { DashboardHero } from '../components/DashboardHero';
import { AssetCard } from '../components/AssetCard';
import { InteractionsTable } from '../../Transactions/components/InteractionsTable';

const Graph = gql`
  query dashboard {
    tokens {
      id

      name
      symbol

      tokenCollaterals {
        collateral {
          tradingViewId
        }
      }
    }
  }
`;

export const DashboardHomePage: React.FC = () => {
  const userContext = useUserContext();
  const clientContext = useClientContext();

  const hasIssuance = useHasFeature(ClientFeature.Issuance);
  const { shouldRegisterWallet, shouldActivateWallet } = clientContext;
  const canClientIssue = hasIssuance && !(shouldRegisterWallet || shouldActivateWallet);

  // ---- Networking ---- //
  const { data } = useDashboardQuery();

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column',
        gap: '3rem'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          gap: '1.5rem'
        }}
      >
        <Box>
          <BreadcrumbHeader
            mainTitle='Home'
          />
          <Typography
            sx={{
              display: 'flex',
              fontSize: 14,
              color: '#848a98',
              justifyContent: 'space-between'
            }}
          >
              Welcome to Backed, {userContext.user?.firstName}.
            <ArrowLink to='/settings/account' label='Account Details' />
          </Typography>
        </Box>

        <DashboardHero />

        <Grid
          alignItems="stretch"
          spacing='1.5rem'
          container
        >
          {data?.tokens?.map((token, key) => (
            <Grid
              key={key}
              xs={12}
              md={4}
              item
            >
              <AssetCard
                name={token.name}
                symbol={token.symbol}
                tradingViewId={token.tokenCollaterals[0].collateral.tradingViewId}
                isButtonDisabled={!canClientIssue}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '1.5rem',
            justifyContent: 'space-between'
          }}
        >
          <Typography
            sx={{
              fontSize: 20
            }}
          >
              Pending Interactions
          </Typography>

          <ArrowLink to='/interactions/history' label='Transaction History' />
        </Box>

        <InteractionsTable />
      </Box>
    </Box>
  );
};
