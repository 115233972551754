import React from 'react';

import { styled } from '@mui/material/styles';
import { Box, ButtonBase, Menu, MenuItem, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { IssueDetailsQuery, RedemptionWalletQuery } from '@backed-fi/graphql';

type Asset = IssueDetailsQuery['tokens'][0] | RedemptionWalletQuery['tokens'][0]

interface Props {
  selectedAsset: Asset;
  availableAssets: Asset[];
  onAssetChange: (asset: Asset) => void;
}

const SelectorButton = styled(ButtonBase)(() => ({
  display: 'flex',
  gap: '0.25rem',
  height: '40px',
  color: '#0066ff',
  alignItems: 'center',
  padding: '0.5rem 0.75rem 0.5rem 1rem',
  border: '2px solid currentColor',
  borderRadius: '20px',

  '&:hover': {
    color: 'black'
  }
}));

export const AssetSelector: React.FC<Props> = ({
  selectedAsset,
  availableAssets,
  onAssetChange
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [menuMinWidth, setMenuMinWidth] = React.useState(0);

  React.useEffect(() => {
    if (anchorEl?.clientWidth) {
      setMenuMinWidth(anchorEl.clientWidth);
    }
  }, [anchorEl]);

  return (
    <Box>
      <SelectorButton
        onClick={(e) => setAnchorEl(!anchorEl ? e.currentTarget : null)}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: 14
          }}
        >
          {selectedAsset.name}
        </Typography>

        <ExpandMoreIcon
          fontSize="small"
        />
      </SelectorButton>

      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {availableAssets?.map((token) => (
          <MenuItem
            key={token.id}
            selected={token.id === selectedAsset.id}
            onClick={() => {
              onAssetChange(token);
              setAnchorEl(null);
            }}
            sx={{
              gap: '0.5rem',
              display: 'flex',
              alignItems: 'center',
              minWidth: menuMinWidth
            }}
          >
            <Box
              src={`/assets/icons/assets/${token.symbol}.svg`}
              component="img"
              width={24}
              height={24}
            />

            <Typography
              sx={{
                lineHeight: 1.125,
                fontWeight: 700,
                fontSize: 14
              }}
            >
              {token.symbol}
            </Typography>

            <Typography
              sx={{
                color: '#848a98',
                fontSize: 12
              }}
            >
              {token.name}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
