import { useFunMode } from '@backed-fi/shared/hooks/useFunMode';
import { useConfettiContext } from '@backed-fi/shared/components/providers/ConfettiProvider';

export const useConfetti = () => {
  const funMode = useFunMode();
  const { confetti } = useConfettiContext();


  return {
    emitConfetti: async (config?: Parameters<typeof confetti.addConfetti>[0]) => {
      if (!funMode.enabled) {
        console.log('😭 Fun mode not enable. That means no confetti');

        return;
      }

      await confetti.addConfetti({
        confettiRadius: 5,
        confettiNumber: 100,
        confettiColors: [
          '#ff0a54',
          '#ff477e',
          '#ff7096',
          '#ff85a1',
          '#fbb1bd',
          '#f9bec7'
        ],

        ...config
      });
    }
  };
};
