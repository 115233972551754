// noinspection ES6UnusedImports
import {} from '@mui/lab/themeAugmentation';

import { createTheme } from '@mui/material';

import { Application } from '@backed-fi/constants';
import { MuiChip } from './components/MuiChip';
import { MuiIcon } from './components/MuiIcon';
import { createMuiCheckbox } from './components/MuiCheckbox';
import { createMuiTextField } from './components/MuiTextField';
import { createMuiTypography } from './components/MuiTypography';
import { createMuiLoadingButton } from './components/MuiLoadingButton';
import { MuiCircularProgress } from './components/MuiCircularProgress';
import { MuiSvgIcon } from './components/MuiSvg';
import { MuiBadge } from './components/MuiBadge';
import { MuiCard } from './components/MuiCard';
import { MuiSkeleton } from './components/MuiSkeleton';
import { MuiDialog } from './components/MuiDialog';
import { MuiDataGrid } from './components/MuiDataGrid';
import { MuiAlert } from './components/MuiAlert';
import { MuiTab } from './components/MuiTab';
import { MuiTabs } from './components/MuiTabs';
import { MuiTableCell } from './components/MuiTableCell';
import { MuiTableRow } from './components/MuiTableRow';
import { MuiBackdrop } from './components/MuiBackdrop';
import { blueLight, errorDark, errorLight, slateDark, slateLight, successDark, successLight, utilityColorDark, utilityColorLight, violetDark, warningDark, warningLight, yellowDark, yellowLight } from './colors';
import { createMuiRadio } from './components/MuiRadio';
import { createMuiSwitch } from './components/MuiSwitch';
import { MuiToolbar } from '@backed-fi/shared/theme/components/MuiToolbar';
import { MuiInputBase } from '@backed-fi/shared/theme/components/MuiInputBase';
import { MuiDrawer } from '@backed-fi/shared/theme/components/MuiDrawer';
import { MuiStepper } from '@backed-fi/shared/theme/components/MuiStepper';
import { MuiStepLabel } from '@backed-fi/shared/theme/components/MuiStepLabel';
import { MuiStepConnector } from '@backed-fi/shared/theme/components/MuiStepConnector';
import { MuiStep } from '@backed-fi/shared/theme/components/MuiStep';
import { MuiStepIcon } from '@backed-fi/shared/theme/components/MuiStepIcon';
import { MuiTablePagination } from '@backed-fi/shared/theme/components/MuiTablePagination';
import { MuiFormControlLabel } from '@backed-fi/shared/theme/components/MuiFormControlLabel';
import { MuiStepContent } from '@backed-fi/shared/theme/components/MuiStepContent';
import { MuiAutocomplete } from '@backed-fi/shared/theme/components/MuiAutocomplete';


declare module '@mui/material/styles/createTypography' {
  interface TypographyOptions {
    fontFamilyMono?: string;
  }

  interface Typography {
    fontFamilyMono: string;
  }
}

export const createBackedTheme = (
  application: Application,
  mode: 'light' | 'dark'
) =>
  createTheme({
    palette: {
      ...(mode === 'light'
        ? {
          primary: blueLight,
          secondary: yellowLight,

          utility: utilityColorLight,

          success: successLight,
          error: errorLight,
          warning: warningLight,
          info: blueLight,

          slate: slateLight,

          white: 'white',
          black: 'black',

          yellow: yellowLight,

          ghost: utilityColorLight
        } : {
          primary: violetDark,

          utility: utilityColorDark,

          success: successDark,
          error: errorDark,
          warning: warningDark,

          slate: slateDark,

          yellow: yellowDark,


          white: 'black',
          black: 'white'
        })
    },

    shape: {
      borderRadius: application === Application.Main ? 3 : 10
    },

    typography: {
      fontFamily: ['\'Twemoji Country Flags\'', 'Inter', 'sans-serif'].join(', '),
      fontFamilyMono: [
        'Twemoji Country Flags',
        '\'JetBrains Mono\'',
        'monospace'
      ].join(', ')
    },

    components: {
      MuiSwitch: createMuiSwitch(application),
      MuiRadio: createMuiRadio(application),
      MuiCheckbox: createMuiCheckbox(application),
      MuiButton: createMuiLoadingButton(application),
      MuiTextField: createMuiTextField(application),
      MuiTypography: createMuiTypography(application),
      MuiLoadingButton: createMuiLoadingButton(application),

      // MuiCircularProgress: {
      //   styleOverrides: {
      //     root: ({ theme }) => {
      //       const { primary } = theme.palette;
      //
      //       return {
      //         color: primary.textLowContrast
      //       };
      //     }
      //   }
      // },

      MuiChip,
      MuiIcon,
      MuiCard,
      MuiStep,
      MuiAlert,
      MuiBadge,
      MuiDialog,
      MuiDrawer,
      MuiSvgIcon,
      MuiToolbar,
      MuiStepper,
      MuiStepIcon,
      MuiSkeleton,
      MuiDataGrid,
      MuiInputBase,
      MuiStepLabel,
      MuiStepContent,
      MuiAutocomplete,
      MuiStepConnector,
      MuiTablePagination,
      MuiFormControlLabel,
      MuiCircularProgress,

      // Add main application customizations
      ...(application === Application.Main && {
        MuiTab,
        MuiTabs,
        MuiTableCell,
        MuiTableRow,
        MuiBackdrop
      }),


      MuiDivider: {
        defaultProps: {
          sx: {
            margin: '1rem 0'
          }
        }
      }

      // ---- Outside the typescript config ---- //
    }
  });
