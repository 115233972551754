/* eslint-disable max-len */
import { IOnboardingStep } from './onboarding-step-interface';
import { Annex2 } from './annex-2';

export const Annex1: IOnboardingStep = {
  id: 'annex-1',
  name: 'Annex 1: Investor Classification Form',
  shouldRenderStep: (data) => {
    return !!data['professional-investor']?.['default']?.includes('is-professional')
      && (
        // And they reside in the EEA
        !!data['eea-residency']?.['in-the-eea']?.includes('within-eea')
        // Or are EEA resident
        || !!(data['residency']?.['default']?.includes('resident-with-residency-permit')));
  },
  getNextStep: (stepData) => {
    // If both forms are filled - continue
    if (
      // First or second form is filled
      ((!!stepData['form1']?.length && !stepData['form2']?.length) ||
        (!stepData['form1']?.length && stepData['form2']?.length > 1)) &&
      !!stepData['name'] &&
      !!stepData['address'] &&
      !!stepData['country']
    ) {
      return Annex2.id;
    }

    return undefined;
  },
  blocks: () => [
    {
      type: 'title',
      title: 'Annex 1',
      subtitle: 'Investor Classification Form'
    }, {
      type: 'paragraph',
      content: 'I/we ',
      margin: true
    }, {
      type: 'textInput',
      label: 'Name',
      valueKey: 'name'
    }, {
      type: 'textInput',
      label: 'Address',
      valueKey: 'address'
    }, {
      type: 'textInput',
      label: 'Country',
      valueKey: 'country'
    }, {
      type: 'paragraph',
      margin: true,
      content:
        'the undersigned prospective purchaser(s) \n' +
        '(Purchaser) – hereby confirm that at the time of signing this confirmation, I/we am/are a EU Professional Client\n' +
        'within the meaning of Annex II of the Markets in Financial Instruments Directive (Directive 2004/39/EC),\n' +
        'as amended and supplemented from time to time (MiFID II) under at least one of the following types of \n' +
        'institution, and that I/we have ticked the box below as appropriate:'
    },
    {
      type: 'paragraph',
      margin: true,
      content:
        'Please fill only one form depending on your classification (Per-se or Elective):'
    },
    {
      type: 'checkbox',
      valueKey: 'form1',
      title: '1. Per-se Professional Clients (as defined in Section I MiFID II)',
      options: [
        {
          value: 'a',
          content:
            '(a) a credit institution;'
        }, {
          value: 'b',
          content:
            '(b) an investment firm;'
        }, {
          value: 'c',
          content:
            '(c) another authorised or regulated financial institution;'
        }, {
          value: 'd',
          content: '(d) an insurance company;'
        }, {
          value: 'e',
          content:
            '(e) a collective investment scheme or a management company of such a scheme;'
        }, {
          value: 'f',
          content: '(f) a pension fund or a management company of such a fund;'
        }, {
          value: 'g',
          content: '(g) a commodity or commodity derivatives dealer;'
        }, {
          value: 'h',
          content: '(h) another institutional investor being authorised or regulated to operate in the financial markets.¹'
        }, {
          value: 'i',
          content:
            '(i) a large undertaking meeting two of the following size requirements on a company basis:\n' +
            '(i) balance sheet total of EUR 20 million; (ii) net turnover of EUR 40 million; (iii) own funds of EUR 2 million;'
        }, {
          value: 'j',
          content: '(j) a national or regional government, a public body that managed public debt, a central bank, an' +
            'international and supranational institution such as the World Bank, the IMF, the ECB,' +
            ' the EIB or another similar international organisation;'
        }, {
          value: 'k',
          content:
            '(k) another institutional investor whose main activity is to invest in financial instruments²;'
        }, {
          value: 'l',
          content: '(l) an entity dedicated to the securitisation of assets or other financing transactions.'
        }
      ]
    },
    {
      type: 'title',
      title: 'Elective Professional Clients (as defined in Section II Annex II MiFID II)'
    }, {
      type: 'paragraph',
      content:
        'Any other entity or person fulfilling two of the following criteria and wishing ' +
        'to declare in the form that they request ' +
        'to be treated as Professional Clients in accordance with Section II Annex II MiFID II:'
    }, {
      type: 'checkbox',
      valueKey: 'form2',
      multiSelect: true,
      options: [
        {
          value: '1',
          content:
            'the entity or person has carried out transactions, in significant ' +
            'size, on the relevant market at an average frequency of 10 per ' +
            'quarter over the previous four quarters;'
        }, {
          value: '2',
          content:
            'the size of the entity’s or person’s financial instrument portfolio, ' +
            'defined as including cash deposits and financial instruments³, exceeds ' +
            'EUR 500,000;'
        }, {
          value: '3',
          content:
            'the entity or person works or has worked in the financial sector for at ' +
            'least one year in a professional position, which requires knowledge of ' +
            'the transactions or services envisaged.'
        }
      ]
    },
    {
      type: 'paragraph',
      margin: true,
      footnote: true,
      content: '1. The list above should be understood as including all authorised entities carrying out the characteristic activities of the entities mentioned: entities authorised by an EU/EEA Member State under a European Directive, entities authorised or regulated by an EU/EEA Member Sate without reference to a European Directive, and entities authorised or regulated by a state which is not an EU/EEA Member State'
    },
    {
      type: 'paragraph',
      margin: true,
      footnote: true,
      content: '2. Financial instruments include:' +

        '\n- Transferable securities;' +
        '\n- Money-market instruments;' +
        '\n- Units in collective investment undertakings;' +
        '\n- Options, futures, swaps, forward rate agreements and any other derivative contracts relating to securities, currencies, interest rates or yields, emission allowances or other derivatives instruments, financial indices or financial measures which may be settled physically or in cash;' +
        '\n- Options, futures, swaps, forwards and any other derivative contracts relating to commodities that must be settled in cash or may be settled in cash at the option of one of the parties other than by reason of default or other termination event;' +
        '\n- Options, futures, swaps, and any other derivative contract relating to commodities that can be physically settled provided that they are traded on a regulated market, a MTF, or an OTF, except for wholesale energy products traded on an OTF that must be physically settled;' +
        '\n- Options, futures, swaps, forwards and any other derivative contracts relating to commodities, that can be physically settled not otherwise mentioned in point 6 of this Section and not being for commercial purposes, which have the characteristics of other derivative financial instruments;' +
        '\n- Derivative instruments for the transfer of credit risk;' +
        '\n- Financial contracts for differences;' +
        '\n- Options, futures, swaps, forward rate agreements and any other derivative contracts relating to climatic variables, freight rates or inflation rates or other official economic statistics that must be settled in cash or may be settled in cash at the option of one of the parties other than by reason of default or other termination event, as well as any other derivative contracts relating to assets, rights, obligations, indices and measures not otherwise mentioned in this Section, which have the characteristics of other derivative financial instruments, having regard to whether, inter alia, they are traded on a regulated market, OTF, or an MTF;' +
        '\n- Emission allowances consisting of any units recognised for compliance with the requirements of Directive 2003/87/EC (Emissions Trading Scheme).'
    },
    {
      type: 'paragraph',
      margin: true,
      footnote: true,
      content: '3. See footnore 2 above.'
    }
  ]
};
