import React from 'react';
import { Box } from '@mui/material';
import { SetPasswordDialog } from '@backed-fi/app/src/app/domain/Settings/components/SetPasswordDialog';
import { PageHeader } from '@backed-fi/app/src/app/domain/Settings/components/PageHeader';
import { useUserContext } from '@backed-fi/context';
import { Title } from '@backed-fi/compound';
import { formatRelative } from 'date-fns';

export const SecuritySettingsPage: React.FC = () => {
  const userContext = useUserContext();

  return (
    <Box>
      <PageHeader
        title="Login & recovery"
        subtitle="Manage your password, and login preferences."
      />

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xxs: '1fr',
            md: '400px 1fr'
          }
        }}
      >
        <Title
          boxed
          title="Password"
          subtitle={
            userContext.user?.passwordLastChange
              ? `Last password change ${formatRelative(new Date(userContext.user?.passwordLastChange), new Date())}`
              : ''
          }
        />

        <Box>
          <SetPasswordDialog />
        </Box>
      </Box>
    </Box>
  );
};
