import { drawerClasses, Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiDrawer: Components<Theme>['MuiDrawer'] = {
  styleOverrides: {
    root: ({ theme }) => {
      const { utility } = theme.palette;

      return ({
        [`.${drawerClasses.paper}`]: {
          borderRadius: '0.5rem',

          border: '1px solid',
          borderColor: utility.borderSubtle,

          color: utility.textHighContrast,
          backgroundColor: utility.backgroundSubtle
        }
      });
    }
  }
};
