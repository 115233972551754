import React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { Protector } from '@backed-fi/compound';
import { useAuthContext } from '@backed-fi/context';

import { DashboardLayout } from '../components/layout/Layout';

import { HomePages } from './Home/Pages';
import { SettingPages } from './Settings/Pages';
import { OnboardingRoutes } from './Onboarding/OnboardingRoutes';
import { TransactionPages } from './Transactions/Pages';
import { AuthenticatePage } from './Account/AuthenticatePage';
import { ClientFeature } from '@backed-fi/graphql';

const NotFoundRedirect = () => {
  const authContext = useAuthContext();

  if (authContext.authenticated) {
    return (
      <Navigate
        to='/dashboard'
      />
    );
  }

  return (
    <Navigate
      to='/authenticate'
    />
  );
};

export const PublicPages = (
  <React.Fragment>
    {OnboardingRoutes}

    <React.Fragment>
      <Route
        path='/authenticate'
        element={<AuthenticatePage />}
      />

      {/* // ---- Client Routes ---- // */}
      <Route
        element={
          <Protector feature={ClientFeature.PlatformAccess}>
            <DashboardLayout />
          </Protector>
        }
      >
        {HomePages}
        {SettingPages}
        {TransactionPages}
      </Route>

      {/* // ---- Redirections ---- // */}
      <Route
        path='*'
        element={<NotFoundRedirect />}
      />
    </React.Fragment>
  </React.Fragment>
);
