import React from 'react';
import { config } from '@backed-fi/config';

export const Experimental: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  if (!config.enableExperimental) {
    return null;
  }

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  )
};
