import React from 'react';

import { z } from 'zod';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';

const schema = z.object({
  terms: z.boolean()
    .refine((v) => v, 'You must accept the terms & conditions'),

  privacy: z.boolean()
    .refine((v) => v, 'You must accept the privacy policy'),

  marketing: z.boolean()
    .optional()
});

export const OnboardingTerms = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // region Form Control

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  const {
    errors,
    isSubmitting
  } = form.formState;
  const [terms, privacy] = form.watch(['terms', 'privacy']);

  // endregion

  // region Effects

  React.useEffect(() => {
    // If there is no state in the location state return the user
    // to the page that collects that
    if (!location.state?.email) {
      navigate('/onboarding/explainer');
    }
  }, [location]);

  // endregion

  // region Actions

  const onContinue = async () => {
    navigate(location.pathname.replace('terms', 'creation'), {
      state: {
        ...location.state,
        acceptsMarketingCommunication: form.getValues('marketing')
      }
    });
  };

  // endregion

  return (
    <Box
      sx={{
        margin: '0 auto',
        maxWidth: '500px'
      }}
    >
      <Typography
        variant="subtitle"
        sx={{
          textAlign: 'center',
          fontWeight: '400'
        }}
      >
        Please read and accept our <br />
        Terms of Service and Privacy Notice
      </Typography>

      <Box mt="2rem">
        <Box id="recaptcha-container" />

        <FormGroup
          sx={{
            mb: '2rem',
            textAlign: 'justify'
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                sx={{ mt: '.25rem' }}
                color={errors.terms ? 'error' : 'primary'}

                {...form.register('terms')}
              />
            }
            sx={{
              mb: '1rem',
              gap: '.75rem',
              alignItems: 'start'
            }}
            label={
              <Typography
                variant="labelBig"
                color={errors.terms ? 'error.textLowContrast' : 'primary.textHighContrast'}
              >
                By checking this box, you confirm that you have read, understood, and accepted the full {' '}

                <Box
                  component="a"
                  href="/assets/docs/TermsOfService.pdf"
                  target="_blank"
                  sx={{
                    color: 'primary.textLowContrast'
                  }}
                >
                  Terms of Service
                </Box>
              </Typography>
            }
          />

          {errors.terms && (
            <Typography
              variant="small"
              color="error.textLowContrast"
              sx={{
                mt: '-20px',
                ml: '30px'
              }}
            >
              You must agree to the Terms & condition
            </Typography>
          )}

          <FormControlLabel
            sx={{
              mb: '1rem',
              gap: '.75rem',
              alignItems: 'start'
            }}
            control={
              <Checkbox
                sx={{ mt: '.25rem' }}
                color={errors.privacy ? 'error' : 'primary'}

                {...form.register('privacy')}
              />
            }
            label={
              <Typography
                variant="labelBig"
                color={errors.privacy ? 'error.textLowContrast' : 'primary.textHighContrast'}
              >
                By checking this box, you confirm that you have read, understood, and accepted our{' '}

                <Box
                  component="a"
                  href="/assets/docs/PrivacyPolicy.pdf"
                  target="_blank"
                  sx={{
                    color: 'primary.textLowContrast'
                  }}
                >
                  Privacy Notice
                </Box>
              </Typography>
            }
          />

          {errors.privacy && (
            <Typography
              variant="small"
              color="error.textLowContrast"
              sx={{
                mt: '-20px',
                ml: '30px'
              }}
            >
              You must agree to the Privacy Policy
            </Typography>
          )}

          <FormControlLabel
            control={
              <Checkbox
                sx={{ mt: '.25rem' }}
                {...form.register('marketing')}
              />
            }
            sx={{
              mb: '1rem',
              gap: '.75rem',
              alignItems: 'start'
            }}
            label={
              <Typography variant="labelBig">
                Please tick this box to receive news and updates about our services. Your data will be processed per our{' '}
                <Box
                  component="a"
                  href="/assets/docs/PrivacyPolicy.pdf"
                  target="_blank"
                  sx={{
                    color: 'primary.textLowContrast'
                  }}
                >
                  privacy policy
                </Box>. You can unsubscribe at any time by clicking the link in every email or by emailing privacy@backedassets.fi
              </Typography>
            }
          />

        </FormGroup>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <LoadingButton
            fullWidth
            variant="publicDashboard"
            disabled={!terms || !privacy}
            loading={isSubmitting}
            onClick={form.handleSubmit(onContinue, console.error)}
          >
            Continue
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};
