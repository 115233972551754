import React from 'react';
import { Avatar, Badge } from '@mui/material';
import { BlockchainNetwork } from '@backed-fi/graphql';

interface Props {
  network: BlockchainNetwork;
  symbol: string;
  small?: boolean;
  large?: boolean
}

export const AssetBadge: React.FC<Props> = ({ symbol, network, small, large }) => {
  return <Badge
    overlap="circular"
    sx={{
      paddingRight: '4px'
    }}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    badgeContent={
      <Avatar
        alt={network}
        src={`/assets/icons/assets/native/${network}.svg`}
        sx={{
          width: '24px',
          height: '24px',
          border: '2px solid white',

          ...((small) && ({
            width: '18px',
            height: '18px',
            borderWidth: '1px'
          })),

          ...((large) && ({
            width: '28px',
            height: '28px'
          }))
        }}
      />
    }
  >
    <Avatar
      alt={symbol}
      src={`/assets/icons/assets/${symbol}.svg`}
      sx={{
        width: '36px',
        height: '36px',

        ...((small) && ({
          width: '28px',
          height: '28px'
        })),

        ...((large) && ({
          width: '48px',
          height: '48px'
        }))
      }}
    />
  </Badge>;
};
