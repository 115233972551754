import { HighRiskJurisdictionsExposure } from './high-risk-jurisdictions-exposure';
import { IOnboardingStep } from './onboarding-step-interface';
import { Rejected } from './rejected';

export const TokenHolder: IOnboardingStep = {
  id: 'token-holder',
  name: 'Backed Token Holder',
  blocks: () => [
    {
      type: 'title',
      title: 'Do you currently hold any Backed issued tokens?'
    },
    {
      type: 'checkbox',
      valueKey: 'default',
      title: '',
      options: [
        {
          value: 'is-holder',
          content: 'Yes, I do'
        }, {
          value: 'is-not-holder',
          content: 'No, I do not'
        }
      ]
    }
  ],
  shouldRenderStep: (data) => {
    return !!data['professional-investor']?.['default']?.includes('is-not-professional');
  },
  getNextStep: (stepData) => {
    const isHolder = stepData['default'];

    if (isHolder) {
      if (isHolder.includes('is-holder')) {
        return HighRiskJurisdictionsExposure.id;
      } else {
        return Rejected.id;
      }
    }

    return undefined;
  }
};
