import { Theme, tabClasses, touchRippleClasses } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiTab: Components<Theme>['MuiTab'] = {
  styleOverrides: {
    root: () => ({
      minWidth: 0,
      fontSize: 16,
      color: '#848a98',
      marginRight: '2rem',
      padding: '0.75rem 0',
      textTransform: 'none',

      [`&.${tabClasses.selected}`]: {
        color: '#0066ff'
      },

      [`.${touchRippleClasses.root}`]: {
        display: 'none'
      }
    })
  }
}
