import React from 'react';

import { IconButton } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

interface Props {
  /**
   * The value that will be copied to the user's clipboard
   * upon click on the button
   */
  value: string;

  /**
   * The text that will be shown to the user as snackbar once the
   * user successfully copies the text
   */
  notificationText?: string;

  // /**
  //  * Text that will be displayed in a tooltip when the user
  //  * hovers over the button
  //  */
  // helperText?: string;
}

export const CopyButton: React.FC<Props> = (props) => {
  // ---- Hooks ---- //
  const snackbar = useSnackbar();

  // ---- Actions ---- //
  const onCopy = async () => {
    await navigator.clipboard.writeText(props.value);

    if (props.notificationText) {
      snackbar.enqueueSnackbar(props.notificationText);
    }
  };

  return (
    <IconButton onClick={onCopy}>
      <ContentCopy />
    </IconButton>
  );
};
