import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiToolbar: Components<Theme>['MuiToolbar'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => {
      const { utility } = theme.palette;

      return {
        color: utility.textLowContrast
      };
    }
  }
};
