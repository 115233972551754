import React from 'react';
import SumsubWebSdk from '@sumsub/websdk-react';

import { gql } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { MessageHandler } from '@sumsub/websdk';

import { useUserContext } from '@backed-fi/context';
import { useCreateVerificationMutation } from '@backed-fi/graphql';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const Graph = gql`
  mutation createVerification {
    createVerification {
      vendorToken
    }
  }
`;

export const OnboardingVerificationPage: React.FC = () => {
  const navigate = useNavigate();
  const userContext = useUserContext();
  const snackbar = useSnackbar();

  // ---- State ---- //
  const [token, setToken] = React.useState<string>();
  const [count, setCount] = React.useState<number>(0);

  // ---- Networking ---- //
  const [createVerification] = useCreateVerificationMutation();

  // ---- Effects ---- //
  React.useEffect(() => {
    const createVer = () => {
      setCount((p) => p + 1);

      if (count >= 5) {
        snackbar.enqueueSnackbar('Something went wrong. Please try again later', {
          variant: 'error'
        });

        return;
      }

      createVerification()
        .then(({ data }) => {
          setToken(data?.createVerification?.vendorToken!);
        })
        .catch((e) => {
          setTimeout(() => {
            createVer();
          }, 3000);
        });
    };

    createVer();
  }, []);

  // Action handlers
  const onMessageReceived: MessageHandler = async (msg, ctx) => {
    if (msg === 'idCheck.onApplicantSubmitted' || (msg === 'idCheck.applicantStatus' && (ctx as any).reviewStatus == 'pending')) {
      navigate('/onboarding/verification/pending');
    }

    if (msg === 'idCheck.applicantStatus' && (ctx as any).reviewStatus === 'completed') {
      navigate('/onboarding/verification/pending');
    }
  };

  return (
    !token ? (
      <CircularProgress />
    ) : (
      <SumsubWebSdk
        accessToken={token || ''}
        expirationHandler={(async () => {
        }) as any}
        config={{
          lang: 'en',
          email: userContext.user?.email
        }}
        options={{}}
        onMessage={onMessageReceived}
        onError={(err) => console.error(err)}
      />
    )

  );
};
