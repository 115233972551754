import React from 'react';
import { Route } from 'react-router-dom';

import { Feature } from '../../components';
import { ClientFeature } from '@backed-fi/graphql';

import { SettingsLayout } from './SettingsLayout';

import { DepositPage } from './pages/DepositPage';
import { ApiSettingsPage } from './pages/ApiSettingsPage';
import { AccountDetailsPage } from './pages/AccountDetailsPage';
import { WalletsSettingsPage } from './pages/WalletsSettingsPage';
import { DocumentSettingsPage } from './pages/DocumentSettingsPage';
import { TransactionSettingsPage } from './pages/TransactionSettingsPage';
import { BankAccountsSettingsPage } from './pages/BankAccountsSettingsPage';
import { SecuritySettingsPage } from '@backed-fi/app/src/app/domain/Settings/pages/SecuritySettingsPage';

export const SettingPages = (
  <Route path='settings' element={<SettingsLayout />}>
    <Route
      path='deposit'
      element={<DepositPage />}
    />

    <Route
      path='account'
      element={<AccountDetailsPage />}
    />

    <Route
      path='documents'
      element={<DocumentSettingsPage />}
    />

    <Route
      path='transactions'
      element={<TransactionSettingsPage />}
    />

    <Route
      path='wallets'
      element={<WalletsSettingsPage />}
    />

    <Route
      path='security'
      element={<SecuritySettingsPage />}
    />

    <Route
      path='bank-accounts'
      element={<BankAccountsSettingsPage />}
    />

    <Route
      path='api'
      element={
        <Feature feature={ClientFeature.ApiAccess}>
          <ApiSettingsPage />
        </Feature>
      }
    />
  </Route>
);
