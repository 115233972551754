import React from 'react';
import { Box, Breadcrumbs, Link, Skeleton, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

interface InternalProps {
  loading?: boolean;
  noBottomMargin?: boolean;

  title: string | React.ReactElement;
  breadcrumbs?: {
    text: string;
    link?: string;
  }[];
}

export const PageHeading: React.FC<React.PropsWithChildren<InternalProps>> = ({
  title,
  loading,
  breadcrumbs,
  children,
  noBottomMargin
}) => {
  return (
    <Box
      sx={{
        ...(!noBottomMargin && ({
          mb: '2rem'
        })),

        display: 'flex',
        alignContent: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Box>
        <Typography variant="title">
          {
            loading
              ? <Skeleton width={350} />
              : title
          }
        </Typography>

        {!!breadcrumbs?.length && (
          <Breadcrumbs
            sx={{
              color: 'utility.textHighContrast'
            }}
          >
            <Box>
              <Link component={RouterLink} underline="hover" color="inherit" to="/">
                Dashboard
              </Link>
            </Box>

            {breadcrumbs?.map((breadcrumb, index) => {
              if (breadcrumb.link) {
                return (
                  <Box key={index}>
                    <Link
                      component={RouterLink}
                      underline="hover"
                      color="inherit"
                      to={breadcrumb.link}
                    >
                      {breadcrumb.text}
                    </Link>
                  </Box>
                );
              }

              return (
                <Box key={index}>
                  <Typography>
                    {breadcrumb.text}
                  </Typography>
                </Box>
              );
            })}
          </Breadcrumbs>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

PageHeading.defaultProps = {
  breadcrumbs: []
};
