/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../../../../../common";
import type {
  RateLimiter,
  RateLimiterInterface,
} from "../../../../../../../@chainlink/contracts-ccip/src/v0.8/ccip/libraries/RateLimiter";

const _abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "capacity",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "requested",
        type: "uint256",
      },
    ],
    name: "AggregateValueMaxCapacityExceeded",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "minWaitInSeconds",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "available",
        type: "uint256",
      },
    ],
    name: "AggregateValueRateLimitReached",
    type: "error",
  },
  {
    inputs: [],
    name: "BucketOverfilled",
    type: "error",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "bool",
            name: "isEnabled",
            type: "bool",
          },
          {
            internalType: "uint128",
            name: "capacity",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "rate",
            type: "uint128",
          },
        ],
        internalType: "struct RateLimiter.Config",
        name: "config",
        type: "tuple",
      },
    ],
    name: "DisabledNonZeroRateLimit",
    type: "error",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "bool",
            name: "isEnabled",
            type: "bool",
          },
          {
            internalType: "uint128",
            name: "capacity",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "rate",
            type: "uint128",
          },
        ],
        internalType: "struct RateLimiter.Config",
        name: "rateLimiterConfig",
        type: "tuple",
      },
    ],
    name: "InvalidRatelimitRate",
    type: "error",
  },
  {
    inputs: [],
    name: "OnlyCallableByAdminOrOwner",
    type: "error",
  },
  {
    inputs: [],
    name: "RateLimitMustBeDisabled",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "capacity",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "requested",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "tokenAddress",
        type: "address",
      },
    ],
    name: "TokenMaxCapacityExceeded",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "minWaitInSeconds",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "available",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "tokenAddress",
        type: "address",
      },
    ],
    name: "TokenRateLimitReached",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        components: [
          {
            internalType: "bool",
            name: "isEnabled",
            type: "bool",
          },
          {
            internalType: "uint128",
            name: "capacity",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "rate",
            type: "uint128",
          },
        ],
        indexed: false,
        internalType: "struct RateLimiter.Config",
        name: "config",
        type: "tuple",
      },
    ],
    name: "ConfigChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "tokens",
        type: "uint256",
      },
    ],
    name: "TokensConsumed",
    type: "event",
  },
] as const;

const _bytecode =
  "0x60566037600b82828239805160001a607314602a57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600080fdfea264697066735822122006e2dcbd43b3aae5b221f92c250ad004d8af4a2a03e0b403627480deccf613df64736f6c63430008170033";

type RateLimiterConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: RateLimiterConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class RateLimiter__factory extends ContractFactory {
  constructor(...args: RateLimiterConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<RateLimiter> {
    return super.deploy(overrides || {}) as Promise<RateLimiter>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): RateLimiter {
    return super.attach(address) as RateLimiter;
  }
  override connect(signer: Signer): RateLimiter__factory {
    return super.connect(signer) as RateLimiter__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): RateLimiterInterface {
    return new utils.Interface(_abi) as RateLimiterInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): RateLimiter {
    return new Contract(address, _abi, signerOrProvider) as RateLimiter;
  }
}
