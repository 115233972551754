import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiStep: Components<Theme>['MuiStep'] = {
  styleOverrides: {
    root: ({ theme }) => {
      const {
        utility,
        primary
      } = theme.palette;

      return ({
        borderRadius: '0.5rem',
        boxSizing: 'border-box',

        border: '1px solid',
        borderColor: utility.borderSubtle,

        color: utility.textHighContrast,
        backgroundColor: utility.elementBackground,

        // ---- Active ---- //
        '&:has(.Mui-active)': {
          color: primary.textHighContrast,
          borderColor: primary.borderElement,
          backgroundColor: primary.elementBackground
        },
        '& .MuiStepLabel-root .Mui-active': {
          color: primary.solidBackground // circle color (ACTIVE)
        },
        '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
          fill: primary.textHighContrast // circle's number (ACTIVE)
        },

        // ---- Completed ---- //
        '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
          color: utility.textHighContrast // Just text label (COMPLETED)
        },
        '& .MuiStepLabel-root .Mui-completed': {
          color: primary.textLowContrast // circle color (COMPLETED)
        },
        '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
          color: utility.textHighContrast // Just text label (COMPLETED)
        }

      });
    }
  }
};
