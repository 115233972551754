/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  IPriceRegistry,
  IPriceRegistryInterface,
} from "../../../../../../../@chainlink/contracts-ccip/src/v0.8/ccip/interfaces/IPriceRegistry";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "fromToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "fromTokenAmount",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "toToken",
        type: "address",
      },
    ],
    name: "convertTokenAmount",
    outputs: [
      {
        internalType: "uint256",
        name: "toTokenAmount",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "destChainSelector",
        type: "uint64",
      },
    ],
    name: "getDestinationChainGasPrice",
    outputs: [
      {
        components: [
          {
            internalType: "uint224",
            name: "value",
            type: "uint224",
          },
          {
            internalType: "uint32",
            name: "timestamp",
            type: "uint32",
          },
        ],
        internalType: "struct Internal.TimestampedPackedUint224",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint64",
        name: "destChainSelector",
        type: "uint64",
      },
    ],
    name: "getTokenAndGasPrices",
    outputs: [
      {
        internalType: "uint224",
        name: "tokenPrice",
        type: "uint224",
      },
      {
        internalType: "uint224",
        name: "gasPrice",
        type: "uint224",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "getTokenPrice",
    outputs: [
      {
        components: [
          {
            internalType: "uint224",
            name: "value",
            type: "uint224",
          },
          {
            internalType: "uint32",
            name: "timestamp",
            type: "uint32",
          },
        ],
        internalType: "struct Internal.TimestampedPackedUint224",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "tokens",
        type: "address[]",
      },
    ],
    name: "getTokenPrices",
    outputs: [
      {
        components: [
          {
            internalType: "uint224",
            name: "value",
            type: "uint224",
          },
          {
            internalType: "uint32",
            name: "timestamp",
            type: "uint32",
          },
        ],
        internalType: "struct Internal.TimestampedPackedUint224[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "getValidatedTokenPrice",
    outputs: [
      {
        internalType: "uint224",
        name: "",
        type: "uint224",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "address",
                name: "sourceToken",
                type: "address",
              },
              {
                internalType: "uint224",
                name: "usdPerToken",
                type: "uint224",
              },
            ],
            internalType: "struct Internal.TokenPriceUpdate[]",
            name: "tokenPriceUpdates",
            type: "tuple[]",
          },
          {
            components: [
              {
                internalType: "uint64",
                name: "destChainSelector",
                type: "uint64",
              },
              {
                internalType: "uint224",
                name: "usdPerUnitGas",
                type: "uint224",
              },
            ],
            internalType: "struct Internal.GasPriceUpdate[]",
            name: "gasPriceUpdates",
            type: "tuple[]",
          },
        ],
        internalType: "struct Internal.PriceUpdates",
        name: "priceUpdates",
        type: "tuple",
      },
    ],
    name: "updatePrices",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class IPriceRegistry__factory {
  static readonly abi = _abi;
  static createInterface(): IPriceRegistryInterface {
    return new utils.Interface(_abi) as IPriceRegistryInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IPriceRegistry {
    return new Contract(address, _abi, signerOrProvider) as IPriceRegistry;
  }
}
