import React from 'react';
import { Button } from '@mui/material';
import { useUserContext } from '@backed-fi/context';
import { useNavigate } from 'react-router-dom';

export const VerifyButton: React.FC<React.ComponentProps<typeof Button>> = (props) => {
  const navigate = useNavigate();
  const userContext = useUserContext();

  // if (!userContext.loaded || userContext.verification?.currentTier !== VerificationTier.Initial) {
  return null;
  // }

  const onClick = () => {
    navigate('/compliance');
  };

  return (
    <Button
      onClick={onClick}
      {...props}
    >
      Verify your Identity
    </Button>
  );
};
