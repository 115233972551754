import { styled } from '@mui/material';

export const Shortcut = styled('div')(({ theme }) => ({
  fontSize: theme.typography.pxToRem(13),
  fontWeight: 700,
  lineHeight: '21px',
  marginLeft: theme.spacing(0.5),
  border: `1px solid ${
    theme.palette.mode === 'dark'
      ? theme.palette.primary.main
      : theme.palette.grey[200]
  }`,
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.primary.main : '#FFF',
  padding: theme.spacing(0, 0.7),
  borderRadius: 5,
}));
