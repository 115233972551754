import React from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom';

import { TransactionsLayout } from './TransactionsLayout';
import { InteractionsHistoryPage } from './pages/InteractionsHistoryPage';
import { InteractionDetailsPage } from './pages/InteractionDetailsPage';
import { InteractionsPendingPage } from './pages/InteractionsPendingPage';

const TransactionToInteractionRedirect: React.FC = () => {
  const location = useLocation();

  return (
    <Navigate
      to={location.pathname.replace('transactions', 'interactions')}
    />
  );
};

export const TransactionPages = (
  <React.Fragment>
    <Route
      path="transactions"
      element={
        <TransactionToInteractionRedirect />
      }
    />

    <Route
      path="interactions"
      element={<TransactionsLayout />}
    >
      <Route
        path="pending"
        element={<InteractionsPendingPage />}
      />

      <Route
        path="history"
        element={<InteractionsHistoryPage />}
      />

      <Route
        path="details/:id"
        element={<InteractionDetailsPage />}
      />
    </Route>
  </React.Fragment>
);
