import React from 'react';

import { Alert, Box, ButtonBase, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AccountSecurityIcon from '@mui/icons-material/AdminPanelSettingsOutlined';

import { SideBarItem } from './SidebarItem';
import { AccountUsageTile } from '../AccountUsageTile';
import { OnboardingTourDialog } from '../dialogs/OnboardingTourDialog';

import { useHasFeature } from '@backed-fi/hooks';
import { ClientFeature, ClientStatus } from '@backed-fi/graphql';
import { DomainRestricted } from '@backed-fi/primitives';
import { config } from '@backed-fi/config';
import { useNavigate } from 'react-router-dom';
import { useClientContext } from '@backed-fi/context';

const TransactionButton = styled(ButtonBase)(() => ({
  flex: 1,
  fontSize: 16,
  fontWeight: 600,
  height: '48px',
  color: 'white',
  backgroundColor: '#0066ff',

  '&:hover': {
    backgroundColor: '#3385ff'
  },

  '&:first-child': {
    borderTopLeftRadius: '24px',
    borderBottomLeftRadius: '24px',
    borderRight: '2px solid #3385ff'
  },

  '&:last-child': {
    borderTopRightRadius: '24px',
    borderBottomRightRadius: '24px'
  }
}));

export const SideBar = () => {
  const navigate = useNavigate();
  const clientContext = useClientContext();

  const hasIssuance = useHasFeature(ClientFeature.Issuance);
  const hasApiAccess = useHasFeature(ClientFeature.ApiAccess);
  const hasRedemption = useHasFeature(ClientFeature.Redemption);

  const [isOnboardingDialogOpen, setOnboardingDialogOpen] = React.useState(false);
  const { shouldRegisterWallet, shouldActivateWallet, status } = clientContext;
  const canClientIssue = hasIssuance && !(shouldRegisterWallet || shouldActivateWallet);
  const canClientRedeem = hasRedemption && !(shouldRegisterWallet || shouldActivateWallet);

  React.useEffect(() => {
    if (
      status === ClientStatus.Onboarding &&
      localStorage.getItem('BackedFi.Onboarding.Dialog') !== 'false'
    ) {
      setOnboardingDialogOpen(true);
    }
  }, [status]);

  const handleOnboardingDialogClose = () => {
    localStorage.setItem('BackedFi.Onboarding.Dialog', 'false');
    setOnboardingDialogOpen(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column',
        zIndex: '120',
        position: 'fixed',
        width: '320px',
        height: '100vh',
        boxShadow: '0 0.125rem 0.5rem #e2e5eb'
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          minHeight: '80px',
          marginTop: '80px',
          padding: '1rem',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: 1,
          borderColor: '#edeff4',

          ...(!canClientIssue && !canClientRedeem && {
            opacity: .5
          })
        }}
      >
        <TransactionButton
          disabled={!canClientIssue}
          onClick={() => navigate('/issue')}
          sx={{
            ...(!canClientIssue && canClientRedeem && {
              backgroundColor: '#0066ff80'
            })
          }}
        >
          Issue
        </TransactionButton>

        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            zIndex: 1,
            left: '50%',
            width: '32px',
            height: '32px',
            color: 'white',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#0066ff',
            transform: 'translateX(-50%)',
            border: '2px solid #3385ff',
            borderRadius: '100%'
          }}
        >
          <SyncAltOutlinedIcon
            sx={{
              width: '20px',
              height: 'auto'
            }}
          />
        </Box>

        <TransactionButton
          disabled={!canClientRedeem}
          onClick={() => navigate('/redeem')}
          sx={{
            ...(canClientIssue && !canClientRedeem && {
              backgroundColor: '#0066ff80'
            })
          }}
        >
          Redeem
        </TransactionButton>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          padding: '1rem',
          overflowY: 'auto'
        }}
      >
        <SideBarItem
          title='Home'
          path='/dashboard'
          Icon={HomeOutlinedIcon}
        />

        <SideBarItem
          Icon={NotificationsNoneOutlinedIcon}
          title='Account Activity'
          subSideBarItems={[
            {
              Icon: AccessTimeOutlinedIcon,
              path: 'interactions/pending',
              title: 'Pending Interactions'
            },
            {
              Icon: ReceiptOutlinedIcon,
              path: 'interactions/history',
              title: 'Interaction History'
            }
          ]}
        />

        <SideBarItem
          title='Settings'
          Icon={SettingsOutlinedIcon}
          subSideBarItems={[
            {
              Icon: AccountBalanceWalletOutlinedIcon,
              path: '/settings/wallets',
              title: 'Wallets'
            },
            {
              Icon: AccountBalanceOutlinedIcon,
              path: '/settings/bank-accounts',
              title: 'Bank Accounts'
            },
            {
              Icon: SyncAltOutlinedIcon,
              path: '/settings/transactions',
              title: 'Transactions'
            },
            {
              Icon: PaidOutlinedIcon,
              path: '/settings/deposit',
              title: 'Deposit Addresses'
            },
            ...(hasApiAccess ? ([
              {
                Icon: VpnKeyOutlinedIcon,
                path: '/settings/api',
                title: 'API Access'
              }
            ]) : []),
            {
              Icon: PersonOutlineOutlinedIcon,
              path: '/settings/account',
              title: 'Account Details'
            },
            {
              Icon: AccountSecurityIcon,
              path: '/settings/security',
              title: 'Account Security'
            }
          ]}
        />
      </Box>

      <Box
        sx={{
          marginTop: 'auto'
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: '#edeff4'
          }}
        >
          <DomainRestricted dev local stage sandbox>
            <Alert
              variant='outlined'
              severity='warning'
              sx={{
                margin: '0 1rem 1rem'
              }}
            >
              {config.isDev && 'Development Environment'}
              {config.isStage && 'Staging Environment'}
            </Alert>
          </DomainRestricted>
        </Box>

        <Box
          sx={{
            padding: '2rem',
            borderBottom: 1,
            borderColor: '#edeff4'
          }}
        >
          <AccountUsageTile />
        </Box>

        <Box
          sx={{
            padding: '1.5rem 2rem',
            color: '#848a98'
          }}
        >
          <Typography
            sx={{
              fontSize: 11
            }}
          >
            {(new Date()).getFullYear()} &copy; Backed Finance. All Rights Reserved.
          </Typography>

          <Typography
            sx={{
              display: 'flex',
              gap: '1rem',
              fontSize: 11,
              marginTop: '0.25rem'
            }}
          >
            <Link
              href='/assets/docs/TermsOfService.pdf'
              target='_blank'
              variant='link'
              color='inherit'
            >
              Terms of Service
            </Link>

            <Link
              href='/assets/docs/PrivacyPolicy.pdf'
              target='_blank'
              variant='link'
              color='inherit'
            >
              Privacy Policy
            </Link>
          </Typography>
        </Box>
      </Box>

      <OnboardingTourDialog
        isOpen={isOnboardingDialogOpen}
        onClose={handleOnboardingDialogClose}
      />
    </Box>
  );
};
