import React from 'react';

import { Box } from '@mui/material';
import { RegisteredWalletsSection } from '../components/RegisteredWalletsSection';

export const WalletsSettingsPage: React.FC = () => (
  <Box>
    <RegisteredWalletsSection />
  </Box>
);
