import * as React from 'react';
import { forwardRef, Ref, SVGProps } from 'react';

const SvgWallet = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 16 16'
    ref={ref}
    {...props}
  >
    <defs>
      <clipPath id='Wallet_svg__a'>
        <path
          data-name='Rectangle 8'
          transform='translate(62 209.425)'
          fill='#01000d'
          d='M0 0h16v16H0z'
        />
      </clipPath>
    </defs>
    <g
      data-name='wallet_ icon'
      transform='translate(-62 -209.425)'
      clipPath='url(#Wallet_svg__a)'
    >
      <path
        data-name='Path 147'
        d='M63.89 212.889h13.228v-1.26H63.89a1.892 1.892 0 0 0-1.89 1.89v8.819a1.892 1.892 0 0 0 1.89 1.891h13.228v-10.08H63.89a.63.63 0 0 1 0-1.26m0 2.52h11.968v7.559H63.89a.631.631 0 0 1-.63-.63v-7.037a1.877 1.877 0 0 0 .63.108m9.449 3.78a.63.63 0 1 1 .63.63.63.63 0 0 1-.63-.63'
        fill='#01000d'
      />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgWallet);
export default ForwardRef;
