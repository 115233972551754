import React from 'react';
import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Badge, Box } from '@mui/material';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';

import { TextButton } from '../../../components/TextButton';
import { ContentCard } from '../../../components/ContentCard';
import { TablePlaceholder } from '../../../components/TablePlaceholder';
import { ServiceAccountStatus, useApiSettingsDataQuery, useDisableServiceAccountMutation } from '@backed-fi/graphql';

const Graph = gql`
  query apiSettingsData {
    serviceAccounts {
      id

      createdAt
      updatedAt

      status
      title
    }
  }

  mutation disableServiceAccount($input: DisableServiceAccountMutationInput!) {
    disableServiceAccount(input: $input)
  }
`;

const tableHeaders = [
  'Status',
  'Date and Time Created',
  'Key ID'
];

export const ApiKeysTable: React.FC = () => {
  const snackbar = useSnackbar();
  const { data, loading } = useApiSettingsDataQuery();

  const [disableServiceAccount] = useDisableServiceAccountMutation({
    refetchQueries: [
      'apiSettingsData'
    ]
  });

  const onDisableServiceAccount = (serviceAccountId: string) => async () => {
    try {
      await disableServiceAccount({
        variables: {
          input: {
            serviceAccountId
          }
        }
      });

      snackbar.enqueueSnackbar('API key successfully disabled.', {
        variant: 'success'
      });
    } catch (e) {
      snackbar.enqueueSnackbar('An error occurred. Please try again or contact us.', {
        variant: 'error'
      });

      console.error(e);
    }
  };

  return (
    <ContentCard>
      <TableContainer
        sx={{
          minHeight: '264px'
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((label, key) => (
                <TableCell key={key}>{label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!data?.serviceAccounts.length && (
              <TablePlaceholder
                message='No API keys have been generated yet.'
                isLoading={loading}
              />
            )}

            {
              !!data?.serviceAccounts.length &&
              [...data.serviceAccounts]
                .sort((a, b) => a.status.localeCompare(b.status) || new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                .map((serviceAccount) => (
                  <TableRow key={serviceAccount.id}>
                    <TableCell width='15%'>
                      <Box
                        sx={{
                          gap: '0.75rem',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <Badge
                          variant='dot'
                          sx={{
                            marginLeft: '0.5rem',

                            '.MuiBadge-badge': {
                              backgroundColor: serviceAccount.status === ServiceAccountStatus.Active
                                ? 'success.solidBackground'
                                : '#c5c9d3'
                            }
                          }}
                        />

                        {serviceAccount.status === ServiceAccountStatus.Active ? 'Active' :
                          serviceAccount.status === ServiceAccountStatus.Expired ? 'Expired' :
                            'Disabled'
                        }
                      </Box>
                    </TableCell>
                    <TableCell width='20%'>
                      {new Date(serviceAccount.createdAt).toLocaleString()}
                    </TableCell>
                    <TableCell width='65%'>
                      <Box
                        sx={{
                          gap: '1rem',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        {serviceAccount.id}
                        {serviceAccount.status === ServiceAccountStatus.Active && (
                          <TextButton
                            onClick={onDisableServiceAccount(serviceAccount.id)}
                          >
                            <RemoveCircleOutlineOutlinedIcon
                              fontSize='small'
                            />

                            Disable
                          </TextButton>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </ContentCard>
  );
};
