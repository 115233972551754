import React from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, TextField, Typography } from '@mui/material';

import { ClientType } from '@backed-fi/graphql';
import { ContentCard } from '@backed-fi/app/src/app/components/ContentCard';

// region Schema

const Schema = z.object({
  email: z.string().email(),

  clientType: z.nativeEnum(ClientType)
});

// endregion

// region Helpers

const ClientTypeDisplayNameMap: Partial<Record<ClientType, string>> = {
  Institution: 'Company'
};

// endregion

export const OnboardingContactPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // region State

  const [emailDisabled, setEmailDisabled] = React.useState<boolean>(false);

  // endregion

  // region Form Control

  const form = useForm<z.infer<typeof Schema>>({
    resolver: zodResolver(Schema),
    defaultValues: {
      clientType: ClientType.Individual
    }
  });

  const { errors } = form.formState;

  // endregion

  // region Effects

  React.useEffect(() => {
    const email = searchParams.get('email');

    if (email) {
      form.setValue('email', email);
      setEmailDisabled(true);
    }
  }, []);

  // endregion

  // region Actions

  const onNext = () =>
    form.handleSubmit((data) => {
      navigate('/onboarding/terms', {
        state: {
          ...data,
          principal: data.clientType,
          challengeId: searchParams.get('challengeId'),
          privateCode: searchParams.get('privateCode')
        }
      });
    });

  // endregion

  return (
    <Box>
      {/* region Information */}

      <Typography
        sx={{
          marginBottom: '1rem',
          fontWeight: 400,
          fontSize: 20
        }}
      >
        A few things to know before you start.
      </Typography>

      <Typography
        sx={{
          fontSize: 14
        }}
      >
        Backed accounts allow users to access our tokenized products directly.
        Please note that different services will be enabled depending on your
        account type and your "Qualified Investor" status.
      </Typography>

      <ContentCard
        variant="soft"
        sx={{
          padding: '1.5rem',
          margin: '1.5rem 0 0.5rem'
        }}
      >
        <Box
          sx={{
            gap: '1rem',
            display: 'grid',
            gridTemplateColumns: {
              xs: '1fr',
              md: '1fr 1fr'
            }
          }}
        >
          <Box>
            <Typography
              sx={{
                fontWeight: 600
              }}
            >
              Qualified Investors
            </Typography>

            <Typography
              sx={{
                mb: '1rem',
                fontWeight: 400,
                textDecoration: 'underline',
                fontSize: 14
              }}
            >
              Can issue and redeem Backed tokens
            </Typography>

            <Typography
              sx={{
                margin: '0.25rem 0 0',
                color: '#848a98',
                fontWeight: 400,
                fontSize: 12
              }}
            >
              You will need to formally confirm your Qualified Investor status
              once your account has been setup.
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{
                fontWeight: 600
              }}
            >
              Non Qualified Investors
            </Typography>

            <Typography
              sx={{
                mb: '1rem',
                fontWeight: 400,
                textDecoration: 'underline',
                fontSize: 14
              }}
            >
              Redemption only
            </Typography>

            <Typography
              sx={{
                margin: '0.25rem 0 0',
                color: '#848a98',
                fontWeight: 400,
                fontSize: 12
              }}
            >
              This is for users who already own Backed tokens and would like to
              redeem them with us.
            </Typography>
          </Box>
        </Box>
      </ContentCard>

      {/* endregion */}

      {/* region Form */}

      <TextField
        fullWidth
        label="Email"
        disabled={emailDisabled}
        error={!!errors.email?.message}
        helperText={errors.email?.message}
        sx={{
          margin: '1rem 0'
        }}
        {...form.register('email')}
      />

      <TextField
        select
        fullWidth
        label="Client Type"
        SelectProps={{
          native: true
        }}
        {...form.register('clientType')}
      >
        <option disabled selected>
          Please select
        </option>

        {[ClientType.Individual, ClientType.Institution].map((type) => (
          <option key={type} value={type}>
            {ClientTypeDisplayNameMap[
              type as keyof typeof ClientTypeDisplayNameMap
            ] ?? type}
          </option>
        ))}
      </TextField>

      {/* endregion */}

      <Box
        sx={{
          margin: '1rem 0 1.25rem',
          padding: '.75rem 1rem',
          borderRadius: '8px',
          backgroundColor: '#f2f4f8'
        }}
      >
        <Typography
          sx={{
            color: '#848a98',
            fontWeight: 400,
            fontSize: 12
          }}
        >
          Backed DOES NOT sell its tokens to U.S. Persons or for the account or
          benefit of U.S. Persons, and tokens are not marketed, offered, or
          solicited in the U.S. or in any other prohibited jurisdiction.
        </Typography>
      </Box>

      <Box
        sx={{
          mt: '1rem',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button variant="publicDashboard" onClick={onNext()}>
          Create your account
        </Button>
      </Box>
    </Box>
  );
};
