import React from 'react';
import { z } from 'zod';
import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Dialog, DialogProps, TextField, Typography } from '@mui/material';

import { useClientContext } from '@backed-fi/context';
import { useCreateBankAccountMutation } from '@backed-fi/graphql';

// region Form Schema

const AddBankAccountSchema = z.object({
  description: z.string()
    .nonempty(),

  iban: z.string()
    .regex(/^[A-Z]{2}\d{2}[A-Z\d]{1,30}$/gm, 'The provided IBAN is not a valid IBAN')
    .nonempty(),

  bankName: z.string()
    .nonempty(),

  swift: z.string()
    .regex(/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/, 'The provided SWIFT code is not a valid SWIFT code')
    .nonempty(),

  bankAddress: z.string()
    .nonempty()
});

type AddBankAccountSchemaType = z.infer<typeof AddBankAccountSchema>

// endregion

// region Graph Registration

const Graph = gql`
  mutation createBankAccount($input: CreateBankAccountInput!) {
    createBankAccount(input: $input) {
      id
    }
  }
`;

// endregion

// region Props Interface

interface Props extends DialogProps {
  dismiss: () => void;
}

// endregion

export const CreateBankAccountDialog: React.FC<Props> = (props) => {
  const snackbar = useSnackbar();
  const clientContext = useClientContext();

  // region Form Control

  const form = useForm<AddBankAccountSchemaType>({
    resolver: zodResolver(AddBankAccountSchema)
  });

  const { errors } = form.formState;

  // endregion

  // region Networking

  const [createBankAccount] = useCreateBankAccountMutation({
    refetchQueries: [
      'fetchAllBankAccounts',
      'fetchDefaultBankAccount'
    ]
  });

  // endregion

  // region Actions

  const onCreateBankAccount = form.handleSubmit(async (data) => {
    try {
      // If we are here the data is valid, so we can just submit it
      await createBankAccount({
        variables: {
          input: data
        }
      });

      // Notify the user that the bank account was created successfully
      snackbar.enqueueSnackbar('Bank account successfully created', {
        variant: 'success'
      });

      // And close the modal
      props.dismiss();

      /// Clear the form for next time
      form.reset();
    } catch (e) {
      console.error(e);

      snackbar.enqueueSnackbar('An error occurred', {
        variant: 'error'
      });
    }
  });

  const onClose = () => {
    // Clear the form
    form.reset();

    // And close the dialog
    props.dismiss();
  };

  // endregion

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      onClose={onClose}
      {...props}
    >
      <Box
        sx={{
          padding: '2rem'
        }}
      >
        <Typography variant='modalTitle'>
          Add Bank Account
        </Typography>

        <Typography variant='modalSubtitle'>
          Your name must be identical to the name on account. An IBAN is required for SEPA countries,
          use an IBAN or account number for any other country.
        </Typography>

        <Box
          sx={{
            gap: '1rem',
            display: 'flex',
            marginTop: '2rem',
            flexDirection: 'column'
          }}
        >
          <TextField
            label='Name on Account'
            value={clientContext.name}
            fullWidth
            disabled
          />

          <TextField
            label='Account Description'
            helperText={errors.description?.message}
            error={Boolean(errors.description)}
            {...form.register('description')}
            fullWidth
          />

          <TextField
            label='IBAN / Account Number'
            helperText={errors.iban?.message}
            error={Boolean(errors.iban)}
            {...form.register('iban')}
            fullWidth
          />

          <TextField
            label='BIC / SWIFT'
            helperText={errors.swift?.message}
            error={Boolean(errors.swift)}
            {...form.register('swift')}
            fullWidth
          />

          <TextField
            label='Bank Name'
            helperText={errors.bankName?.message}
            error={Boolean(errors.bankName)}
            {...form.register('bankName')}
            fullWidth
          />

          <TextField
            label='Bank Address'
            helperText={errors.bankAddress?.message}
            error={Boolean(errors.bankAddress)}
            {...form.register('bankAddress')}
            multiline
            fullWidth
            rows={3}
          />

          <LoadingButton
            onClick={onCreateBankAccount}
            variant='publicDashboard'
            fullWidth
          >
            Add Bank Account
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  );
};
