import { useEffect } from 'react';

export const useScript = (
  url: string,
  onLoaded?: (this: GlobalEventHandlers, ev: Event) => any
) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    script.onload = onLoaded || null;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url, onLoaded]);
};
