import React from "react";
import Decimal from "decimal.js";
import { gql } from "@apollo/client";

import { Box, CircularProgress } from "@mui/material";
import { ContentCard } from "../../../components/ContentCard";
import { TransactionCard } from "../components/TransactionCard";
import { TransactionForm } from "../components/TransactionForm";
import { NetworkSelectorConfig } from "../../../components/NetworkSelector";
import { AssetSelector } from "../components/AssetSelector";
import { PorCard } from "../components/PorCard";

import {
  BlockchainNetwork,
  IssueDetailsQuery,
  useIssueDetailsQuery,
} from "@backed-fi/graphql";
import { useSearchParams } from "react-router-dom";
import { useWeb3Context } from "@backed-fi/shared/components/providers/context/Web3Context";
import {
  StablecoinSelector,
  StablecoinSelectorConfig,
} from "../../../components/StablecoinSelector";

const Graph = gql`
  query issueDetails($network: BlockchainNetwork!) {
    tokens {
      id
      name
      symbol

      deployments {
        id
        network
        chainId
        address
        stablecoinConfigs {
          symbol
          decimals
          address
          network
          chainId
          minimumTransactionValue
        }
      }

      collateral {
        exchange {
          isOpen
        }
      }

      sweepingWallet(network: $network) {
        id

        address
      }
    }
  }
`;

export const IssueHomePage: React.FC = () => {
  const [search] = useSearchParams();
  const web3Context = useWeb3Context();

  const [selectedAsset, setSelectedAsset] =
    React.useState<IssueDetailsQuery["tokens"][0]>();
  const [selectedNetwork, setSelectedNetwork] =
    React.useState<NetworkSelectorConfig>();
  const [availableStableConfigs, setAvailableStableConfigs] = React.useState<
    StablecoinSelectorConfig[]
  >([]);
  const [stablecoinConfig, setStablecoinConfig] =
    React.useState<StablecoinSelectorConfig>();
  const [refetchAttempts, setRefetchAttempts] = React.useState<number>(0);

  const { data, error, refetch, loading } = useIssueDetailsQuery({
    variables: {
      network: selectedNetwork?.network ?? BlockchainNetwork.Ethereum,
    },
  });
  const { tokens } = (data || {}) as IssueDetailsQuery;

  // Poll the wallets for sweeping wallet until there is no problem in creating them concurrently
  React.useEffect(() => {
    if (error && refetchAttempts <= 5) {
      setRefetchAttempts((p) => ++p);
      refetch();
    }
  }, [error, refetchAttempts, refetch]);

  // Set selected token from URL query parameter if provided, otherwise default to first
  // Example: /issue?token=bCOIN
  React.useEffect(() => {
    if (tokens) {
      const tokenParam = search.get("token");

      if (tokenParam) {
        for (const token of tokens) {
          if (token.symbol === tokenParam) {
            setSelectedAsset(token);
            return;
          }
        }
      }

      if (!selectedAsset) {
        setSelectedAsset(tokens[0]);
      }
    }
  }, [tokens, search, selectedAsset]);

  // Update stablecoin configuration when selected asset or network changes
  React.useEffect(() => {
    if (selectedAsset) {
      const assetDeploymentOnNetwork =
        selectedNetwork &&
        selectedAsset.deployments.find(
          (deployment) => deployment.network === selectedNetwork.network
        );
      const assetDeployment =
        assetDeploymentOnNetwork || selectedAsset.deployments[0];

      setAvailableStableConfigs(
        selectedAsset.deployments.flatMap((deployment) => [
          ...deployment.stablecoinConfigs,
        ])
      );

      if (assetDeployment) {
        if (!selectedNetwork || !assetDeploymentOnNetwork) {
          setSelectedNetwork({
            network: assetDeployment.network,
            chainId: assetDeployment.chainId,
          });
        }

        const newStablecoinConfig =
          assetDeployment.stablecoinConfigs.find(
            (stablecoin) =>
              stablecoin.network === selectedNetwork?.network &&
              stablecoin.symbol === stablecoinConfig?.symbol
          ) || assetDeployment.stablecoinConfigs[0];

        if (newStablecoinConfig) {
          setStablecoinConfig(newStablecoinConfig);
        }
      }
    }
  }, [selectedAsset, selectedNetwork, stablecoinConfig]);

  React.useEffect(() => {
    if (selectedNetwork && web3Context.connected) {
      web3Context.switchNetwork(selectedNetwork.chainId);
    }
  }, [selectedNetwork, web3Context]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "2rem",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <TransactionCard
        title="Issue Tokens"
        subtitle="Please select the asset and network you wish to issue on."
      >
        {(loading ||
          !selectedAsset ||
          !selectedNetwork ||
          !stablecoinConfig) && (
          <ContentCard
            variant="outline"
            sx={{
              height: "256px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={32} />
          </ContentCard>
        )}

        {!loading && selectedAsset && selectedNetwork && stablecoinConfig && (
          <TransactionForm
            isMarketOpen={selectedAsset.collateral.exchange?.isOpen}
            sendAsset={{
              network: selectedNetwork.network,
              symbol: stablecoinConfig!.symbol,
              type: "Stablecoin",
              decimals: stablecoinConfig!.decimals,
              address: stablecoinConfig!.address,
            }}
            transactionMinimum={new Decimal(
              stablecoinConfig.minimumTransactionValue
            )
              .div(100)
              .toString()}
            receiveAsset={{
              network: selectedNetwork.network,
              symbol: selectedAsset.symbol,
            }}
            network={selectedNetwork.network}
            address={selectedAsset.sweepingWallet.address}
            message={
              <>
                Sending{" "}
                <Box component="span" fontWeight={700}>
                  {stablecoinConfig.symbol}
                </Box>{" "}
                to the address above initiates an issuance process for{" "}
                <Box component="span" fontWeight={700}>
                  {selectedAsset.symbol}
                </Box>{" "}
                tokens. Each asset to be issued uses a different deposit
                address. Ensure you copy and use the correct address for the
                asset you wish to issue.
              </>
            }
            sendChildren={
              <StablecoinSelector
                selectedStablecoin={stablecoinConfig}
                availableStablecoins={availableStableConfigs}
                onStablecoinChange={(stablecoinConfig) => {
                  setStablecoinConfig(stablecoinConfig);
                  setSelectedNetwork({
                    chainId: stablecoinConfig.chainId,
                    network: stablecoinConfig.network,
                  });
                }}
              />
            }
            receiveChildren={
              <AssetSelector
                selectedAsset={selectedAsset}
                availableAssets={tokens}
                onAssetChange={(token) =>
                  setSelectedAsset(token as IssueDetailsQuery["tokens"][0])
                }
              />
            }
          />
        )}
      </TransactionCard>

      {selectedAsset && <PorCard assetSymbol={selectedAsset.symbol} />}
    </Box>
  );
};
