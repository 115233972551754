import { useAuthContext, useBrowserContext } from '@backed-fi/context';
import { gql } from '@apollo/client';
import { useAuthenticateWithEmailChallengeMutation, useAuthenticateWithPasswordChallengeMutation, useAuthenticateWithTransitionTokenMutation, useStartEmailChallengeMutation } from '@backed-fi/graphql';
import { useLocation } from 'react-router-dom';
import { useUrlQuery } from '@backed-fi/hooks';

// region Graph Declaration

gql`
  fragment authResult on AuthenticationResult {
    successful
    destination

    transitionToken
    authenticationToken

    errorCode
    errorMessage
    errorReference
  }

  mutation authenticateWithTransitionToken($input: AuthenticateWithTransitionTokenInput!) {
    authenticateWithTransitionToken(input: $input) {
      ...authResult
    }
  }

  mutation startEmailChallenge($input: StartEmailChallengeInput!) {
    startEmailChallenge(input: $input) {
      successful
      errorMessage
      hasPassword
    }
  }

  mutation authenticateWithEmailChallenge($input: AuthenticateEmailChallengeInput!) {
    authenticateWithEmailChallenge(input: $input) {
      ...authResult
    }
  }

  mutation authenticateWithPasswordChallenge($input: AuthenticateWithPasswordInput!) {
    authenticateWithPassword(input: $input) {
      ...authResult
    }
  }
`;

// endregion

export const useOneTimeAuthentication = () => {
  const query = useUrlQuery();
  const location = useLocation();
  const authContext = useAuthContext();
  const browserContext = useBrowserContext();

  // region Networking

  const [startEmailChallengeMutation, { loading: startLoading }] = useStartEmailChallengeMutation();
  const [authenticateWithEmailChallengeMutation, { loading: authenticateLoading }] = useAuthenticateWithEmailChallengeMutation();
  const [authenticateWithTransitionTokenMutation, { loading: authenticateMFALoading }] = useAuthenticateWithTransitionTokenMutation();
  const [authenticateWithPasswordMutation, { loading: authenticateWithPasswordLoading }] = useAuthenticateWithPasswordChallengeMutation();
  // endregion

  // region Actions

  const startEmailChallenge = async (email: string, forceSend: boolean = false) => {
    const result = (await startEmailChallengeMutation({
      variables: {
        input: {
          forceSend,
          email,
          browserId: browserContext.browserId,
          path: (location.state as any)?.from
        }
      }
    })).data!.startEmailChallenge;

    if (result.successful || result.hasPassword) {
      return result;
    } else {
      throw new Error(result.errorMessage || undefined);
    }
  };

  const authenticateWithEmailChallenge = async (challengeId: string, privateCode: string): Promise<{ redirectTo: string }> => {
    const result = (
      await authenticateWithEmailChallengeMutation({
        variables: {
          input: {
            challengeId,
            privateCode,
            browserId: browserContext.browserId
          }
        }
      })
    ).data!.authenticateWithEmailChallenge;

    if (result.successful) {

      if (result.transitionToken) {
        return {
          redirectTo: `/mfa?transitionToken=${result.transitionToken}${result.destination ? `&destination=${result.destination}` : ''}`
        };
      } else {
        authContext.authenticate(result.authenticationToken!);

        return {
          redirectTo: result.destination || '/'
        };
      }
    } else {
      throw new Error(result.errorMessage || 'Unsuccessful authentication');
    }
  };

  const authenticateWithTransitionToken = async (transitionToken: string, code: string) => {
    const result = (
      await authenticateWithTransitionTokenMutation({
        variables: {
          input: {
            code,
            token: transitionToken,
            browserId: browserContext.browserId
          }
        }
      })
    ).data!.authenticateWithTransitionToken;

    if (result.successful) {
      authContext.authenticate(result.authenticationToken!);
    } else {
      throw new Error(result.errorMessage || 'Unsuccessful authentication');
    }
  };

  const authenticateWithPassword = async (email: string, password: string) => {
    const result = (
      await authenticateWithPasswordMutation({
        variables: {
          input: {
            email,
            password
          }
        }
      })
    ).data!.authenticateWithPassword;

    if (result.successful) {
      authContext.authenticate(result.authenticationToken!);
    } else {
      throw new Error(result.errorMessage || 'Unsuccessful authentication');
    }
  };

  // endregion

  return {
    startEmailChallenge,
    authenticateWithPassword,
    authenticateWithEmailChallenge,
    authenticateWithTransitionToken,

    startLoading,
    authenticateLoading,
    authenticateMFALoading,
    authenticateWithPasswordLoading,

    loading:
      startLoading ||
      authenticateLoading ||
      authenticateMFALoading ||
      authenticateWithPasswordLoading
  };
};
