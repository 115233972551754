import { RoutesConfiguration } from './routes';

export const SharedConfiguration = {
  routes: RoutesConfiguration,

  limits: {
    minimum: {
      display: '1000'
    }
  }
};
