import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    success: true;
  }
}

export const MuiSvgIcon: Components<Theme>['MuiSvgIcon'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme, ownerState }) => {
      const { error, success, warning } = theme.palette;

      return {
        // fill: 'none'

        ...(ownerState.color === 'error' && {
          color: error.textLowContrast,
        }),

        ...(ownerState.color === 'success' && {
          color: success.textLowContrast,
        }),

        ...(ownerState.color === 'warning' && {
          color: warning.textLowContrast,
        }),
      };
    },
  },
};
