import { ethers } from 'ethers';


import {
  BackedFactory,
  BackedFactory__factory,
  BackedAutoFeeTokenFactory,
  BackedAutoFeeTokenFactory__factory,
  BackedOracle,
  BackedOracle__factory,
  BackedOracleFactory,
  BackedOracleFactory__factory,
  BackedTokenImplementation,
  BackedTokenImplementation__factory,
  ProxyAdmin,
  ProxyAdmin__factory,
  Stablecoin,
  Stablecoin__factory,
  Safe,
  Safe__factory, BackedOracleV1__factory, BackedOracleV1,
  BackedAutoFeeTokenImplementation,
  BackedAutoFeeTokenImplementation__factory,
  BackedCCIPReceiver__factory,
  BackedCCIPReceiver,
  EVM2EVMOffRamp__factory,
  Router__factory,
} from '../../generated/typechain';

// ---- Get contract factory ---- //

type OracleFactory = (contract: 'BackedOracle', signer?: ethers.Signer) => BackedOracle__factory;
type OracleV1Factory = (contract: 'BackedOracleV1', signer?: ethers.Signer) => BackedOracleV1__factory;
type StablecoinFactory = (contract: 'Stablecoin', signer?: ethers.Signer) => Stablecoin__factory;
type BackedFactoryFactory = (contract: 'BackedFactory', signer?: ethers.Signer) => BackedFactory__factory;
type BackedAutoFeeTokenFactoryFactory = (contract: 'BackedAutoFeeTokenFactory', signer?: ethers.Signer) => BackedAutoFeeTokenFactory__factory;
type BackedOracleFactoryFactory = (contract: 'OracleFactoryFactory', signer?: ethers.Signer) => BackedOracleFactory__factory;
type BackedImplementationFactory = (contract: 'BackedTokenImplementation', signer?: ethers.Signer) => BackedTokenImplementation__factory;
type BackedAutoFeeTokenImplementationFactory = (contract: 'BackedAutoFeeTokenImplementation', signer?: ethers.Signer) => BackedAutoFeeTokenImplementation__factory;
type ProxyAdminFactory = (contract: 'ProxyAdmin', signer?: ethers.Signer) => ProxyAdmin__factory;
type SafeFactory = (contract: 'Safe', signer?: ethers.Signer) => Safe__factory;
type BackedCcipReceiverFactory = (contract: 'BackedCCIPReceiver', signer?: ethers.Signer) => BackedCCIPReceiver__factory;
type EVM2EVMOffRampFactory = (contract: 'EVM2EVMOffRamp', signer?: ethers.Signer) => EVM2EVMOffRamp__factory;
type RouterFactory = (contract: 'Router', signer?: ethers.Signer) => Router__factory;


type GetContractFactoryFn =
  OracleFactory &
  OracleV1Factory &
  BackedFactoryFactory &
  BackedAutoFeeTokenFactoryFactory &
  BackedImplementationFactory &
  BackedAutoFeeTokenImplementationFactory &
  BackedOracleFactoryFactory &
  StablecoinFactory &
  ProxyAdminFactory &
  SafeFactory &
  BackedCcipReceiverFactory &
  EVM2EVMOffRampFactory &
  RouterFactory

/**
 * Returns type factory for the selected contract type
 *
 * @param contract
 * @param signer
 */
export const getContractFactory: GetContractFactoryFn = (contract, signer) => {
  switch (contract) {
    case 'BackedFactory':
      return new BackedFactory__factory(signer) as any;
    case 'BackedAutoFeeTokenFactory':
      return new BackedAutoFeeTokenFactory__factory(signer) as any;
    case 'BackedOracle':
      return new BackedOracle__factory(signer) as any;
    case 'BackedOracleV1':
      return new BackedOracleV1__factory(signer) as any;
    case 'OracleFactoryFactory':
      return new BackedOracleFactory__factory(signer) as any;
    case 'ProxyAdmin':
      return new ProxyAdmin__factory(signer) as any;
    case 'BackedTokenImplementation':
      return new BackedTokenImplementation__factory(signer) as any;
    case 'BackedAutoFeeTokenImplementation':
      return new BackedAutoFeeTokenImplementation__factory(signer) as any;
    case 'Stablecoin':
      return new Stablecoin__factory(signer) as any;
    case 'Safe':
      return new Safe__factory(signer) as any;
    case 'BackedCCIPReceiver':
      return new BackedCCIPReceiver__factory(signer) as any;
    case 'EVM2EVMOffRamp':
      return new EVM2EVMOffRamp__factory(signer) as any;
    case 'Router':
      return new Router__factory(signer) as any;
    default:
      throw new Error('Unsupported factory contract type');
  }
};


// ---- Get contract ---- //
type GetSafeContract = (contract: 'Safe', address: string, signerOrProvider?: ethers.Signer | ethers.providers.Provider) => Safe;
type GetProxyAdminContract = (contract: 'ProxyAdmin', address: string, signerOrProvider?: ethers.Signer | ethers.providers.Provider) => ProxyAdmin;
type GetStablecoinContract = (contract: 'Stablecoin', address: string, signerOrProvider?: ethers.Signer | ethers.providers.Provider) => Stablecoin;
type GetBackedOracleContract = (contract: 'BackedOracle', address: string, signerOrProvider?: ethers.Signer | ethers.providers.Provider) => BackedOracle;
type GetBackedOracleV1Contract = (contract: 'BackedOracleV1', address: string, signerOrProvider?: ethers.Signer | ethers.providers.Provider) => BackedOracleV1;
type GetBackedFactoryContract = (contract: 'BackedFactory', address: string, signerOrProvider?: ethers.Signer | ethers.providers.Provider) => BackedFactory;
type GetBackedAutoFeeTokenFactoryContract = (contract: 'BackedAutoFeeTokenFactory', address: string, signerOrProvider?: ethers.Signer | ethers.providers.Provider) => BackedAutoFeeTokenFactory;
type GetOracleFactoryContract = (contract: 'OracleFactoryFactory', address: string, signerOrProvider?: ethers.Signer | ethers.providers.Provider) => BackedOracleFactory;
type GetBackedTokenImplementationContract = (contract: 'BackedTokenImplementation', address: string, signerOrProvider?: ethers.Signer | ethers.providers.Provider) => BackedTokenImplementation;
type GetBackedAutoFeeTokenImplementationContract = (contract: 'BackedAutoFeeTokenImplementation', address: string, signerOrProvider?: ethers.Signer | ethers.providers.Provider) => BackedAutoFeeTokenImplementation;
type GetBackedCcipReceiverContract = (contract: 'BackedCCIPReceiver', address: string, signerOrProvider?: ethers.Signer | ethers.providers.Provider) => BackedCCIPReceiver;

type GetContractAtFn =
  GetSafeContract &
  GetProxyAdminContract &
  GetStablecoinContract &
  GetBackedFactoryContract &
  GetBackedAutoFeeTokenFactoryContract &
  GetBackedOracleContract &
  GetOracleFactoryContract &
  GetBackedOracleV1Contract &
  GetBackedTokenImplementationContract &
  GetBackedAutoFeeTokenImplementationContract &
  GetBackedCcipReceiverContract;

/**
 * Returns typed instance of the contract at the provided address
 *
 * @param contract - The contract type
 * @param address - The address, at which the contract is located
 * @param signerOrProvider - Signer or provider, needed if read/write operations will be performed
 */
export const getContractAt: GetContractAtFn = (contract, address, signerOrProvider) => {
  const ethersContract = getContractFactory(contract as any).attach(address);

  return signerOrProvider
    // The cast here is needed because TS looses track of the interface used
    ? ethersContract.connect(signerOrProvider) as any
    : ethersContract;
};
