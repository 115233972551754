import { gql } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { AssetReservesQuery, useAssetReservesQuery } from '@backed-fi/graphql';

import { Box, Grid, Link, Tooltip, Typography, styled } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';

gql`
  query assetReserves {
    assetReserves {
      symbol
      provider
      reserves
      inTransit
      tokensInCirculation
      timestamp
    }
  }
`;

interface Props {
  assetSymbol: string;
}

type Asset = {
  symbol: string;
  supplyBalance: number;
  transitBalance: number;
  reserveBalance: number;
  reserveRatio: number;
  timestamp: Date;
}

const Label = styled(Typography)(() => ({
  display: 'flex',
  minHeight: '20px',
  alignItems: 'center',
  color: '#848a98',
  gap: '0.25rem',
  fontSize: 12
}));

const Value = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.25rem',
  fontSize: 16
}));

export const PorCard: React.FC<Props> = ({ assetSymbol }) => {
  const { data } = useAssetReservesQuery();
  const { assetReserves } = (data || {}) as AssetReservesQuery;
  const [asset, setAsset] = useState<Asset | null>(null);

  const formatNumber = (value: number) =>
    value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

  useEffect(() => {
    if (assetSymbol && assetReserves) {
      const asset = assetReserves.find(
        ({ symbol }) => `b${symbol}` === assetSymbol
      );

      if (asset) {
        setAsset({
          symbol: asset.symbol,
          reserveBalance: +asset.reserves,
          transitBalance: +asset.inTransit,
          supplyBalance: +asset.tokensInCirculation,
          reserveRatio: (+asset.reserves / +asset.tokensInCirculation) * 100,
          timestamp: new Date(asset.timestamp)
        });
      } else {
        setAsset(null);
      }
    }
  }, [assetSymbol, assetReserves]);

  return asset && (
    <Box
      sx={{
        width: '100%',
        padding: '2rem',
        maxWidth: '640px',
        backgroundColor: '#e2e5eb',
        borderRadius: '0.5rem'
      }}
    >
      <Typography
        sx={{
          marginBottom: '0.75rem',
          fontSize: 18
        }}
      >
        <Box component="span" fontWeight="bold">b{asset.symbol}</Box> - Proof of Reserves
      </Typography>
      <Grid
        alignItems='center'
        spacing='1rem'
        container
      >
        <Grid md={4} item>
          <Label>Tokens in Circulation</Label>
          <Value>{formatNumber(asset.supplyBalance)} b{asset.symbol}</Value>
        </Grid>
        <Grid md={4} item>
          <Label>
            Collateral in Custody
            <Tooltip
              title={(
                <Typography
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '192px',
                    gap: '0.5rem',
                    fontSize: 12
                  }}
                >
                  Includes fiat balance waiting to be settled with broker.
                  <Box component="span" fontWeight="bold">
                    ~{formatNumber(asset.transitBalance)} {asset.symbol} in Transit
                  </Box>
                </Typography>
              )}
              placement="top"
              arrow
            >
              <HelpOutlineIcon fontSize="small" />
            </Tooltip>
          </Label>
          <Value>{formatNumber(asset.reserveBalance)} {asset.symbol}</Value>
        </Grid>
        <Grid md={4} item>
          <Label>Reserve Ratio</Label>
          <Value>
            {formatNumber(asset.reserveRatio)}%
            {
              asset.reserveRatio >= 100 ? (
                <CheckCircleIcon fontSize="small" sx={{ color: 'success.solidBackground' }} />
              ) : (
                <WarningIcon fontSize="small" sx={{ color: 'warning.solidBackground' }} />
              )
            }
          </Value>
        </Grid>
      </Grid>
      <Typography
        sx={{
          marginTop: '1rem',
          color: '#848a98',
          fontSize: 12
        }}
      >
        Data as of {asset.timestamp.toLocaleString()} - Monitored and validated by{' '}
        <Link href="https://chain.link" target="_blank" variant="link" color="inherit">
          Chainlink
        </Link>
      </Typography>
    </Box>
  );
};
