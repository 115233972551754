import React from 'react';
import { OnboardingConfiguration } from '@backed-fi/app/src/app/domain/Onboarding/configuration';
import { useClientContext } from '@backed-fi/context';
import { useUpdateQuestionnaireMutation } from '@backed-fi/graphql';

// region Types

type Status = 'Pending' | 'InProgress' | 'Completed';

interface StepData {
  step: string;
  status: Status;

  data: any;
}

interface OnboardingContextValues {
  questionnaireId?: string;

  isSaving: boolean;
  isAllDataSaved: boolean;

  steps: Record<string, StepData>;
  currentStep: string;
}

interface OnboardingContextFunctions {
  /**
   * Set data for specific key for the currently active step
   *
   * @param key
   * @param data
   */
  setData: (key: string, data: any) => void;

  /**
   * Get saved data for a step. If no step is provide
   * the data for the currently active step is returned
   *
   * @param key
   * @param step
   */
  getData: (key: string, step?: string) => any;

  /**
   * Manually trigger data save
   */
  saveData: () => Promise<void>;

  /**
   * @internal
   *
   * Function for marking the saving as in progress
   */
  startSaving: () => void;

  /**
   * @internal
   *
   * Function to mark the saving as concluded
   *
   * @param saved - Boolean, indicating if the save was successful
   */
  stopSaving: (saved: boolean) => void;

  /**
   * Forcefully replace all data
   *
   * @param data
   */
  initData: (questionnaireId: string, data: any) => void;

  progressToNextStep: () => void;
}

// endregion

const OnboardingContext = React.createContext<
  OnboardingContextValues & OnboardingContextFunctions
>({} as any);

export const OnboardingContextProvider: React.FC<React.PropsWithChildren> = ({
  children
}) => {
  const clientContext = useClientContext();
  const [contextValue, setContextValue] =
    React.useState<OnboardingContextValues>({
      isSaving: false,
      isAllDataSaved: true,

      steps: {},
      currentStep: ''
    });

  // region Networking

  const [updateQuestionnaire] = useUpdateQuestionnaireMutation();

  // endregion

  const currentStepConfiguration = React.useMemo(
    () =>
      OnboardingConfiguration.steps.find(
        (x) => x.id === contextValue.currentStep
      ),
    [contextValue.currentStep]
  );

  // region Helper Functions

  const setData: OnboardingContextFunctions['setData'] = (key, data) => {
    setContextValue((prev) => ({
      ...prev,
      isAllDataSaved: false,
      steps: {
        ...prev.steps,
        [prev.currentStep]: {
          latestUpdate: new Date().getTime(),
          ...prev.steps[prev.currentStep],
          [key]: data
        }
      }
    }));
  };

  const getData: OnboardingContextFunctions['getData'] = (key, step) => {
    const stepData = contextValue.steps[
      step ?? contextValue.currentStep
    ] as any;

    return stepData?.[key];
  };

  const initData: OnboardingContextFunctions['initData'] = (
    questionnaireId,
    data
  ) => {
    // Find the latest step that the client has done
    const latestStep = Object.entries<any>(data).reduce(
      (prev, current) => {
        return (prev[1].latestUpdate ?? 0) > current[1].latestUpdate
          ? prev
          : current;
      },
      ['professional-investor', { latestUpdate: 0 }]
    );

    setContextValue((prev) => ({
      ...prev,

      questionnaireId,
      steps: data,
      isSaving: false,
      isAllDataSaved: true,

      currentStep: latestStep[0] ?? 'professional-investor'
    }));
  };

  const progressToNextStep: OnboardingContextFunctions['progressToNextStep'] =
    () => {
      if (currentStepConfiguration) {
        const nextStep = currentStepConfiguration.getNextStep(
          contextValue.steps[contextValue.currentStep],
          contextValue.steps,
          clientContext
        );

        if (nextStep) {
          setContextValue((prev) => ({
            ...prev,
            currentStep: nextStep
          }));
        }
      }
    };

  const startSaving: OnboardingContextFunctions['startSaving'] = () => {
    setContextValue((prev) => ({
      ...prev,
      isSaving: true
    }));
  };

  const stopSaving: OnboardingContextFunctions['stopSaving'] = (saved) => {
    setContextValue((prev) => ({
      ...prev,
      isSaving: false,
      isAllDataSaved: saved
    }));
  };

  const saveData = async () => {
    if (!contextValue.isAllDataSaved) {
      startSaving();

      let savedSuccessfully = true;

      try {
        const startTime = new Date().getTime();

        await updateQuestionnaire({
          variables: {
            input: {
              payload: contextValue.steps,
              questionnaireId: contextValue.questionnaireId!
            }
          }
        });

        // If the update was less than second and half
        // delay finishing in order to avoid icon flashes
        if (startTime + 1500 > new Date().getTime()) {
          await new Promise((res) => {
            setTimeout(() => {
              res(undefined);
            }, 1000);
          });
        }
      } catch {
        savedSuccessfully = false;
      }

      stopSaving(savedSuccessfully);
    }
  };

  // endregion

  // region Effects

  React.useEffect(() => {
    const handler = setTimeout(async () => {
      await saveData();
    }, 3000);

    return () => {
      clearTimeout(handler);
    };
  }, [contextValue]);

  // endregion

  return (
    <OnboardingContext.Provider
      value={{
        ...contextValue,

        setData,
        getData,
        initData,
        saveData,
        stopSaving,
        startSaving,
        progressToNextStep
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboardingContext = () => React.useContext(OnboardingContext);
