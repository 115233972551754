import React from 'react';

import { gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import { CentsFormatter } from '@backed-fi/shared';
import { InteractionDetailsItem } from '../components/InteractionDetailsItem';
import { InteractionDetailsCard } from '../components/InteractionDetailsCard';
import { IssuanceIcon, RedemptionIcon } from '../../../components/icons/comps';
import { ClientInteractionDetailsQuery, useClientInteractionDetailsQuery } from '@backed-fi/graphql';
import { InteractionStatusLabel } from '../components/InteractionStatusLabel';
import { InteractionAssetsList } from '@backed-fi/app/src/app/domain/Transactions/components/InteractionAssetsList';

// region GraphQL declarations

gql`
  query clientInteractionDetails($interactionId: String!) {
    interaction(
      where: {
        id: $interactionId
      }
    ) {
      id

      status
      type
      createdAt

      tokenDeployments {
        token {
          symbol
        }
      }

      incomingTransactions {
        id

        hash
        network
        amount
        decimals
        explorerUrl
        type

        tokenSymbol
      }

      outgoingTransactions {
        id
        hash
        network
        explorerUrl
        createdAt
        tokenSymbol
        type

        amount
        decimals
      }

      fee {
        id
        fee
        principal
      }

      orders {
        id
        createdAt
        pricePerShare
      }

      invoice {
        id
        downloadUrl
      }

      incomingTransfers {
        ...InteractionBankTransferDetails
      }

      outgoingTransfers {
        ...InteractionBankTransferDetails
      }
    }
  }
`;

// endregion

export const InteractionDetailsPage: React.FC = () => {
  const params = useParams<{ id: string }>();

  // region Networking

  const { data, previousData, loading } = useClientInteractionDetailsQuery({
    pollInterval: 9 * 60 * 1000,
    variables: {
      interactionId: params.id!
    }
  });

  const { interaction } = (data || previousData || {}) as ClientInteractionDetailsQuery;

  // endregion

  // region Rendering

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {(!interaction && loading) && <CircularProgress size={32} />}

      {interaction && (
        <InteractionDetailsCard
          status={interaction.status}
          symbol={
            interaction.tokenDeployments?.length > 0 ?
              interaction.tokenDeployments[0].token.symbol : undefined
          }
          type={interaction.type as string}
          id={interaction.id}
        >

          <InteractionDetailsItem label="Action">
            {interaction.type === 'Issuance' ? (
              <IssuanceIcon width={24} height={24} />
            ) : (
              <RedemptionIcon width={24} height={24} />
            )}

            {interaction.type}
          </InteractionDetailsItem>

          <InteractionDetailsItem label="Status">
            <InteractionStatusLabel status={interaction.status} />
          </InteractionDetailsItem>

          <InteractionDetailsItem label="Date and Time Initiated">
            {new Date(interaction.createdAt).toLocaleString()}
          </InteractionDetailsItem>


          <InteractionDetailsItem label="Total (Including Fee)">
            {
              interaction?.fee?.principal
                ? CentsFormatter.format(interaction.fee.principal)
                : 'N/A'
            }
          </InteractionDetailsItem>

          <InteractionDetailsItem label="Fee (Excluding Gas)">
            {interaction?.fee?.fee
              ? CentsFormatter.format(interaction.fee.fee)
              : 'N/A'}
          </InteractionDetailsItem>

          {interaction.invoice?.downloadUrl && (
            <InteractionDetailsItem label="Invoice">
              <Typography
                component="a"
                variant="link"
                href={interaction.invoice.downloadUrl}
                target="_blank"
              >
                Download
              </Typography>
            </InteractionDetailsItem>
          )}

          <InteractionDetailsItem
            fullWidth
            label="Incoming Assets"
          >
            <InteractionAssetsList
              small
              clickable
              assets={[
                ...interaction.incomingTransfers.map((x) => ({
                  ...x,
                  type: 'Fiat' as const
                })),

                ...interaction.incomingTransactions.map((x) => ({
                  ...x,
                  assetType: x.type,
                  type: 'Blockchain' as const
                }))
              ] ?? []}
            />
          </InteractionDetailsItem>

          <InteractionDetailsItem
            fullWidth
            label="Outgoing Assets"
          >
            <InteractionAssetsList
              small
              clickable
              assets={[
                ...interaction.outgoingTransfers.map((x) => ({
                  ...x,
                  type: 'Fiat' as const
                })),

                ...interaction.outgoingTransactions.map((x) => ({
                  ...x,
                  assetType: x.type,
                  type: 'Blockchain' as const
                }))
              ] ?? []}
            />
          </InteractionDetailsItem>
        </InteractionDetailsCard>
      )}
    </Box>
  );

  // endregion
};
