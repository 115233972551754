import React from 'react';
import { z } from 'zod';
import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Avatar, Box, CircularProgress, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Tooltip, Typography } from '@mui/material';

import { ExecutionMode, FiatCurrency, PayoutMethod, useLoadClientPreferencesQuery, useUpdateClientPreferencesMutation } from '@backed-fi/graphql';

import { PageHeader } from '../components/PageHeader';
import { SettingsGroup } from '../components/SettingsGroup';
import { ContentCard } from '../../../components/ContentCard';

// region Form Validation Declaration

const FormSchema = z.object({
  payoutMethod: z.nativeEnum(PayoutMethod),
  executionMode: z.nativeEnum(ExecutionMode),
  bankAccountPayoutCurrency: z.nativeEnum(FiatCurrency),
  defaultStablecoinId: z.string()
});

type FormSchemaType = z.infer<typeof FormSchema>;

// endregion

// region Graph Declarations

gql`
  query loadClientPreferences {
    clientPreferences {
      id

      payoutMethod
      executionMode
      bankAccountPayoutCurrency
      defaultStablecoin {
        id
        symbol
      }
    }

    stablecoins {
      id
      symbol
    }

    bankAccounts {
      id

      iban
      verificationStatus
    }
  }

  mutation updateClientPreferences($input: UpdateClientPreferencesInput!) {
    updateClientPreferences(input: $input) {
      id
    }
  }
`;

// endregion

export const TransactionSettingsPage = () => {
  const snackbar = useSnackbar();

  // region State

  const [loaded, setLoaded] = React.useState<boolean>(false);

  // endregion

  // region Networking

  const [updatePreferences] = useUpdateClientPreferencesMutation();
  const { data, refetch } = useLoadClientPreferencesQuery();

  // endregion

  // region Form Control

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema)
  });

  const isSubmitting = form.formState.isSubmitting;
  const payoutMethod = form.watch('payoutMethod');

  // endregion

  // region Effects

  React.useEffect(() => {
    if (data?.clientPreferences) {
      const prefs = data.clientPreferences;

      form.setValue('payoutMethod', prefs.payoutMethod);
      form.setValue('executionMode', prefs.executionMode);
      form.setValue('bankAccountPayoutCurrency', prefs.bankAccountPayoutCurrency);
      form.setValue('defaultStablecoinId', prefs.defaultStablecoin!.id);

      setLoaded(true);
    }
  }, [data]);

  // endregion

  // region Actions

  const onSaveChanges = form.handleSubmit(async (values) => {
    try {
      // Send the request
      await updatePreferences({
        variables: {
          input: values
        }
      });

      // Refetch the updated values
      await refetch();

      // If request is successful - send success message
      snackbar.enqueueSnackbar('Transaction settings successfully updated', {
        variant: 'success'
      });
    } catch (e) {
      // If not - send error message
      snackbar.enqueueSnackbar('Transaction settings could not be updated', {
        variant: 'error'
      });
    }
  });

  // endregion

  return (
    <Box>
      <PageHeader
        title="Transaction Settings"
        subtitle="Changes to the transaction settings will only affect transactions received after the change."
      />

      {!loaded && (
        <ContentCard
          sx={{
            display: 'flex',
            height: '192px',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress size={32} />
        </ContentCard>
      )}

      {loaded && (
        <ContentCard
          sx={{
            padding: '2rem',
            gap: '1.5rem',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <SettingsGroup title="Payout Method">
            <Grid
              alignItems="center"
              spacing="1.5rem"
              container
            >
              <Grid
                md={6}
                item
              >
                <Controller
                  name="payoutMethod"
                  control={form.control}
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      <FormControlLabel
                        value={PayoutMethod.BlockchainPayout}
                        label="Blockchain Wallet"
                        control={<Radio />}
                      />

                      <FormControlLabel
                        value={PayoutMethod.BankPayout}
                        label="Bank Account"
                        control={<Radio />}
                      />
                    </RadioGroup>
                  )}
                />

                {payoutMethod === PayoutMethod.BankPayout && (
                  <ContentCard
                    variant="soft"
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      padding: '0.25rem 2rem',
                      marginTop: '0.25rem',
                      gap: '1rem'
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#848a98',
                        fontSize: 14
                      }}
                    >
                      Payout Currency
                    </Typography>

                    <Controller
                      name="bankAccountPayoutCurrency"
                      control={form.control}
                      render={({ field }) => (
                        <RadioGroup
                          sx={{
                            display: 'flex',
                            flexDirection: 'row'
                          }}
                          {...field}
                        >
                          {Object.values(FiatCurrency).map((currency) => (
                            <FormControlLabel
                              key={currency}
                              value={currency}
                              label={currency}
                              control={<Radio />}
                            />
                          ))}
                        </RadioGroup>
                      )}
                    />
                  </ContentCard>
                )}
                {payoutMethod === PayoutMethod.BlockchainPayout && (
                  <ContentCard
                    variant="soft"
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      padding: '0.25rem 2rem',
                      marginTop: '0.25rem',
                      gap: '1rem'
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#848a98',
                        fontSize: 14
                      }}
                    >
                      Payout Stablecoin
                    </Typography>

                    <Controller
                      name="defaultStablecoinId"
                      control={form.control}
                      render={({ field }) => (
                        <RadioGroup
                          sx={{
                            display: 'flex',
                            flexDirection: 'row'
                          }}
                          {...field}
                        >
                          {data!.stablecoins.map((stablecoin) => (
                            <FormControlLabel
                              key={stablecoin.id}
                              value={stablecoin.id}
                              label={<Box sx={{ display: 'flex' }}><Avatar
                                alt={stablecoin.symbol}
                                src={`/assets/icons/assets/${stablecoin.symbol}.svg`}
                                sx={{
                                  width: '24px',
                                  height: '24px',
                                  border: '2px solid white'

                                }}
                              />{stablecoin.symbol}</Box>}
                              control={<Radio />}
                            />
                          ))}
                        </RadioGroup>
                      )}
                    />
                  </ContentCard>
                )}
              </Grid>
              <Grid
                md={6}
                item
              >
                <Typography
                  sx={{
                    fontSize: 12
                  }}
                >
                  If you choose Blockchain Wallet as your Payout Method, your payout will go to the same verified wallet from
                  which you
                  sent your tokens. On redemption, your payout will be in stablecoin.
                </Typography>
              </Grid>
            </Grid>
          </SettingsGroup>

          <SettingsGroup title="Execution Mode">
            <Grid
              alignItems="center"
              spacing="1.5rem"
              container
            >
              <Grid
                md={6}
                item
              >
                <Controller
                  name="executionMode"
                  control={form.control}
                  render={({ field }) => (
                    <FormControl>
                      <RadioGroup {...field}>
                        <FormControlLabel
                          value={ExecutionMode.EndOfDay}
                          label="Same Day Execution"
                          control={<Radio />}
                        />
                        <Tooltip
                          title="Market execution is coming soon"
                          placement="right"
                          arrow
                        >
                          <FormControlLabel
                            value={ExecutionMode.Market}
                            label="Market Execution"
                            control={<Radio />}
                            disabled
                          />
                        </Tooltip>
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid
                md={6}
                item
              >
                <Typography
                  sx={{
                    fontSize: 12
                  }}
                >
                  Selecting 'Same Day Execution' will execute your order at market closing price.<br />
                  Selecting 'Market Execution' will execute your order during market hours.
                </Typography>
              </Grid>
            </Grid>
          </SettingsGroup>

          <LoadingButton
            variant="publicDashboardGhost"
            onClick={onSaveChanges}
            loading={isSubmitting}
            sx={{
              alignSelf: 'end'
            }}
          >
            Save Changes
          </LoadingButton>
        </ContentCard>
      )}
    </Box>
  );
};
