import React from 'react';
import { Box, Card, CircularProgress, InputAdornment, TextField } from '@mui/material';
import { Logo } from '@backed-fi/primitives';
import KeyRoundedIcon from '@mui/icons-material/KeyRounded';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import { useOneTimeAuthentication } from '@backed-fi/shared/authentication/oneTimeCodeAuthentication';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useToggle } from '@backed-fi/hooks';


export const MultiFactorPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const authentication = useOneTimeAuthentication();

  const loading = useToggle();


  // region Actions

  const onFieldDataChange: StandardInputProps['onChange'] = async (e) => {
    const value = e.target.value;

    if (value?.length === 6) {
      try {
        loading.setTrue();

        await authentication.authenticateWithTransitionToken(searchParams.get('transitionToken')!, value);

        navigate(searchParams.get('destination') || '/');
      } finally {
        loading.setFalse();
      }
    }
  };

// endregion


  return (
    <Box
      sx={{
        backgroundColor: 'grey.100',
        height: '100vh',
        width: '100wv',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          width: '100vw',
          maxWidth: '500px'
        }}
      >
        <Card
          sx={{
            gap: '2rem',
            padding: '1rem',
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center'
          }}
        >
          <Logo />

          <TextField
            fullWidth
            label="Verification Code"
            helperText="The 6 digit code from your authenticator app. Once provided it will automatically verify"
            onChange={onFieldDataChange}
            disabled={loading.open}
            inputProps={{ maxLength: 6 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {loading.open ? (
                    <CircularProgress
                      size={20}
                    />
                  ) : (
                    <KeyRoundedIcon />
                  )}
                </InputAdornment>
              )
            }}
          />
        </Card>
      </Box>
    </Box>
  );
};
