/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  OCR2BaseNoChecks,
  OCR2BaseNoChecksInterface,
} from "../../../../../../../@chainlink/contracts-ccip/src/v0.8/ccip/ocr/OCR2BaseNoChecks";

const _abi = [
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "expected",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "actual",
        type: "bytes32",
      },
    ],
    name: "ConfigDigestMismatch",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "expected",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "actual",
        type: "uint256",
      },
    ],
    name: "ForkedChain",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "message",
        type: "string",
      },
    ],
    name: "InvalidConfig",
    type: "error",
  },
  {
    inputs: [],
    name: "OracleCannotBeZeroAddress",
    type: "error",
  },
  {
    inputs: [],
    name: "UnauthorizedTransmitter",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "expected",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "actual",
        type: "uint256",
      },
    ],
    name: "WrongMessageLength",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint32",
        name: "previousConfigBlockNumber",
        type: "uint32",
      },
      {
        indexed: false,
        internalType: "bytes32",
        name: "configDigest",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "uint64",
        name: "configCount",
        type: "uint64",
      },
      {
        indexed: false,
        internalType: "address[]",
        name: "signers",
        type: "address[]",
      },
      {
        indexed: false,
        internalType: "address[]",
        name: "transmitters",
        type: "address[]",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "f",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "bytes",
        name: "onchainConfig",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "uint64",
        name: "offchainConfigVersion",
        type: "uint64",
      },
      {
        indexed: false,
        internalType: "bytes",
        name: "offchainConfig",
        type: "bytes",
      },
    ],
    name: "ConfigSet",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
    ],
    name: "OwnershipTransferRequested",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes32",
        name: "configDigest",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "epoch",
        type: "uint32",
      },
    ],
    name: "Transmitted",
    type: "event",
  },
  {
    inputs: [],
    name: "acceptOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getTransmitters",
    outputs: [
      {
        internalType: "address[]",
        name: "",
        type: "address[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "latestConfigDetails",
    outputs: [
      {
        internalType: "uint32",
        name: "configCount",
        type: "uint32",
      },
      {
        internalType: "uint32",
        name: "blockNumber",
        type: "uint32",
      },
      {
        internalType: "bytes32",
        name: "configDigest",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "latestConfigDigestAndEpoch",
    outputs: [
      {
        internalType: "bool",
        name: "scanLogs",
        type: "bool",
      },
      {
        internalType: "bytes32",
        name: "configDigest",
        type: "bytes32",
      },
      {
        internalType: "uint32",
        name: "epoch",
        type: "uint32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "signers",
        type: "address[]",
      },
      {
        internalType: "address[]",
        name: "transmitters",
        type: "address[]",
      },
      {
        internalType: "uint8",
        name: "f",
        type: "uint8",
      },
      {
        internalType: "bytes",
        name: "onchainConfig",
        type: "bytes",
      },
      {
        internalType: "uint64",
        name: "offchainConfigVersion",
        type: "uint64",
      },
      {
        internalType: "bytes",
        name: "offchainConfig",
        type: "bytes",
      },
    ],
    name: "setOCR2Config",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32[3]",
        name: "reportContext",
        type: "bytes32[3]",
      },
      {
        internalType: "bytes",
        name: "report",
        type: "bytes",
      },
      {
        internalType: "bytes32[]",
        name: "rs",
        type: "bytes32[]",
      },
      {
        internalType: "bytes32[]",
        name: "ss",
        type: "bytes32[]",
      },
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    name: "transmit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "typeAndVersion",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
] as const;

export class OCR2BaseNoChecks__factory {
  static readonly abi = _abi;
  static createInterface(): OCR2BaseNoChecksInterface {
    return new utils.Interface(_abi) as OCR2BaseNoChecksInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): OCR2BaseNoChecks {
    return new Contract(address, _abi, signerOrProvider) as OCR2BaseNoChecks;
  }
}
