import React from 'react';

export const useInputState = (initialValue: string = '') => {
  const [value, setRawValue] = React.useState<string>(initialValue);

  const setValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRawValue(e.currentTarget.value);
  };

  return {
    value,

    inputProps: {
      value,
      onChange: setValue
    }
  };
};
