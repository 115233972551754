import { ClientFeature } from '@backed-fi/graphql';
import React from 'react';
import { useClientContext } from '@backed-fi/context';

interface Props {
  feature: ClientFeature;
}

/**
 * Component, that will render its children, only
 * if the client has the feature, provided with prop
 */
export const Feature: React.FC<React.PropsWithChildren<Props>> = ({ feature, ...props }) => {
  const clientContext = useClientContext();

  return (
    <React.Fragment>
      {clientContext.features?.includes(feature) && (
        <React.Fragment
          {...props}
        />
      )}
    </React.Fragment>
  );
};
