import { IOnboardingStep } from './onboarding-step-interface';

export const Summary: IOnboardingStep = {
  id: 'summary',
  name: 'Summary',
  blocks: () => [
    {
      type: 'redirect',
      path: '/onboarding/questionnaire/summary'
    }
  ],
  shouldRenderStep: () => false,
  getNextStep: () => ''
};
