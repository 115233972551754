import { BlockchainNetwork } from '@prisma/client';

const EVMStrategy = (address: string) => address.toLowerCase().trim();

export const BlockchainAddressFormatter: Record<BlockchainNetwork, (address: string) => string> = {
  Ethereum: EVMStrategy,
  Polygon: EVMStrategy,
  Gnosis: EVMStrategy,
  BinanceSmartChain: EVMStrategy,
  Arbitrum: EVMStrategy,
  Avalanche: EVMStrategy,
  Fantom: EVMStrategy,
  Base: EVMStrategy,
  Lisk: EVMStrategy,
  Etherlink: EVMStrategy,
  Sonic: EVMStrategy
};
