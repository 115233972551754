import { ClientType } from '@prisma/client';
import { IOnboardingStep } from './onboarding-step-interface';
import { EEAResidency } from './eea-residency';
import { RestrictedJurisdictionsExposure } from './restricted-jurisdictions-exposure';

export const HighRiskJurisdictionsExposure: IOnboardingStep = {
  id: 'high-risk-jurisdictions-exposure',
  name: 'High Risk Jurisdictions',
  blocks: (clientContext) => {
    const options = (() => {
      const optionsGenerator = (placeholder: string) => ([
        {
          value: 'true',
          content: `Yes, ${placeholder} a resident of a country listed above`
        },
        {
          value: 'false',
          content: `No, ${placeholder} not a resident of any country listed above`
        }
      ]);

      switch (clientContext.type) {
        case ClientType.Institution:
          return optionsGenerator('my company is');
        case ClientType.Fund:
          return optionsGenerator('my fund is');
        case ClientType.Foundation:
          return optionsGenerator('my foundation is');
        default:
          return optionsGenerator('I am');
      }
    })();

    return [
      {
        type: 'title',
        title: 'Country of residence/domicile'
      },
      {
        type: 'paragraph',
        content: 'Please refer to the list of countries below'
      },
      {
        block: true,
        type: 'list',
        items: [

          'Afghanistan',
          'Albania',
          'Algeria',
          'Angola',
          'Antigua and Barbuda',
          'Argentina',
          'Armenia',
          'Aruba',
          'Azerbaijan',
          'Bahamas',
          'Bangladesh',
          'Barbados',
          'Belarus',
          'Belize',
          'Benin',
          'Bhutan',
          'Bosnia and Herzegovina',
          'Botswana',
          'Brazil',
          'Brunei',
          'Bulgaria',
          'Burkina Faso',
          'Burundi',
          'Cambodia',
          'Cameroon',
          'Cape Verde ',
          'Central African Republic',
          'Chad',
          'Chile ',
          'China',
          'Colombia',
          'Comoros',
          'Congo ',
          'Congo (Democratic Republic)',
          'Costa Rica',
          'Côte d’Ivoire (Ivory Coast)',
          'Croatia',
          'Cuba',
          'Curacao',
          'Djibouti',
          'Dominican Republic',
          'Ecuador',
          'Egypt',
          'El Salvador',
          'Equatorial Guinea',
          'Eritrea',
          'Eswathini (Swaziland)',
          'Ethiopia ',
          'Gabon',
          'Gambia',
          'Ghana',
          'Gibraltar',
          'Grenada',
          'Guatemala',
          'Guinea',
          'Guinea Bissau',
          'Haiti',
          'Honduras',
          'Hong Kong ',
          'India',
          'Indonesia',
          'Iran',
          'Irak',
          'Israel',
          'Jamaica',
          'Kenya',
          'Kuwait',
          'Kyrgyz Republic',
          'Laos',
          'Lebanon ',
          'Lesotho',
          'Liberia',
          'Libya',
          'Macao',
          'Madagascar',
          'Malawi',
          'Malaysia',
          'Mali',
          'Mauritania',
          'Mauritius',
          'Mexico',
          'Mongolia',
          'Montenegro',
          'Montserrat',
          'Mozambique',
          'Myanmar',
          'Namibia',
          'Nepal',
          'Nicaragua',
          'Niger',
          'Nigeria',
          'North Korea',
          'Pakistan',
          'Palestine',
          'Panama',
          'Papua New Guinea',
          'Paraguay',
          'Peru ',
          'Philippines',
          'Russia',
          'Sahel ',
          'Saint Kitts and Nevis',
          'Saint Lucia',
          'Saint Vincent and the Grenadines ',
          'Serbia and Montenegro',
          'Senegal',
          'Seychelles',
          'Sierra Leone',
          'Sinai Peninsula',
          'Sint Maarten ',
          'Somalia',
          'South Africa',
          'South Sudan',
          'Sri Lanka',
          'Sudan',
          'Suriname',
          'Syria',
          'Tajikistan',
          'Tanzania',
          'Thailand',
          'The Lake Chad Region',
          'The Southern Philippines',
          'The Sulu / Sulawesi Seas Littoral ',
          'The Trans - Sahara',
          'Timor - Leste',
          'Togo',
          'Tonga',
          'Trinidad and Tobago',
          'Türkiye',
          'Turkmenistan',
          'Turks and Caicos Islands',
          'Uganda',
          'Ukraine ',
          'United Arab Emirates',
          'Uzbekistan',
          'Vanuatu',
          'Venezuela',
          'Vietnam',
          'West Bank and Gaza',
          'Yemen',
          'Zambia',
          'Zimbabwe'
        ]
      },
      {
        type: 'checkbox',
        valueKey: 'default',
        title: '',
        options: options
      }
    ];
  },
  shouldRenderStep: () => true,
  getNextStep: (stepData) => {
    const data = stepData['default'];

    if (data) {
      if (data.includes('true')) {
        return RestrictedJurisdictionsExposure.id;
      } else {
        return EEAResidency.id;
      }
    }
    return undefined;
  }
};
