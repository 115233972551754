import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { VerifyWalletProofOfControlManuallyByMicroTransaction } from './VerifyWalletProofOfControlManuallyByMicroTransaction';
import { VerifyWalletProofOfControlManuallyBySignedMessage } from './VerifyWalletProofOfControlManuallyBySignedMessage';

interface Props {
  action: string,
  isCreation: boolean,
  address?: string,
  onCancel: () => any
  onVerifiedManuallyBySigningMessage: (address: string, signature: string) => any,
}

export const VerifyWalletProofOfControlManually: React.FC<Props> = ({ action, isCreation, address, onVerifiedManuallyBySigningMessage, onCancel }) => {
  const [manualType, setManualType] = React.useState<'micro-transaction' | 'signed-message'>();

  return (
    <Box
      sx={{
        padding: '2rem',
        display: 'flex',
        flexFlow: 'column'
      }}
    >
      {!manualType && (
        <React.Fragment>
          <Typography variant='modalTitle'>
              Manual {action}
          </Typography>

          <Typography variant='modalSubtitle'>
              In order to proceed with the manual {action.toLowerCase()} of your{' '}
              wallet please select one of the following options:
          </Typography>

          <Box
            sx={{
              gap: '1rem',
              display: 'flex',
              marginTop: '2rem',
              flexDirection: 'column'
            }}
          >
            <Button
              onClick={() => setManualType('micro-transaction')}
              variant='publicDashboard'
              fullWidth
            >
                Micro-Transaction
            </Button>

            <Button
              onClick={() => setManualType('signed-message')}
              variant='publicDashboard'
              fullWidth
            >
                Signed Message
            </Button>

            <Button
              onClick={onCancel}
              variant='publicDashboardGhost'
              fullWidth
            >
                Cancel {action}
            </Button>
          </Box>
        </React.Fragment>
      )}
      {
        manualType === 'micro-transaction' && (
          <VerifyWalletProofOfControlManuallyByMicroTransaction address={address}/>
        )
      }
      {
        manualType === 'signed-message' && (
          <VerifyWalletProofOfControlManuallyBySignedMessage
            address={address}
            isCreation={isCreation}
            onVerify={onVerifiedManuallyBySigningMessage}/>
        )
      }

    </Box>
  );
};
