import JSConfetti from 'js-confetti';
import React from 'react';

export const ConfettiContext = React.createContext<{ confetti: JSConfetti }>({} as any);

export const ConfettiProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const confettiRef = React.useRef(new JSConfetti());

  return (
    <ConfettiContext.Provider
      value={{
        confetti: confettiRef.current!
      }}
    >
      {children}
    </ConfettiContext.Provider>
  );
};

export const useConfettiContext = () => React.useContext(ConfettiContext);
