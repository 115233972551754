import { Controller } from 'react-hook-form';
import { Checkbox } from '@mui/material';
import React from 'react';

type Props = React.ComponentProps<typeof Checkbox> & {
  name: string;
  control: any;
}

export const ControlledCheckbox: React.FC<Props> = ({ control, ...rest }) => {
  return (
    <Controller
      name={rest.name}
      control={control}
      render={({ field: props }) => (
        <Checkbox
          {...rest}
          {...props}
          checked={props.value}
          onChange={(e) => props.onChange(e.target.checked)}
        />
      )}
    />
  );
};
