import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { SideBar } from '../navigation/SideBar';
import { NavigationBar } from '../navigation/NavigationBar';
import { AccountActivationBanner } from '../AccountActivationBanner';

export const DashboardLayout = () => {
  return (
    <Box>
      <NavigationBar />

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '320px minmax(0, 1fr)'
        }}
      >
        <SideBar />

        {/* This is so that the sidebar stays here */}
        <Box />

        <Box
          sx={{
            paddingTop: '80px',
            minHeight: '100vh',
            backgroundColor: '#f2f4f8'
          }}
        >
          <AccountActivationBanner />

          <Box
            sx={{
              padding: '3rem',
              height: '100%'
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
