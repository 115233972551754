import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';

import { Box, Tab, Tabs } from '@mui/material';
import { BreadcrumbHeader } from '../../components/BreadcrumbHeader';

const pageTitles = {
  pending: 'Pending Transactions',
  history: 'Transaction History',
  details: 'Transaction Details'
};

export const TransactionsLayout: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const onNavigate = (_: any, value: string) => {
    navigate(`/interactions/${value}`);
  };

  const getCurrentPageTitle = () => {
    for (const [key, title] of Object.entries(pageTitles)) {
      if (location.pathname.includes(key)) {
        return title;
      }
    }

    return undefined;
  };

  if (location.pathname.includes('details')) {
    return <Outlet />;
  }

  return (
    <Box>
      <BreadcrumbHeader
        mainTitle="Account Activity"
        currentPage={getCurrentPageTitle()}
      />

      <Tabs
        value={location.pathname.split('/')[2]}
        indicatorColor="primary"
        onChange={onNavigate}
        sx={{
          marginTop: '0.5rem'
        }}
      >
        <Tab value="pending" label={pageTitles.pending} />
        <Tab value="history" label={pageTitles.history} />
      </Tabs>

      <Box
        sx={{
          marginTop: '3rem'
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
