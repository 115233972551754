import React from 'react';
import { useNavigate } from 'react-router';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';

import { useClientContext } from '@backed-fi/context';
import { OnboardingConfiguration } from '@backed-fi/app/src/app/domain/Onboarding/configuration';

import { OnboardingTitleBlock } from '@backed-fi/app/src/app/domain/Onboarding/components/blocks/OnboardingTitleBlock';
import { OnboardingCheckboxBlock } from '@backed-fi/app/src/app/domain/Onboarding/components/blocks/OnboardingCheckboxBlock';
import { OnboardingParagraphBlock } from '@backed-fi/app/src/app/domain/Onboarding/components/blocks/OnboardingParagraphBlock';

import { OnboardingSavingIndicator } from '@backed-fi/app/src/app/domain/Onboarding/components/OnboardingSavingIndicator';
import { useOnboardingContext } from '@backed-fi/app/src/app/domain/Onboarding/OnboardingContext';
import { OnboardingRedirectionBlock } from '@backed-fi/app/src/app/domain/Onboarding/components/blocks/OnboardingRedirectionBlock';
import { OnboardingListBlock } from '@backed-fi/app/src/app/domain/Onboarding/components/blocks/OnboardingListBlock';
import { OnboardingTextInputBlock } from '@backed-fi/app/src/app/domain/Onboarding/components/blocks/OnboardingTextInputBlock';

export const OnboardingQuestionnairePage: React.FC = () => {
  const navigate = useNavigate();

  const { currentStep, questionnaireId, progressToNextStep, steps } =
    useOnboardingContext();
  const clientContext = useClientContext();

  const currentStepConfiguration = React.useMemo(
    () => OnboardingConfiguration.steps.find((x) => x.id === currentStep),
    [currentStep]
  );

  React.useEffect(() => {
    if (!questionnaireId) {
      navigate('/onboarding');
    }
  }, [questionnaireId]);

  return (
    <Box>
      <OnboardingSavingIndicator />

      {!currentStepConfiguration && <Box>Something unexpected happened</Box>}

      {currentStepConfiguration && (
        <React.Fragment>
          {currentStepConfiguration
            .blocks(clientContext, steps[currentStep])
            .map((block, index) => (
              <React.Fragment key={currentStepConfiguration.id + index}>
                {block.type === 'title' && <OnboardingTitleBlock {...block} />}

                {block.type === 'checkbox' && (
                  <OnboardingCheckboxBlock {...block} />
                )}

                {block.type === 'paragraph' && (
                  <OnboardingParagraphBlock {...block} />
                )}

                {block.type === 'redirect' && (
                  <OnboardingRedirectionBlock {...block} />
                )}

                {block.type === 'list' && <OnboardingListBlock {...block} />}

                {block.type === 'textInput' && (
                  <OnboardingTextInputBlock {...block} />
                )}
              </React.Fragment>
            ))}

          <Box
            sx={{
              mt: '1rem',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <LoadingButton
              variant="publicDashboard"
              onClick={progressToNextStep}
            >
              Continue
            </LoadingButton>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};
