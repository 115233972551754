import React from 'react';

import { styled } from '@mui/material/styles';
import { Button, Box, ButtonBase, MenuItem, Typography, SxProps, Theme, Dialog, DialogContent, dialogClasses, menuItemClasses } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { BlockchainNetwork } from '@backed-fi/graphql';
import { useToggle } from '@backed-fi/hooks';
import { NetworkSelector, NetworkSelectorConfig } from './NetworkSelector';
import { AssetBadge } from './AssetBadge';
import { CheckCircle } from '@mui/icons-material';

export interface StablecoinSelectorConfig {
  address: string;
  symbol: string;
  decimals: number;
  network: BlockchainNetwork;
  chainId: number;
  minimumTransactionValue: string;
}

interface Props {
  selectedStablecoin: StablecoinSelectorConfig;
  availableStablecoins: StablecoinSelectorConfig[];
  // eslint-disable-next-line no-unused-vars
  onStablecoinChange: (stablecoin: StablecoinSelectorConfig) => void
  sx?: SxProps<Theme>;
}

const SelectorButton = styled(ButtonBase)(() => ({
  display: 'flex',
  gap: '0.25rem',
  height: '40px',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0.5rem 0.9375rem 0.5rem 1.125rem',
  backgroundColor: '#e2e5eb',
  borderRadius: '20px',

  '&:hover': {
    backgroundColor: '#d4d8e0'
  }
}));

export const StablecoinSelector: React.FC<Props> = ({
  selectedStablecoin,
  availableStablecoins,
  onStablecoinChange,
  sx
}) => {
  const availableNetworks = React.useMemo<NetworkSelectorConfig[]>(() => {
    return [...new Map(availableStablecoins.map((s) => [s.chainId, s])).values()]
  }, [availableStablecoins]);
  const [newStablecoin, setNewStablecoin] = React.useState(selectedStablecoin);
  const [selectedNetwork, setSelectedNetwork] = React.useState<NetworkSelectorConfig>({
    chainId: selectedStablecoin.chainId,
    network: selectedStablecoin.network
  });
  const toggle = useToggle();

  return (
    <>
      <Dialog
        sx={{
          [`.${dialogClasses.paper}`]: {
            backgroundColor: '#f2f4f8',
            maxWidth: '24rem',
            padding: 0
          }
        }}
        fullWidth
        {...toggle}
      >
        <DialogContent sx={{ padding: '1rem' }}>
          <Box
            sx={{
              padding: '1rem',
              marginBottom: '1rem'
            }}
          >
            <Typography variant='modalTitle'>
              Select Stablecoin
            </Typography>

            <Typography
              variant='modalSubtitle'
              sx={{ color: '#848a98' }}
            >
              Please select the network and stablecoin you want to issue Backed tokens with.
            </Typography>
          </Box>

          <NetworkSelector
            selectedNetwork={selectedNetwork!}
            availableNetworks={availableNetworks}
            onNetworkChange={(selectedNetwork) => {
              const stablecoins = availableStablecoins.filter(
                (stablecoin) => stablecoin.chainId === selectedNetwork.chainId
              );

              const stablecoin = stablecoins.find(
                (stablecoin) => stablecoin.symbol === newStablecoin.symbol
              ) || stablecoins[0];

              setSelectedNetwork(selectedNetwork);
              setNewStablecoin(stablecoin);
            }}
            variant='outline'
            sx={{
              width: '100%',
              height: '48px',
              marginBottom: '1rem',
              padding: '0.75rem 1rem',
              borderRadius: '0.5rem',

              '&:hover': {
                backgroundColor: 'white'
              }
            }}
          />

          <Box
            sx={{
              padding: '1rem',
              marginBottom: '1rem',
              backgroundColor: 'white',
              borderRadius: '0.5rem',
              border: 1,
              borderStyle: 'solid',
              borderColor: '#edeff4'
            }}
          >
            {availableStablecoins
              .filter((stablecoin) => stablecoin.chainId === selectedNetwork.chainId)
              .map((stablecoin) => (
                <MenuItem
                  key={stablecoin.symbol}
                  selected={stablecoin.symbol === newStablecoin.symbol}
                  onClick={() => setNewStablecoin(stablecoin)}
                  sx={{
                    padding: '0.75rem 1rem',
                    borderRadius: '0.25rem',
                    fontWeight: 500,
                    fontSize: 14,

                    '&:hover': {
                      backgroundColor: 'white'
                    },

                    [`&.${menuItemClasses.selected}`]: {
                      backgroundColor: '#f2f4f8',

                      '&:hover': {
                        backgroundColor: '#f2f4f8'
                      }
                    }
                  }}
                >
                  <AssetBadge
                    symbol={stablecoin.symbol}
                    network={stablecoin.network}
                    small
                  />

                  <Typography
                    component='div'
                    sx={{
                      paddingLeft: '8px',
                      fontWeight: 500,
                      fontSize: 14
                    }}
                  >
                    {stablecoin.symbol}
                  </Typography>

                  {stablecoin.symbol === newStablecoin.symbol && (
                    <CheckCircle
                      fontSize='small'
                      sx={{
                        marginLeft: 'auto',
                        color: '#0066ff'
                      }}
                    />
                  )}
                </MenuItem>)
              )
            }
          </Box>

          <Button
            onClick={() => {
              onStablecoinChange(newStablecoin);
              toggle.setFalse();
            }}
            variant='publicDashboard'
            fullWidth
          >
            Confirm Selection
          </Button>
        </DialogContent>
      </Dialog>
      <Box>
        <SelectorButton
          onClick={() => {
            setSelectedNetwork({
              chainId: selectedStablecoin.chainId,
              network: selectedStablecoin.network
            });
            setNewStablecoin(selectedStablecoin);
            toggle.setTrue();
          }}
          sx={sx}
        >

          <Typography
            component='div'
            sx={{
              fontWeight: 500,
              fontSize: 14
            }}
          >
            {selectedStablecoin.symbol} on {selectedStablecoin.network}
          </Typography>

          <ExpandMoreIcon
            fontSize='small'
          />
        </SelectorButton>
      </Box>
    </>

  );
};
