import React from 'react';
import { Logout } from '@mui/icons-material';
import UserIcon from '@mui/icons-material/PersonOutlineRounded';

import {
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';

import { ArrowUp } from './icons/comps';
import { useAuthContext, useUserContext } from '@backed-fi/context';

export const ProfileTile: React.FC = () => {
  const { user } = useUserContext();
  const authContext = useAuthContext();

  // ---- State ---- //
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // ---- Actions ---- //
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = async () => {
    await authContext.logout();
  };

  return (
    <Box
      sx={{
        p: '.5rem',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Avatar
        sx={{
          mr: '.5rem',
        }}
      >
        <UserIcon />
      </Avatar>

      <Box>
        <Typography
          sx={{
            /// It is not good to put that much font styling here, but the design is so inconsistent
            /// that it is not feasible to create any type structure
            fontWeight: '600',
            fontSize: 14,
            textTransform: 'uppercase',
          }}
        >
          {user?.firstName} {user?.lastName}
        </Typography>
      </Box>

      <Box
        sx={{
          marginLeft: 'auto',
        }}
      >
        <IconButton onClick={handleClick}>
          <ArrowUp
            style={{
              margin: '3px 0',
              width: '16px',
              height: '10px',
              transform: 'rotate(180deg)',
            }}
          />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
        >
          <MenuItem
            onClick={onLogout}
            sx={{
              color: 'primary.textLowContrast',
            }}
          >
            <ListItemIcon>
              <Logout
                fontSize="small"
                sx={{
                  color: 'primary.textLowContrast',
                }}
              />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};
