import React from 'react';
import { v4 } from 'uuid';
import { LocalStorage } from '@backed-fi/constants';

interface BrowserContext {
  browserId: string;
}

const BrowserContext = React.createContext<BrowserContext>(
  null!
);
export const BrowserContextProvider: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  // region State

  const [browserId, setBrowserId] = React.useState<string>(localStorage.getItem(LocalStorage.BrowserId) || v4());

  // endregion

  // region Effects

  React.useEffect(() => {
    if (!localStorage.getItem(LocalStorage.BrowserId)) {
      localStorage.setItem(LocalStorage.BrowserId, browserId);
    }
  }, []);

  // endregion

  return (
    <BrowserContext.Provider
      value={{
        browserId
      }}
      children={children}
    />
  );
};

export const useBrowserContext = () => {
  return React.useContext(BrowserContext);
};
