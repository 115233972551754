import React from 'react';
import { gql } from '@apollo/client';
import { Box, Button, Dialog } from '@mui/material';

import { useCreateExternalWalletBySigningMessageMutation } from '@backed-fi/graphql';
import { VerifyWalletProofOfControl } from './components/VerifyWalletProofOfControl';
import { VerifyWalletProofOfControlManually } from './components/VerifyWalletProofOfControlManually';

// region Graph Declaration
const Graph = gql`
  mutation createExternalWalletBySigningMessage($input: CreateExternalWalletInput!) {
    createExternalWalletBySigningMessage(input: $input) {
      id
    }
  }
`;
// endregion

// region Props
interface Props extends React.ComponentProps<typeof Dialog> {
  onCreated?: () => any;
}
// endregion

export const RegisterWalletDialog: React.FC<Props> = ({ onCreated, ...props }) => {
  // region State
  const [manual, setManual] = React.useState<boolean>(false);
  // endregion

  // region Networking
  const [createWallet] = useCreateExternalWalletBySigningMessageMutation();
  // endregion


  // region Actions and handlers
  const onCreateWallet = async (address: string, signature: string) => {
    // Send the verification signature to the backend
    await createWallet({
      variables: {
        input: {
          signedMessage: signature,
          walletAddress: address
        }
      }
    });

    if (typeof onCreated === 'function') {
      onCreated();
    }
  };
  // endregion

  // region Renderer
  return (
    <Dialog
      fullWidth
      maxWidth={'sm'}
      {...props}
    >
      {
        !manual && (
          <React.Fragment>
            <VerifyWalletProofOfControl
              action='Register'
              isCreation={true}
              onVerified={onCreateWallet}/>
            <Box
              sx={{
                paddingX: '2rem',
                paddingTop: '1rem',
                paddingBottom: '2rem',
                display: 'flex',
                flexFlow: 'column'
              }}
            >
              <Button
                variant='publicDashboardGhost'
                onClick={() => setManual(true)}
                fullWidth
              >
              Register Manually
              </Button>
            </Box>
          </React.Fragment>
        )
      }
      {
        manual && <VerifyWalletProofOfControlManually
          action='Registration'
          isCreation={true}
          onCancel={() => setManual(false)}
          onVerifiedManuallyBySigningMessage={onCreateWallet}/>
      }
    </Dialog>
  );
};
