import React from 'react';
import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Badge, Box, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ClearIcon from '@mui/icons-material/Clear';
import SecurityIcon from '@mui/icons-material/Security';
import { ExternalWalletStatus, useRegisteredWalletsQuery } from '@backed-fi/graphql';
import { useToggle } from '@backed-fi/hooks';
import { PageHeader } from './PageHeader';
import { RegisterWalletDialog } from '../../../components';
import { ContentCard } from '../../../components/ContentCard';
import { TablePlaceholder } from '../../../components/TablePlaceholder';
import { useSearchParams } from 'react-router-dom';
import { RemoveWalletDialog } from '../../../components/dialogs/RemoveWalletDialog';
import { ActivateWalletDialog } from '../../../components/dialogs/ActivateWalletDialog';

// region Graph Declarations

const Graph = gql`
  query registeredWallets {
    externalWallets {
      id

      createdAt
      updatedAt

      status
      address
    }
  }
`;

// endregion

export const RegisteredWalletsSection: React.FC = () => {
  const registerWalletDialog = useToggle();
  const activateWalletDialog = useToggle();
  const removeWalletDialog = useToggle();
  const snackbar = useSnackbar();

  const [searchParams, setSearchParams] = useSearchParams();

  // ---- State ---- //
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [wallet, setWallet] = React.useState<null | {id: string, address: string, isActive: boolean}>(null);

  // ---- Actions ---- //
  const handleClick = (event: React.MouseEvent<HTMLElement>, walletId: string, address: string, isActive: boolean) => {
    setAnchorEl(event.currentTarget);
    setWallet({
      id: walletId,
      address,
      isActive
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  // region Effects

  // Open the dialog on initial render if requested
  React.useEffect(() => {
    if (searchParams.get('dialog') as any === 'open') {
      registerWalletDialog.setTrue();

      searchParams.delete('dialog');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  // endregion

  // region Networking

  const { data, loading, refetch } = useRegisteredWalletsQuery();

  // endregion

  return (
    <Box>
      <RegisterWalletDialog
        {...registerWalletDialog}
        onCreated={async () => {
          // Refetch the client context
          await refetch();

          // And close the dialog
          registerWalletDialog.setFalse();
        }}
      />
      <ActivateWalletDialog
        {...activateWalletDialog}
        address={wallet?.address}
        onActivated={async () => {
          // Refetch the client context
          await refetch();

          // And close the dialog
          activateWalletDialog.setFalse();
        }}
      />
      <RemoveWalletDialog
        {...removeWalletDialog}
        externalWallet={
          { ...wallet! }
        }
        onRemoved={async () => {
          // Refetch the client context
          await refetch();

          // And close the dialog
          removeWalletDialog.setFalse();
        }}
      />

      <PageHeader
        title='Registered Wallets'
        subtitle='To deposit or withdraw tokens to/from an external wallet, you must first register that wallet and confirm ownership. You can do that automatically using a Web3 provider such as MetaMask, or manually by signing a message with your private key.'
        action={{
          label: 'Register Wallet',
          onClick: registerWalletDialog.setTrue
        }}
      />

      <ContentCard>
        <TableContainer
          sx={{
            minHeight: '264px'
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width='10%'>Active</TableCell>
                <TableCell width='25%'>Date and Time Connected</TableCell>
                <TableCell width='60%'>Wallet Address</TableCell>
                <TableCell width='5%'></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!data?.externalWallets?.length && (
                <TablePlaceholder
                  message='No wallet has been registered yet.'
                  isLoading={loading}
                />
              )}

              {!!data?.externalWallets?.length && data?.externalWallets.map((wallet) => (
                <TableRow key={wallet.id}>
                  <TableCell>
                    <Box
                      sx={{
                        gap: '0.75rem',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Badge
                        variant='dot'
                        sx={{
                          marginLeft: '0.5rem',
                          '.MuiBadge-badge': {
                            backgroundColor: wallet.status === ExternalWalletStatus.Active ?
                              'success.solidBackground' : wallet.status === ExternalWalletStatus.Deactivated ?
                                'error.solidBackground' : 'warning.solidBackground'
                          }
                        }}
                      />
                      { wallet.status === ExternalWalletStatus.Active ?
                        'Active' : wallet.status === ExternalWalletStatus.Deactivated ?
                          'Disabled' : 'Inactive'
                      }
                    </Box>
                  </TableCell>
                  <TableCell>
                    {new Date(wallet.updatedAt).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant='code'
                      sx={{
                        height: '21px',
                        gap: '0.25rem',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 14
                      }}
                    >
                      {wallet.address}

                      <IconButton
                        color='inherit'
                        onClick={() => {
                          navigator.clipboard.writeText(wallet.address).then(() => {
                            snackbar.enqueueSnackbar('Copied wallet address to clipboard', {
                              variant: 'success'
                            });
                          });
                        }}
                      >
                        <ContentCopyIcon fontSize='small' />
                      </IconButton>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {wallet.status !== ExternalWalletStatus.Deactivated &&
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          onClick={(e) =>
                            handleClick(e, wallet.id, wallet.address, wallet.status === ExternalWalletStatus.Active)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ContentCard>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        onClick={handleClose}
      >
        {wallet && !wallet.isActive && <MenuItem onClick={activateWalletDialog.setTrue}>
          <ListItemIcon>
            <SecurityIcon
              fontSize="small"
            />
          </ListItemIcon>
            Activate
        </MenuItem>
        }
        <MenuItem onClick={removeWalletDialog.setTrue}>
          <ListItemIcon>
            <ClearIcon
              fontSize="small"
            />
          </ListItemIcon>
            Disable
        </MenuItem>
      </Menu>
    </Box>
  );
};
