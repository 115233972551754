import React from 'react';
import { useSnackbar } from 'notistack';

import { Box, IconButton, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface Props {
  name?: string;
  symbol?: string;
  address: string;
  stablecoins?: string[];
  availableNetworks?: string[];
}

export const DepositToken: React.FC<Props> = ({
  name,
  symbol,
  address,
  stablecoins,
  availableNetworks
}) => {
  const snackbar = useSnackbar();

  return (
    <Box
      sx={{
        gap: '1.5rem',
        display: 'flex',
        alignItems: 'center',
        padding: '1.5rem 2rem',

        '&:not(:last-child)': {
          borderBottom: 1,
          borderColor: '#edeff4'
        }
      }}
    >
      <Box
        sx={{
          minWidth: '200px',
          width: '25%',
          gap: '0.75rem',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Box
          src={`/assets/icons/assets/${symbol || 'placeholder'}.svg`}
          component="img"
          width={40}
          height={40}
        />

        <Box
          sx={{
            overflow: 'hidden'
          }}
        >
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 700
            }}
          >
            {symbol || 'bTokens'}
          </Typography>

          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: '#848a98'
            }}
          >
            {name || 'Backed Finance'}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          minWidth: '400px',
          width: '40%'
        }}
      >
        <Typography
          sx={{
            fontSize: 12,
            color: '#848a98'
          }}
        >
          Address
        </Typography>

        <Typography
          variant="code"
          sx={{
            height: '21px',
            gap: '0.25rem',
            display: 'flex',
            alignItems: 'center',
            fontSize: 14
          }}
        >
          {address}

          <IconButton
            color="inherit"
            onClick={() => {
              navigator.clipboard.writeText(address).then(() => {
                snackbar.enqueueSnackbar(
                  `Copied ${symbol || 'redemption'} address to clipboard`,
                  {
                    variant: 'success'
                  }
                );
              });
            }}
          >
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Typography>
      </Box>

      {availableNetworks?.length && (
        <Box
          sx={{
            minWidth: '150px',
            width: '20%'
          }}
        >
          <Typography
            sx={{
              fontSize: 12,
              color: '#848a98'
            }}
          >
            Network
          </Typography>

          {availableNetworks.map((network, index) => (
            <Typography
              key={index}
              sx={{
                fontSize: 14,
                marginBottom: '4px'
              }}
            >
              {network}
            </Typography>))}

        </Box>
      )}
      {stablecoins && (
        <Box>
          <Typography
            sx={{
              fontSize: 12,
              color: '#848a98'
            }}
          >
            Stablecoin
          </Typography>

          {stablecoins.map((stablecoin, index) =>
            (<Typography
              key={index}
              sx={{
                display: 'flex',
                fontSize: 14,
                marginBottom: '4px'
              }}
            >
              <Box
                mr={0.5}
                src={`/assets/icons/assets/${stablecoin || 'placeholder'}.svg`}
                component="img"
                width={20}
                height={20}
              />
              {stablecoin}
            </Typography>)
          )}

        </Box>)}
    </Box>
  );
};
