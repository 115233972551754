import React from 'react';
import { Box, Typography, Skeleton } from '@mui/material';


// region Props

interface Props {
  title: string;
  subtitle?: string;

  /**
   * Boolean, indicating if the rendered text should
   * be smaller than normal
   */
  small?: boolean;

  /**
   * Boolean, that if set to true will make sure
   * that the returned elements will be wrapped
   * with a div elemnent
   */
  boxed?: boolean;

  /**
   * Boolean, indicating if loader animation should be rendered
   */
  loading?: boolean;
}

// endregion

export const Title: React.FC<Props> = ({
  small,
  title,
  subtitle,
  boxed,
  loading
}) => {
  const Wrapper = boxed ? Box : React.Fragment;

  return (
    <Wrapper>
      <Typography
        variant="titleSmall"
        sx={{
          ...(small && ({
            fontSize: '1rem'
          }))
        }}
      >
        {title}
      </Typography>

      {subtitle ? (
        <Typography
          variant="subtitleSmall"
          sx={{
            ...(small && ({
              fontSize: '.8rem'
            }))
          }}
        >
          {subtitle}
        </Typography>
      ) : (
        loading ? (
          <Skeleton
            variant='rounded'
            width={small ? 100 : 200}
            height={small ? 10 : 15}
          />
        ) : undefined
      )}
    </Wrapper>
  );
};
