import * as React from 'react';
import { forwardRef, Ref, SVGProps } from 'react';

const SvgHistory = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 16 16'
    ref={ref}
    {...props}
  >
    <defs>
      <clipPath id='History_svg__a'>
        <path
          data-name='Rectangle 231'
          transform='translate(260 371)'
          fill='none'
          d='M0 0h16v16H0z'
        />
      </clipPath>
      <clipPath id='History_svg__b'>
        <path
          data-name='Rectangle 230'
          fill='none'
          stroke='#01000d'
          strokeWidth={0.65}
          d='M0 0h13.997v14.773H0z'
        />
      </clipPath>
    </defs>
    <g
      data-name='history icon'
      transform='translate(-260 -371)'
      clipPath='url(#History_svg__a)'
    >
      <g data-name='Group 688'>
        <g
          data-name='Group 687'
          clipPath='url(#History_svg__b)'
          stroke='#01000d'
          strokeWidth={0.65}
          transform='translate(261.002 372.027)'
        >
          <path
            data-name='Path 446'
            d='M7.322 14.205H.916a5.341 5.341 0 0 0 1.145-3.32V.574h9.883v5.437a.284.284 0 1 0 .568 0V.29a.284.284 0 0 0-.284-.284H1.777a.284.284 0 0 0-.284.284v10.595a4.782 4.782 0 0 1-1.41 3.4.284.284 0 0 0 .2.485h7.039a.284.284 0 1 0 0-.568Z'
          />
          <path
            data-name='Path 447'
            d='M10.557 3.583H3.581a.285.285 0 1 0 0 .568h6.976a.285.285 0 1 0 0-.568'
          />
          <path
            data-name='Path 448'
            d='M10.861 5.988a.294.294 0 0 0-.3-.284h-6.98a.285.285 0 1 0 0 .568h6.976a.294.294 0 0 0 .3-.284Z'
          />
          <path
            data-name='Path 449'
            d='M10.861 8.109a.294.294 0 0 0-.3-.284h-6.98a.285.285 0 1 0 0 .568h6.976a.294.294 0 0 0 .3-.284Z'
          />
          <path
            data-name='Path 450'
            d='M10.861 10.23a.294.294 0 0 0-.3-.284h-6.98a.285.285 0 1 0 0 .568h6.976a.294.294 0 0 0 .3-.284Z'
          />
          <path
            data-name='Path 451'
            d='M6.675 14.2a.284.284 0 1 0 0 .568h7.037a.284.284 0 0 0 .2-.485 4.782 4.782 0 0 1-1.41-3.4V.284A.284.284 0 0 0 12.22 0H1.769a.284.284 0 0 0-.284.284v5.721a.284.284 0 1 0 .568 0V.568h9.883v10.311a5.341 5.341 0 0 0 1.145 3.32Z'
          />
        </g>
      </g>
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgHistory);
export default ForwardRef;
