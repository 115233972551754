import React from 'react';
import { ethers } from 'ethers';
import { useSnackbar } from 'notistack';
import { Backdrop, Box, Button, CircularProgress, Typography } from '@mui/material';

import { ExternalWalletSigningMessageType, useCreateExternalWalletSigningMessageMutation } from '@backed-fi/graphql';

interface Props {
  action: string;
  address?: string;
  isCreation: boolean;
  onVerified: (address: string, signature: string) => any;
}

export const VerifyWalletProofOfControl: React.FC<Props> = ({ action, address, isCreation, onVerified }) => {
  const snackbar = useSnackbar();

  const [verificationState, setVerificationState] =
    React.useState<'gettingAccounts' | 'creatingEntity' | 'signingMessage' | 'verifying'>();


  const [createSigningMessage, {
    data: signingMessage
  }] = useCreateExternalWalletSigningMessageMutation();

  // region Actions and handlers

  const onAutomaticallyValidate = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(
        (window as any).ethereum
      );

      setVerificationState('gettingAccounts');

      // Ask to get the ETH accounts of the user
      await provider.send('eth_requestAccounts', []);

      setVerificationState('creatingEntity');

      // And create signer for the main one
      const signer = provider.getSigner();

      // Get the address of the selected wallet and save it to the database
      const signerAddress = await signer.getAddress();
      if (!!address && signerAddress.toLowerCase() !== address) {
        throw new Error('Connected account does not match wallet address');
      }

      const createSigningMessageResult = signingMessage
        ? signingMessage
        : (
          await createSigningMessage({
            variables: {
              input: {
                walletAddress: signerAddress,
                type: isCreation ?
                  ExternalWalletSigningMessageType.Create : ExternalWalletSigningMessageType.Activate
              }
            }
          })
        ).data;

      // The wallet was created successfully, attempt to sign the message
      setVerificationState('signingMessage');

      const signature = await signer.signMessage(
        createSigningMessageResult!.createExternalWalletSigningMessage.message
      );

      setVerificationState('verifying');


      if (typeof onVerified === 'function') {
        await onVerified(signerAddress, signature);
      }

      snackbar.enqueueSnackbar('Wallet successfully created and verified', {
        variant: 'success'
      });
    } catch (e: any) {
      let message: string = e.message || 'An error occurred';

      if (e.message?.includes('missing provider')) {
        message = 'No available Web3 provider was found';
      }

      snackbar.enqueueSnackbar(message || 'An error occurred', {
        variant: 'error'
      });
    } finally {
      setVerificationState(undefined);
    }
  };

  return (
    <Box>
      {(verificationState === 'gettingAccounts' ||
        verificationState === 'signingMessage') && (
        <Backdrop
          open
          sx={{
            color: '#fff',
            zIndex: 9999
          }}
        >
          <Box display='flex' flexDirection='column' alignItems='center'>
            <CircularProgress color='inherit' />

            <Typography
              sx={{
                marginTop: '1rem'
              }}
            >
              {verificationState === 'gettingAccounts' &&
                'Waiting wallet authorization'}
              {verificationState === 'signingMessage' &&
                'Waiting for message signature'}
            </Typography>
          </Box>
        </Backdrop>
      )}

      <Box
        sx={{
          display: 'flex',
          height: '256px',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f2f4f8',
          backgroundImage: 'url(/assets/images/backgrounds/abstract-lines-modal.svg)',
          backgroundPosition: 'center bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% auto'
        }}
      >
        <Box
          src='/assets/images/register-wallet-modal-header.svg'
          component='img'
          width={208}
          height={64}
        />
      </Box>
      <Box
        sx={{
          paddingX: '2rem',
          paddingTop: '2rem',
          display: 'flex',
          flexFlow: 'column'
        }}
      >
        <Typography variant='modalTitle'>
          {action} Your Wallet
        </Typography>

        <Typography variant='modalSubtitle'>
              We use your wallet provider to get your wallet address and sign a message that verifies that you are the
              owner of that wallet.
        </Typography>

        <Box
          sx={{
            gap: '1rem',
            display: 'flex',
            marginTop: '2rem',
            flexDirection: 'column'
          }}
        >
          <Button
            variant='publicDashboard'
            disabled={!!verificationState}
            onClick={onAutomaticallyValidate}
            fullWidth
          >
              Connect Web3 Provider
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
