import { stepIconClasses, Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiStepIcon: Components<Theme>['MuiStepIcon'] = {
  styleOverrides: {
    root: ({ theme }) => {
      const { primary } = theme.palette;

      return ({
        [`.${stepIconClasses.active} .${stepIconClasses.text}`]: {
          fill: `${primary.background} !important`
        }
      });
    }
  }
};
