import { Components } from '@mui/material/styles/components';
import { autocompleteClasses, Theme } from '@mui/material';

export const MuiAutocomplete: Components<Theme>['MuiAutocomplete'] = {
  styleOverrides: {
    paper: ({ theme }) => {
      const { utility } = theme.palette;


      return {
        backgroundColor: utility.elementBackground,
        border: `1px solid ${utility.borderSubtle}`,

        [`.${autocompleteClasses.noOptions}, .${autocompleteClasses.option}`]: {
          color: utility.textHighContrast
        }
      };
    }

  }
};
