import * as React from 'react';
import { forwardRef, Ref, SVGProps } from 'react';

const SvgAccountActivity = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 24 24'
    ref={ref}
    {...props}
  >
    <defs>
      <clipPath id='AccountActivity_svg__a'>
        <path
          data-name='Rectangle 24'
          transform='translate(133.5 354)'
          fill='#fff'
          stroke='#707070'
          d='M0 0h24v24H0z'
        />
      </clipPath>
      <clipPath id='AccountActivity_svg__b'>
        <path
          data-name='Rectangle 23'
          fill='#01000d'
          stroke='#01000d'
          d='M0 0h23.25v16.232H0z'
        />
      </clipPath>
    </defs>
    <g
      data-name='account activitiy _ icon'
      transform='translate(-133.5 -354)'
      opacity={0.6}
      clipPath='url(#AccountActivity_svg__a)'
    >
      <g
        data-name='Group 315'
        clipPath='url(#AccountActivity_svg__b)'
        transform='translate(133.5 358)'
      >
        <path
          data-name='Path 165'
          d='M23.25 10.7a.363.363 0 0 1-.128.277l-6.093 5.168a.363.363 0 0 1-.6-.277v-2.22H6.457a.363.363 0 0 1 0-.727h10.336a.363.363 0 0 1 .363.363v1.8l5.169-4.384-5.168-4.384v1.8a.363.363 0 0 1-.363.363H6.82V10.7a.363.363 0 0 1-.6.277L.128 5.809a.363.363 0 0 1 0-.554L6.222.086a.363.363 0 0 1 .6.277v2.221h9.973a.363.363 0 0 1 0 .727H6.457a.363.363 0 0 1-.363-.363v-1.8L.925 5.532l5.168 4.384v-1.8a.363.363 0 0 1 .363-.363h9.974V5.532a.363.363 0 0 1 .6-.277l6.093 5.168a.363.363 0 0 1 .127.277Z'
          fill='#01000d'
          stroke='#01000d'
        />
      </g>
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgAccountActivity);
export default ForwardRef;
