import { SharedConfiguration } from './shared';

import { ProductionConfig } from './prod';
import { StagingConfiguration } from './stage';
import { DevelopmentConfiguration } from './dev';

const GraphEndpoint = process.env['NX_API_URL'];
const Environment: 'dev' | 'prod' | 'stage' | 'sandbox' = (process.env['BACKED_ENV'] || process.env['NX_BACKED_ENV'] || process.env['NODE_ENV']) as any;

const isDev = Environment === 'dev' || Environment as any === 'development';
const isProd = Environment === 'prod' || Environment as any === 'production';
const isStage = Environment === 'stage' || Environment as any === 'staging';

const EnvironmentConfiguration = isDev
  ? DevelopmentConfiguration
  : Environment === 'stage'
    ? StagingConfiguration
    : ProductionConfig;


export const config = {
  isDev,
  isProd,
  isStage,

  environment: Environment,
  enableExperimental: Boolean(process.env['NX_ENABLE_EXPERIMENTAL']),

  ...SharedConfiguration,
  ...EnvironmentConfiguration,

  service: {
    graphEndpoint: GraphEndpoint
  }
};

if (!config.isProd) {
  // eslint-disable-next-line no-console
  console.info(`Running in ${config.environment} environment`);
}
