import React from 'react';

import { Button, Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';

import { useClientContext, useUserContext } from '@backed-fi/context';
import { useNavigate } from 'react-router-dom';
import { UserFeature, useDissmisSetPasswordPromptMutation } from '@backed-fi/graphql';
import { gql } from '@apollo/client';

// region Graph

gql`
  mutation dissmisSetPasswordPrompt {
    setPasswordPromptFeature
  }
`;

// endregion

export const AccountActivationBanner: React.FC = () => {
  const clientContext = useClientContext();
  const userContext = useUserContext();
  const navigate = useNavigate();

  // region Networking

  const [dissmisSetPasswordPromptMutation] = useDissmisSetPasswordPromptMutation();

  // endregion

  const [status, setStatus] = React.useState<{
    message: string;
    isRequired: boolean;
    onDismiss?: () => Promise<void>;
    button: {
      label: string;
      action: string;
      icon: React.ReactNode;
    };
      }>({} as any);
  const [isHidden, setIsHidden] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!clientContext.loading && userContext.loaded) {
      if (clientContext.shouldRegisterWallet) {
        setStatus((prev) => ({
          ...prev,
          message: 'Please connect a wallet to activate your account.',
          isRequired: true,
          button: {
            label: 'Connect Wallet',
            action: '/settings/wallets?dialog=open',
            icon: <AccountBalanceWalletIcon />
          }
        }));
      } else if (clientContext.shouldActivateWallet) {
        setStatus((prev) => ({
          ...prev,
          message: 'Please activate your wallet.',
          isRequired: false,
          button: {
            label: 'Activate Wallet',
            action: '/settings/wallets',
            icon: <AccountBalanceWalletIcon />
          }
        }));
      } else if (
        userContext.user &&
        !userContext.user.hasPasswordSet &&
        !userContext.user.features.includes(UserFeature.SetPasswordPromptDismissed)
      ) {
        setStatus((prev) => ({
          ...prev,
          message: 'Please set your password',
          isRequired: false,
          onDismiss: async () => {
            try {
              await dissmisSetPasswordPromptMutation({
                awaitRefetchQueries: true,
                refetchQueries: [
                  'GetUserContext'
                ]
              });
            } catch (e) {

            }
          },
          button: {
            label: 'Set Password',
            action: '/settings/security?action=setPassword',
            icon: <LockOpenOutlinedIcon />
          }
        }));
      }
    }
  }, [clientContext, userContext]);

  const onDismiss = async (onDismissFn?: () => Promise<void>) => {
    if (typeof onDismissFn === 'function') {
      await onDismissFn();
    }

    setIsHidden(true);
  };

  return (
    <>
      {(!!status.message && !isHidden) && (
        <Box
          sx={{
            minHeight: '80px',
            display: 'flex',
            gap: '1.5rem',
            flexWrap: 'wrap',
            alignItems: 'center',
            padding: '1.25rem 3rem',
            justifyContent: 'space-between',
            backgroundColor: '#e2e5eb'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '0.375rem',
              alignItems: 'center'
            }}
          >
            {status.isRequired ?
              <InfoOutlinedIcon fontSize='small' /> :
              <CloseIcon sx={{ cursor: 'pointer' }} fontSize='small' onClick={() => onDismiss(status.onDismiss)} />
            }

            <Typography sx={{ fontSize: 14 }}>
              <Box
                fontWeight='fontWeightBold'
                component='span'
              >
                Action {status.isRequired ? 'Required' : 'Recommended'}
              </Box> &#8211; {status.message}
            </Typography>
          </Box>

          <Button
            onClick={() => navigate(status.button.action)}
            startIcon={status.button.icon}
            variant='publicDashboard'
          >
            {status.button.label}
          </Button>
        </Box>
      )}
    </>
  );
};
