import * as React from 'react';
import { forwardRef, Ref, SVGProps } from 'react';

const SvgDepositAddress = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 16 16.5'
    ref={ref}
    {...props}
  >
    <g data-name='deposit address icon'>
      <g data-name='Group 596'>
        <path
          data-name='Path 419'
          d='M15.434 8.256c0 4.4-3.206 7.96-7.16 7.96s-7.16-3.564-7.16-7.96S4.32.296 8.274.296s7.16 3.564 7.16 7.96'
          fill='#f0f2f5'
        />
        <path
          data-name='Path 420'
          d='M9.258.25H7.194C3.779.25 1 3.84 1 8.25s2.779 8 6.194 8h2.064c3.414 0 6.194-3.59 6.194-8s-2.78-8-6.194-8M7.075.766A5.9 5.9 0 0 0 5.6 1.798H4.311A4.663 4.663 0 0 1 7.075.766M3.741 2.315h1.37a7.76 7.76 0 0 0-1.032 1.548H2.6a7.082 7.082 0 0 1 1.141-1.548m-1.4 2.065h1.5a9.548 9.548 0 0 0-.661 2.323H1.63a8.878 8.878 0 0 1 .707-2.323m-.821 3.87c0-.351.021-.694.054-1.032h1.549c-.034.338-.052.681-.052 1.032a10.14 10.14 0 0 0 .119 1.548H1.637a9.807 9.807 0 0 1-.121-1.548m.222 2.065h1.548a9.36 9.36 0 0 0 .679 2.065h-1.5a8.715 8.715 0 0 1-.723-2.065m1.009 2.581h1.47a7.719 7.719 0 0 0 .89 1.29h-1.37a6.848 6.848 0 0 1-.994-1.29m1.574 1.806h1.29a5.9 5.9 0 0 0 1.474 1.032 4.663 4.663 0 0 1-2.764-1.032Zm4.937 1.032c-3.13 0-5.677-3.355-5.677-7.484S6.128.766 9.258.766s5.677 3.355 5.677 7.484-2.547 7.484-5.677 7.484'
          fill='#01000d'
          stroke='#01000d'
          strokeWidth={0.5}
        />
        <path
          data-name='Path 421'
          d='M9.255 1.54c-2.7 0-4.9 3.009-4.9 6.71s2.2 6.71 4.9 6.71 4.9-3.009 4.9-6.71-2.2-6.71-4.9-6.71m0 12.9c-2.418 0-4.387-2.779-4.387-6.194s1.969-6.194 4.387-6.194 4.387 2.779 4.387 6.194-1.966 6.194-4.387 6.194'
          fill='#01000d'
          stroke='#01000d'
          strokeWidth={0.5}
        />
        <path
          data-name='Path 422'
          d='m7.25 9.893 3.65-3.65.364.365-3.65 3.65Z'
          fill='#01000d'
          stroke='#01000d'
        />
      </g>
      <path data-name='Rectangle 153' fill='none' d='M0 .25h16v16H0z' />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgDepositAddress);
export default ForwardRef;
