import React from 'react';

import { Box, Typography } from '@mui/material';

export const DashboardHero: React.FC = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        padding: '2rem',
        color: 'white',
        background: 'linear-gradient(90deg, #0066ff 50%, #3385ff 100%)',
        backgroundColor: '#0066ff',
        borderRadius: '0.5rem'
      }}
    >
      <Box
        sx={{
          position: 'relative',
          zIndex: 1
        }}
      >
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: 600
          }}
        >
          Backed Assets
        </Typography>

        <Typography
          sx={{
            maxWidth: '520px',
            marginTop: '1.5rem',
            fontSize: 14
          }}
        >
          The prices presented here are indicative and DO NOT represent the final price of the assets. The final
          price will be determined when the underlying securities of your bTokens are traded with our brokers.
        </Typography>
      </Box>

      <Box
        src='/assets/images/backed-dashboard-icon.svg'
        component='img'
        sx={{
          position: 'absolute',
          height: 'calc(100% - 2rem)',
          right: '2rem',
          top: '2rem',
          opacity: 0.25
        }}
      />
    </Box>
  )
}
