import * as React from 'react';
import { forwardRef, Ref, SVGProps } from 'react';

const SvgPending = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 16 16'
    ref={ref}
    {...props}
  >
    <defs>
      <clipPath id='Pending_svg__a'>
        <path
          data-name='Rectangle 229'
          transform='translate(304 363)'
          fill='none'
          d='M0 0h16v16H0z'
        />
      </clipPath>
      <clipPath id='Pending_svg__b'>
        <path
          data-name='Rectangle 228'
          fill='#01000d'
          stroke='#01000d'
          strokeWidth={0.5}
          d='M0 0h10.48v16H0z'
        />
      </clipPath>
    </defs>
    <g
      data-name='pending icon'
      transform='translate(-304 -363)'
      clipPath='url(#Pending_svg__a)'
    >
      <g data-name='Group 686'>
        <g
          data-name='Group 685'
          clipPath='url(#Pending_svg__b)'
          transform='translate(306.76 363)'
        >
          <path
            data-name='Path 445'
            d='M9.56 4.212V3.08h.167a.751.751 0 0 0 .753-.74V.741A.752.752 0 0 0 9.727 0H.753A.752.752 0 0 0 0 .741v1.6a.751.751 0 0 0 .753.74H.92v1.131a1.756 1.756 0 0 0 .664 1.377L4.593 8l-2.95 2.288a1.752 1.752 0 0 0-.683 1.389v1.243H.753a.751.751 0 0 0-.753.74v1.6a.752.752 0 0 0 .753.74h8.974a.752.752 0 0 0 .753-.741v-1.6a.751.751 0 0 0-.753-.74H9.6v-1.234a1.775 1.775 0 0 0-.7-1.4L5.892 7.992 8.9 5.589a1.756 1.756 0 0 0 .66-1.377M.8.8h8.88v1.48H.8Zm8.88 14.4H.8v-1.48h.491c.023 0 .046.007.07.007s.047-.007.071-.007h7.692c.023 0 .046.007.07.007s.047-.007.07-.007h.416Zm-.88-3.515v1.235H1.76v-1.243a.958.958 0 0 1 .374-.761L5.242 8.5l3.175 2.414a.972.972 0 0 1 .383.767m-.4-6.72L5.241 7.488 2.084 4.964a.96.96 0 0 1-.364-.752V3.08h7.04v1.132a.96.96 0 0 1-.363.752'
            fill='#01000d'
            stroke='#01000d'
            strokeWidth={0.5}
          />
        </g>
      </g>
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgPending);
export default ForwardRef;
