import { TextField } from '@mui/material';
import React from 'react';

interface Props {
  enum: Record<string, string>;
}

export const EnumSelect = React.forwardRef<any, Props & React.ComponentProps<typeof TextField>>(((props, ref) => (
  <TextField
    select
    {...props}
    ref={ref}
  >
    {Object.keys(props.enum).map((key) => (
      <option key={key} value={props.enum[key]}>
        {props.enum[key]}
      </option>
    ))}
  </TextField>
)));
