import React from 'react';

interface Options {
  default: number;

  max: number;
  min: number;
}

const defaultOptions: Options = {
  default: 1,
  min: 1,
  max: Number.MAX_SAFE_INTEGER
};

export const useIncrement = (options: Partial<Options>) => {
  const opts = {
    ...defaultOptions,
    ...options
  };

  const [increment, setIncrement] = React.useState<number>(opts.default);

  const increase = (number: number | any = 1) => {
    setIncrement((i) => {
      const value = i + (typeof number === number ? number : 1);

      return value > opts.max
        ? opts.min
        : value;
    });
  };

  const decrease = (number: number | any = 1) => {
    setIncrement((i) => {
      const value = i - (typeof number === number ? number : 1);

      return value < opts.min
        ? opts.max
        : value;
    });
  };

  return {
    increment,

    increase,
    decrease,

    next: increase,
    previous: decrease,

    isStep: (step: number) => increment === step,

    isLastStep: increment === opts.max,
    isFirstStep: increment === opts.min
  };
};
