import { useSnackbar } from 'notistack';

export const useErrorSnackbar = () => {
  const snackbar = useSnackbar();

  return (error: any) => {
    try {
      const parsedMessage = JSON.parse((error as any)?.message);

      if (Array.isArray(parsedMessage)) {
        parsedMessage.map((e: any) => {
          snackbar.enqueueSnackbar((e as any).message ?? e, {
            variant: 'error'
          });
        });
      }
    } catch (e) {
    }
  };
};
