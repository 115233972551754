import * as React from 'react';
import { forwardRef, Ref, SVGProps } from 'react';

const SvgAccountSecurity = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    data-name='account security icon'
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 16 16'
    ref={ref}
    {...props}
  >
    <defs>
      <clipPath id='AccountSecurity_svg__a'>
        <path
          data-name='Rectangle 28'
          fill='#01000d'
          stroke='#01000d'
          d='M0 0h16v16H0z'
        />
      </clipPath>
    </defs>
    <g data-name='Group 324' clipPath='url(#AccountSecurity_svg__a)'>
      <g
        data-name='Group 326'
        fill='#01000d'
        stroke='#01000d'
        strokeWidth={0.6}
      >
        <path
          data-name='Path 176'
          d='M13.897 1.079H2.103a.27.27 0 0 0-.27.27v7.13a5.057 5.057 0 0 0 2.56 4.392l3.474 1.969a.27.27 0 0 0 .267 0l3.474-1.969a5.057 5.057 0 0 0 2.559-4.392v-7.13a.27.27 0 0 0-.27-.27m-.27 7.4a4.515 4.515 0 0 1-2.286 3.92L8 14.292l-3.341-1.893a4.515 4.515 0 0 1-2.285-3.92V1.62h11.253Z'
        />
        <path
          data-name='Path 177'
          d='M12.049 2.989h-8.1a.251.251 0 0 0-.251.251v5.191a3.267 3.267 0 0 0 1.653 2.838l2.523 1.429a.251.251 0 0 0 .247 0l2.522-1.429a3.267 3.267 0 0 0 1.654-2.838V3.242a.251.251 0 0 0-.251-.251m-.251 5.442a2.764 2.764 0 0 1-1.4 2.4l-2.4 1.359-2.4-1.359a2.764 2.764 0 0 1-1.4-2.4V3.489h7.6Z'
        />
      </g>
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgAccountSecurity);
export default ForwardRef;
