import { Application } from '@backed-fi/constants';
import { Components } from '@mui/material/styles/components';
import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';

const BaseIcon: React.FC<React.PropsWithChildren<{ sx?: SxProps }>> = ({
  children,
  sx
}) => {
  return (
    <Box
      sx={{
        borderRadius: '10000px',
        border: '1px solid',

        '.Mui-focusVisible &': {
          outline: '2px auto',
          outlineColor: 'primary.borderHovered',
          outlineOffset: 2
        },

        'input:hover ~ &': {
          borderColor: 'primary.borderElement',
          boxShadow: '0 0.25rem 0.5rem #0066ff33'
        },

        ...(sx || {})
      }}
    >
      {children}
    </Box>
  );
};

const Unchecked = (
  <BaseIcon
    sx={{
      padding: '8px',
      borderColor: 'utility.borderSubtle'
    }}
  />
);

const Checked = (
  <BaseIcon
    sx={{
      borderColor: 'primary.solidBackground',
      boxShadow: '0 0.25rem 0.5rem #0066ff33'
    }}
  >
    <Box
      sx={{
        margin: '3px',
        padding: '5px',
        borderRadius: '1000px',
        backgroundColor: 'primary.solidBackground'
      }}
    />
  </BaseIcon>
);

export const createMuiRadio = (application: Application): Components<Theme>['MuiRadio'] => {
  return {
    defaultProps: {
      disableRipple: true,

      icon: Unchecked,
      checkedIcon: Checked
    },

    styleOverrides: {
      root: ({
        theme,
        ownerState
      }) => {
        const {
          primary,
          error
        } = theme.palette;

        return {
          padding: '0',
          color: primary.borderHovered,

          '&.Mui-checked': {
            color: primary.solidBackground
          },

          '&.Mui-disabled': {
            color: '#CCCCCF'
          },

          ...(ownerState.color === 'error' && ({
            color: error.textLowContrast,

            '&.Mui-checked': {
              color: error.solidBackground
            }
          }))
        };
      }
    }
  };
};
