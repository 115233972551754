import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";

import { config } from "@backed-fi/config";
import { divide } from "lodash";

enum SentryInitialisationState {
  NotStarted = 0,
  InProgress = 1,
  Succeed = 2,
  Failed = 3
}

export const SentryProvider: React.FC = () => {
  const [sentryState, setSentryState] = useState<SentryInitialisationState>(SentryInitialisationState.NotStarted);

  useEffect(() => {
    setSentryState((prev) => {
      if (prev === SentryInitialisationState.NotStarted && config.isProd) {
        try {
          Sentry.init({
            dsn: "https://e5dab8dafff040b1a1f04b2319e979ce@o4505388859129856.ingest.sentry.io/4505388863782912",
            integrations: [
              new Sentry.Replay(),
              new Sentry.BrowserTracing({
                // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["localhost", /^https:\/\/api(\.[a-z]*)?\.backed\.fi/]
              })
            ],

            // Performance Monitoring
            tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!

            // Session Replay
            replaysSessionSampleRate: 1.0, // This sets the sample rate at 100%.
            replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
          });

          return SentryInitialisationState.Succeed;
        } catch (e) {
          console.error("An error occurred while initialising Sentry", {
            error: e
          });

          return SentryInitialisationState.Failed;
        }
      } else {
        return prev;
      }
    });
  }, []);

  return (
    <div />
  );
};
