import React from 'react';
import { Box } from '@mui/material';
import { InteractionsTable } from '../components/InteractionsTable';

export const InteractionsPendingPage: React.FC = () => {
  return (
    <Box>
      {/* region The table */}

      <Box
        sx={{
          marginTop: '40px'
        }}
      >
        <InteractionsTable />
      </Box>

      {/* endregion */}
    </Box>
  );
};
