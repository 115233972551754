import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiTableRow: Components<Theme>['MuiTableRow'] = {
  styleOverrides: {
    root: () => ({
      '&:nth-of-type(even)': {
        backgroundColor: '#f2f4f780'
      }
    })
  }
}
