import * as React from 'react';
import { forwardRef, Ref, SVGProps } from 'react';

const SvgIssuanceIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    data-name='issuance icon'
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 31.844 16.704'
    ref={ref}
    {...props}
  >
    <g data-name='issuance icon'>
      <g
        data-name='Ellipse 37'
        transform='rotate(90.97 14.054 17.878)'
        fill='none'
        stroke='#01000d'
        strokeWidth={1.4}
      >
        <circle cx={4.241} cy={4.241} r={4.241} stroke='none' />
        <circle cx={4.241} cy={4.241} r={3.541} />
      </g>
      <path
        data-name='Path 606'
        d='m18.134 15.167 4.145-6.37a.728.728 0 0 0 .014-.785l-3.927-6.509A2.808 2.808 0 0 0 15.997.217L5.222.033A4.328 4.328 0 0 0 .597 3.995l-.138 8.078a4.328 4.328 0 0 0 4.489 4.116l10.775.184a2.808 2.808 0 0 0 2.41-1.206Zm-13.159-.594a2.593 2.593 0 0 1-2.693-2.47l.138-8.077a2.593 2.593 0 0 1 2.775-2.377l10.775.184a.936.936 0 0 1 .793.43l3.684 6.11-3.89 5.982a.936.936 0 0 1-.808.402Z'
        fill='#01000d'
      />
      <path
        data-name='Line 122'
        fill='none'
        stroke='#01000d'
        strokeLinecap='round'
        strokeWidth={1.50021}
        d='m4.89 4.683 10.667.181'
      />
      <path
        data-name='Line 123'
        fill='none'
        stroke='#01000d'
        strokeLinecap='round'
        strokeWidth={1.50021}
        d='m4.832 8.107 10.667.181'
      />
      <path
        data-name='Line 124'
        fill='none'
        stroke='#01000d'
        strokeLinecap='round'
        strokeWidth={1.50021}
        d='m4.774 11.53 6.058.103'
      />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgIssuanceIcon);
export default ForwardRef;
