import { FiatCurrency } from '@backed-fi/graphql';

export const DateFormatter = new Intl.DateTimeFormat('en', {
  year: 'numeric',
  month: 'long',
  day: 'numeric'
});

export const DateShortFormatter = new Intl.DateTimeFormat('en', {
  year: 'numeric',
  month: 'short',
  day: 'numeric'
});

const _dateTimeFormatter = new Intl.DateTimeFormat('en', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  minute: '2-digit',
  hour: 'numeric'
});

export const DateTimeFormatter = {
  format: (value: Date | string) => {
    if (typeof value === 'string') {
      value = new Date(value);
    }

    return _dateTimeFormatter.format(value);
  }
};

export const EURFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'EUR'
});

export const USDFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

export const USDUnitFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 6
});

export const CHFFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'CHF'
});

export const PercentageFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent'
});

export const IntAmountFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 0
});

export const CryptoDecimalAwareFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 4
});

export const CryptoStablecoinDecimalAwareFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 2
});

export const CentsFormatter = {
  format: (cents: number | string, currency: FiatCurrency = FiatCurrency.Usd) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency.toUpperCase()
    });

    if (typeof cents !== 'number') {
      cents = Number(cents);
    }

    return formatter.format((cents || 0) / 100);
  }
};

export const EuroCentsFormatter = {
  format: (cents: number) => EURFormatter.format((cents || 0) / 100)
};

export const CentimesFormatter = {
  format: (centimes: number) => CHFFormatter.format((centimes || 0) / 100)
};

export const CryptoFormatter = {
  format: (units: number | string, decimals: number, symbol?: string, isStablecoin?: boolean) => {
    if (typeof units !== 'number') {
      units = Number(units);
    }

    return (isStablecoin ? CryptoStablecoinDecimalAwareFormatter : CryptoDecimalAwareFormatter)
      .format((units || 0) / (10 ** decimals)) + (symbol ? ` ${symbol}` : '');
  }
};

export const CapitaliseFormatter = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || '';
