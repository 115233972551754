import React, { PropsWithChildren } from 'react';
import { Box } from '@mui/material';

type InternalProps = React.ComponentProps<typeof Box>;

export const Flex: React.FC<PropsWithChildren<InternalProps>> = (props) => (
  <Box
    {...props}
    sx={{
      display: 'flex',

      ...props.sx,
    }}
  />
);
