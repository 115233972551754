import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ContentCard } from '../../../components/ContentCard';
import { SharedConfiguration } from '@backed-fi/shared/config/shared';

interface Props extends React.PropsWithChildren {
  title: string;
  subtitle: string;
}

export const TransactionCard: React.FC<Props> = ({ title, subtitle, children }) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
      }}
    >
      <Box
        src='/assets/images/backgrounds/abstract-lines-dashboard.svg'
        component='img'
        width={960}
        height={464}
        sx={{
          position: 'absolute',
          maxWidth: '1280px',
          top: '-10rem',
          width: '100%',
          height: 'auto'
        }}
      />

      <ContentCard
        sx={{
          position: 'relative',
          display: 'flex',
          width: '100%',
          maxWidth: '640px',
          flexDirection: 'column',
          padding: '2rem',
          gap: '2rem'
        }}
      >
        <Box
          sx={{
            gap: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Box>
            <Typography
              component='h2'
              sx={{
                fontWeight: 400,
                fontSize: 28
              }}
            >
              {title}
            </Typography>

            <Typography
              sx={{
                color: '#848a98',
                fontSize: 14
              }}
            >
              {subtitle}
            </Typography>
          </Box>

          <Box
            src={'/assets/icons/backed-soft.svg'}
            component='img'
            width={64}
            height={64}
          />
        </Box>

        <Box>
          {children}
        </Box>

        <Box
          sx={{
            gap: '0.5rem',
            display: 'flex',
            color: '#848a98',
            padding: '0 1.5rem'
          }}
        >
          <InfoOutlinedIcon fontSize='small' />
          <Typography
            sx={{
              fontSize: 12
            }}
          >
            Your on-chain transactions are subject to blockchain network fees which are your sole responsibility; check the
            transaction cost before confirming. Orders that do not meet the minimum order size of {SharedConfiguration.limits.minimum.display} will be reverted,
            funds returned to sender, and a fee of CHF 50.00 will be charged to cover operational costs.
          </Typography>
        </Box>
      </ContentCard>
    </Box>
  );
};
