import React from 'react';
import { Box, Typography } from '@mui/material';
import { CopyButton } from './CopyButton';
import QRCode from 'react-qr-code';

interface Props {
  /**
   * If symbol is provided the sweeping wallet will be treated
   * as issuance sweeping wallet
   */
  symbol?: string;

  address: string;
  availableNetworks: string[];
}

export const SweepingWallet: React.FC<Props> = (props) => {
  return props.symbol ? (
    <React.Fragment>
      <Box
        sx={{
          // minWidth: '175px',
          display: 'flex',
          gap: '12px',
          pl: '12px',
          flex: 1,
        }}
      >
        <Box
          component="img"
          src={`/assets/icons/assets/${props.symbol}.svg`}
          sx={{
            height: '24px',
            width: '24px',
          }}
        />

        <Typography>{props.symbol}</Typography>
      </Box>

      <Box
        sx={{
          flex: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography>{props.address}</Typography>

        <CopyButton
          value={props.address}
          notificationText={`Successfully copied ${props.symbol} sweeping address`}
        />
      </Box>
    </React.Fragment>
  ) : (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          py: '.5rem',
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            gap: '12px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box>
            <Typography
              sx={{
                /// It is not good to put that much font styling here, but the design is so inconsistent
                /// that it is not feasible to create any type structure
                fontWeight: 'medium',
                fontSize: 10,
                color: '#01000D99',
                textTransform: 'uppercase',
              }}
            >
              Address
            </Typography>

            <Typography
              sx={{
                /// It is not good to put that much font styling here, but the design is so inconsistent
                /// that it is not feasible to create any type structure
                fontWeight: 'regular',
                fontSize: 16,
                color: '#01000D',
              }}
            >
              {props.address}
            </Typography>
          </Box>

          <CopyButton
            value={props.address}
            notificationText="Successfully copied the redemption wallet address"
          />
        </Box>

        <Box>
          <Typography
            sx={{
              /// It is not good to put that much font styling here, but the design is so inconsistent
              /// that it is not feasible to create any type structure
              color: '#01000D99',
              fontSize: 10,
              fontWeight: 'medium',
              textTransform: 'uppercase',
            }}
          >
            Network
          </Typography>

          <Typography
            sx={{
              /// It is not good to put that much font styling here, but the design is so inconsistent
              /// that it is not feasible to create any type structure
              fontWeight: 'medium',
              fontSize: 10,
              color: '#01000D',
            }}
          >
            {props.availableNetworks.join(', ')}
          </Typography>
        </Box>
      </Box>

      <Box>
        <QRCode size={96} value={props.address} />
      </Box>
    </Box>
  );
};
