import React from 'react';
import { gql } from '@apollo/client';
import { Box, Button, Dialog } from '@mui/material';

import { VerifyWalletProofOfControl } from './components/VerifyWalletProofOfControl';
import { VerifyWalletProofOfControlManually } from './components/VerifyWalletProofOfControlManually';
import { useActivateExternalWalletBySigningMessageMutation } from '@backed-fi/graphql';

// region Graph Declaration
const Graph = gql`
  mutation activateExternalWalletBySigningMessage($input: ActivateExternalWalletBySigningMessageInput!) {
    activateExternalWalletBySigningMessage(input: $input) {
      id
    }
  }
`;

// endregion

// region Props

interface Props extends React.ComponentProps<typeof Dialog> {
  address?: string;
  onActivated?: () => any;
}

// endregion

export const ActivateWalletDialog: React.FC<Props> = ({ address, onActivated, ...props }) => {
  // region State

  const [manual, setManual] = React.useState<boolean>(false);

  // endregion

  // region Networking
  const [activateWallet] = useActivateExternalWalletBySigningMessageMutation();
  // endregion


  // region Actions and handlers
  const onActivateWallet = async (address: string, signature: string) => {
    // Send the verification signature to the backend
    await activateWallet({
      variables: {
        input: {
          signedMessage: signature,
          walletAddress: address
        }
      }
    });

    if (typeof onActivated === 'function') {
      onActivated();
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      {...props}
    >
      {
        !manual && (
          <React.Fragment>
            <VerifyWalletProofOfControl
              action='Activate'
              address={address}
              isCreation={false}
              onVerified={onActivateWallet}
            />
            <Box
              sx={{
                paddingX: '2rem',
                paddingTop: '1rem',
                paddingBottom: '2rem',
                display: 'flex',
                flexFlow: 'column'
              }}
            >
              <Button
                variant='publicDashboardGhost'
                onClick={() => setManual(true)}
                fullWidth
              >
              Activate Manually
              </Button>
            </Box>
          </React.Fragment>
        )
      }
      {
        manual && <VerifyWalletProofOfControlManually
          action='Activation'
          address={address}
          isCreation={false}
          onCancel={() => setManual(false)}
          onVerifiedManuallyBySigningMessage={onActivateWallet}/>
      }
    </Dialog>
  );
};
