import * as z from 'zod';
import React from 'react';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { gql } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { zodResolver } from '@hookform/resolvers/zod';
import CopyIcon from '@mui/icons-material/ContentCopyRounded';
import { Box, Button, Card, IconButton, TextField, Typography } from '@mui/material';

import { ExternalWalletSigningMessageType, useCreateExternalWalletSigningMessageMutation } from '@backed-fi/graphql';
import { ethers } from 'ethers';

const Graph = gql`
  mutation createExternalWalletSigningMessage($input: CreateExternalWalletSigningMessageInput!) {
    createExternalWalletSigningMessage(input: $input) {
      id

      message
      walletAddress
    }
  }
`;

// region Form validation and types

const FormSchema = z.object({
  address: z
    .string()
    .regex(/^0x[a-fA-F0-9]{40}$/, 'The provided address is not valid')
    .nonempty('Please provide wallet address'),

  signature: z.string().nonempty()
});

type FormData = z.infer<typeof FormSchema>;

// endregion

interface Props {
  onVerify: (address: string, signature: string) => any
  address?: string,
  isCreation: boolean
}

export const VerifyWalletProofOfControlManuallyBySignedMessage: React.FC<Props> = ({ address, isCreation, onVerify }) => {
  const snackbar = useSnackbar();

  const [createSigningMessage, {
    data: signingMessage,
    loading: creatingSigningMessage
  }] = useCreateExternalWalletSigningMessageMutation();

  const { register, ...form } = useForm<FormData>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      address
    }
  });


  const onCreateSigningMessage = async () => {
    // Get the address of the wallet from the form
    const address = form.getValues('address');

    await createSigningMessage({
      variables: {
        input: {
          walletAddress: address,
          type: isCreation ?
            ExternalWalletSigningMessageType.Create : ExternalWalletSigningMessageType.Activate
        }
      }
    });
  };

  const onCopyVerificationMessage = async () => {
    try {
      await navigator.clipboard.writeText(
        signingMessage?.createExternalWalletSigningMessage?.message || ''
      );

      const provider = new ethers.providers.Web3Provider(
        (window as any).ethereum);


      // Ask to get the ETH accounts of the user
      await provider.send('eth_requestAccounts', []);

      const signer = provider.getSigner();

      // Get the address of the selected wallet and save it to the database

      const signature = await signer.signMessage(
        signingMessage!.createExternalWalletSigningMessage!.message || ''
      );

      console.log(signature);
      snackbar.enqueueSnackbar('Verification message successfully copied to clipboard', {
        variant: 'success'
      });
    } catch {
      snackbar.enqueueSnackbar(
        'An error occurred while writing to clipboard. Please manually copy the verification message'
      );
    }
  };

  const onVerifyWallet = async () => {
    const address = await form.getValues('address');
    const signature = await form.getValues('signature');

    // Send the verification signature to the backend
    await onVerify(address, signature);

    // Notify the user that the wallet was successfully created
    snackbar.enqueueSnackbar('Wallet successfully verified', {
      variant: 'success'
    });
  };

  return (
    <React.Fragment>
      { !signingMessage && <React.Fragment>
        <Typography variant='modalTitle'>
            Enter Your Address
        </Typography>

        <Typography variant='modalSubtitle'>
          Enter the address of the wallet you wish to verify.
        </Typography>

        <TextField
          label='Blockchain Address'
          {...register('address')}
          sx={{
            margin: '2rem 0 1rem'
          }}
        />

        <LoadingButton
          onClick={onCreateSigningMessage}
          loading={creatingSigningMessage}
          variant='publicDashboard'
        >
          Save Wallet
        </LoadingButton>
      </React.Fragment>
      }
      {signingMessage && <React.Fragment>
        <Typography variant='modalTitle'>
          Verify Your Wallet
        </Typography>

        <Typography variant='modalSubtitle'>
          Please sign the following message and input it in the text field bellow.
        </Typography>

        <Box
          sx={{
            gap: '1rem',
            display: 'flex',
            marginTop: '2rem',
            flexDirection: 'column'
          }}
        >
          <Card
            sx={{
              gap: '1rem',
              display: 'flex',
              padding: '1rem',
              backgroundColor: '#f2f4f8',
              border: 0
            }}
          >
            <Typography
              variant='code'
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'normal',
                fontSize: 14
              }}
            >
              {signingMessage?.createExternalWalletSigningMessage?.message}
            </Typography>

            <Box>
              <IconButton
                onClick={onCopyVerificationMessage}
                color='inherit'
              >
                <CopyIcon />
              </IconButton>
            </Box>
          </Card>

          <TextField
            label='Message signature'
            {...register('signature')}
          />

          <Button
            variant='publicDashboard'
            onClick={onVerifyWallet}
          >
            Verify Wallet
          </Button>
        </Box>
      </React.Fragment>
      }
    </React.Fragment>
  );
};
