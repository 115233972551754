import React from 'react';

import { Box, Button, Typography } from '@mui/material';

interface Props {
  title: string;
  subtitle: string;
  action?: {
    label: string;
    href?: string;
    onClick?: (event: React.SyntheticEvent) => void
  }
}

export const PageHeader: React.FC<Props> = ({ title, subtitle, action }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: '1.5rem',
        gap: '3rem'
      }}
    >
      <Box
        sx={{
          flexGrow: 1
        }}
      >
        <Typography
          component='h2'
          sx={{
            fontSize: 20
          }}
        >
          {title}
        </Typography>

        <Typography
          sx={{
            marginTop: '0.25rem',
            color: '#848a98',
            fontSize: 14
          }}
        >
          {subtitle}
        </Typography>
      </Box>

      {!!action && (
        <Button
          variant='publicDashboardGhost'
          onClick={action.onClick}
          href={action.href}
          sx={{
            flexShrink: 0
          }}
        >
          {action.label}
        </Button>
      )}
    </Box>
  )
}
