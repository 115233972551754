import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';

interface InternalProps {
  title: string;
  subtitle?: string;

  noSeparator?: boolean;
}

export const FormGroup: React.FC<React.PropsWithChildren<InternalProps>> = ({
  title,
  subtitle,
  noSeparator,
  children,
}) => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        py: '1rem',
        width: '100%',
        display: 'flex',
        borderTop: noSeparator
          ? undefined
          : `1px solid ${palette.utility.borderElement}`,
      }}
    >
      <Box
        sx={{
          gap: '2rem',
          width: '100%',
          display: 'grid',
          gridTemplateColumns: '2fr 5fr',
          maxWidth: '1600px',
        }}
      >
        <Box>
          <Typography variant="titleSmall">{title}</Typography>

          {subtitle && (
            <Typography variant="subtitleSmall">{subtitle}</Typography>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexFlow: 'column',
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};
