import React from 'react';
import { Box, Typography } from '@mui/material';
import { InteractionStatus as InteractionStatusEnum } from '@backed-fi/graphql';

// region Types

type Status = InteractionStatusEnum;

interface Props {
  status: Status;
  confirmations?: number;
  failureReason?: string;
}

// endregion

const PendingGradient =
  'linear-gradient(340deg, ' +
  'rgba(2,0,36,1) 0%, ' +
  'rgba(0,0,0,1) 50%, ' +
  'rgba(181,186,198,1) 50%, ' +
  'rgba(181,186,198,1) 100%' +
  ')';

const background: Record<Status, string> = {
  ManualActionRequired: PendingGradient,
  PendingIncomingTransactionProcessing: PendingGradient,
  PendingInternalFundsMovement: PendingGradient,
  PendingLimitsCheck: PendingGradient,
  PendingOrdersExecution: PendingGradient,
  PendingPayout: PendingGradient,
  PendingWalletRegistration: PendingGradient,
  Completed: '#78C247',
  Failed: '#FF4D4E',
  Refunded: '#FF4D4E',
  RefundPending: PendingGradient
};

const userFriendlyLabels: { [key in Status]?: string } = {
  ManualActionRequired: 'Pending',
  PendingIncomingTransactionProcessing: 'Pending',
  PendingInternalFundsMovement: 'Pending',
  PendingLimitsCheck: 'Pending',
  RefundPending: 'Pending',
  PendingOrdersExecution: 'Pending Order Execution',
  PendingPayout: 'Pending Token Sending',
  PendingWalletRegistration: 'Pending Wallet Registration',
  Refunded: 'Refunded'
};

export const InteractionStatusLabel: React.FC<Props> = ({
  status,
  failureReason
}) => {
  return (
    <Box
      sx={{
        gap: '8px',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          width: '16px',
          height: '16px',
          borderRadius: '16px',
          background: background[status]
        }}
      />

      {userFriendlyLabels[status] ?? status}

      {failureReason && (
        <Typography
          sx={{
            fontWeight: 'medium',
            opacity: .6
          }}
        >
          ({failureReason})
        </Typography>
      )}
    </Box>
  );
};
