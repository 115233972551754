import React from 'react';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { ClientType } from '@backed-fi/graphql';
import { useClientContext } from '@backed-fi/context';

export interface ListBlock {
  block: boolean;
  items: (string | {
    content: string;
    renderOnlyForType: ClientType[]
  })[];
  sx?: SxProps<Theme>;
}

export const OnboardingListBlock: React.FC<ListBlock> = ({
  block,
  items,
  sx
}) => {
  const clientContext = useClientContext();

  return (
    <Box
      component="ul"

      sx={{
        ...(block && ({
          display: 'grid',
          gridTemplateColumns: {
            xxs: '1fr',
            md: '1fr 1fr 1fr'
          },

          maxHeight: '600px',
          overflowY: 'auto',

          gap: '.25rem',
          padding: '2rem',
          borderRadius: '8px',
          listStyleType: 'none',
          backgroundColor: 'utility.elementBackground'
        })),
        ...sx
      }}
    >
      {items.map((item, index) => (
        !(typeof item !== 'string' && !item.renderOnlyForType.includes(clientContext.type)) && (
          <Typography
            key={index}
            component="li"
            sx={{
              fontSize: '.9rem',
              fontWeight: '400'
            }}
          >
            {typeof item === 'string'
              ? item
              : item.content
            }
          </Typography>
        ))
      )}
    </Box>
  );
};
