import React from 'react';
import { Dialog, DialogContent, Typography } from '@mui/material';
import { gql } from '@apollo/client';
import { useDeactivateExternalWalletMutation } from '@backed-fi/graphql';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';

gql`
mutation deactivateExternalWallet($input: DeactivateExternalWalletInput!) {
  deactivateExternalWallet(input: $input) {
    id
  }
}
`;

// region Props

interface Props extends React.ComponentProps<typeof Dialog> {
  externalWallet: {
    address: string,
    id: string
  };
  onRemoved?: () => any;
}

// endregion

export const RemoveWalletDialog: React.FC<Props> = ({ externalWallet, onRemoved, ...props }) => {
  const snackbar = useSnackbar();

  const [deactivateWallet, {
    data: wallet,
    loading: deactivatingWallet
  }] = useDeactivateExternalWalletMutation();


  const onRemove = async () => {
    try {
      await deactivateWallet({
        variables: {
          input: {
            externalWalletId: externalWallet!.id
          }
        }
      });

      if (typeof onRemoved === 'function') {
        await onRemoved();
      }
      snackbar.enqueueSnackbar('Wallet successfully removed', {
        variant: 'success'
      });
    } catch (e: any) {
      let message: string = e.message || 'An error occurred';

      snackbar.enqueueSnackbar(message || 'An error occurred', {
        variant: 'error'
      });
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth='xs'
      {...props}
    >
      <DialogContent>
        <Typography variant='modalTitle'>
          Are you sure you want to remove wallet?
        </Typography>
        <Typography variant="modalSubtitle">
          You will no longer be able to use this wallet to interact with system.
        </Typography>

        <Typography variant="modalSubtitle" sx={{ marginTop: '16px' }}>
          Wallet address:
        </Typography>
        <Typography sx={{ paddingBottom: '8px' }} variant='subtitleSmall'>
          {externalWallet!.address}
        </Typography>

        <LoadingButton
          onClick={onRemove}
          loading={deactivatingWallet}
          variant='publicDashboard'
          fullWidth
        >
            Remove
        </LoadingButton>
      </DialogContent>
    </Dialog>
  );

  // endregion
};
