import { z } from 'zod';
import { useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { gql } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { ClientType, useInvitationQuery } from '@backed-fi/shared/graphql/generated';

// region Graph Declaration
gql`
  query Invitation($email: String) {
    invitation(email: $email) {
      isClaimed
    }
  }
`;

// endregion

const schema = z.object({
  marketing: z.boolean()
    .optional()
});
interface OnboardingTermsProps {
  clientType: ClientType;
  email: string;
  isContactFormValid: boolean;
}
export const OnboardingTerms = (
  {
    clientType,
    email,
    isContactFormValid
  }: OnboardingTermsProps
) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, loading } = useInvitationQuery({
    variables: { email },
    skip: !email
  });

  // region Form Control
  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  const {
    errors,
    isSubmitting
  } = form.formState;

  // endregion

  // region Actions
  const onContinue = async () => {
    navigate(location.pathname.replace('explainer', 'creation'), {
      state: {
        ...location.state,
        clientType,
        principal: clientType,
        email,
        challengeId: searchParams.get('challengeId'),
        privateCode: searchParams.get('privateCode'),
        acceptsMarketingCommunication: form.getValues('marketing')
      }
    });
  };

  useEffect(() => {
    if (data?.invitation?.isClaimed) {
      onContinue();
    }
  }, [data]);

  // endregion

  return (
    <Box
      sx={{
        margin: '0 auto',
      }}
    >

      <Box mt="1rem">
        {/* region  Terms & Privacy*/}
        <Typography
          sx={{
            fontWeight: '400'
          }}
        >
          By pressing <strong>Continue</strong>, you confirm that you have read and accepted:
        </Typography>

        <ul
          style={{
            paddingLeft: '1.25rem'
          }}
        >
          <li>          <Typography
            variant="labelBig"
          >
            Our {' '}

            <Box
              component="a"
              href="/assets/docs/TermsOfService.pdf"
              target="_blank"
              sx={{
                color: 'primary.textLowContrast'
              }}
            >
              Our Terms of Service
            </Box>
          </Typography></li>
          <li>
            <Typography
              variant="labelBig"
            >
              Our {' '}

              <Box
                component="a"
                href="/assets/docs/PrivacyPolicy.pdf"
                target="_blank"
                sx={{
                  color: 'primary.textLowContrast'
                }}
              >
                Privacy Notice
              </Box>
            </Typography>
          </li>
        </ul>
        {/* endregion */}


        <FormGroup
          sx={{
            mb: '1.5rem',
            mt: '1.5rem',
            p: '1rem 1rem 1rem 1.75rem',
            borderRadius: '8px',
            border: '1px dashed rgb(0 102 255)',
            backgroundColor: 'rgb(237 244 255)'
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  mt: '.25rem',
                  backgroundColor: 'white'
                }}
                {...form.register('marketing')}
              />
            }
            sx={{
              gap: '.75rem',
              alignItems: 'start'
            }}
            label={
              <>
                <Typography variant="labelBig" sx={{ fontWeight: '400' }}>
                  <strong>Stay updated!</strong> Receive news and updates about our services. <br />
                </Typography>
                <Typography variant='label' sx={{ mt: '0.25rem', color: 'rgba(0,0,0,.8)' }} >
                  You can unsubscribe anytime by clicking the link in any email or by reaching out to <i>privacy@backedassets.fi</i>.
                </Typography>
              </>
            }
          />
        </FormGroup>
        <Box id="recaptcha-container" />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <LoadingButton
            fullWidth
            variant="publicDashboard"
            disabled={!form.formState.isValid || !isContactFormValid}
            loading={isSubmitting}
            onClick={form.handleSubmit(onContinue, console.error)}
          >
            Continue
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};
