import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { BlockchainNetwork } from '@backed-fi/graphql';

export const BlockchainSelect: React.FC<TextFieldProps> = (props) => (
  <TextField
    select
    label="Blockchain Network"
    SelectProps={{
      native: true,
    }}
    {...props}
  >
    <option selected disabled>
      Select Network
    </option>

    {Object.keys(BlockchainNetwork).map((network) => (
      <option value={network} key={network}>
        {network}
      </option>
    ))}
  </TextField>
);
