import React from 'react';
import { Box } from '@mui/material';

export const BackgroundContainer: React.FC<React.PropsWithChildren<React.ComponentProps<typeof Box>>> = (props) => {
  return (
    <Box
      {...props}
      sx={{
        width: '100vw',
        minHeight: '100vh',
        background: 'black',
        backgroundImage: 'url(/assets/images/backgrounds/abstract-lines-onboarding.svg)',
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'no-repeat',
        ...(props.sx ?? {})
      }}
    />
  );
};
