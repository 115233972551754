import React from 'react';

export const useStoredState = <T>(
  key: string,
  initialState: any = {}
) => {
  const [state, setState] = React.useState<T>(
    JSON.parse(localStorage.getItem(key) || JSON.stringify(initialState))
  );

  React.useEffect(() => {
    if (state) {
      localStorage.setItem(key, JSON.stringify(state));
    }
  }, [state, key]);

  return [state, setState];
};
