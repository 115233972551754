import React from 'react';

import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from '@apollo/client';

import { config } from '@backed-fi/config';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { Application, LocalStorage } from '@backed-fi/constants';
import { useAuthContext } from '@backed-fi/context';

const GraphEndpoint = config.service.graphEndpoint;

export interface GraphProviderProps {
  graphEndpoint?: string;
  application: Application;
}

const unauthenticatedOperations = [
  'startEmailChallenge',
  'authenticateWithEmailChallenge',
  'authenticateWithTransitionToken',
  'authenticateWithPassword'
];

export const GraphProvider: React.FC<React.PropsWithChildren<GraphProviderProps>> = (props) => {
  const authContext = useAuthContext();
  const {
    children,
    application,
    graphEndpoint
  } = props;

  const client = React.useMemo(() => {
    const link = createUploadLink({
      uri: graphEndpoint || GraphEndpoint,
      headers: {
        'Apollo-Require-Preflight': 'true'
      }
    });

    const authLink = new ApolloLink((operation, forward) => {
      const authToken = localStorage.getItem(LocalStorage.AuthToken);

      operation.setContext(({ headers }: any) => ({
        headers: {
          ...(headers || []),
          ...(authToken && !unauthenticatedOperations.includes(operation.operationName) && ({
            authorization: authToken
          })),

          'X-BackedFi-App': application,
          'X-BackedFi-BrowserId': localStorage.getItem(LocalStorage.BrowserId)


        }
      }));

      return forward(operation).map((response) => {
        const refreshToken = response.extensions?.RefreshedAuthenticationToken;


        if (refreshToken) {
          authContext.authenticate(refreshToken);
        }

        return response;
      });
    });

    const errorHandler = onError(({
      graphQLErrors,
      networkError
    }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({
          message,
          ...error
        }) =>
          console.error(`[GraphQL error]: Message: ${message}`, error)
        );
      }

      if (networkError) {
        console.error('[Network error]:', networkError);
      }
    });

    return new ApolloClient({
      cache: new InMemoryCache(),
      link: ApolloLink.from([authLink, errorHandler, link as any])
    });
  }, [graphEndpoint]);

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
};
