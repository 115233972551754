import React from 'react';

export const useToggle = (defaultOpen = false) => {
  const [open, setOpen] = React.useState<boolean>(defaultOpen);

  return {
    open,
    setOpen,

    value: open,
    setValue: setOpen,

    setTrue: () => setOpen(true),
    setFalse: () => setOpen(false),

    toggle: () => setOpen((prev) => !prev),

    // ---- For MUI ---- //
    onClose: () => setOpen(false)
  };
};
