import * as React from 'react';
import { forwardRef, Ref, SVGProps } from 'react';

const SvgTransactionSettings = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    data-name='transaction settings icon'
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
    ref={ref}
    {...props}
  >
    <defs>
      <clipPath id='TransactionSettings_svg__a'>
        <path data-name='Rectangle 261' fill='#01000d' d='M0 0h16v16H0z' />
      </clipPath>
    </defs>
    <g
      data-name='Group 832'
      clipPath='url(#TransactionSettings_svg__a)'
      fill='#01000d'
    >
      <path
        data-name='Path 609'
        d='M8.012 16A8.012 8.012 0 1 0 0 7.988 8.021 8.021 0 0 0 8.012 16m0-14.88a6.868 6.868 0 1 1-6.867 6.868 6.873 6.873 0 0 1 6.867-6.867'
      />
      <path
        data-name='Path 610'
        d='M4.578 7.416h6.868a.573.573 0 1 0 0-1.145h-5.8l.551-.827a.572.572 0 1 0-.952-.635L4.1 6.526a.572.572 0 0 0 .476.89'
      />
      <path
        data-name='Path 611'
        d='M4.578 9.705h5.8l-.552.827a.572.572 0 0 0 .952.635l1.145-1.717a.572.572 0 0 0-.476-.89H4.579a.573.573 0 0 0 0 1.145Z'
      />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgTransactionSettings);
export default ForwardRef;
