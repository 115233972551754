import { Theme, tabsClasses } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiTabs: Components<Theme>['MuiTabs'] = {
  styleOverrides: {
    root: () => ({
      boxShadow: 'inset 0 -1px 0 #d4d8e0',

      [`.${tabsClasses.indicator}`]: {
        height: '3px',
        borderTopLeftRadius: '2px',
        borderTopRightRadius: '2px',
        backgroundColor: '#0066ff'
      },
    })
  }
}
