import * as z from 'zod';
import React, { useEffect, useMemo, useState } from 'react';
import { ethers } from 'ethers';
import { useForm } from 'react-hook-form';
import { gql } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { zodResolver } from '@hookform/resolvers/zod';
import { Card, TextField, Typography } from '@mui/material';

import { InfoLabel } from '@backed-fi/compound';
import { BlockchainNetwork, MicroTransactionWalletStrategy, useBlockchainNetworkConfigurationsQuery, useCreateExternalWalletMicroTransactionMutation, useMicroTransactionWalletAddressLazyQuery } from '@backed-fi/graphql';
import { NetworkSelector, NetworkSelectorConfig } from '../../NetworkSelector';

const Graph = gql`
  mutation createExternalWalletMicroTransaction($input: CreateExternalWalletMicroTransactionInput!) {
    createExternalWalletMicroTransaction(input: $input) {
      id

      verifyAmount
      walletAddress
    }
  }

  query microTransactionWalletAddress($network: BlockchainNetwork!) {
    blockchainNetworkConfiguration(network: $network) {
      microTransactionWalletAddress
      nativeCurrency
      microTransactionWalletStrategy
      microTransactionWalletStablecoin {
        stablecoin {
          symbol
        }
        decimals
        address
      }
    }
  }

  query blockchainNetworkConfigurations {
    blockchainNetworkConfigurations {
      chainId
      network
    }
  }
`;

// region Form validation and types

const FormSchema = z.object({
  address: z
    .string()
    .regex(/^0x[a-fA-F0-9]{40}$/, 'The provided address is not valid')
    .nonempty('Please provide wallet address'),

  signature: z.string().nonempty()
});

type FormData = z.infer<typeof FormSchema>;

// endregion

interface Props {
  address?: string,
}

export const VerifyWalletProofOfControlManuallyByMicroTransaction: React.FC<Props> = ({ address }) => {
  const [selectedNetwork, setSelectedNetwork] = useState<NetworkSelectorConfig>();
  const networkConfigurations = useBlockchainNetworkConfigurationsQuery();

  const availableNetworks = useMemo<NetworkSelectorConfig[]>(() => networkConfigurations.data
  ? networkConfigurations.data.blockchainNetworkConfigurations.map(x => ({
    chainId: x.chainId,
    network: x.network
  })).sort((a,b) => a.network === BlockchainNetwork.Ethereum ? -1 : 1)
  : [], [networkConfigurations.data])

  useEffect(() => {
    if(availableNetworks)
      setSelectedNetwork(availableNetworks[0])
  }, [availableNetworks])

  const { register, ...form } = useForm<FormData>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      address
    }
  });

  const [createMicroTransaction, {
    data: microTransaction,
    loading: creatingMicroTransaction
  }] = useCreateExternalWalletMicroTransactionMutation();
  const [getMicroTransactionWalletAddress, {
    data: networkConfiguration,
    refetch
  }] = useMicroTransactionWalletAddressLazyQuery({
    variables: {
      network: selectedNetwork?.network!
    }
  });

  const selectedNetworkTxToken = useMemo(() => networkConfiguration?.blockchainNetworkConfiguration.microTransactionWalletStrategy === MicroTransactionWalletStrategy.Native
  ? networkConfiguration?.blockchainNetworkConfiguration.nativeCurrency
  : networkConfiguration?.blockchainNetworkConfiguration.microTransactionWalletStablecoin?.stablecoin.symbol, [networkConfiguration])

  const selectedNetworkTxTokenDecimals = useMemo(() => networkConfiguration?.blockchainNetworkConfiguration.microTransactionWalletStrategy === MicroTransactionWalletStrategy.Native
  ? 18
  : networkConfiguration?.blockchainNetworkConfiguration.microTransactionWalletStablecoin?.decimals!, [networkConfiguration])

  const onCreate = async () => {
    // Get the address of the wallet from the form
    const address = form.getValues('address');

    // And create the necessary challenge

    await refetch();

    await createMicroTransaction({
      variables: {
        input: {
          walletAddress: address,
          network: selectedNetwork!.network
        }
      }
    });
  };

  return (
    <React.Fragment>
      {!microTransaction && availableNetworks && selectedNetwork && (
        <React.Fragment>
          <Typography variant='modalTitle'>
            Enter Your Address
          </Typography>

          <Typography variant='modalSubtitle'>
            Please follow the steps below to verify your wallet: <br /><br />

            1. Add your wallet in the text field <br />
            2. Send the specified amount from the wallet you want to verify to the wallet, specified in the
            next step <br />
            3. We will automatically detect the transaction and approve your wallet in our system
          </Typography>

          <TextField
            label='Blockchain Address'
            {...register('address')}
            sx={{
              margin: '2rem 0 1rem'
            }}
          />

          <NetworkSelector
            selectedNetwork={selectedNetwork}
            availableNetworks={availableNetworks}
            onNetworkChange={(network) => setSelectedNetwork(network)}
          />
          <LoadingButton
            onClick={onCreate}
            disabled={!selectedNetwork}
            loading={creatingMicroTransaction}
            variant='publicDashboard'
          >
            Save Wallet
          </LoadingButton>
        </React.Fragment>
      )}
      { microTransaction && <React.Fragment>
        <Typography variant='modalTitle'>
          Verify Your Wallet
        </Typography>

        <Typography variant='modalSubtitle'>
          Please follow the steps below to verify your wallet: <br /><br />

          1. Send the specified amount of {selectedNetworkTxToken} to the address you see below <br />
          2. We will automatically detect the transaction and approve your wallet in our system
        </Typography>

        <Card
          sx={{
            display: 'flex',
            marginTop: '2rem',
            flexDirection: 'column',
            padding: '0.75rem 1.5rem',
            backgroundColor: '#f2f4f8',
            border: 0
          }}
        >
          <InfoLabel
            label='Network'
            content={selectedNetwork?.network}
          />

          <InfoLabel
            copy
            label='Amount'
            copyValue={ethers.utils.formatUnits(microTransaction.createExternalWalletMicroTransaction.verifyAmount, selectedNetworkTxTokenDecimals)}
            content={`${ethers.utils.formatUnits(microTransaction.createExternalWalletMicroTransaction.verifyAmount, selectedNetworkTxTokenDecimals)}
             ${selectedNetworkTxToken}`}
          />

          <InfoLabel
            copy
            label='Address'
            content={networkConfiguration?.blockchainNetworkConfiguration?.microTransactionWalletAddress}
          />
        </Card>
      </React.Fragment>
      }
    </React.Fragment>
  );
};
