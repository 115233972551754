import { ClientType } from '@prisma/client';
import { IOnboardingStep } from './onboarding-step-interface';

export const EEAResidency: IOnboardingStep = {
  id: 'eea-residency',
  name: 'EEA Residency',
  blocks: (clientContext) => {
    const content = (() => {
      const contentGenerator = (titlePlaceholder: string, optionsPlaceholder: string) => ({
        subtitle: `${titlePlaceholder} reside within the European Economic Area ?`,
        options: [
          {
            value: 'within-eea',
            content: `Yes, ${optionsPlaceholder} reside within the European Economic Area`
          }, {
            value: 'outside-eea',
            content: `No, ${optionsPlaceholder} not reside within the European Economic Area`
          }
        ]
      });

      switch (clientContext.type) {
        case ClientType.Institution:
          return contentGenerator('Does your company', 'It does');
        case ClientType.Fund:
          return contentGenerator('Does your fund', 'It does');
        case ClientType.Foundation:
          return contentGenerator('Does your foundation', 'It does');
        default:
          return contentGenerator('Do you', 'I do');
      }
    })();

    return [
      {
        type: 'title',
        title: 'EEA Residency',
        subtitle: content.subtitle
      }, {
        type: 'checkbox',
        valueKey: 'in-the-eea',
        options: content.options
      }
    ];
  },
  shouldRenderStep: (data) => {
    return !data['high-risk-jurisdictions-exposure']?.['default']?.includes('true');
  },
  getNextStep: (stepData) => {
    const result = stepData?.['in-the-eea'] ?? [];

    // The client has selected an option and that option is within the EEA
    if (result.length) {
      if (result.includes('within-eea')) {
        return 'annex-1';
      } else {
        return 'us-citizen';
      }
    }

    // If the client has not selected an option don't go to the next step
    return undefined;
  }
};
