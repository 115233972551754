import * as React from 'react';
import { forwardRef, Ref, SVGProps } from 'react';

const SvgRedemptionIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    data-name='redemption icon'
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 31.567 16.156'
    ref={ref}
    {...props}
  >
    <g data-name='redemption icon'>
      <g
        data-name='Ellipse 37'
        transform='rotate(-90 6.26 6.26)'
        fill='none'
        stroke='#01000d'
        strokeWidth={1.4}
      >
        <circle cx={4.241} cy={4.241} r={4.241} stroke='none' />
        <circle cx={4.241} cy={4.241} r={3.541} />
      </g>
      <path
        data-name='Path 606'
        d='M13.845 1.246 9.809 7.685a.728.728 0 0 0 0 .785l4.036 6.44a2.808 2.808 0 0 0 2.39 1.246H27.01a4.327 4.327 0 0 0 4.557-4.039V4.039A4.327 4.327 0 0 0 27.01 0H16.235a2.808 2.808 0 0 0-2.39 1.246Zm13.165.37a2.593 2.593 0 0 1 2.734 2.423v8.078a2.593 2.593 0 0 1-2.734 2.423H16.235a.936.936 0 0 1-.8-.416l-3.787-6.046 3.788-6.047a.936.936 0 0 1 .8-.415Z'
        fill='#01000d'
      />
      <path
        data-name='Line 122'
        fill='none'
        stroke='#01000d'
        strokeLinecap='round'
        strokeWidth={1.5}
        d='M27.263 11.502H16.596'
      />
      <path
        data-name='Line 123'
        fill='none'
        stroke='#01000d'
        strokeLinecap='round'
        strokeWidth={1.5}
        d='M27.263 8.078H16.596'
      />
      <path
        data-name='Line 124'
        fill='none'
        stroke='#01000d'
        strokeLinecap='round'
        strokeWidth={1.5}
        d='M27.263 4.655h-6.058'
      />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgRedemptionIcon);
export default ForwardRef;
