import * as React from 'react';
import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { PageHeader } from '../components/PageHeader';
import { ApiKeysTable } from '../components/ApiKeysTable';
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';

import { useToggle } from '@backed-fi/hooks';
import { InfoLabel } from '@backed-fi/compound';
import { useCreateServiceAccountMutation } from '@backed-fi/graphql';

// region Networking

const Graph = gql`
  mutation createServiceAccount {
    createServiceAccount
  }
`;

// endregion

export const ApiSettingsPage = () => {
  const snackbar = useSnackbar();
  const accessKeyToggle = useToggle();

  // region Networking

  const [createServiceAccount, { data: createdServiceAccount }] = useCreateServiceAccountMutation({
    refetchQueries: [
      'apiSettingsData'
    ]
  });

  // endregion

  // region Actions

  const onCreateServiceAccount = async () => {
    try {
      // Create the service account
      await createServiceAccount();

      // Open the modal, displaying the access key for that service account
      accessKeyToggle.setTrue();
    } catch (e: any) {
      snackbar.enqueueSnackbar(e.message, {
        variant: 'error'
      });
    }
  };

  // endregion

  return (
    <Box>
      <PageHeader
        title='Generated API Keys'
        subtitle='API keys enable you to programmatically retrieve deposit addresses and list your transactions.'
        action={{
          label: 'Generate API Key',
          onClick: onCreateServiceAccount
        }}
      />

      {/* region Key Created Dialog */}

      <Dialog
        fullWidth
        maxWidth='sm'
        {...accessKeyToggle}
      >
        <DialogTitle>
          <Typography
            variant='titleSmall'
          >
            Your new API key
          </Typography>

          <Typography
            variant='subtitleSmall'
          >
            Please save your API key! You won't be able to see it again.
          </Typography>
        </DialogTitle>

        <DialogContent>
          <InfoLabel
            copy
            label='Access Key'
            content={createdServiceAccount?.createServiceAccount}
          />

          <Button
            onClick={accessKeyToggle.setFalse}
            variant='publicDashboard'
            fullWidth
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>


      {/* endregion */}

      {/* region Table with existing keys */}

      <ApiKeysTable />

      {/* endregion */}
    </Box>
  );
};
