import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const OnboardingVerificationPendingPage = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexFlow: 'column'
      }}
    >

      <Typography
        align='center'
        sx={{
          width: '450px',
          mb: '2em'
        }}
      >
        Thank you. <br /><br />
        Your application is complete and pending review.<br /><br />
        We will contact you for additional information if required, and you will receive an email once approved.
      </Typography>
    </Box>
  );
};
