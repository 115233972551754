import { Button } from '@mui/material';
import React from 'react';
import { useWeb3Context } from '../providers/context/Web3Context';


export const ConnectWeb3Button: React.FC<React.ComponentProps<typeof Button>> = ({
  ...props
}) => {
  // ---- Hooks ---- //
  const web3Context = useWeb3Context();


  // ---- Actions ---- //
  const onClick = async () => {
    await web3Context.connectWallet();
  };

  return (
    <Button
      {...props}
      onClick={onClick}
      sx={{
        ...props.sx
      }}
    >
        Connect Wallet
    </Button>
  );
};
