import React from 'react';
import { gql } from '@apollo/client';
import { GetClientContextQuery, useGetClientContextQuery } from '@backed-fi/graphql';
import { useAuthContext } from './AuthContext';

// ---- Client state types ---- //

interface ClientContext {
  loading: boolean;
  refetch: () => Promise<void>;
}

export const ClientContext = React.createContext<ClientContext & GetClientContextQuery['client']>(
  null!
);

const ClientContextQuery = gql`
  query GetClientContext {
    client {
      id

      name
      type
      status
      classification

      jurisdiction
      features
      hasWalletRegistered
      externalWallets {
        address
        status
      }

      isProfessional

      shouldRegisterWallet
      shouldActivateWallet

      verificationProfile {
        currentTier
        approved
      }

      limits {
        tier
        
        dailyTransactionLimit
        monthlyTransactionLimit
      }

      usage {
        issuanceDailyUsage
        redemptionDailyUsage

        issuanceMonthlyUsage
        redemptionMonthlyUsage
      }
    }
  }
`;

const defaultContext: Partial<GetClientContextQuery['client']> = {
  features: []
};

export const ClientContextProvider: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  const authContext = useAuthContext();

  // ---- The Networking ---- //
  const { data, loading, refetch, ...query } = useGetClientContextQuery({
    pollInterval: 1000,
    skip: !authContext.authenticated
  });

  // region Refetching Logic

  React.useEffect(() => {
    const client = data?.client;

    if (client && (client.shouldRegisterWallet || client.shouldActivateWallet)) {
      query.startPolling(1000);
    } else {
      // If the onboarding status of the client is final poll every 10 seconds,
      // otherwise poll every second
      query.startPolling(10000);
    }
  }, [data]);

  // endregion

  // ---- The Networking Trigger ---- //

  return (
    <ClientContext.Provider
      value={{
        ...(data?.client || (defaultContext as any)),
        refetch,
        loading
      }}
      children={children}
    />
  );
};

export const useClientContext = () => {
  return React.useContext(ClientContext);
};
