/* eslint-disable max-len */
import { ClientType } from '@prisma/client';
import { IOnboardingStep } from './onboarding-step-interface';
import { InvestorWarning } from './jersey-investor-warning';

export const Facta: IOnboardingStep = {
  id: 'facta',
  name: 'FACTA',
  blocks: (clientContext, stepData) => {
    return [
      {
        type: 'title',
        title: 'Self-Certification Form for Individuals',
        subtitle: 'Declaration and Information for CRS and FATCA Compliance.'
      }, {
        type: 'title',
        title: '1. Personal Information:',
        margin: true
      }, {
        type: 'textInput',
        label: 'Title (Mr/Ms)',
        valueKey: 'title'
      }, {
        type: 'textInput',
        label: 'First name',
        valueKey: 'firstName'
      }, {
        type: 'textInput',
        label: 'Middle name',
        valueKey: 'middleName'
      }, {
        type: 'textInput',
        label: 'Family name',
        valueKey: 'familyName'
      }, {
        type: 'textInput',
        label: 'Date of birth (DD.MM.YYYY)',
        valueKey: 'dateOfBirth'
      }, {
        type: 'textInput',
        textArea: true,
        label: 'Residential address (street, number, postal code, city, country)',
        valueKey: 'residentialAddress'
      }, {
        type: 'textInput',
        label: 'Nationality (if more than one, list all)',
        valueKey: 'nationality',
        margin: true
      }, {
        type: 'title',
        title: '2. Country/Jurisdiction of Residency for Tax Purposes:'
      }, {
        type: 'paragraph',
        content: 'Please enter all countries in which you are resident for tax purposes. There are cases where you are' +
          'liable to pay tax in more than one place.In this case, it is important that all countries are listed.',
        margin: true
      }, {
        type: 'textInput',
        label: 'Country / Jurisdiction of Residency for Tax Purposes',
        valueKey: 'tax-residency'
      }, {
        type: 'textInput',
        label: 'TIN',
        valueKey: 'tin'
      }, {
        type: 'textInput',
        label: 'Reason for a missing TIN according to the following legend (A-D)',
        valueKey: 'tax-nationality',
        margin: true
      }, {
        type: 'paragraph',
        content: 'Legend: Possible reasons for missing a TIN:\n' +
          'A.My country/ jurisdiction of residence for tax purposes does not assign TINs to its residents.\n' +
          'B.I am a new resident and his/ her TIN is not issued yet (please note that the TIN must be submitted within 90days).\n' +
          'C.Despite the country / jurisdiction of residence for tax purposes generally issuing TINs, I am not required to obtain a TIN.Please specify the reason below:\n' +
          'D.I am otherwise unable to provide a TIN.Please specify the reason below:',
        margin: true
      },
      {
        type: 'title',
        title: '3. US Person:\n' +
          'The term “US Person” comprises any person under a) or b) below; please select as appropriate:'
      },
      {
        type: 'checkbox',
        valueKey: 'us-person',
        options: [
          {
            content: 'a) Are you a US citizen (incl. a person born in the USA or a US Territory?'
          },
          {
            value: 'a',
            content:
              'Yes',
            summaryContent: '(a) I\'m a US citizen ((incl. a person born in the USA or a US Territory)'
          }
        ]
      },
      ...((stepData && stepData['us-person']?.length) ? [{
        type: 'checkbox',
        valueKey: 'us-person-reason',
        options: [
          {
            content: 'b) Are you a US resident because you are any of the following?'
          },
          {
            value: 'b-green-card',
            content:
              'A person in possession of a US Green Card',
            summaryContent: '(b) I\'m a US resident because: a person in possesion of a US Green Card'
          },
          {
            value: 'b-substantial-presence',
            content:
              'A person that meets the substantial presence test',
            summaryContent: '(b) I\'m a US resident because:  a person that meets the substantial presence test'
          },
          {
            value: 'b-resident',
            content:
              'A person resident in the USA for any other reason',
            summaryContent: '(b) I\'m a US resident because: a person resident in the USA for any other reason'
          }
        ]
      }] : [] as any),
      {
        type: 'paragraph',
        content: 'I hereby certify that:',
        sx: {
          borderTop: '1px solid #6E6E6E',
          paddingTop: '16px',
          marginTop: '16px'
        }
      },
      {
        type: 'checkbox',
        valueKey: 'us-tax-person',

        options: [
          {
            value: 'not-us-tax-person',
            content:
              ' I am NOT a US Person for TAX Purposes.'
          },
          {
            value: 'us-tax-person',
            content:
              'I AM a US Person for Tax Purposes and will provide an IRS Form W-9.'
          }
        ]
      },
      {
        type: 'paragraph',
        content: 'Change in Circumstances',
        margin: true,
        sx: {
          textDecoration: 'underline'
        }
      },
      {
        type: 'paragraph',
        content: 'For the duration of the contractual relationship with Backed Assets (JE) Limited, I hereby confirm that I\n' +
          'undertake to notify Backed Assets(JE) Limited within 30 days on my own initiative, if my status for U.S.\n' +
          'tax purposes and/ or my country(ies) / jurisdiction(s) of residence for tax purposes changes.If any\n' +
          'information provided or certification made on this form becomes incorrect, I agree that I will submit a\n' +
          'new form and/ or further necessary forms and documentation within 90 days after such change in circumstances.',
        margin: true
      },
      {
        type: 'paragraph',
        content: 'For the duration of the contractual relationship with Backed Assets (JE) Limited, I hereby confirm that I\n' +
          'undertake to notify Backed Assets(JE) Limited within 30 days on my own initiative, if my status for U.S.\n' +
          'tax purposes and/ or my country(ies) / jurisdiction(s) of residence for tax purposes changes.If any\n' +
          'information provided or certification made on this form becomes incorrect, I agree that I will submit a\n' +
          'new form and/ or further necessary forms and documentation within 90 days after such change in circumstances.',
        margin: true
      },
      {
        type: 'paragraph',
        content: 'In case of any change in circumstances, I further affirm that I am aware that the above-mentioned\n' +
          'relationship with Backed Assets (JE) Limited may be terminated, if I fail to comply with my obligation to\n' +
          'submit the relevant updated documentation required to allow Backed Assets (JE) Limited to comply with\n' +
          'applicable tax information reporting legislation, including Jersey legislation implementing US FATCA and\n' +
          'the OECD\'s CRS.',
        margin: true
      },
      {
        type: 'paragraph',
        content: 'Declaration and Signature',
        margin: true,
        sx: {
          textDecoration: 'underline'
        }
      },
      {
        type: 'paragraph',
        content: 'By signing this form, I declare that all statements made in this form are, to the best of my knowledge and\n' +
          'belief, true, correct and complete.\n',
        margin: true
      },
      {
        type: 'paragraph',
        content: 'I hereby acknowledge and agree that Backed Assets (JE) Limited is required to disclose certain information\n' +
          'in relation to my account to the tax authorities in Jersey, and that information contained in this\n' +
          'certification (including any attachments hereto) may be reported to the tax authorities of Jersey as\n' +
          'required by the CRS or FATCA, and that the Jersey tax authorities may provide the information to the tax\n' +
          'and other authorities of the jurisdiction or jurisdictions in which I am resident for tax purposes. I hereby\n' +
          'explicitly consent to disclose the above information, if required.',
        margin: true
      },
      {
        type: 'paragraph',
        content: 'I am aware that willfully providing incorrect information on a self-certification, not notifying Backed Assets\n' +
          '(JE) Limited about any change in circumstances or providing incorrect information about any change in\n' +
          'circumstances may be subject to penalty.',
        margin: true
      }
    ];
  },
  shouldRenderStep: (data, clientContext) => {
    return clientContext.type === ClientType.Individual;
  },
  getNextStep: (stepData) => {
    if (
      !!stepData['title'] &&
      !!stepData['firstName'] &&
      !!stepData['familyName'] &&
      !!stepData['dateOfBirth'] &&
      !!stepData['residentialAddress'] &&
      !!stepData['nationality'] &&
      !!stepData['tax-residency'] &&
      !!stepData['tin'] &&
      !!stepData['tax-nationality']
    ) {
      return InvestorWarning.id;
    }

    return undefined;
  }
};
