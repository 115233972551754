/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../../../../../common";
import type {
  CallWithExactGas,
  CallWithExactGasInterface,
} from "../../../../../../../@chainlink/contracts-ccip/src/v0.8/shared/call/CallWithExactGas";

const _abi = [
  {
    inputs: [],
    name: "NoContract",
    type: "error",
  },
  {
    inputs: [],
    name: "NoGasForCallExactCheck",
    type: "error",
  },
  {
    inputs: [],
    name: "NotEnoughGasForCall",
    type: "error",
  },
] as const;

const _bytecode =
  "0x60566037600b82828239805160001a607314602a57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600080fdfea264697066735822122024eb88b3c5af45c6b1a6d9e175055033cde079cec4d4697d3b1f9250790b099864736f6c63430008170033";

type CallWithExactGasConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: CallWithExactGasConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class CallWithExactGas__factory extends ContractFactory {
  constructor(...args: CallWithExactGasConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<CallWithExactGas> {
    return super.deploy(overrides || {}) as Promise<CallWithExactGas>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): CallWithExactGas {
    return super.attach(address) as CallWithExactGas;
  }
  override connect(signer: Signer): CallWithExactGas__factory {
    return super.connect(signer) as CallWithExactGas__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): CallWithExactGasInterface {
    return new utils.Interface(_abi) as CallWithExactGasInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): CallWithExactGas {
    return new Contract(address, _abi, signerOrProvider) as CallWithExactGas;
  }
}
