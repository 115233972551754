import * as React from 'react';
import { forwardRef, Ref, SVGProps } from 'react';

const SvgArrowUp = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 16 10.66'
    ref={ref}
    {...props}
  >
    <path
      data-name='Path 136'
      d='M16.009-11.079a.922.922 0 0 1-.2.571L8.7-1.671a.912.912 0 0 1-.711.34.908.908 0 0 1-.709-.34l-7.071-8.8a.911.911 0 0 1 .14-1.28.911.911 0 0 1 1.28.14L7.991-3.7l6.4-7.955a.911.911 0 0 1 1.28-.14.914.914 0 0 1 .338.716Z'
      transform='rotate(180 8.005 -.666)'
      fill='#06f'
      style={{
        mixBlendMode: 'normal',
        isolation: 'isolate'
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(SvgArrowUp);
export default ForwardRef;
