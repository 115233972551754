import React from 'react';
import { Card } from '@mui/material';
import { InfoLabel } from './InfoLabel';

interface InternalProps {
  label: string;
  value: string | number;
}

export const InfoLabelCard: React.FC<
  InternalProps & React.ComponentProps<typeof Card>
> = ({ value, label, ...cardProps }) => (
  <Card
    sx={{
      ...cardProps.sx
    }}
  >
    <InfoLabel label={label} content={value}/>
  </Card>
);
