import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

declare module '@mui/material/Card' {
  interface CardPropsColorOverrides {
    primary: true;
  }
}

export const MuiCard: Components<Theme>['MuiCard'] = {
  defaultProps: {
    variant: 'outlined'
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => {
      const { primary, utility } = theme.palette;

      return {
        color: utility.textHighContrast,
        backgroundColor: utility.background,
        border: '1px solid',
        borderColor: utility.borderSubtle,

        ...(ownerState.color === 'primary' && {
          border: primary.borderElement,
          color: primary.textLowContrast,
          backgroundColor: primary.elementBackground
        })
      };
    }
  }
};
