import React from 'react';
import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Radio } from '@mui/material';

import { useToggle } from '@backed-fi/hooks';
import { FetchAllBankAccountsQuery, FetchDefaultBankAccountQuery, useFetchAllBankAccountsQuery, useFetchDefaultBankAccountQuery, useUpdateDefaultBankAccountMutation } from '@backed-fi/graphql';

import { PageHeader } from './PageHeader';
import { ContentCard } from '../../../components/ContentCard';
import { CreateBankAccountDialog } from './CreateBankAccountDialog';
import { TablePlaceholder } from '../../../components/TablePlaceholder';

// region Graph Declaration

const Graph = gql`
  query fetchDefaultBankAccount {
    clientPreferences {
      defaultBankAccountId
    }
  }

  mutation updateDefaultBankAccount($input: UpdateDefaultBankAccountInput!) {
    updateDefaultBankAccount(input: $input)
  }

  query fetchAllBankAccounts {
    bankAccounts {
      id

      description
      nameOnAccount
      iban
      swift

      bankName
      bankAddress
    }
  }
`;

// endregion

export const BankAccountSection: React.FC = () => {
  const snackbar = useSnackbar();
  const bankAccountDialog = useToggle();

  // region Networking

  // Queries
  const allBankAccountsQuery = useFetchAllBankAccountsQuery();
  const defaultBankAccountQuery = useFetchDefaultBankAccountQuery();

  // Mutations
  const [updateDefaultBankAccount] = useUpdateDefaultBankAccountMutation({
    refetchQueries: [
      'fetchDefaultBankAccount'
    ]
  });

  // endregion

  // region Destructuring

  const { loading } = allBankAccountsQuery
  const { bankAccounts } = (allBankAccountsQuery.data || {}) as FetchAllBankAccountsQuery;
  const { defaultBankAccountId } = (defaultBankAccountQuery.data?.clientPreferences || {}) as FetchDefaultBankAccountQuery['clientPreferences'];

  // endregion

  // region Actions

  const onSetAsDefault = (bankAccountId: string) => async () => {
    // If the bank account is already the default don't do anything
    if (defaultBankAccountId && defaultBankAccountId === bankAccountId) {
      return;
    }

    // If it is change send request to the backend to change it
    try {
      await updateDefaultBankAccount({
        variables: {
          input: {
            bankAccountId
          }
        }
      });

      // Give some feedback to the client
      snackbar.enqueueSnackbar('The default bank account was successfully updated', {
        variant: 'success'
      });
    } catch (e) {
      console.error(e);

      snackbar.enqueueSnackbar('An error occurred while trying to update the default bank account', {
        variant: 'error'
      });
    }
  };

  // endregion

  return (
    <Box>
      <CreateBankAccountDialog
        open={bankAccountDialog.open}
        dismiss={bankAccountDialog.setFalse}
      />

      <PageHeader
        title='Added Bank Accounts'
        subtitle='Adding a bank account enables you to directly withdraw your tokens in either USD, EUR or CHF, without having to pass funds through your wallet. The payout method and currency can be selected in your transaction settings.'
        action={{
          label: 'Add Bank Account',
          onClick: bankAccountDialog.setTrue
        }}
      />

      <ContentCard>
        <TableContainer
          sx={{
            minHeight: '264px'
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Default</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Name on Account</TableCell>
                <TableCell>IBAN / Account Number</TableCell>
                <TableCell>BIC / SWIFT</TableCell>
                <TableCell>Bank Name</TableCell>
                <TableCell>Bank Address</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!bankAccounts?.length && (
                <TablePlaceholder
                  message='No bank account has been added yet.'
                  isLoading={loading}
                />
              )}

              {!!bankAccounts?.length && bankAccounts.map((bankAccount) => (
                <TableRow key={bankAccount.id}>
                  <TableCell>
                    <Box
                      sx={{
                        height: '21px',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Radio
                        checked={defaultBankAccountId === bankAccount.id}
                        onClick={onSetAsDefault(bankAccount.id)}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>{bankAccount.description}</TableCell>
                  <TableCell>{bankAccount.nameOnAccount}</TableCell>
                  <TableCell>{bankAccount.iban}</TableCell>
                  <TableCell>{bankAccount.swift}</TableCell>
                  <TableCell>{bankAccount.bankName}</TableCell>
                  <TableCell>{bankAccount.bankAddress}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ContentCard>
    </Box>
  );
};
