import * as React from 'react';
import { forwardRef, Ref, SVGProps } from 'react';

const SvgNotificationIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    data-name='notifications icon'
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 22.864 24'
    ref={ref}
    {...props}
  >
    <defs>
      <clipPath id='NotificationIcon_svg__a'>
        <path data-name='Rectangle 4' fill='none' d='M0 0h22.864v24H0z' />
      </clipPath>
    </defs>
    <g data-name='Group 291' clipPath='url(#NotificationIcon_svg__a)'>
      <path
        data-name='Path 146'
        d='m22.754 16.2-2.6-9.366A9.316 9.316 0 0 0 2.082 7.3l-2.01 9.048A3 3 0 0 0 3 20h3.6a5 5 0 0 0 9.8 0h3.468a3 3 0 0 0 2.888-3.8ZM11.5 21.995a3 3 0 0 1-2.815-2h5.629a3 3 0 0 1-2.815 2m9.16-4.393a.993.993 0 0 1-.8.395H3a1 1 0 0 1-.975-1.216l2.01-9.045a7.317 7.317 0 0 1 14.193-.372l2.6 9.366a.992.992 0 0 1-.163.873'
      />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgNotificationIcon);
export default ForwardRef;
