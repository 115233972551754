import React from 'react';
import { TextField } from '@mui/material';
import { gql } from '@apollo/client';

import { AvailableDeploymentsQuery, useAvailableDeploymentsQuery } from '@backed-fi/graphql';
import { FieldError, UseFormReturn } from 'react-hook-form';

const GraphQl = gql`
  query availableDeployments {
    tokens {
      id

      name
      symbol

      deployments {
        id
        network
      }
    }
  }
`;

interface Props {
  name: string;
  form: UseFormReturn<any>;
}

export const TokenDeploymentSelect: React.FC<Props> = ({ form, name }) => {
  const { data } = useAvailableDeploymentsQuery();

  // ----- State ----- //
  const [deployments, setDeployments] = React.useState<AvailableDeploymentsQuery['tokens'][0]['deployments']>([]);

  // ---- Destructure ---- //
  const { errors } = form.formState;
  const { tokens } = (data || {}) as AvailableDeploymentsQuery;

  // ----- Actions ----- //
  const onTokenSelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setDeployments(
      tokens?.find(x => x.id === event.target.value)?.deployments ?? []
    );
  };


  return (
    <React.Fragment>
      {tokens && (
        <React.Fragment>
          <TextField
            select
            label='Token'
            onChange={onTokenSelected as any}
          >
            <option
              style={{
                display: 'none'
              }}
            />

            {tokens.map((token) => (
              <option
                key={token.id}
                value={token.id}
              >
                {token.name} ({token.symbol})
              </option>
            ))}
          </TextField>

          <TextField
            select
            label='Network'
            error={!!errors[name]}
            helperText={(errors[name] as FieldError)?.message}
            {...form.register(name)}
          >
            <option
              style={{
                display: 'none'
              }}
            />

            {!deployments.length && (
              <option disabled>
                Select token first
              </option>
            )}

            {deployments.map((deployment) => (
              <option
                key={deployment.id}
                value={deployment.id}
              >
                {deployment.network}
              </option>
            ))}
          </TextField>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
