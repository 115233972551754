import { Theme, backdropClasses } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiBackdrop: Components<Theme>['MuiBackdrop'] = {
  styleOverrides: {
    root: () => ({
      backgroundColor: 'rgba(0, 0, 0, 0.75)',

      [`&.${backdropClasses.invisible}`]: {
        backgroundColor: 'transparent'
      }
    })
  }
}
