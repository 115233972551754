import React from 'react';
import Decimal from 'decimal.js';
import { useClientContext } from './ClientContext';
import { GetClientContextQuery } from '@backed-fi/graphql';

// ---- Client state types ---- //

interface UsageContext {
  loading: boolean;
  usage: {
    daily: Decimal;
    monthly: Decimal;
    dailyPct: Decimal;
    monthlyPct: Decimal;
  }
  limits: GetClientContextQuery['client']['limits']
}

export const UsageContext = React.createContext<UsageContext>(
  null!
);

const defaultContext: Partial<UsageContext> = {
  loading: true
};

export const UsageContextProvider: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  const {
    usage,
    limits,
    loading
  } = useClientContext();

  let value = {

  };
  if (usage && limits) {
    const daily = new Decimal(usage.issuanceDailyUsage).add(usage.redemptionDailyUsage);
    const monthly = new Decimal(usage.issuanceMonthlyUsage).add(usage.redemptionMonthlyUsage);
    const dailyPct = limits.dailyTransactionLimit ?
      daily.div(limits.dailyTransactionLimit).mul(100) : new Decimal(0);
    const monthlyPct = limits.monthlyTransactionLimit ?
      monthly.div(limits.monthlyTransactionLimit).mul(100) : new Decimal(0);

    value = {
      usage: {
        daily,
        monthly,
        dailyPct,
        monthlyPct
      }
    };
  }

  return (
    <UsageContext.Provider
      value={{
        ...(loading ? defaultContext as any : value),
        limits,
        loading
      }}
      children={children}
    />
  );
};

export const useUsageContext = () => {
  return React.useContext(UsageContext);
};
