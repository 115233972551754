import * as React from 'react';
import { forwardRef, Ref, SVGProps } from 'react';

const SvgPersonalDetails = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 16 16'
    ref={ref}
    {...props}
  >
    <defs>
      <clipPath id='PersonalDetails_svg__a'>
        <path
          data-name='Rectangle 31'
          transform='translate(1 1)'
          fill='none'
          d='M0 0h16v16H0z'
        />
      </clipPath>
    </defs>
    <g data-name='personal details icon'>
      <g
        data-name='Group 331'
        clipPath='url(#PersonalDetails_svg__a)'
        transform='translate(-1 -1)'
      >
        <path
          data-name='Path 191'
          d='M8.5 1.5a7.183 7.183 0 0 0-7 7 7.177 7.177 0 0 0 7 7 7.179 7.179 0 0 0 7-7 7.183 7.183 0 0 0-7-7m0 13.2a6.086 6.086 0 0 1-3.575-1.211 3.579 3.579 0 0 1 7.15 0A6.086 6.086 0 0 1 8.5 14.7M6.511 7.3a1.989 1.989 0 1 1 1.989 2 1.991 1.991 0 0 1-1.989-1.993m6.289 5.566a4.385 4.385 0 0 0-2.712-3.282 2.784 2.784 0 1 0-3.177 0A4.385 4.385 0 0 0 4.2 12.873 6.21 6.21 0 0 1 2.3 8.5a6.367 6.367 0 0 1 6.2-6.2 6.367 6.367 0 0 1 6.2 6.2 6.21 6.21 0 0 1-1.9 4.373'
          fill='#01000d'
          stroke='#01000d'
          strokeWidth={0.4}
        />
      </g>
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgPersonalDetails);
export default ForwardRef;
