import React, { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { styled } from '@mui/material/styles';
import { TokenDeployment, TransactionFeesQuery, useTransactionFeesQuery } from '@backed-fi/graphql';

import { Box, Table, TableBody, TableCell as MuiTableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { NetworkSelector, NetworkSelectorConfig } from '../../../components/NetworkSelector';
import { ContentCard } from '../../../components/ContentCard';
import { SettingsGroup } from './SettingsGroup';
import { SharedConfiguration } from '@backed-fi/shared/config/shared';

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const Graph = gql`
  query transactionFees {
    tokens {
      name
      symbol

      feeConfiguration {
        id

        issuanceInitialFee
        issuanceMinimumFee
        issuancePercentageFee

        redemptionInitialFee
        redemptionMinimumFee
        redemptionPercentageFee
      }

      deployments {
        network
        chainId
      }
    }
  }
`;

const TableCell = styled(MuiTableCell)(() => ({
  padding: '0.75rem 1rem'
}));

export const AccountUsageSection: React.FC = () => {
  const { data } = useTransactionFeesQuery();
  const { tokens } = (data || {}) as TransactionFeesQuery;
  const [availableNetworks, setAvailableNetworks] = useState<NetworkSelectorConfig[]>([]);
  // TODO
  const [selectedNetwork, setSelectedNetwork] = useState<NetworkSelectorConfig>();

  useEffect(() => {
    if (!selectedNetwork && availableNetworks.length > 0) {
      setSelectedNetwork(availableNetworks[0]);
    }
  }, [availableNetworks]);

  useEffect(() => {
    if (tokens) {
      for (const { deployments } of tokens) {
        for (const { network, chainId } of deployments) {
          setAvailableNetworks((availableNetworks) => {
            return availableNetworks.findIndex((n) => n.chainId === chainId) < 0
              ? [...availableNetworks, { network,
                chainId }]
              : availableNetworks;
          }
          );
        }
      }
    }
  }, [tokens]);

  return (
    <SettingsGroup
      title="Transaction Fees"
    >
      { selectedNetwork && <Box>
        <NetworkSelector
          onNetworkChange={(network) => setSelectedNetwork(network)}
          availableNetworks={availableNetworks}
          selectedNetwork={selectedNetwork}
          variant='outline'
          sx={{
            width: '100%',
            height: '48px',
            marginBottom: '1rem',
            padding: '0.75rem 1rem',
            borderRadius: '0.5rem'
          }}
        />

        <ContentCard
          variant='outline'
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product</TableCell>
                  <TableCell>Fee per Issuance</TableCell>
                  <TableCell>Fee per Redemption</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { tokens?.map((token) => {
                  const { feeConfiguration } = token;

                  return feeConfiguration && (
                    <TableRow key={token.symbol}>
                      <TableCell>
                        <Box
                          sx={{
                            gap: '0.5rem',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <Box
                            src={`/assets/icons/assets/${token.symbol}.svg`}
                            component="img"
                            width={24}
                            height={24}
                          />
                          <Typography
                            sx={{
                              fontSize: 14,
                              fontWeight: 700
                            }}
                          >
                            {token.symbol}
                          </Typography>
                          <Typography
                            sx={{
                              color: '#848a98',
                              fontSize: 12
                            }}
                          >
                            {token.name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ fontSize: 14 }}>
                          {feeConfiguration.issuancePercentageFee * 100}%
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ fontSize: 14 }}>
                          {feeConfiguration.redemptionPercentageFee * 100}%
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </ContentCard>
      </Box>
      }

      <Typography
        sx={{
          marginTop: 'auto',
          maxWidth: '400px',
          color: '#848a98',
          fontSize: 12
        }}
      >
        Minimum Fee per Transaction: CHF 100.00<br />
        Minimum Transaction Size: {SharedConfiguration.limits.minimum.display}<br /><br />
        Transactions with a lower value will automatically be reverted, and a fee of CHF 50.00 will be charged to cover
        operational costs.
      </Typography>
    </SettingsGroup>
  );
};
