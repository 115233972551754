import React from 'react';
import { gql } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';

import { BlockchainNetwork, LoadClientPreferencesQuery, PayoutMethod, RedemptionWalletQuery, useLoadClientPreferencesQuery, useRedemptionWalletQuery } from '@backed-fi/graphql';

import { ContentCard } from '../../../components/ContentCard';
import { AssetSelector } from '../components/AssetSelector';
import { TransactionCard } from '../components/TransactionCard';
import { TransactionForm } from '../components/TransactionForm';
import { NetworkSelector, NetworkSelectorConfig } from '../../../components/NetworkSelector';
import { useWeb3Context } from '@backed-fi/shared/components/providers/context/Web3Context';

// region Graph

gql`
  query redemptionWallet($network: BlockchainNetwork!) {
    client {
      redemptionWallet(network: $network) {
        id

        address

        networks
      }
    }
    tokens {
      id
      name
      symbol
      minimumTransactionValue
      deployments {
        id
        network
        chainId
        address
      }
    }

  }
`;

// endregion

export const RedeemHomePage: React.FC = () => {
  const [search] = useSearchParams();
  const web3Context = useWeb3Context();


  const clientPreferencesQuery = useLoadClientPreferencesQuery();


  const [isLoading, setLoading] = React.useState(true);
  const [receiveAssetSymbol, setReceiveAssetSymbol] = React.useState('');
  const [selectedNetwork, setSelectedNetwork] = React.useState<NetworkSelectorConfig>();
  const [availableNetworks, setAvailableNetworks] = React.useState<NetworkSelectorConfig[]>([]);
  const [selectedAsset, setSelectedAsset] = React.useState<RedemptionWalletQuery['tokens'][0]>();
  const [tokenConfig, setTokenConfig] = React.useState<{ address: string, decimals: number }>();

  const redemptionWalletQuery = useRedemptionWalletQuery({
    variables: {
      network: selectedNetwork?.network ?? BlockchainNetwork.Ethereum
    }
  });

  const { client } = (redemptionWalletQuery.data || {}) as RedemptionWalletQuery;
  const { clientPreferences } = (clientPreferencesQuery.data || {}) as LoadClientPreferencesQuery;
  const { tokens } = (redemptionWalletQuery.data || redemptionWalletQuery.previousData || {}) as RedemptionWalletQuery;


  // Set selected token from URL query parameter if provided, otherwise default to first
  // Example: /redeem?token=bCOIN
  React.useEffect(() => {
    if (tokens) {
      const tokenParam = search.get('token');

      if (tokenParam) {
        for (const token of tokens) {
          if (token.symbol === tokenParam) {
            setSelectedAsset(token);
            return;
          }
        }
      }

      setSelectedAsset(tokens[0]);
    }
  }, [tokens, search]);

  // Update networks when selected asset changes
  React.useEffect(() => {
    if (selectedAsset) {
      setAvailableNetworks(
        selectedAsset.deployments
          .map((deployment: any) => ({
            network: deployment.network,
            chainId: deployment.chainId
          }))
      );

      const newSelectedNetwork = selectedAsset.deployments
        .find((d) => {
          if (web3Context.connected) {
            return d.chainId === web3Context.chainId;
          } else {
            return selectedNetwork && d.network === selectedNetwork.network;
          }
        }) || selectedAsset.deployments[0];

      if (newSelectedNetwork) {
        if (newSelectedNetwork.chainId !== selectedNetwork?.chainId) {
          redemptionWalletQuery
            .refetch({
              network: newSelectedNetwork.network
            })
            .then(() => {
              setSelectedNetwork({
                network: newSelectedNetwork.network,
                chainId: newSelectedNetwork.chainId
              });
            });
        }

        setTokenConfig({
          address: newSelectedNetwork.address!,
          decimals: 18
        });
      }
    }
  }, [selectedAsset, web3Context.chainId]);

  React.useEffect(() => {
    if (selectedNetwork && web3Context.connected) {
      web3Context.switchNetwork(selectedNetwork.chainId);
    }
  }, [selectedNetwork]);

  React.useEffect(() => {
    if (!redemptionWalletQuery.loading && !clientPreferencesQuery.loading) {
      setLoading(false);
    }
  }, [redemptionWalletQuery, clientPreferencesQuery]);

  // Update receive symbol when client's preferences or selected stablecoin change
  React.useEffect(() => {
    if (clientPreferences) {
      setReceiveAssetSymbol(
        clientPreferences.payoutMethod === PayoutMethod.BankPayout
          ? clientPreferences.bankAccountPayoutCurrency
          : clientPreferences.defaultStablecoin!.symbol
      );
    }
  }, [clientPreferences]);

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <TransactionCard
        title="Redeem Tokens"
        subtitle="Please send your Backed issued tokens to the address below."
      >
        {(isLoading || !selectedAsset || !selectedNetwork) && (
          <ContentCard
            variant="outline"
            sx={{
              height: '256px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CircularProgress size={32} />
          </ContentCard>
        )}

        {!isLoading && selectedAsset && selectedNetwork && (
          <TransactionForm
            sendAsset={{
              network: selectedNetwork.network,
              symbol: selectedAsset.symbol,
              type: 'BackedTokenImplementation',
              address: tokenConfig!.address,
              decimals: tokenConfig!.decimals
            }}
            receiveAsset={{
              network: selectedNetwork.network,
              symbol: receiveAssetSymbol,
              iconName:
                clientPreferences.payoutMethod === PayoutMethod.BankPayout
                  ? 'currency'
                  : clientPreferences.defaultStablecoin!.symbol
            }}
            transactionMinimum={selectedAsset.minimumTransactionValue}
            network={selectedNetwork.network}
            address={client?.redemptionWallet?.address}
            message={
              <React.Fragment>
                Sending{' '}
                <Box component="span" fontWeight={700}>
                  {selectedAsset.symbol}
                </Box>{' '}
                to the address above initiates a redemption process. Determined
                by your transaction settings, you will receive{' '}
                <Box component="span" fontWeight={700}>
                  {receiveAssetSymbol}
                </Box>{' '}
                in your{' '}
                {clientPreferences.payoutMethod === PayoutMethod.BankPayout
                  ? 'default bank account'
                  : 'verified wallet'}
                .
              </React.Fragment>
            }

            sendChildren={
              <AssetSelector
                selectedAsset={selectedAsset}
                availableAssets={tokens}
                onAssetChange={(token) => setSelectedAsset(token as RedemptionWalletQuery['tokens'][0])}
              />
            }

            receiveChildren={(
              clientPreferences.payoutMethod === PayoutMethod.BankPayout
                ? (
                  <Typography
                    sx={{
                      padding: '0.25rem 1rem',
                      color: '#848a98',
                      fontSize: 12,
                      border: 1,
                      borderColor: '#edeff4',
                      borderRadius: '1rem'
                    }}
                  >
                    Payout method: Bank Account
                  </Typography>
                ) : (
                  <NetworkSelector
                    selectedNetwork={selectedNetwork}
                    availableNetworks={availableNetworks}
                    onNetworkChange={(network) => setSelectedNetwork(network)}
                  />
                )
            )}
          />
        )}
      </TransactionCard>
    </Box>
  );
};
