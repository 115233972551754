import React from 'react';
import { useOnboardingContext } from '@backed-fi/app/src/app/domain/Onboarding/OnboardingContext';
import { Box, TextField, TextFieldProps } from '@mui/material';

export interface TextInputBlock {
  /**
   * Label, describing the data that is being input
   */
  label: string;

  /**
   * Should it be text area.
   */
  textArea?: boolean;
  /**
   * Extra information about the data that is being input
   */
  helperText?: string;

  /**
   * The key that will be used to store the client
   * input data in the context
   */
  valueKey: string;
}

export const OnboardingTextInputBlock: React.FC<TextInputBlock> = ({
  label,
  textArea,
  helperText,
  valueKey
}) => {
  const {
    setData,
    getData
  } = useOnboardingContext();

  // region Actions

  const onInputChange: TextFieldProps['onChange'] = (e) => {
    setData(valueKey, e.target.value);
  };

  // endregion

  return (
    <Box>
      <TextField
        fullWidth
        {...textArea ? {
          multiline: true,
          rows: 4
        } : {}}
        label={label}
        helperText={helperText}
        onChange={onInputChange}
        defaultValue={getData(valueKey)}
      />
    </Box>
  );
};
