import React from 'react';

interface Props {
  /**
   * Allow the component rendering when running locally
   */
  local?: boolean;

  /**
   * Allow the component rendering when running in the deployed development environment
   */
  dev?: boolean;

  /**
   * Allow the component rendering when running in the deployed staging environment
   */
  stage?: boolean;

  /**
   * Allow the component rendering when running in the deployed prod environment
   */
  prod?: boolean;


  /**
   * Allow the component rendering when running in the deployed sandbox environment
   */
  sandbox?: boolean;
}

// ----- Config ----- //
const LocalRoutes = ['127.0.0.1', 'localhost'];

const DevRoutes = [
  'app.dev.backed.fi',
  'admin.dev.backed.fi'
];

const StageRoutes = [
  'app.stage.backed.fi',
  'admin.stage.backed.fi'
];

const SandboxRoutes = [
  'app.sandbox.backed.fi',
  'admin.sandbox.backed.fi'
];

export const DomainRestricted: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  // ----- State ----- //
  const [render, setRender] = React.useState<boolean>(false);

  // ----- Effects ----- //
  React.useEffect(() => {
    // Check for local
    if (props.local) {
      setRender(
        (render) =>
          render ||
          LocalRoutes.some((route) => route.includes(location.hostname))
      );
    }

    // Check for development
    if (props.dev) {
      setRender(
        (render) =>
          render || DevRoutes.some((route) => route.includes(location.hostname))
      );
    }

    // Check for sandbox
    if (props.sandbox) {
      setRender(render => render || SandboxRoutes.some((route) => route.includes(location.hostname)));
    }

    // Check for staging
    if (props.stage) {
      setRender(
        (render) =>
          render ||
          StageRoutes.some((route) => route.includes(location.hostname))
      );
    }
  }, [location.hostname]);

  return !render ? null : <>{props.children}</>;
};
