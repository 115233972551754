import React from 'react';

import { ButtonBase, ButtonBaseProps } from '@mui/material';

export const TextButton: React.FC<ButtonBaseProps> = ({ sx, children, ...props }) => {
  return (
    <ButtonBase
      disableRipple
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        color: '#0066ff',
        gap: '0.25rem',
        fontSize: 14,
        fontWeight: 500,

        '&:hover': {
          textDecoration: 'underline'
        },

        ...sx
      }}
      {...props}
    >
      {children}
    </ButtonBase>
  )
}
