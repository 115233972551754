import React from 'react';

import { PublicPages } from './domain/Pages';
import { BackedProvider } from '@backed-fi/providers';

export const App = () => {
  return (
    <BackedProvider
      pages={PublicPages}
      application='BackedFi.Main'
    />
  );
};

export default App;
