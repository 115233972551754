import { IOnboardingStep } from './onboarding-step-interface';

export const Rejected: IOnboardingStep = {
  id: 'rejected',
  name: 'Rejected',
  blocks: () => [
    {
      type: 'redirect',
      path: '/onboarding/questionnaire/completed?result=rejected'
    }
  ],
  shouldRenderStep: () => false,
  getNextStep: () => ''
};
