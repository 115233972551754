import React from 'react';
import { gql } from '@apollo/client';

import { GetUserContextQuery, useGetUserContextQuery } from '@backed-fi/graphql';
import { useAuthContext } from './AuthContext';

// ---- User state types ---- //

interface UserContextType {
  // Boolean, indicating whether the context has fully loaded
  loaded: boolean;

  // Allows you to trigger manual refresh of the user's data
  refetch: () => Promise<GetUserContextQuery>;

  user?: GetUserContextQuery['user'];
}

const UserContext = React.createContext<UserContextType>(null!);

gql`
  query GetUserContext {
    user {
      id

      firstName
      lastName

      email

      features
      
      hasPasswordSet
      passwordLastChange
    }
  }
`;

export const UserContextProvider: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  const authContext = useAuthContext();

  // region State

  const defaultContextData: Partial<UserContextType> = {
    loaded: false,

    refetch: async () => (await query.refetch()).data
  };

  const [userContextState, setUserContextState] = React.useState<UserContextType>(defaultContextData as UserContextType);

  // endregion

  // region Networking

  const query = useGetUserContextQuery({
    pollInterval: document.hidden ? undefined : 1000 * 60, // Refresh the user data every minute
    skip: !authContext.authenticated
  });

  // endregion

  // region Effects

  // Update the context on graphql data changes
  React.useEffect(() => {
    // Update the context only if the query has loaded
    if (query.data) {
      const { user } = query.data;

      setUserContextState((prevState) => ({
        ...prevState,

        loaded: true,

        // Set those properties only if the user is registered
        ...(user && {
          user
        })
      }));
    }
  }, [query.data]);

  // endregion

  return (
    <UserContext.Provider
      value={userContextState}
      children={children}
    />
  );
};

export const useUserContext = () => React.useContext(UserContext);
