import { Components } from '@mui/material/styles/components';
import { tablePaginationClasses, Theme } from '@mui/material';

export const MuiTablePagination: Components<Theme>['MuiTablePagination'] = {
  styleOverrides: {
    root: ({ theme }) => {
      const { utility } = theme.palette;

      return {
        color: utility.textHighContrast,

        [`.${tablePaginationClasses.selectIcon}`]: {
          color: utility.textHighContrast
        },

        [`.${tablePaginationClasses.actions} .MuiSvgIcon-root`]: {
          color: utility.textHighContrast
        }
      };
    }
  }
};
