import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';

interface Props {
  description?: string
}

export const LoaderSection: React.FC<Props> = ({ description }) => {
  return (
    <Box
      sx={{
        my: '4rem',
        gap: '1rem',
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        textAlign: 'center'
      }}
    >
      <CircularProgress />

      <Box>
        <Typography variant='titleSmall'>
          Loading
        </Typography>

        {description && (
          <Typography variant='subtitleSmall'>
            {description}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
