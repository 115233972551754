import { useParams } from 'react-router-dom';
import { useLocationState } from './useLocationState';

type Params = {
  clientId: string;
};

export const useClientId = () => {
  const params = useParams<Params>();
  const state = useLocationState<Params>();

  return params.clientId ?? state.clientId;
};
