import React from 'react';
import { BlockchainNetwork } from '@backed-fi/graphql';
import { Box } from '@mui/material';

interface Props {
  network: BlockchainNetwork;
  label?: string | React.ReactElement;
  size?: number;
}

export const BlockchainNetworkLabel: React.FC<Props> = ({
  network,
  label,
  size = 16
}) => {
  return (
    <Box
      sx={{
        gap: '0.5rem',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Box
        src={`/assets/icons/assets/native/${network}.svg`}
        component='img'
        width={size}
        height={size}
      />

      {label || network}
    </Box>
  );
};
