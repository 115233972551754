import React from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Box, Skeleton, SxProps, Theme, Tooltip, Typography } from '@mui/material';

import CopyIcon from '@mui/icons-material/ContentCopyRounded';
import EditIcon from '@mui/icons-material/EditNoteRounded';

interface InternalProps {
  label: string;
  content?: string | number | React.ReactElement | null;

  sx?: SxProps<Theme>;

  copy?: boolean;
  copyValue?: string;

  to?: string;
  /**
   * Boolean, indicating if the `to` address is external link
   */
  externalLink?: boolean;

  tooltip?: string;

  /**
   * Optional callback function triggered when the user clicks on the edit button.
   * Providing this function will render the edit button.
   */
  onEdit?: () => void;
}

export const InfoLabel: React.FC<InternalProps> = ({
  label,
  content,
  sx,
  copy,
  copyValue,
  tooltip,
  to,
  externalLink,
  onEdit
}) => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  return (
    <Box
      sx={{
        my: '.75rem',
        maxWidth: '100%',
        overflowWrap: 'anywhere',
        ...sx,

        ...((to) && {
          cursor: 'pointer'
        })
      }}
      onClick={async () => {
        if (to && content) {
          if (externalLink) {
            window.open(to, '_blank');
          } else {
            navigate(to);
          }
        }
      }}
    >
      <Typography variant="label">
        {tooltip ? (
          <Tooltip placement="top" title={tooltip}>
            <Box
              component="span"
              sx={{
                maxWidth: '100%',
                wordBreak: 'break-all'
              }}
            >
              {label}
            </Box>
          </Tooltip>
        ) : (
          label
        )}
      </Typography>

      <Typography
        variant="labelBig"
        sx={{
          verticalAlign: 'middle'
        }}
      >
        {content || typeof content === 'number' ? (
          <>
            {content}

            {copy && (
              <CopyIcon
                fontSize="inherit"
                onClick={async () => {
                  if (copy) {
                    if (typeof content === 'string' || copyValue) {
                      // If copyValue is not truthy, content must be of type string
                      await navigator.clipboard.writeText(copyValue ?? content as string);

                      snackbar.enqueueSnackbar(`Copied ${label?.toLowerCase()}`);
                    } else {
                      snackbar.enqueueSnackbar('Cannot copy', {
                        variant: 'error'
                      });
                    }
                  }
                }}
                sx={{
                  ml: '.5rem',
                  cursor: 'pointer'
                }}
              />
            )}

            {onEdit && (
              <EditIcon
                fontSize="inherit"
                onClick={onEdit}
                sx={{
                  ml: '.5rem',
                  cursor: 'pointer'
                }}
              />
            )}
          </>
        ) : (
          <Skeleton
            animation="wave"
            variant="text"
            sx={{
              maxWidth: '200px'
            }}
          />
        )}
      </Typography>
    </Box>
  );
};
