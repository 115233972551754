import { Box, TextField } from '@mui/material';
import { z } from 'zod';
import React from 'react';
import { useUserContext } from '@backed-fi/context';
import { Title } from '@backed-fi/compound';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import LoadingButton from '@mui/lab/LoadingButton';
import { gql } from '@apollo/client';
import { useSetUserPasswordMutation } from '@backed-fi/graphql';
import { useSnackbar } from 'notistack';


// region Schema

const Schema = z.object({
  newPassword: z.string()
    .regex(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W).{8,}$/,
      'The password must include one uppercase, one lowercase, ' +
      'one number and one special character'
    ),
  oldPassword: z.string()
    .optional()
});

// endregion

// region Graph

gql`
  mutation setUserPassword($input: SetUserPasswordInput!) {
    setUserPassword(input: $input)
  }
`;

// endregion

export const SetPasswordDialog = () => {
  const snackbar = useSnackbar();
  const userContext = useUserContext();


  // region Networking

  const [setUserPasswordMutation] = useSetUserPasswordMutation();

  // endregion

  // region Form Control

  const form = useForm<z.infer<typeof Schema>>({
    resolver: zodResolver(Schema)
  });

  const {
    errors,
    isSubmitting
  } = form.formState;

  // endregion

  // region Actions

  const onSetPassword = form.handleSubmit(async (data) => {
    await setUserPasswordMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        'GetUserContext'
      ],
      variables: {
        input: data
      }
    });

    snackbar.enqueueSnackbar('Password successfully set', {
      variant: 'success'
    });

    form.reset({
      newPassword: '',
      oldPassword: ''
    });
  });

  // endregion

  return (
    <Box
      sx={{
        '& .MuiFormControl-root': {
          backgroundColor: 'transparent'
        }
      }}
    >
      <Title
        title={`${userContext.user?.hasPasswordSet ? 'Update' : 'Set'} Password`}
        subtitle={
          userContext.user?.hasPasswordSet
            ? 'Update your current password'
            : 'Add password to your account'
        }
      />

      <Box
        sx={{
          my: '.5rem'
        }}
      />

      {userContext.user?.hasPasswordSet && (
        <TextField
          fullWidth
          label="Old Password"
          type="password"
          helperText={errors.oldPassword?.message}
          error={Boolean(errors.oldPassword)}
          {...form.register('oldPassword')}
        />
      )}

      <TextField
        fullWidth
        label="New Password"
        type="password"
        helperText={errors.newPassword?.message}
        error={Boolean(errors.newPassword)}
        {...form.register('newPassword')}
      />

      <LoadingButton
        onClick={onSetPassword}
        loading={isSubmitting}
        sx={{
          float: 'right'
        }}
      >
        {userContext.user?.hasPasswordSet ? 'Update' : 'Set'} Password
      </LoadingButton>
    </Box>
  );
};
