import { Theme, tableCellClasses } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiTableCell: Components<Theme>['MuiTableCell'] = {
  styleOverrides: {
    root: () => ({
      whiteSpace: 'nowrap',

      [`&.${tableCellClasses.head}`]: {
        fontSize: 12,
        color: '#848a98',
        borderBottom: '1px solid #edeff4'
      },

      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        borderBottom: 'none'
      }
    })
  }
}
