import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';

export interface TitleBlock {
  title: string;
  tooltip?: string;
  subtitle?: string;
}


export const OnboardingTitleBlock: React.FC<TitleBlock> = ({
  title,
  tooltip,
  subtitle
}) => {
  return (
    <Box
      sx={{
        mt: '.5rem',
        mb: '1rem'
      }}
    >
      <Tooltip title={tooltip}>
        <Typography variant="title">
          {title}
        </Typography>
      </Tooltip>

      {subtitle && (
        <Typography variant="subtitle">
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};
