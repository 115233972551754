import React from 'react';
import { Route } from 'react-router-dom';

import { OnboardingLayout } from './OnboardingLayout';


import { OnboardingTerms } from './pages/Creation/OnboardingTerms';
import { OnboardingCreation } from './pages/Creation/OnboardingCreation';
import { OnboardingContactPage } from './pages/Creation/OnboardingContactPage';
import { Protector } from '@backed-fi/compound';
import { OnboardingVerificationPage } from './pages/OnboardingVerificationPage';
import { OnboardingVerificationPendingPage } from './pages/OnboardingVerificationPendingPage';
import { OnboardingQuestionnairePage } from '@backed-fi/app/src/app/domain/Onboarding/pages/OnboardingQuestionnairePage';
import { OnboardingContextProvider } from '@backed-fi/app/src/app/domain/Onboarding/OnboardingContext';
import { OnboardingSortingPage } from '@backed-fi/app/src/app/domain/Onboarding/pages/OnboardingSortingPage';
import { OnboardingQuestionnaireResultPage } from '@backed-fi/app/src/app/domain/Onboarding/pages/OnboardingQuestionnaireResultPage';
import { RejectedPage } from '@backed-fi/app/src/app/domain/Onboarding/pages/Status/RejectedPage';
import { OnboardingQuestionnaireSummaryPage } from './pages/OnboardingQuestionnaireSummaryPage';

const VerificationRoutes = (
  <Route
    path="verification"
  >
    <Route
      index
      element={
        <Protector>
          <OnboardingVerificationPage />
        </Protector>
      }
    />

    <Route
      path="pending"
      element={
        <OnboardingVerificationPendingPage />
      }
    />
  </Route>
);


export const OnboardingRoutes = (
  <Route
    element={(
      <OnboardingContextProvider>
        <OnboardingLayout />
      </OnboardingContextProvider>
    )}
  >
    <Route
      path="rejected"
      element={(
        <RejectedPage />
      )}
    />

    <Route path="onboarding">
      {/*
          // No one should be navigated to /onboarding from anywhere
          // within the site. But if someone manually navigates here
          // make sure to correct their mistake
        */}
      <Route
        index
        element={
          <OnboardingSortingPage />
        }
      />

      {VerificationRoutes}

      <Route
        path="explainer"
        element={<OnboardingContactPage />}
      />

      <Route
        path="terms"
        element={
          <OnboardingTerms />
        }
      />

      <Route
        path="creation"
        element={
          <OnboardingCreation />
        }
      />

      <Route
        index
        path="questionnaire"
        element={
          <Protector>
            <OnboardingQuestionnairePage />
          </Protector>
        }
      />

      <Route
        index
        path="questionnaire/summary"
        element={
          <Protector>
            <OnboardingQuestionnaireSummaryPage />
          </Protector>
        }
      />

      <Route
        path="questionnaire/completed"
        element={
          <Protector>
            <OnboardingQuestionnaireResultPage />
          </Protector>
        }
      />
    </Route>
  </Route>
);
