import React from 'react';
import { gql } from '@apollo/client';
import { useUsageContext } from '@backed-fi/context';
import { CentimesFormatter } from '@backed-fi/shared';

import { Box } from '@mui/material';
import { AccountDetailsQuery, ClientType, useAccountDetailsQuery } from '@backed-fi/graphql';
import { SettingsGroup } from './SettingsGroup';
import { AccountLabel } from './AccountLabel';

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const Graph = gql`
  query accountDetails {
    client {
      id
      name
      type
      jurisdiction
    }
    user {
      id
      firstName
      lastName
      email
    }
  }
`;

export const AccountUserSection: React.FC = () => {
  const { data } = useAccountDetailsQuery();
  const { client, user } = (data || {}) as AccountDetailsQuery;

  const {
    usage,
    limits,
    loading
  } = useUsageContext();

  return (
    <Box
      sx={{
        gap: '1.5rem',
        display: 'flex',
        minHeight: '100%',
        flexDirection: 'column'
      }}
    >
      {client?.type === ClientType.Institution && (
        <SettingsGroup
          title='Company Information'
        >
          <AccountLabel
            label='Organization Name'
          >
            {client?.name}
          </AccountLabel>

          <AccountLabel
            label='Organization Country'
          >
            {client?.jurisdiction}
          </AccountLabel>
        </SettingsGroup>
      )}

      <SettingsGroup
        title='Personal Information'
      >
        <AccountLabel
          label='First Name'
        >
          {user?.firstName}
        </AccountLabel>

        <AccountLabel
          label='Last Name'
        >
          {user?.lastName}
        </AccountLabel>

        <AccountLabel
          label='Email Address'
        >
          {user?.email}
        </AccountLabel>
      </SettingsGroup>

      <SettingsGroup
        title='Transaction Limits'
      >
        {!loading && (
          <>
            <AccountLabel
              label="Daily Limit"
            >
              {CentimesFormatter.format(usage.daily.toNumber())}
              {' '}of{' '}
              {CentimesFormatter.format(limits.dailyTransactionLimit)}
              {' '}
              ({Math.round(usage.dailyPct.toNumber())}%)
            </AccountLabel>

            <AccountLabel
              label="Monthly Limit"
            >
              {CentimesFormatter.format(usage.monthly.toNumber())}
              {' '}of{' '}
              {CentimesFormatter.format(limits.monthlyTransactionLimit)}
              {' '}
              ({Math.round(usage.monthlyPct.toNumber())}%)
            </AccountLabel>
          </>
        )}
      </SettingsGroup>
    </Box>
  );
};
