import React from 'react';
import { gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { useHasFeature } from '@backed-fi/hooks';
import { useClientContext } from '@backed-fi/context';

import { BlockchainNetwork, ClientFeature, DepositPageIssuanceDataQuery, DepositPageRedemptionDataQuery, useDepositPageIssuanceDataLazyQuery, useDepositPageRedemptionDataLazyQuery } from '@backed-fi/graphql';


import { PageHeader } from '../components/PageHeader';
import { DepositToken } from '../components/DepositToken';
import { ContentCard } from '../../../components/ContentCard';
import { SharedConfiguration } from '@backed-fi/shared/config/shared';

gql`
  query depositPageRedemptionData($network: BlockchainNetwork!) {
    client {
      redemptionWallet(network: $network) {
        id

        address
      }
    }
  }

  query depositPageIssuanceData {
    tokens {
      id
      name
      symbol

      deployments {
        network
        stablecoinConfigs {
          symbol
        }
        sweepingWallet {
          id
          address
        }
      }
    }
  }
`;

export const DepositPage: React.FC = () => {
  const navigate = useNavigate();
  const clientContext = useClientContext();
  const hasIssuance = useHasFeature(ClientFeature.Issuance);
  const hasRedemption = useHasFeature(ClientFeature.Redemption);

  const {
    shouldRegisterWallet,
    shouldActivateWallet
  } = clientContext;
  const canClientIssue = hasIssuance && !(shouldRegisterWallet || shouldActivateWallet);
  const canClientRedeem = hasRedemption && !(shouldRegisterWallet || shouldActivateWallet);
  const [fetchIssuanceData, { data: issuanceData }] =
    useDepositPageIssuanceDataLazyQuery();
  const [fetchRedemptionData, { data: redemptionData }] =
    useDepositPageRedemptionDataLazyQuery();

  const { tokens } = (issuanceData || {}) as DepositPageIssuanceDataQuery;
  const { client } = (redemptionData || {}) as DepositPageRedemptionDataQuery;

  React.useEffect(() => {
    if (canClientIssue) {
      fetchIssuanceData();
    }
    if (canClientRedeem) {
      fetchRedemptionData({
        variables: {
          network: BlockchainNetwork.Ethereum
        }
      });
    }
  }, [canClientIssue, canClientRedeem]);


  return (
    <Box
      sx={{
        gap: '3rem',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {canClientIssue && (
        <Box>
          <PageHeader
            title="Issuing Tokens"
            subtitle={
              'Sending stablecoins to the addresses below initiates an issuance ' +
              'process. For each asset to be issued, a different deposit address ' +
              'is provided. Ensure you copy and use the correct address for the ' +
              'asset you wish to issue.'
            }
            action={{
              label: 'Issue Tokens',
              onClick: () => navigate('/issue')
            }}
          />

          <ContentCard
            sx={{
              overflowX: 'auto'
            }}
          >
            {tokens && (
              Object.values(tokens?.reduce((acc, token) => {
                token.deployments.forEach((deployment) => {
                  const address = deployment.sweepingWallet.address;

                  acc[address] = acc[address] || {
                    ...token,
                    address,
                    deployments: []
                  };

                  acc[address].deployments.push(deployment);
                });
                return acc;
              }, {} as Record<string, any>)).map(({
                deployments,
                ...rest
              }) => ({
                ...rest,
                deployments
              }))
                .map((d) => {
                  return (
                    <DepositToken
                      key={d.symbol}
                      name={d.name}
                      symbol={d.symbol}
                      address={d.address}
                      availableNetworks={d.deployments.map((x: any) => x.network)}
                      stablecoins={
                        [...new Set(
                          d.deployments
                            .flatMap((d: any) => (
                              d.stablecoinConfigs
                                .map((s: any) => s.symbol)
                            ))
                        )] as string[]
                      }
                    />
                  );
                })
            )}
          </ContentCard>
        </Box>
      )}

      {canClientRedeem && (
        <Box>
          <PageHeader
            title="Redeeming Tokens"
            subtitle={
              'Sending Backed issued tokens to the address below initiates a ' +
              'redemption process. Depending on your payout method, you will ' +
              'either receive stablecoin in your verified wallet, or your ' +
              'currency of choice if you have selected a bank account in your ' +
              'transaction settings. For addresses on other chains please go to' +
              'the redeem page'
            }
            action={{
              label: 'Redeem Tokens',
              onClick: () => navigate('/redeem')
            }}
          />

          <ContentCard
            sx={{
              overflowX: 'auto'
            }}
          >
            {client?.redemptionWallet?.address && (
              <DepositToken
                address={client.redemptionWallet?.address}
                availableNetworks={[
                  BlockchainNetwork.Ethereum
                ]}
              />
            )}
          </ContentCard>
        </Box>
      )}

      {!canClientIssue && !canClientRedeem && (
        <ContentCard
          sx={{
            display: 'flex',
            height: '192px',
            padding: '2rem',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#848a98',
            fontSize: 16
          }}
        >
          Your deposit addresses will be available when the account is
          activated.
        </ContentCard>
      )}

      <Box
        sx={{
          gap: '1rem',
          display: 'flex',
          padding: '2rem',
          color: '#848a98',
          backgroundColor: '#e2e5eb',
          borderRadius: '0.5rem'
        }}
      >
        <InfoOutlinedIcon fontSize="small" />
        <Typography
          sx={{
            fontSize: 12
          }}
        >
          Your on-chain transactions are subject to blockchain network fees
          which are your sole responsibility; check the transaction cost before
          confirming. Orders that do not meet the minimum order size of
          {SharedConfiguration.limits.minimum.display} will be reverted,
          funds returned to sender, and a fee of CHF 50.00 will be charged
          to cover operational costs.
        </Typography>
      </Box>
    </Box>
  );
};
