import React from 'react';
import { useSearchParams } from 'react-router-dom';

export const usePaginationModel = (isDisabled?: boolean) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const [paginationModel, setPaginationModel] = React.useState({
    page: searchParams.has('page') ? Number(searchParams.get('page')) : 0,
    pageSize: searchParams.has('pageSize') ? Number(searchParams.get('pageSize')) : 10
  });

  const onPaginationModelChange = (newModel: typeof paginationModel, details: any) => {
    if (details.reason) { // Does not work in datagrid v6.17.1+ due to https://github.com/mui/mui-x/issues/12897
      setSearchParams((prev) => {
        prev.set('page', newModel.page.toString());
        prev.set('pageSize', newModel.pageSize.toString());

        return prev;
      });

      setPaginationModel(newModel);
    }
  };


  return isDisabled ? {
    isDisabled,
    hideFooter: true,
    paginationVariables: {
      page: 0,
      pageSize: 25
    }
  } : {
    isDisabled,
    hideFooter: false,
    paginationMode: 'server' as const,

    pageSizeOptions: [
      10,
      25,
      50
    ],

    paginationModel,
    onPaginationModelChange,

    paginationVariables: paginationModel
  };
};
