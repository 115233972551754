import React from 'react';
import { Box, Button, Typography } from '@mui/material';

import RejectedIcon from '@mui/icons-material/DoNotDisturbRounded';
import { useToggle } from '@backed-fi/hooks';
import { ContactForm } from '@backed-fi/app/src/app/components/ContactForm';
import { useLocation } from 'react-router-dom';

export const RejectedPage = () => {
  const contactUs = useToggle();
  const { state } = useLocation();

  return (
    <Box>
      {!contactUs.open && (
        <React.Fragment>
          <Box
            sx={{
              gap: '.5rem',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <RejectedIcon
              sx={{
                fontSize: 32,
                color: 'primary.textLowContrast'
              }}
            />

            <Typography
              variant="title"
            >
              {state?.from === 'questionnaire'
                ? 'APPLICATION DECLINED'
                : 'Account Closed'
              }
            </Typography>
          </Box>

          <Typography
            sx={{
              mt: '1rem',
              mb: '2rem',
              fontWeight: 400
            }}
          >
            {state?.from === 'questionnaire'
              ? 'We are unable to approve your application at this time. Contact us for further information ' +
              'if you think this needs to be corrected or to understand why.'
              : 'Your account with Backed Assets has been closed. Please feel free to contact us if you ' +
              'would like to reopen your account or if you have any further questions.'
            }
          </Typography>

          <Box>
            <Button
              onClick={contactUs.setTrue}
              variant="publicDashboard"
            >
              Contact us
            </Button>
          </Box>
        </React.Fragment>
      )}

      {contactUs.open && (
        <ContactForm
          title
          context="Rejected Client"
        />
      )}
    </Box>
  );
};
