import React from 'react';
import { countries } from 'countries-list';
import { Autocomplete, TextField } from '@mui/material';

import {
  Country,
  SystemConfigurationKey,
  useSystemConfigurationQuery,
} from '@backed-fi/graphql';

interface Props {
  onlyFlags?: true;
  handleCountryChange: (country: Country) => any;
}

export const CountryField: React.FC<
  React.ComponentProps<typeof TextField> & Props
> = (props) => {
  const { data } = useSystemConfigurationQuery({
    variables: {
      key: SystemConfigurationKey.JurisdictionsAllowed,
    },
  });

  return (
    <Autocomplete
      fullWidth
      autoSelect
      disableClearable
      onChange={(change, data) => {
        props.handleCountryChange(data as Country);
      }}
      options={
        data?.systemConfiguration
          ? Object.values(Country).filter((c) =>
              data?.systemConfiguration?.value?.includes(c)
            )
          : ['Loading' as any]
      }
      getOptionLabel={(country: Country) =>
        `${countries[country as keyof typeof countries].emoji} ${
          !props.onlyFlags ? countries[country as keyof typeof countries].name : ''
        }`
      }
      renderInput={(params) => <TextField {...params} {...props} />}
    />
  );
};
