import { styled } from '@mui/material';

export const Shortcut = styled('span')(({ theme }) => {
  const { primary } = theme.palette;

  return {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 700,
    marginLeft: theme.spacing(0.5),
    border: `1px solid ${primary.borderElement}`,
    backgroundColor: primary.elementBackground,
    padding: theme.spacing(0, 0.8),
    paddingTop: '0.1rem',
    borderRadius: 5,
  };
});
