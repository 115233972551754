import React from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { Box, Button, IconButton, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { ContentCard } from '../../../components/ContentCard';
import { InteractionStatus } from '@backed-fi/graphql';

interface Props extends React.PropsWithChildren {
  status: InteractionStatus;
  symbol?: string;
  type: string;
  id: string;
}

export const InteractionDetailsCard: React.FC<Props> = ({
  status,
  symbol,
  type,
  id,
  children
}) => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const isTransactionPending = status !== InteractionStatus.Completed && status !== InteractionStatus.Failed;

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
      }}
    >
      <Box
        src="/assets/images/backgrounds/abstract-lines-dashboard.svg"
        component="img"
        width={960}
        height={464}
        sx={{
          position: 'absolute',
          maxWidth: '1280px',
          top: '-10rem',
          width: '100%',
          height: 'auto'
        }}
      />

      <ContentCard
        sx={{
          position: 'relative',
          display: 'flex',
          width: '100%',
          maxWidth: '480px',
          flexDirection: 'column',
          padding: '2rem',
          gap: '2rem'
        }}
      >
        <Box
          src={`/assets/icons/assets/${symbol ?? 'placeholder'}.svg`}
          component="img"
          width={64}
          height={64}
          sx={{
            position: 'absolute',
            top: '0',
            left: '50%',
            translate: '-50% -50%'
          }}
        />

        <Box
          sx={{
            textAlign: 'center',
            marginTop: '1rem'
          }}
        >
          <Typography
            component="h2"
            sx={{
              fontWeight: 400,
              fontSize: 28
            }}
          >
            <Box
              component="span"
              fontWeight={
                symbol
                  ? 700
                  : 'inherit'
              }
            >
              {symbol ?? 'Token'}
            </Box> {type}
          </Typography>

          <Typography
            sx={{
              display: 'flex',
              gap: '0.125rem',
              height: '24px',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#848a98',
              fontSize: 14
            }}
          >
            {id}

            <IconButton
              color="inherit"
              onClick={() => {
                navigator.clipboard.writeText(id).then(() => {
                  snackbar.enqueueSnackbar('Copied transaction reference ID to clipboard', {
                    variant: 'success'
                  });
                });
              }}
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Typography>
        </Box>

        <Box
          sx={{
            gap: '1rem',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr'
          }}
        >
          {children}
        </Box>

        <Button
          onClick={() => {
            navigate(
              isTransactionPending
                ? '/interactions/pending'
                : '/interactions/history'
            );
          }}
          variant="publicDashboard"
          fullWidth
        >
          Go Back
        </Button>
      </ContentCard>
    </Box>
  );
};
