import * as React from 'react';
import { forwardRef, Ref, SVGProps } from 'react';

const SvgApi = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    data-name='api icon'
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 16 16'
    ref={ref}
    {...props}
  >
    <defs>
      <clipPath id='Api_svg__a'>
        <path
          data-name='Rectangle 27'
          fill='#01000d'
          stroke='#01000d'
          strokeWidth={0.5}
          d='M0 0h16v16H0z'
        />
      </clipPath>
    </defs>
    <g data-name='Group 323' clipPath='url(#Api_svg__a)'>
      <path
        data-name='Path 175'
        d='M13.33 2.67a4.513 4.513 0 0 0-7.659 2.558 4.557 4.557 0 0 0 .358 2.5l-4.681 4.678v2.242h2.246l.726-.726-.006-.822.834-.002.892-.892v-.742l.749-.008L8.275 9.97a4.556 4.556 0 0 0 2.5.358 4.513 4.513 0 0 0 3.873-4.468 4.483 4.483 0 0 0-1.318-3.19Zm-.551 5.83a3.712 3.712 0 0 1-4.4.654l-.254-.136-1.662 1.661-1.2.012v1.191l-.438.438-1.293.009.009 1.275-.268.268H2.127v-1.143l4.855-4.855-.134-.255a3.733 3.733 0 1 1 5.933.881'
        fill='#01000d'
        stroke='#01000d'
        strokeWidth={0.5}
      />
    </g>
    <g
      data-name='Ellipse 2'
      transform='translate(9 3)'
      fill='none'
      stroke='#01000d'
      strokeWidth={1.2}
    >
      <circle cx={2} cy={2} r={2} stroke='none' />
      <circle cx={2} cy={2} r={1.4} />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgApi);
export default ForwardRef;
