import React from 'react';

import { Box, Modal, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { SharedConfiguration } from '@backed-fi/shared/config/shared';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const slides = [
  {
    image: '/assets/images/onboarding/how-backed-works.svg',
    title: 'How Backed Works',
    content: (
      <>
        Backed is blockchain-based, and actions are triggered by sending either stablecoins or bTokens from an
        external wallet. Once our system picks up a transaction and processes it, the resulting asset will
        be sent to the wallet that initiated the transaction.
      </>
    )
  },
  {
    image: '/assets/images/onboarding/supported-networks.svg',
    title: 'Multichain Support',
    content: (
      <>
        Backed tokens can be issued on multiple blockchain networks. We currently support Ethereum,
        Polygon, and Gnosis Chain and are continuously expanding our network integrations.
      </>
    )
  },
  {
    image: '/assets/images/onboarding/register-wallet.svg',
    title: 'Register a Wallet',
    content: (
      <>
        To issue and redeem on Backed, you must register your external wallet in the account settings. Your
        wallet address will be used to identify incoming transactions and deliver the resulting transaction
        asset.
      </>
    )
  },
  {
    image: '/assets/images/onboarding/issuance-redemption.svg',
    title: 'Issuance and Redemption',
    content: (
      <>
        Interacting with the Backed platform works by sending stablecoin to an issuance address or bTokens to the
        redemption address. Please always ensure to send assets to the correct address to avoid the loss of
        funds.
      </>
    )
  },
  {
    image: '/assets/images/onboarding/transaction-fees.svg',
    title: 'Transaction Fees',
    content: (
      <>
        Orders on Backed are subject to a 0.2% transaction fee (CHF 100 minimum), with a minimum order size
        of {SharedConfiguration.limits.minimum.display}. During the platform beta, the transaction fee minimum will not apply
      </>
    )
  },
  {
    image: '/assets/images/onboarding/execution-times.svg',
    title: 'Execution Times',
    content: (
      <>
        Issuances will be completed by the end of the following business day (T+1) and redemptions by the
        end of the third business day (T+3). Whenever possible, we will use available working capital to
        complete the transaction on a shorter timeline.
      </>
    )
  }
];

export const OnboardingTourDialog: React.FC<Props> = ({
  isOpen,
  onClose
}) => {
  const [activeSlide, setActiveSlide] = React.useState(0);

  const sliderContainerStyles = {
    display: 'flex',
    alignItems: 'flex-start',
    transition: 'transform 0.25s',
    width: slides.length * 100 + '%',
    transform: `translateX(-${activeSlide * (100 / slides.length)}%)`
  };

  return (
    <Modal
      open={isOpen}
      sx={{
        overflowY: 'auto',
        display: 'flex',
        padding: '2rem'
      }}
      disableEnforceFocus
      disableAutoFocus
    >
      <Box
        sx={{
          width: '100%',
          margin: 'auto',
          maxWidth: '512px'
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: '0.5rem',
            boxShadow: 24
          }}
        >
          <Box
            sx={{
              overflow: 'hidden',
              aspectRatio: '32 / 25',
              backgroundColor: '#0066ff',
              borderTopLeftRadius: '0.5rem',
              borderTopRightRadius: '0.5rem'
            }}
          >
            <Box sx={sliderContainerStyles}>
              {slides.map((slide, index) => (
                <Box
                  key={index}
                  src={slide.image}
                  component="img"
                  width={512}
                  height={400}
                />
              ))}
            </Box>
          </Box>

          <Box sx={{ overflow: 'hidden' }}>
            <Box sx={sliderContainerStyles}>
              {slides.map((slide, index) => (
                <Box
                  key={index}
                  sx={{
                    width: '100%',
                    padding: '2rem 2rem 1.5rem'
                  }}
                >
                  <Typography variant="modalTitle">
                    {slide.title}
                  </Typography>

                  <Typography
                    sx={{ marginTop: '1rem' }}
                    variant="modalSubtitle"
                  >
                    {slide.content}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              userSelect: 'none',
              padding: '0 2rem 2rem',
              justifyContent: 'space-between',
              borderBottomLeftRadius: '0.5rem',
              borderBottomRightRadius: '0.5rem'
            }}
          >
            <Box
              sx={{
                gap: '0.5rem',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {slides.map((slide, index) => (
                <Box
                  onClick={() => setActiveSlide(index)}
                  title={slide.title}
                  key={index}
                  sx={{
                    position: 'relative',
                    width: '0.5rem',
                    height: '0.5rem',
                    cursor: 'pointer',
                    transition: 'all 0.25s',
                    backgroundColor: '#e2e5eb',
                    borderRadius: '0.25rem',

                    ...(index === activeSlide && {
                      backgroundColor: '#0066ff',
                      width: '1.5rem'
                    }),

                    '&::before': {
                      position: 'absolute',
                      inset: '-0.25rem',
                      content: '""'
                    }
                  }}
                />
              ))}
            </Box>

            <Typography
              onClick={() => {
                if (activeSlide < slides.length - 1) {
                  setActiveSlide((activeSlide) => activeSlide + 1);
                } else {
                  onClose();
                }
              }}
              sx={{
                gap: '0.25rem',
                display: 'flex',
                cursor: 'pointer',
                alignItems: 'center',
                color: '#0066ff',
                fontWeight: 600,
                fontSize: 14
              }}
            >
              {activeSlide < slides.length - 1 ? 'Continue' : 'Get Started'}
              <ArrowForwardIcon fontSize="small" />
            </Typography>
          </Box>
        </Box>

        <Typography
          sx={{
            marginTop: '1rem',
            textAlign: 'center',
            color: '#848a98',
            fontSize: 14
          }}
        >
          Ready to get started?{' '}
          <Box
            onClick={() => onClose()}
            component="span"
            sx={{
              textDecoration: 'underline',
              cursor: 'pointer'
            }}
          >
            Skip the tour
          </Box>
        </Typography>
      </Box>
    </Modal>
  );
};
