import React from 'react';

import { z } from 'zod';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Alert, Box, Card, CircularProgress, TextField, Typography } from '@mui/material';

import { config } from '@backed-fi/config';
import { DomainRestricted, Logo } from '@backed-fi/primitives';
import { useOneTimeAuthentication } from '../../authentication/oneTimeCodeAuthentication';
import { useUrlQuery } from '@backed-fi/hooks';
import { useSnackbar } from 'notistack';


// region Form Schema

const FormSchema = z.object({
  email: z.string()
    .email()
    .nonempty()
});

// endregion

export const AuthenticatePage = () => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const query = useUrlQuery();

  const {
    startEmailChallenge,
    authenticateWithEmailChallenge,
    loading
  } = useOneTimeAuthentication();

  // region State

  const [emailRequested, setEmailRequested] = React.useState(false);
  const [authenticating, setAuthenticating] = React.useState(false);

  // endregion

  // region Form Control

  const {
    formState,
    ...form
  } = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema)
  });

  const { errors } = formState;

  // endregion

  // region Effects

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        query.has('challengeId') &&
        query.has('privateCode')
      ) {
        setAuthenticating(true);

        authenticateWithEmailChallenge(
          query.get('challengeId')!,
          query.get('privateCode')!
        )
          .then((result) => {
            setTimeout(() => {
              navigate(result.redirectTo ?? '/');
            }, 1000);
          })
          .catch((e) => {
            console.error(e);

            snackbar.enqueueSnackbar('Something went wrong. Please try again', {
              variant: 'error'
            });
          })
          .finally(() => {
            setTimeout(() => {
              setAuthenticating(false);
            }, 1000);
          });
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  // endregion

  // region Actions

  const onStartEmailChallenge = form.handleSubmit(async (data) => {
    try {
      await startEmailChallenge(data.email);
    } catch (e: any) {
      snackbar.enqueueSnackbar(e.message || 'Something went wrong. Please try again', {
        variant: 'error'
      });

      return;
    };


    setEmailRequested(true);
  });

  // endregion

  return (
    <Box
      sx={{
        backgroundColor: 'grey.100',
        height: '100vh',
        width: '100wv',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          width: '100vw',
          maxWidth: '500px'
        }}
      >
        <DomainRestricted dev local stage sandbox>
          <Alert
            variant='outlined'
            severity='warning'
            sx={{
              mb: '1rem',
              width: '100%'
            }}
          >
            {config.isDev && 'Development Environment'}
            {config.isStage && 'Staging Environment'}
          </Alert>
        </DomainRestricted>

        <Card>
          <Box
            sx={{
              width: 'calc(100% - .5rem)',
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '4rem .25rem',
              marginBottom: '1rem'
            }}
          >
            <Logo />

            {!emailRequested && (
              <Typography variant='title'>Welcome to Backed.fi</Typography>
            )}

            <Box
              sx={{
                width: '100%',
                padding: '1rem',
                my: '1rem'
              }}
            >
              {(authenticating) && (
                <Box
                  sx={{
                    textAlign: 'center',
                    gap: '8px',
                    display: 'flex',
                    flexFlow: 'column',
                    alignItems: 'center'
                  }}
                >
                  <CircularProgress />

                  <Typography>
                    Signing you in
                  </Typography>
                </Box>
              )}

              {!authenticating && (
                <React.Fragment>
                  {emailRequested ? (
                    <React.Fragment>
                      <Typography
                        align='center'
                        variant='titleSmall'
                      >
                        Check your email
                      </Typography>

                      <Typography
                        align='center'
                        variant='subtitleSmall'
                      >
                        We have sent a temporary login link. Please check you email at {form.getValues('email')}
                      </Typography>
                    </React.Fragment>
                  ) : (
                    <Box component='form'>
                      <TextField
                        fullWidth
                        label='Email Address'
                        sx={{ flex: 1 }}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        {...form.register('email')}
                      />

                      <LoadingButton
                        fullWidth
                        type='submit'
                        variant='contained'
                        loading={loading}
                        onClick={onStartEmailChallenge}
                        onSubmit={onStartEmailChallenge}
                      >
                        Continue
                      </LoadingButton>
                    </Box>
                  )}
                </React.Fragment>
              )}
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};
