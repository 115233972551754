import React from 'react';

import { Box, Button, Typography } from '@mui/material';
import { MiniChart } from 'react-ts-tradingview-widgets';
import { useNavigate } from 'react-router-dom';

interface Props {
  name: string;
  symbol: string;
  tradingViewId: string;
  isButtonDisabled: boolean;
}

export const AssetCard: React.FC<Props> = ({ name, symbol, tradingViewId, isButtonDisabled }) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        boxShadow: '0 0.125rem 0.25rem #e2e5eb',
        backgroundColor: 'white',
        borderRadius: '0.5rem'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '1.5rem 2rem',
          gap: '1rem'
        }}
      >
        <Box
          src={`/assets/icons/assets/${symbol}.svg`}
          component='img'
          width={64}
          height={64}
        />

        <Box
          sx={{
            overflow: 'hidden'
          }}
        >
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 700
            }}
          >
            {symbol}
          </Typography>

          <Typography
            noWrap
            sx={{
              fontSize: 14,
              color: '#848a98'
            }}
          >
            {name}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          padding: '1rem',
          borderTop: 1,
          borderBottom: 1,
          borderColor: '#edeff4'
        }}
      >
        <MiniChart
          symbol={tradingViewId}
          trendLineColor='#0066ff'
          underLineColor='#0066ff40'
          width='100%'
          height={192}
          isTransparent
        />
      </Box>

      <Box
        sx={{
          padding: '2rem'
        }}
      >
        <Button
          onClick={() => navigate(`/issue?token=${symbol}`)}
          variant='publicDashboardGhost'
          disabled={isButtonDisabled}
          fullWidth
        >
          Issue {symbol}
        </Button>
      </Box>
    </Box>
  );
};
