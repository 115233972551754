import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';
import { Application } from '@backed-fi/constants';

export const createMuiSwitch = (
  application: Application
): Components<Theme>['MuiSwitch'] => ({
  styleOverrides: {
    root: ({ theme }) => {
      const { primary } = theme.palette;

      return {
        '.Mui-checked': {
          color: primary.solidBackground,

          '&:hover': {
            backgroundColor: primary.backgroundSubtle,
          },
        },

        '.Mui-checked + .MuiSwitch-track': {
          backgroundColor: primary.elementBackgroundActive,
        },
      };
    },
    track: ({ theme, ownerState }) => ({
      opacity: 'unset !important',
      backgroundColor: theme.palette.utility.solidBackground,

      ...(ownerState.checked && {
        backgroundColor: `${theme.palette.primary.elementBackground}`
      })
    }),

    thumb: ({ theme, ownerState }) => ({
      opacity: 'unset',
      backgroundColor: theme.palette.primary.backgroundSubtle,

      ...(ownerState.checked && {
        backgroundColor: theme.palette.primary.textLowContrast
      }),
    }),
  },
});
